import { useQuery } from "@tanstack/react-query"
import { IPoint } from "../../../../hooks/react-query/usePatrimonyChart";
import api from "../../../../services/api"

interface IStockProfile {
  image_url: string
  name: string
  complete_name: string
}

export const useStockProfile = (stock_id: string) => {
  const query = useQuery(['stock-profile', stock_id], async () => {
    const response = await api.get<IStockProfile>(`/stocks/get-profile/${stock_id}`)

    return response.data
  })

  return query
}
