import styled, { css } from 'styled-components';
import Button from '../../../../../../components/Button';

export const Container = styled.div`
${({ theme }) => css`
  margin-top: auto;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
`}
`;

export const Steps = styled.div`
${({ theme }) => css`
  display: flex;
  align-items: center;

  padding: 0.8rem;
  border-radius: 1.6rem;

  background: ${theme.colors.lightestGrey};

  > div {
    display: flex;
    align-items: center;
  }
`}
`;

export const ButtonsContainer = styled.div`
${({ theme }) => css`
  display: flex;
`}
`;

export const StyledBackButton = styled(Button)`
${({ theme }) => css`
  margin-right: 0.8rem;
  background: ${theme.colors.lightestGrey};
  color: ${theme.colors.white};
  padding: 1.6rem;

  font-weight: bold;
`}
`;

export const StyledNextButton = styled(Button)`
${({ theme }) => css`
  margin-right: 0.8rem;
  background: ${theme.colors.success};
  color: ${theme.colors.white};
  padding: 1.6rem;

  font-weight: bold;
`}
`;

interface StepProps {
  isFinalStep?: boolean
  isCurrentStep?: boolean;
}

export const Step = styled.div<StepProps>`
${({ theme, isFinalStep, isCurrentStep }) => css`
  padding: 1rem 3.2rem;
  border-radius: 1.6rem;

  ${isFinalStep && css`
    padding: 1rem 2.8rem;
  `}

  border: solid 0.2rem ${theme.colors.extremeLightGrey};
  color: ${theme.colors.extremeLightGrey};

  ${isCurrentStep && css`
    border: 0;
    background: ${theme.colors.success};
    color: ${theme.colors.white};
    font-weight: bold;
  `}
`}
`;

export const StepDivider = styled.div<StepProps>`
${({ theme, isCurrentStep }) => css`
  height: 0.2rem;
  width: 1.6rem;
  background: ${theme.colors.extremeLightGrey};

  ${isCurrentStep && css`
    border: solid 0.1rem ${theme.colors.success};
    color: ${theme.colors.success};
  `}
`}
`;
