import React from 'react';
import { useTheme } from 'styled-components';

interface WlltIconProps {
  color?: string;
  width?: number;
  height?: number;
}

const WlltIcon: React.FC<WlltIconProps> = ({
  color,
  width = 21,
  height = 24
}) => {
  const theme = useTheme();

  return (
    <svg width={width} height={height} viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.0817 8.10744V3.14495L13.8338 5.00028V0L2.01102 6.75384C1.5923 6.99308 1.24219 7.33906 0.997692 7.75564C0.807904 8.08085 0.689655 8.44137 0.646311 8.81185H0.626953V18.1134C0.626953 19.1048 1.14666 19.992 2.01775 20.4862C2.45035 20.7317 2.92376 20.8546 3.39844 20.8546C3.55751 20.8546 3.717 20.8393 3.87481 20.8119V21.2583C3.87481 22.2498 4.39451 23.1365 5.26518 23.6312C5.6982 23.8771 6.17288 24 6.64756 24C7.12687 24 7.60702 23.8746 8.04425 23.6237L18.7725 17.3953C19.6234 16.9064 20.1524 15.9993 20.1524 15.0282V6.32687L17.0817 8.10744ZM5.21805 11.4538C5.29169 11.3276 5.38133 11.2129 5.48443 11.1128L5.49705 11.1008C5.593 11.0073 5.70199 10.9255 5.8215 10.8574L15.9598 5.0659V8.75869L7.47825 13.6776C6.96149 13.9741 6.34331 13.9749 5.82487 13.6805C5.30642 13.386 4.99712 12.8581 4.99712 12.2679C4.99712 11.9834 5.07371 11.7018 5.21805 11.4538ZM2.57365 7.71245L12.7119 1.92095V5.64156L5.25971 9.89796C5.13221 9.97065 5.01396 10.0562 4.90033 10.1476L4.2304 10.5318C3.71364 10.8283 3.09546 10.83 2.57743 10.5355C2.05899 10.241 1.74969 9.71314 1.74969 9.12294C1.74969 8.8376 1.82628 8.55559 1.96978 8.30929C2.11496 8.06133 2.3241 7.85491 2.57365 7.71245ZM2.57743 19.526C2.05899 19.2315 1.74969 18.7036 1.74969 18.1134V11.3217C1.83511 11.3836 1.92391 11.4426 2.01775 11.4958C2.45119 11.7421 2.92629 11.865 3.40097 11.865C3.5735 11.865 3.74646 11.848 3.91689 11.8156C3.90889 11.8625 3.89837 11.909 3.8929 11.9564H3.87481V19.673C3.44515 19.8026 2.98142 19.7548 2.57743 19.526ZM19.0305 15.0274C19.0305 15.606 18.7157 16.1459 18.2061 16.4383L7.47825 22.6668C6.96149 22.9633 6.34331 22.965 5.82529 22.6705C5.30684 22.376 4.99754 21.8481 4.99754 21.2579V14.4684C5.08255 14.5303 5.17218 14.5876 5.2656 14.6407C6.13627 15.1354 7.17442 15.1333 8.04509 14.6341L19.0305 8.26236V15.0274Z" fill={color || theme.colors.primary}/>
    </svg>
  )
}

export default WlltIcon;
