import React from 'react';
import { connect } from 'react-redux';

import { IState } from '../../../../../store';
import { IWallet } from '../../../../../store/modules/wallet/types';

import { animations } from '../../../../../resources';

import { ITesouroWallet } from '../../../../../store/modules/tesouroWallet/types';

import {
  Container,
  Row,
  WalletValue,
  ActiveList,
  TodayVariation,
} from './styles';

import Currency from '../../../../../components/Currency';
import Percentage from '../../../../../components/Percentage';
import { ImArrowDownRight2, ImArrowUpRight2 } from 'react-icons/im';
import { useTheme } from 'styled-components';
import Tooltip from '../../../../../components/Tooltip';

interface WalletsSummaryListProps {
  onClick(): void;
  animation: any;
  today_variation?: number;
  total_current_value: number;
  total_wallet_value: number;
  name: string;
  dollar?: boolean;
  profit: number;
  profit_in_percentage: number;
  infos: {
    id: string;
    name: string;
    profit_in_percentage: number;
  }[]
}

const WalletsSummaryList: React.FC<WalletsSummaryListProps> = ({
  total_current_value,
  total_wallet_value,
  today_variation,
  name,
  profit,
  profit_in_percentage,
  infos,
  animation,
  onClick,
  dollar,
}) =>  {
  const theme = useTheme();

  return (
    <Container
      variants={animation}
      whileHover={{ y: -2 }}
      onClick={onClick}
    >
      <h1>{name}</h1>

      <Row
        profit={profit}
      >
        <div>
          <Currency
            dollar={dollar}
            value={profit}
          />

          <Percentage
            value={profit_in_percentage}
          />
        </div>

        {(!!today_variation || today_variation === 0) && (
          <Tooltip
            title="24h"
            color={today_variation > 0 ? theme.colors.success : theme.colors.error}
            textColor={theme.colors.white}
          >
            <TodayVariation
              positive={today_variation > 0}
              negative={today_variation < 0}
            >
              <Percentage
                value={today_variation}
              />
            </TodayVariation>
          </Tooltip>
        )}
      </Row>

      <WalletValue>
        <Currency
          dollar={dollar}
          value={total_current_value}
        />
        {profit > 0 ? (
          <ImArrowUpRight2
            size={32}
            color={theme.colors.success}
            />
            ) : (
          <ImArrowDownRight2
            size={32}
            color={theme.colors.error}
          />
        )}
      </WalletValue>

      <ActiveList>
        {infos.map(info => (
          <div key={info.id}>
            <span>{info.name}</span>
            <Percentage
              color={info.profit_in_percentage > 0 ? theme.colors.success : theme.colors.error}
              value={info.profit_in_percentage}
            />
          </div>
        ))}
      </ActiveList>
    </Container>
  );
};

const mapStateToProps = (state: IState) => ({
  wallets: state.wallet.wallets,
  tesouro_wallets: state.tesouro.tesouro_wallets,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(WalletsSummaryList)
