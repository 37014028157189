import React, { useEffect, useMemo, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { useLocation } from 'react-router-dom';
import { Dispatch } from 'redux';
import Chart from "react-apexcharts";

import { IWallet } from '../../../../../store/modules/wallet/types';
import { IState } from '../../../../../store';

import * as Styled from './style';

import { format } from 'date-fns';
import formatCurrency from '../../../../../utils/formatCurrency';
import { toggleOrderModal } from '../../../../../store/modules/ui/actions';
import { IPatrimonyInfos } from '../../../../../store/modules/profit/types';
import { Box, CardTitle, Loading } from '../../../../../components';
import { WidgetCard } from '../../../../../components/WidgetCard';
import { usePatrimonyChart } from '../../../../../hooks/react-query/usePatrimonyChart';

const PatrimonyChart: React.FC = () => {
  const { data: patrimonyData, isLoading: isLoadingPatrimony } = usePatrimonyChart()
  const [display, setDisplay] = useState(false)

  const location = useLocation();
  const theme = useTheme();

  useEffect(() => {
    const timeout = setTimeout(() => setDisplay(true), 400);
    return () => clearTimeout(timeout);
  }, [])

  const wallet = useSelector<IState, IWallet>(state => {
    const [id] = location.pathname.split('/').reverse();

    const wallet = state.wallet.wallets.find((wallet: any) => wallet.id === id);

    if (!wallet) {
      return state.wallet.wallets[0];
    }

    return wallet;
  });

  const linearChartData = useMemo(() => {
    const wallet_id = wallet.id;
    const wallet_name = wallet.name;

    return [
      {
        name: `${wallet_name} - Valor Investido`,
        data: patrimonyData?.walletsPatrimony[wallet_id]?.invested?.slice(-120) || [],
      },
      {
        name: `${wallet_name} - Patrimônio`,
        data: patrimonyData?.walletsPatrimony[wallet_id]?.patrimony?.slice(-120) || [],
      },
    ];
  }, [wallet, patrimonyData]);

  const linearChartColor = useMemo(() => {
    if (!patrimonyData?.walletsPatrimony[wallet.id]) {
      return [theme.colors.extremeLightGrey, theme.colors.success];
    }

    const lastItemIndex = patrimonyData?.walletsPatrimony[wallet.id].invested.length - 1;

    if (patrimonyData?.walletsPatrimony[wallet.id].invested[lastItemIndex].y > patrimonyData?.walletsPatrimony[wallet.id].patrimony[lastItemIndex].y) {
      return [theme.colors.extremeLightGrey, theme.colors.error];
    }

    return [theme.colors.extremeLightGrey, theme.colors.success];
  }, [patrimonyData, wallet]);

  const shouldRenderChart = useMemo(() => {
    return !!linearChartData[0].data.length;
  }, [linearChartData]);

  if (isLoadingPatrimony || !display) {
    return (
      <WidgetCard w='100%'>
        <CardTitle
          title='Variação do Patrimônio'
          type='secondary'
        />
        <Box w='100%' h={'365px'} alignItems='center' justifyContent='center'>
          <Loading color={theme.colors.primary} size={20} />
        </Box>
      </WidgetCard>
    )
  }

  return (
    <WidgetCard
      w='100%'
      minHeight='40rem'
      title='Variação do Patrimônio'
      withoutContent={!shouldRenderChart}
      withoutContentText={'Diariamente, o patrimônio referente à essa carteira será atualizado e mostrado aqui, para que você consiga acompanhar sua evolução'}
    >
      <Styled.GraphContainer>
        <Chart
          options={{
            stroke: {
              curve: 'smooth',
            },
            colors: linearChartColor,
            fill: {
              type: 'gradient',
              gradient: {
                shade: 'dark',
                opacityFrom: 0.6,
                opacityTo: 0.25,
              }
            },
            dataLabels: {
              enabled: false,
              distributed: true,
              offsetY: -8,
              style: {
                colors: linearChartColor,
              }
            },
            tooltip: {
              enabled: true,
              x: {
                show: true,
                formatter: (x) => `${format(new Date(x), 'dd-MM-yyyy')}`,
              },
              y: {
                formatter: (val) => formatCurrency(val),
                title: {
                  formatter: (seriesName) => seriesName
                }
              },
              marker: {
                show: false,
              }
            },
            chart: {
              id: "linear-chart",
              animations: {
                enabled: false,
              },
              zoom: {
                enabled: true,
              },
              toolbar: {
                show: false,
                offsetX: 0,
                offsetY: 0,
                autoSelected: 'pan',
                tools: {
                  selection: false,
                  pan: false,
                  download: false,
                  zoom: false,
                  zoomin: false,
                  zoomout: false,
                  reset: false,
                },
              },
            },
            legend: {
              offsetY: 8,
              labels: {
                colors: [theme.colors.grey],
              },
              markers: {
                fillColors: linearChartColor
              }
            },
            xaxis: {
              type: 'datetime',
              tooltip: {
                enabled: false,
              },
              axisBorder: {
                show: true,
                color: theme.colors.grey,
              },
              labels: {
                style: {
                  colors: [theme.colors.grey],
                },
                rotate: -45,
              }
            },
            yaxis: {
              seriesName: wallet.name,
              axisBorder: {
                show: false,
              },
              labels: {
                formatter: (val) => formatCurrency(val),
                style: {
                  colors: [theme.colors.grey],
                },
              }
            },
            grid: {
              borderColor: theme.colors.lightGrey,
            }
          }}
          series={linearChartData}
          type="area"
          width="100%"
          height="350"
          animations={{ initialAnimation: { enabled: false }}}
        />
      </Styled.GraphContainer>
    </WidgetCard>
  );
};

const mapStateToProps = (state: IState) => ({})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  toggleOrderModal: () => dispatch(toggleOrderModal()),
})

export default connect(mapStateToProps, mapDispatchToProps)(PatrimonyChart);
