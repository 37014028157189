export enum TesouroWalletActionTypes {
  GET_TESOURO_WALLETS_REQUEST = 'GET_TESOURO_WALLETS_REQUEST',
  GET_TESOURO_WALLETS_SUCCESS = 'GET_TESOURO_WALLETS_SUCCESS',
  GET_TESOURO_WALLETS_FAILURE = 'GET_TESOURO_WALLETS_FAILURE',
}

export interface ITesouroWallet {
  id: string;
  name: string;
  total_wallet_value: number;
  current_wallet_value: number;
  yesterday_wallet_value: number;
  profit: number;
  profit_in_percentage: number;
  tesouros: IWalletTesouro[];
  created_at: Date;
}

export interface IWalletTesouro {
  id: string;
  name: string;
  technical_name: string;
  rentability: number;
  purchase_value: number;
  current_value: number;
  yesterday_value: number;
  profit: number;
  profit_in_percentage: number;
  type: string;
}

export interface ITesouroWalletState {
  tesouro_wallets: ITesouroWallet[];
  isLoadingTesouroWallets: boolean;
}
