import { EarningsByMonth, IPatrimony, IProfit, ProfitActionTypes, ProfitGraph } from "./types";

export function setIsLoadingProfits() {
  return {
    type: ProfitActionTypes.SET_LOADING_PROFIT,
  }
}

export function getProfitsRequest() {
  return {
    type: ProfitActionTypes.GET_PROFITS_REQUEST,
  }
}

export function getProfitsSuccess(profits: IProfit[]) {
  return {
    type: ProfitActionTypes.GET_PROFITS_SUCCESS,
    payload: {
      profits,
    }
  }
}

export function getProfitsFailure(error: string) {
  return {
    type: ProfitActionTypes.GET_PROFITS_FAILURE,
    payload: {
      error,
    }
  }
}

export function getProfitGraphRequest() {
  return {
    type: ProfitActionTypes.GET_PROFIT_GRAPH_REQUEST,
  }
}

export function getProfitGraphSuccess(profitGraph: ProfitGraph) {
  return {
    type: ProfitActionTypes.GET_PROFIT_GRAPH_SUCCESS,
    payload: {
      profitGraph,
    }
  }
}

export function getProfitGraphFailure(error: string) {
  return {
    type: ProfitActionTypes.GET_PROFIT_GRAPH_FAILURE,
    payload: {
      error,
    }
  }
}

export function getDividendMonthlyGraphRequest() {
  return {
    type: ProfitActionTypes.GET_DIVIDEND_MONTHLY_REQUEST,
  }
}

export function getDividendMonthlyGraphSuccess(earningsByMonth: EarningsByMonth) {
  return {
    type: ProfitActionTypes.GET_DIVIDEND_MONTHLY_SUCCESS,
    payload: {
      earningsByMonth,
    }
  }
}

export function getDividendMonthlyGraphFailure(error: string) {
  return {
    type: ProfitActionTypes.GET_DIVIDEND_MONTHLY_FAILURE,
    payload: {
      error,
    }
  }
}
