import styled, { css } from 'styled-components';

import { motion } from 'framer-motion';
import { transparentize } from 'polished';

export const Container = styled(motion.div)`
${({ theme }) => css`
  margin-right: 2.4rem;

  background-color: ${transparentize(0.05, theme.colors.background)};

  backdrop-filter: blur(0.05rem);

  border-radius: 1.6rem 0.4rem 0.8rem 1.6rem;

  padding: 2rem 2.4rem;

  display: flex;
  flex-direction: column;

  align-items: flex-start;

  cursor: pointer;

  > h1 {
    padding: 0.4rem 0.8rem;
    background: ${theme.colors.extremeLightGrey};

    border-radius: 0.8rem;

    color: ${theme.colors.primary};
    font-size: ${theme.fontSizes.huge};

    margin-bottom: 0.8rem;
  }
`}
`;

export const Row = styled.div<{ profit: number }>`
${({ theme, profit }) => css`
  display: flex;
  width: 100%;

  justify-content: space-between;

  margin-bottom: 1.6rem;

  > div:first-child {
    display: flex;

    > h6 {
      padding: 0.2rem 0.8rem;
      ${profit > 0 ? css`
        background: ${theme.colors.success};
        ` : css`
        background: ${theme.colors.error};
      `}

      color: ${theme.colors.white};

      font-size: ${theme.fontSizes.default};

      margin-right: 0.4rem;
      border-radius: 0.4rem;
    }
  }
`}
`;

export const WalletValue = styled.div`
${({ theme }) => css`
  display: flex;
  align-items: center;

  margin-bottom: 2rem;

  > svg {
    margin-left: 1.2rem;
  }

  > h6 {
    font-size: ${theme.fontSizes.medium_large};
    color: ${theme.colors.white};

    > p {
      margin-right: 0.8rem;
    }
  }
`}
`

export const ActiveList = styled.div`
${({ theme }) => css`
  max-height: 12rem;
  width: 100%;

  overflow-y: scroll;

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 0.8rem 0.8rem 0.8rem 0;

    > span {
      font-size: ${theme.fontSizes.default};
      color: ${theme.colors.white};
    }
  }
`}
`

export const TodayVariation = styled.div<{ positive?: boolean; negative?: boolean }>`
${({ theme, positive, negative }) => css`
  display: flex;
  align-items: center;
  justify-content: center;

  > h6 {
    padding: 0.4rem;

    border-radius: 0.4rem;

    border: 1px solid ${theme.colors.grey};
    color: ${theme.colors.grey};

    ${positive && css`
      border: 1px solid ${theme.colors.success};
      color: ${theme.colors.success};
    `}

    ${negative && css`
      color: ${theme.colors.error};
      border: 1px solid ${theme.colors.error};
    `}
  }
`}
`;
