import styled, { css } from 'styled-components';

export const Container = styled.div`
${({ theme }) => css`
  background: ${theme.colors.background};
  border-radius: 1.6rem;
  padding: 5.6rem 4.8rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`}
`
