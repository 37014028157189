import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

export const Container = styled.div`
${({ theme }) => css`
  background: ${transparentize(0.05, theme.colors.background)};
  width: 100%;
  height: 100%;
  padding: 2.4rem;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  border-radius: 1.5rem 0.3rem 1rem 1.5rem;
  margin-right: 2.4rem;

  margin-bottom: 2.4rem;

  > h1:first-child {
    margin-bottom: 1.6rem;
  }
`}
`;
