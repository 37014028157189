import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

export const Container = styled.h6<{ value: number; color?: string; type?: string }>`
${({ theme, color, type, value }) => css`
  display: flex;
  align-items: center;
  color: ${color ?? theme.colors.grey};

  ${type === 'variation' && css`
    color: ${theme.colors.white};
    padding: 0.4rem 1.6rem;
    font-size: ${theme.fontSizes.huge};
    background: ${value > 0 ? theme.colors.success : theme.colors.error};

    border-radius: 0.8rem;
    box-shadow: 3px 2px 9px ${value > 0 ? 'rgba(66, 141, 242, 0.38)' : transparentize(0.62, theme.colors.error)};
    text-align: center;
  `}
`}
`;
