import { PersonalizedWallet, PersonalizedWalletActionTypes } from "./types";

export function getPersonalizedWalletsRequest() {
  return {
    type: PersonalizedWalletActionTypes.GET_PERSONALIZED_WALLETS_REQUEST,
  }
}

export function getPersonalizedWalletsSuccess(personalized_wallets: PersonalizedWallet[]) {
  return {
    type: PersonalizedWalletActionTypes.GET_PERSONALIZED_WALLETS_SUCCESS,
    payload: {
      personalized_wallets,
    }
  }
}

export function getPersonalizedWalletsFailure(error: string) {
  return {
    type: PersonalizedWalletActionTypes.GET_PERSONALIZED_WALLETS_FAILURE,
    payload: {
      error,
    }
  }
}
