import React, { useMemo, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Chart from "react-apexcharts";

import { IState } from '../../../../store';

import CardTitle from '../../../../components/CardTitle';

import {
  Container,
  GraphContainer,
} from './styles';
import formatCurrency from '../../../../utils/formatCurrency';
import { useTheme } from 'styled-components';
import { IProfit, ProfitTypeTranslator } from '../../../../store/modules/profit/types';
import { graphPallete } from '../../../../utils/colors';
import { Box } from '../../../../components/Box';
import { Loading } from '../../../../components';

interface EarningsByWalletGraphProps {
  profits: IProfit[]
}

const EarningsByWalletGraph: React.FC<EarningsByWalletGraphProps> = ({ profits }) => {
  const theme = useTheme();
  const [display, setDisplay] = useState(false);

  useEffect(() => {
    setTimeout(() => setDisplay(true), 150);
  }, [])

  const data = useMemo(() => {
    const data = {} as any;

    profits.forEach(profit => {
      if (data[ProfitTypeTranslator[profit.type]]) {
        data[ProfitTypeTranslator[profit.type]] += profit.profit;
      } else {
        data[ProfitTypeTranslator[profit.type]] = profit.profit;
      }
    });

    return data;
  }, [profits])

  const pieChartData = useMemo(() => {
    return Object.entries(data).map(([key, value]: any) => value);
  }, []);

  const pieChartDataLabels = useMemo(() => {
    return Object.entries(data).map(([key, value]: any) => key);
  }, []);


  if(!display) {
    return (
      <Box h={'50%'} flex={1} alignItems={'center'} justifyContent={'center'}>
        <Loading color={theme.colors.primary} size={16} />
      </Box>
    );
  }

  return (
    <Container>
      <CardTitle
        title='Proventos por Tipo'
        type='secondary'
      />

      <GraphContainer>
        <Chart
          options={{
            colors: graphPallete,
            tooltip: {
              y: {
                formatter: function(val) {
                  return 'R$ ' + formatCurrency(val / 100)
                },
                title: {
                  formatter: function (seriesName) {
                    return seriesName;
                  },
                },
              }
            },
            labels: pieChartDataLabels,
            legend: {
              // floating: true,
              position: 'bottom',
              offsetY: 8,
              height: 36,
              show: true,
              labels: {
                colors: [theme.colors.grey],
              }
            },
            states: {
              active: {
                filter: {
                  type: 'none'
                },
              },
              hover: {
                filter: {
                  type: 'none'
                },
              },
            },
            chart: {
              animations: {
                enabled: false,
                easing: 'easeinout',
                speed: 800,
              },
              events: {
                click: undefined,
                markerClick: undefined,
              }
            },
            stroke: {
              show: false,
            },
            dataLabels: {
              formatter: (val, opt) => {
                return `${Number(val).toFixed(2)} %`;
              },
            },
            plotOptions: {
              pie: {
                expandOnClick: false,
                dataLabels: {
                  offset: -5,
                },
                donut: {
                  background: 'transparent',
                },
              },
            }
          }}
          series={pieChartData}
          type="donut"
          width="100%"
          height="100%"
          animations={{ initialAnimation: { enabled: false }}}
        />
      </GraphContainer>
    </Container>
  )
}

const mapStateToProps = (state: IState) => ({
  profits: state.profit.profits,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(EarningsByWalletGraph)
