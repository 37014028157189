import React from 'react'
import { Null } from '../Null'

interface ShowProps<T> {
  when: T | undefined | null | false
  renderElse?: () => React.ReactElement | null
  children?: React.ReactNode | null
}
/**
 * Declarative show/hide, as opposed to {foo && <Bar />}
 *    <Show when={foo}>
 *      <Bar />
 *    </Show>
 */
export function Show<T>({ when, children, renderElse }: ShowProps<T>) {
  const Else = renderElse ?? (() => <Null />)
  if (!when) return <Else />

  return <>{children}</>
}
