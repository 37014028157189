import React, { memo } from 'react';

import {
  Container,
} from './styles';

import BackgroundImage from '../../../../assets/images/illustrations/background.png';
import { motion } from 'framer-motion';

const animation = {
  unMounted: { width: '0%' },
  mounted: {
    width: '100%'
  }
}

const BackgroundMask: React.FC = () => {
  return (
    <Container>
      <motion.img
        src={BackgroundImage}
        variants={animation}
        initial="unMounted"
        animate="mounted"
        transition={{ duration: 0.9, delay: 0.3, ease: 'easeInOut' }}
      />
    </Container>
  );
};

export default memo(BackgroundMask);
