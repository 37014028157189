import styled, { css }  from 'styled-components';
import { transparentize } from 'polished';

export const LeftHeader = styled.div<{ positiveProfit: boolean }>`
${({ theme, positiveProfit }) => css`
  display: flex;
  align-items: center;

  > h1 {
    font-size: ${theme.fontSizes.medium_large};
    padding: 0rem 1.6rem;

    color: ${theme.colors.primary};
    background: ${theme.colors.background};

    border-radius: 0.9rem 0px 0.9rem 0.9rem;

    margin-right: 1.6rem;
  }

  @media (max-width: 670px) {
    flex-direction: column;
    align-items: flex-start;

    margin-bottom: 1.6rem;

    > h1 {
      margin-right: 0rem;
      font-size: ${theme.fontSizes.large};
    }

    > div {
      margin-bottom: 0.8rem;

      > h6 {
        font-size: ${theme.fontSizes.large};
      }
    }
  }
`}
`;

export const RightHeader = styled.div`
${({ theme }) => css`
  > button {
    background: 0;
    border: 1px solid ${theme.colors.success};

    > svg {
      color: ${theme.colors.success};
    }

    transition: 0.5s;

    &:hover {
      border: 1px solid ${theme.colors.error};
      background: ${transparentize(0.80, theme.colors.error)};

      > svg {
        color: ${theme.colors.error};
      }
    }
  }
`}
`

export const WalletValue = styled.div`
${({ theme }) => css`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-bottom: 1.6rem;

  > div {
    > h6 {
      > p {
        margin-right: 1.6rem;
      }

      color: ${theme.colors.white};
      font-size: 10rem;

      margin-right: 2.4rem;
    }
  }

  @media (max-width: 670px) {
    > div {
      > h6 {
        font-size: ${theme.fontSizes.giant};
      }
    }
  }
`}
`;
