import React, { useState } from 'react';
import { FiArrowRight, FiFileText } from 'react-icons/fi';
import { useTheme } from 'styled-components';
import Currency from '../../../../components/Currency';
import CurrencyInput from '../../../../components/CurrencyInput';
import Percentage from '../../../../components/Percentage';

import {
  Container,
  Header,
  Content,
  MainContent,
  FakeInput,
} from './styles';

type InvestmentStock = {
  isDarkTheme: boolean;
  name: string;
  numberOfPapers: number;
  unitPrice: number;
  newPercentage: number;
  oldPercentage: number;
  newProfit: number;
  oldProfit: number;
  changePrice(name: number): void;
  changePapers(papers: number): void;
}

const InvestmentStock: React.FC<InvestmentStock> = ({
  isDarkTheme = false,
  name,
  numberOfPapers,
  unitPrice,
  changePrice,
  changePapers,
  newPercentage,
  oldPercentage,
  newProfit,
  oldProfit,
}) => {
  const [isInvalid] = useState(false);
  const theme = useTheme();

  const handleChangePrice = (value: string) => {
    let formattedValue = Number(value) * 100;

    if (Number.isNaN(formattedValue)) {
      formattedValue = 0;
    }

    changePrice(formattedValue);
  }

  const handleChangePapers = (e: React.ChangeEvent<HTMLInputElement>) => {
    let formattedValue = Number(e.target.value);

    if (Number.isNaN(formattedValue)) {
      formattedValue = 0;
    }

    changePapers(formattedValue);
  }

  return (
    <Container
      isInvalid={isInvalid}
      isDarkTheme={isDarkTheme}
      hasPapers={!!numberOfPapers}
    >
      <Header>
        <div>
          <FiFileText size={18} />
          <h1>{name}</h1>
        </div>
      </Header>

      <MainContent>
        <Content inputIsGreen>
          <input value={numberOfPapers} onChange={handleChangePapers} />
        </Content>

        <Content>
          <CurrencyInput
            callBack={handleChangePrice}
            defaultValue={`R$ ${String(unitPrice/100).replace('.', ',')}`}
          />
        </Content>

        <Content>
          <FakeInput>
            <Currency
              value={unitPrice * numberOfPapers}
            />
          </FakeInput>
        </Content>

        <Content>
          <Percentage
            value={oldProfit}
          />
          <FiArrowRight size={16} color={theme.colors.grey} />
          <Percentage
            value={newProfit}
          />
        </Content>

        <Content maleft={1.2}>
          <Percentage
            value={oldPercentage}
          />
          <FiArrowRight size={16} color={theme.colors.grey} />
          <Percentage
            value={newPercentage}
            color={theme.colors.success}
          />
        </Content>
      </MainContent>
    </Container>
  );
};

export default InvestmentStock;
