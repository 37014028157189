import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { useTheme } from 'styled-components';
import Chart from "react-apexcharts";

import { IState } from '../../../../store';

import { animations } from '../../../../resources';

import {
  Container,
  GraphContainer,
  NoContentWrapper,
} from './styles';

import { IProfit } from '../../../../store/modules/profit/types';
import { format, isAfter, isEqual } from 'date-fns';
import { getMonthObject } from '../../../../utils/dateUtils';
import formatCurrency from '../../../../utils/formatCurrency';
import getPallete from '../../../../utils/colors';
import Button from '../../../../components/Button';
import { useHistory } from 'react-router-dom';

const containerAnimation = {
  ...animations.containerAnimation,
  mounted: {
    ...animations.containerAnimation.mounted,
    transition: {
      ...animations.containerAnimation.mounted.transition,
      duration: undefined,
      staggerChildren: undefined,
      delay: 0.8,
    }
  }
}

interface MonthVariationProps {
  profits: IProfit[];
}

const MonthVariation: React.FC<MonthVariationProps> = ({ profits }) =>  {
  const theme = useTheme();
  const history = useHistory();

  const labelFormatter = (date: number) => {
    const month = new Date(date).getMonth();
    const year = format(new Date(date), 'yyyy');

    const monthString = getMonthObject(month).monthAbbrev;

    return `${monthString}/${year}`;
  }


  const chartData = useMemo(() => {
    const today = new Date().toISOString();
    const dividends = profits.filter(profit => profit.type !== 'SELL' && profit.created_at < today);

    const monthsList = [] as Date[];
    const monthsLabels = [] as string[];
    const chartSeries = [{
      name: 'Dividendos',
      data: [],
    }] as any;

    dividends.forEach(dividend => {
      const month = new Date(dividend.created_at).getMonth();
      const year = new Date(dividend.created_at).getFullYear();

      const dividendMonthDate = new Date(year, month, 1);

      const isInMonthsList = monthsList.find(div => isEqual(div, dividendMonthDate))

      if (!isInMonthsList) {
        monthsList.push(dividendMonthDate);
        monthsLabels.push(format(dividendMonthDate, 'yyyy-MM-dd'));

        monthsLabels.sort();
        monthsList.sort((a, b)=> {
          if (isAfter(a, b)) {
            return 1;
          }

          return -1;
        });
      }
    });

    monthsList.forEach((monthDate) => {
      const profits = dividends.filter(div => {
        const dividendMonth = new Date(div.created_at).getMonth();
        const dividendYear = new Date(div.created_at).getFullYear();

        const datesMatch = dividendMonth === monthDate.getMonth() && dividendYear === monthDate.getFullYear();

        return datesMatch;
      });

      let totalProfits = 0;
      profits.forEach((profit) => {
        totalProfits += profit.profit;
      })

      chartSeries[0].data.push(totalProfits);
    });

    return {
      chartSeries: [{
        name: 'Dividendos',
        data: chartSeries[0].data.slice(-12),
      }],
      monthsList: monthsList.slice(-12),
      monthsLabels: monthsLabels.slice(-12),
    }
  }, [profits]);

  const chartColors = useMemo(() => {
    const pallete = getPallete(theme.colors.success, '#fff', chartData.chartSeries[0].data.length)

    return pallete;
  }, [chartData])

  const labelChartColors = useMemo(() => {
    return chartData.monthsLabels.map(() => theme.colors.grey);
  }, [chartData]);

  const chartLegendColor = useMemo(() => {
    return chartData.chartSeries.map(() => theme.colors.grey);
  }, [chartData]);

  const chartIsAvailable = useMemo(() => {
    return !!chartData.chartSeries[0].data.length;
  }, [chartData]);

  const handleGoToCreateWallet = () => {
    history.push('/dashboard/create-wallet');
  }

  return (
    <Container
      variants={containerAnimation}
    >
      <h1>Dividendos Recebidos</h1>

      <GraphContainer>
        {chartIsAvailable && (
          <Chart
            options={{
              states: {
                active: {
                  filter: {
                    type: 'none'
                  }
                },
                hover: {
                  filter: {
                    type: 'none'
                  }
                },
                normal: {
                  filter: {
                    type: 'none',
                  },
                },
              },
              plotOptions: {
                bar: {
                  horizontal: true,
                }
              },
              dataLabels: {
                style: {
                  fontSize: '10px',
                },
                enabled: true,
                formatter: function(val, opt) {
                  return `${val > 1000 ? formatCurrency(Number(val) / 100) : ''}`
                },
                dropShadow: {
                  enabled: true,
                  left: 2,
                  top: 2,
                  opacity: 0.5
                },
              },
              tooltip: {
                theme: 'dark',
                onDatasetHover: {
                  highlightDataSeries: false,
                },
                enabled: true,
                x: {
                  show: true,
                  formatter: labelFormatter,
                },
                y: {
                  formatter: (val) => formatCurrency(Number(val) / 100),
                  title: {
                    formatter: (seriesName) => seriesName
                  }
                },
                marker: {
                  show: true,
                },
              },
              colors: chartColors,
              chart: {
                animations: {
                  enabled: false,
                  speed: 1000,
                  animateGradually: {
                    enabled: false,
                  },
                  dynamicAnimation: {
                    enabled: true,
                    speed: 200,
                  }
                },
                id: "bar-chart",
                type: 'bar',
                stacked: false,
                toolbar: {
                  show: false,
                  offsetX: 0,
                  offsetY: 0,
                  autoSelected: 'pan',
                  tools: {
                    selection: false,
                    pan: false,
                    download: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    reset: false,
                  },
                },
              },
              responsive: [
                {
                  breakpoint: 480,
                  options: {
                    legend: {
                      position: 'bottom',
                      offsetX: -10,
                      offsetY: 0,
                    },
                  },
                },
              ],
              legend: {
                showForZeroSeries: false,
                itemMargin: {
                  horizontal: 8,
                  vertical: 4,
                },
                position: 'right',
                offsetY: -10,
                labels: {
                  colors: chartLegendColor,
                }
              },
              xaxis: {
                tooltip: {
                  enabled: false,
                },
                axisBorder: {
                  show: true,
                  color: theme.colors.grey,
                },
                crosshairs: {
                  show: false,
                },
                categories: chartData.monthsList,
                labels: {
                  rotateAlways: true,
                  formatter: (val) => `${formatCurrency(Number(val) / 100)}`,
                  style: {
                    fontSize: '1rem',
                    colors: labelChartColors,
                  },
                  rotate: 0,
                }
              },
              yaxis: {
                seriesName: 'Dividendos',
                axisBorder: {
                  show: false,
                },
                labels: {
                  formatter: labelFormatter,
                  style: {
                    colors: labelChartColors,
                  },
                },
              },
              grid: {
                borderColor: theme.colors.lightGrey,
              }
            }}
            series={chartData.chartSeries}
            height="100%"
            type="bar"
          />
        )}

        {!chartIsAvailable && (
          <NoContentWrapper>
            <p>Os destaques de suas carteiras aparecerão aqui quando criá-las!</p>
            <Button
              onClick={handleGoToCreateWallet}
            >
              Criar Carteira
            </Button>
          </NoContentWrapper>
        )}
      </GraphContainer>
    </Container>
  );
};

const mapStateToProps = (state: IState) => ({
  profits: state.profit.profits,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(MonthVariation)

