import React from 'react';
import { FiCloudLightning } from 'react-icons/fi';

import {
  ImportOption,
  ImportOptionContent,
  ImportOptionHeader,
} from './styles';

interface ImportComponentOptionComponentProps {
  icon: any;
  title: string;
  details: string;
  disabled?: boolean;
  onClick(): void;
}

const ImportOptionComponent: React.FC<ImportComponentOptionComponentProps> = ({
  icon,
  title,
  details,
  disabled,
  onClick,
}) => {
  return (
    <ImportOption
      disabled={disabled}
      onClick={onClick}
      whileHover={{ scale: 1.005, transition: { duration: 0.2 } }}
      whileTap={{ scale: 0.995, transition: { duration: 0.2 } }}
    >
      <ImportOptionHeader>
        {!!icon && icon}
        <h3>
          {title}
        </h3>
      </ImportOptionHeader>

      <ImportOptionContent>
        {details}
      </ImportOptionContent>
    </ImportOption>
  )
}

export default ImportOptionComponent;
