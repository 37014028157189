import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { useTable } from 'react-table';
import CardTitle from '../../../../../components/CardTitle';
import Currency from '../../../../../components/Currency';

import { IState } from '../../../../../store';
import { IProfit } from '../../../../../store/modules/profit/types';

import * as Styled from './styles';

interface ActivesTableProps {
  profits: IProfit[];
}

const ActivesTable: React.FC<ActivesTableProps> = ({
  profits,
}) => {
  const data = useMemo(() => {
    const actives = [] as any;

    for (const profit of profits) {
      const active = actives.find((active: any) => active.name === profit.name && active.wallet_name === profit.wallet_name);

      if (active) {
        active.total += profit.profit;
      } else {
        actives.push({
          name: profit.name,
          wallet_name: profit.wallet_name,
          total: profit.profit,
        });
      }
    }

    return actives.sort((a: any, b: any) => b.total - a.total);
  }, [profits]);

  const columns: any = useMemo(() => {
    return (
      [
        {
          Header: 'Código',
          accessor: 'name',
        },
        {
          Header: 'Ativo',
          accessor: 'wallet_name',
        },
        {
          Header: 'Receita',
          accessor: 'total',
        },
      ]
    )
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
  } = useTable(
    {
      columns,
      data,
    }
  )

  return (
    <Styled.Container>
      <Styled.TableWrap className="tableWrap">
        <Styled.Table {...getTableProps()}>
          <Styled.Head>
            {headerGroups.map(headerGroup => (
              <Styled.HeadRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <Styled.HeadData
                    {...column.getHeaderProps({
                      className: column.collapse ? 'collapse' : '',
                    })}
                  >
                    {column.render('Header')}
                  </Styled.HeadData>
                ))}
              </Styled.HeadRow>
            ))}
          </Styled.Head>
          <Styled.TableBody {...getTableBodyProps()}>
            {rows.map((row: any, i: number) => {
              prepareRow(row)
              return (
                <Styled.HeadRow {...row.getRowProps()}>
                  {row.cells.map((cell: any) => {
                    return (
                      <Styled.Content
                        {...cell.getCellProps({
                          className: cell.column.collapse ? 'collapse' : '',
                        })}
                      >
                        {cell.column.Header === 'Código' && (
                          <span><strong>{cell.value}</strong></span>
                        )}

                        {cell.column.Header === 'Ativo' && (
                          <span>{cell.value}</span>
                        )}

                        {cell.column.Header === 'Receita' && (
                          <Currency
                            value={cell.value}
                          />
                        )}
                      </Styled.Content>
                    )
                  })}
                </Styled.HeadRow>
              )
            })}
          </Styled.TableBody>
        </Styled.Table>
      </Styled.TableWrap>
    </Styled.Container>
  )
}

const mapStateToProps = (state: IState) => ({
  profits: state.profit.profits
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ActivesTable)
