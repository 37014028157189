import { Reducer } from "redux";
import { ISideBarState } from "./types";
import produce from 'immer';

const INITIAL_SIDEBAR_STATE = {
  current_page: '',
}

const sideBarNavigator: Reducer<ISideBarState> = (state = INITIAL_SIDEBAR_STATE, action) => {
  return produce(state, draft => {
    switch(action.type) {
      case 'CHANGE_ACTIVE_DROPDOWN': {
        draft.current_page = action.payload.key;

        break;
      }
      default: {
        return draft;
      }
    }
  });
}

export default sideBarNavigator;
