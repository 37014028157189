import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

export const Container = styled.div`
${({ theme }) => css`
  width: 100%;
  overflow-y: scroll;

  padding: 0 0.8rem;
`}
`;

export const MonthContainer = styled.div`
${({ theme }) => css`
  display: flex;
  flex-direction: column;

  & + div {
    margin-top: 2.4rem;
  }


  width: 100%;
`}
`;

export const Heading = styled.div`
${({ theme }) => css`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 1.2rem;

  width: 100%;

  > h1 {
    font-size: ${theme.fontSizes.big};
    color: ${theme.colors.white};
  }

  > h6 {
    font-size: ${theme.fontSizes.big};
    color: ${theme.colors.white};

    > p {
      color: ${theme.colors.grey};
    }
  }
`}
`;

export const Body = styled.div`
${({ theme }) => css`

`}
`;

export const SideBar = styled.div`
${({ theme }) => css`
  margin: 0 1.6rem 0 0.4rem;
  width: 0.2rem;

  background: ${theme.colors.extremeLightGrey};
`}
`;

export const MonthContent = styled.div`
${({ theme }) => css`
  display: flex;
`}
`;

export const DayOfMonthContainer = styled.div`
${({ theme }) => css`
  width: 100%;
  position: relative;
`}
`;

export const DayOfMonthHeading = styled.h2`
${({ theme }) => css`
  font-size: ${theme.fontSizes.large};
  color: ${theme.colors.grey};
  font-weight: normal;

  margin-bottom: 1.6rem;
`}
`;

export const EarningsList = styled.div`
${({ theme }) => css`
  display: flex;
  flex-direction: column;

  width: 100%;
`}
`;

export const DayEarning = styled.div`
${({ theme }) => css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.6rem;

  > h6 {
    font-size: ${theme.fontSizes.default};
    color: ${theme.colors.white};

    > p {
      color: ${theme.colors.grey};
    }
  }
`}
`;

export const DayEarningLabel = styled.div`
${({ theme }) => css`
  color: ${theme.colors.white};
  font-size: ${theme.fontSizes.default};
`}
`;

export const DayOfMonthDot = styled.div`
${({ theme }) => css`
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;

  top: 0.8rem;
  left: -2.3rem;

  position: absolute;

  background: ${theme.colors.lightGrey};
  border: solid 0.1rem ${theme.colors.primary};
`}
`;

