import styled, { css } from 'styled-components';
import { InputTypes } from '.';

export const Container = styled.div<{
  icon?: boolean;
  type: InputTypes;
}>`
${({ theme, icon, type }) => css`
  display: flex;
  flex-direction: column;

  display: flex;

  height: 4.8rem;

  padding: 0.8rem 1.6rem;
  border: 0;
  background: ${theme.colors.lightGrey};

  border: solid 1px ${theme.colors.grey};

  ${type === 'secondary' && css`
    border: 0px;
    padding: 1.2rem 0.8rem;
  `}

  border-radius: 0.8rem;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;

    ${icon && css`
      > svg {
        margin-right: 0.8rem;
      }
    `}

    > input {
      color: ${theme.colors.grey};
      height: 100%;
      width: 100%;

      border: 0;
      background: ${theme.colors.lightGrey};
      font-size: ${theme.fontSizes.big};
    }

    >input::placeholder {
      color: ${theme.colors.grey};
    }
  }

  > label {
    margin-bottom: 0.4rem;
  }

  ${type === 'quaternary' && css`
    border: 0px;
    background: transparent;
    padding: 0rem;

    > div {
      > input {
        background: transparent;
      }
    }

    border-bottom: solid 1px ${theme.colors.grey};
  `}
`}
`;
