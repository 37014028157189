import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'

import './styles/fonts/SpaceGrotesk.css';

import store from './store';
import AppCustomProviders from './contexts';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import shouldOperate from './utils/shouldOperate';
import { ThemeProvider } from 'styled-components';
import dark from './styles/dark';
import App from './App';
import { BrowserRouter } from 'react-router-dom';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchInterval: 30000,
      staleTime: Infinity,
    }
  },
})

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={dark}>
        <Provider store={store}>
          <QueryClientProvider client={queryClient}>
            <AppCustomProviders>
              <App />
            </AppCustomProviders>
          </QueryClientProvider>
        </Provider>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
