function isDescendant(parent: Node, child: Node) {
  var node = child.parentNode;
  while (node != null) {
      if (node == parent) {
          return true;
      }
      node = node.parentNode;
  }
  return false;
}

export default isDescendant;
