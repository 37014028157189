import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import Chart from "react-apexcharts";

import * as Styled from './style';

import { IState } from '../../../../../store';

import { format } from 'date-fns';
import formatCurrency from '../../../../../utils/formatCurrency';
import { useTesouroWallet } from '..';
import { Box, CardTitle } from '../../../../../components';
import { transparentize } from 'polished';
import { usePatrimonyChart } from '../../../../../hooks/react-query/usePatrimonyChart';

export const TesouroPatrimonyValueChart: React.FC = () => {
  const { data: patrimonyData, isLoading: isLoadingPatrimony } = usePatrimonyChart()

  const theme = useTheme();

  const { wallet } = useTesouroWallet()

  const linearChartData = useMemo(() => {
    const wallet_id = wallet.id;
    const wallet_name = wallet.name;

    return [
      {
        name: `${wallet_name} - Valor Investido`,
        data: patrimonyData?.walletsPatrimony[wallet_id]?.invested?.slice(-120) || [],
      },
      {
        name: `${wallet_name} - Patrimônio`,
        data: patrimonyData?.walletsPatrimony[wallet_id]?.patrimony.slice(-120) || [],
      },
    ];
  }, [wallet, patrimonyData]);

  const linearChartColor = useMemo(() => {
    if (!patrimonyData?.walletsPatrimony[wallet.id]) {
      return [theme.colors.extremeLightGrey, theme.colors.success];
    }

    const lastItemIndex = patrimonyData?.walletsPatrimony[wallet.id].invested.length - 1;

    if (patrimonyData?.walletsPatrimony[wallet.id].invested[lastItemIndex].y > patrimonyData?.walletsPatrimony[wallet.id].patrimony[lastItemIndex].y) {
      return [theme.colors.extremeLightGrey, theme.colors.error];
    }

    return [theme.colors.extremeLightGrey, theme.colors.success];
  }, [patrimonyData, wallet]);

  const shouldRenderChart = useMemo(() => {
    return !!linearChartData[0].data.length;
  }, [linearChartData]);

  if (!shouldRenderChart) {
    return null;
  }

  return (
    <Styled.Container>
      <CardTitle title={'Valor da Carteira'} type='secondary' />
      <Box mb={'0.8rem'}/>
      <Styled.GraphContainer>
        <Chart
          options={{
            colors: linearChartColor,
            fill: {
              type: 'gradient',
              gradient: {
                shade: 'dark',
                opacityFrom: 0.6,
                opacityTo: 0.25,
              }
            },
            dataLabels: {
              enabled: false,
              distributed: true,
              offsetY: -8,
              style: {
                colors: linearChartColor,
              }
            },
            tooltip: {
              enabled: true,
              x: {
                show: true,
                formatter: (x) => `${format(new Date(x), 'dd-MM-yyyy')}`,
              },
              y: {
                formatter: (val) => formatCurrency(val),
                title: {
                  formatter: (seriesName) => seriesName
                }
              },
              marker: {
                show: false,
              }
            },
            chart: {
              id: "linear-chart",
              zoom: {
                enabled: true,
              },
              toolbar: {
                show: false,
                offsetX: 0,
                offsetY: 0,
                autoSelected: 'pan',
                tools: {
                  selection: false,
                  pan: false,
                  download: false,
                  zoom: false,
                  zoomin: false,
                  zoomout: false,
                  reset: false,
                },
              },
            },
            legend: {
              offsetY: 8,
              labels: {
                colors: [theme.colors.grey],
              },
              markers: {
                fillColors: linearChartColor
              }
            },
            xaxis: {
              type: 'datetime',
              tooltip: {
                enabled: false,
              },
              axisBorder: {
                show: true,
                color: theme.colors.grey,
              },
              labels: {
                style: {
                  colors: [theme.colors.grey],
                },
                rotate: -45,
              }
            },
            yaxis: {
              seriesName: wallet.name,
              axisBorder: {
                show: false,
              },
              labels: {
                formatter: (val) => formatCurrency(val),
                style: {
                  colors: [theme.colors.grey],
                },
              }
            },
            grid: {
              borderColor: theme.colors.lightGrey,
            }
          }}
          series={linearChartData}
          type="area"
          height={'308'}
          width={'100%'}
          animations={{ initialAnimation: { enabled: true }}}
        />
      </Styled.GraphContainer>
    </Styled.Container>
  );
};
