import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled(motion.div)`
  height: 100vh;
  width: 100vw;

  position: fixed;
  top: 0;
  left: 0;

  z-index: 99;

  background: rgba(0, 0, 0, 0.75);

  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;
`;
