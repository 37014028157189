import styled, { css } from 'styled-components';

export const Container = styled.div`
${({ theme }) => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`}
`

export const CardImage = styled.img`
${({ theme }) => css`
  height: 25rem;
  margin-bottom: 3.6rem;
`}
`

export const Description = styled.span`
${({ theme }) => css`
  font-size: ${theme.fontSizes.default};
  color: ${theme.colors.secondary};
  text-align: center;

  margin-top: auto;
  max-width: 35rem;
  height: 8rem;
`}
`
