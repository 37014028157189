import { isEqual, startOfDay, startOfMonth } from "date-fns";
import { FormattedOrder, StatusType } from "..";
import { IOrder } from "../../../../../../store/modules/actives/types";

export const identifyErrors = (formattedOrders: FormattedOrder[], previousOrders: IOrder[], statusKeywords: StatusType) => {
  const errorArray = formattedOrders.map((newOrder) => {
    const isDupedOrder = previousOrders.find((order) => {
      const orderAmount = statusKeywords.SELL.includes(newOrder.status) ? -1 +newOrder.amount : +newOrder.amount;

      const startOfNewOrderDate = startOfMonth(newOrder.date);
      const startOfOrderDate = startOfMonth(new Date(order.created_at));

      const datesAreEqual = isEqual(startOfNewOrderDate, startOfOrderDate);

      const pricesRange = [0.9 * newOrder.price, 1.1 * newOrder.price];
      const pricesBetweenRange =  order.price > pricesRange[0] && order.price < pricesRange[1];

      const amountIsSame = Math.floor(+order.amount) === Math.floor(+orderAmount);

      const nameIsSame = order.stock.name === newOrder.name;

      return amountIsSame && datesAreEqual && pricesBetweenRange && nameIsSame;
    });

    if (isDupedOrder) {
      return {
        hasError: true,
        message: 'Parece que existe uma outra ordem muito parecida com essa! Verifique se não é o caso de uma duplicação'
      }
    } else {
      return {
        hasError: false,
        message: ''
      }
    }
  });

  return errorArray;
}
