import { DEFAULT_TRANSITION } from "../constants"

const containerAnimation = {
  unMounted: { y: 25, opacity: 0 },
  mounted: {
    y: 0,
    opacity: 1,
    transition: {
      ...DEFAULT_TRANSITION,
      staggerChildren: 0.05,
      duration: 0.3,
    }
  },
}

const cardsAnimation = {
  unMounted: { x: -25, opacity: 0 },
  mounted: {
    x: 0,
    opacity: 1,
  },
}

const animations = {
  cardsAnimation,
  containerAnimation,
}

export const getContainerAnimation = (delay: number) => ({
  ...animations.containerAnimation,
  mounted: {
    ...animations.containerAnimation.mounted,
    transition: {
      ...animations.containerAnimation.mounted.transition,
      staggerChildren: 0.25,
      delay,
    }
  }
})

export default animations;
