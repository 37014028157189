import React, { useState } from 'react';
import { FiX } from 'react-icons/fi';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import Button from '../../../../../components/Button';
import WalletSearchBar, { IWalletOption } from '../../../../../components/SearchBars/WalletSearchBar';
import { animations } from '../../../../../resources';

import { IState } from '../../../../../store';
import { IWallet } from '../../../../../store/modules/wallet/types';
import StockForms from './Forms/StockForms';
import CryptoForms from './Forms/CryptoForms';

import {
  Container,
  Heading,
  SelectWalletContainer,
  NoContentWrapper,
} from './styles';
import TesouroForms from './Forms/TesouroForms';


interface AddOrderFormProps {
  email: string | undefined;
  phone: string | undefined;
  closeModal(): void;
  wallets: IWallet[];
}

const containerAnimation = {
  ...animations.containerAnimation,
  mounted: {
    ...animations.containerAnimation.mounted,
    transition: {
      ...animations.containerAnimation.mounted.transition,
      delay: 0.1,
      duration: undefined,
    }
  }
}

const AddOrderForm: React.FC<AddOrderFormProps> = ({ closeModal, wallets }) => {
  const [selectedWallet, setSelectedWallet] = useState<IWalletOption | null>(null);

  const history = useHistory();

  const handleGoToCreateWallet = () => {
    history.push('/dashboard/create-wallet');
    closeModal();
  }

  const handleSelectWallet = (wallet: IWalletOption) => {
    if (wallet.name.length) {
      setSelectedWallet(wallet);
    } else {
      setSelectedWallet(null);
    }
  }

  if (!wallets.length) {
    return (
      <NoContentWrapper>
        <p>Parece que você ainda não tem carteiras para adicionar ordens! O que acha de criar alguma?</p>
        <Button
          onClick={handleGoToCreateWallet}
        >
          Criar Carteira
        </Button>
      </NoContentWrapper>
    );
  }

  return (
    <Container
      variants={containerAnimation}
      isExpanded={!!selectedWallet}
    >
      <Heading>
        <h1>Adicionar Nova Ordem</h1>
        <button
          onClick={() => closeModal()}
        >
          <FiX />
        </button>
      </Heading>

      <SelectWalletContainer>
        <WalletSearchBar
          callback={({ wallet }) => handleSelectWallet(wallet)}
          placeholder="Selecionar Carteira"
        />
      </SelectWalletContainer>

      {selectedWallet?.type === 'STOCKS' && (
        <StockForms
          wallet_id={selectedWallet.id}
        />
      )}

      {selectedWallet?.type === 'TESOURO' && (
        <TesouroForms
          wallet_id={selectedWallet.id}
        />
      )}

      {selectedWallet?.type === 'CRYPTO' && (
        <CryptoForms
          wallet_id={selectedWallet.id}
        />
      )}
    </Container>
  );
}

const mapStateToProps = (state: IState) => ({
  email: state.user.user?.email,
  phone: state.user.user?.phone,
  wallets: state.wallet.wallets,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(AddOrderForm)
