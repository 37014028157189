import React, { useState } from 'react';
import { FiHome, FiMenu, FiPlus, FiX } from 'react-icons/fi';
import { useHistory } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';

import Dropdown from './Dropdown';
import HelpDesk from './HelpDesk';

import { sidebarNavigation } from '../../../resources';
import { SideBarNavigationIndex, SideBarNavigationTypes } from '../../../resources/sidebar.navigation';

import { DEFAULT_TRANSITION } from '../../../constants';

import Balance from './Balance';

import {
  Container,
  AmountInvested,
  Wrapper,
  ButtonsContainer,
  OpenMenuButton,
  SidebarMenu,
  AddWalletButton,
  StyledTooltip,
  Separator,
} from './styles';

import { WlltIcon } from '../../../assets/images/icons';
import { useModals } from '../../../contexts/modals';

const animation = {
  unMounted: { opacity: 0, y: 50 },
  mounted: { opacity: 1, y: 0 },
}

const SideBar: React.FC = () => {
  const history = useHistory();
  const isSmallDevice = useMediaQuery({ query: '(max-width: 670px)' });

  const [isMenuOpen, setIsMenuOpen] = useState(!isSmallDevice);
  const { turnModalOn } = useModals()

  const handleDropdownClick = (key: SideBarNavigationIndex) => {
    const formatted_key = SideBarNavigationTypes[key];

    if (isSmallDevice) {
      setIsMenuOpen(false);
    }

    history.push(`/dashboard/${formatted_key}`);
  }

  const handleOpenMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  }

  const handleSeeWallets = () => {
    history.push(`/dashboard/wallets`);
  }

  const handleSeeDashboard = () => {
    history.push(`/dashboard`);
  }

  const handleAddWallet = () => {
    turnModalOn('CREATE_WALLET')
  }

  return (
    <Wrapper>
      <Container
        variants={animation}
        initial="unMounted"
        animate="mounted"
        transition={{ ...DEFAULT_TRANSITION }}
      >
        <ButtonsContainer>
          <AmountInvested
            onClick={handleSeeDashboard}
            home
          >
            Dashboard
            <FiHome size={"1.6rem"} />
          </AmountInvested>
        </ButtonsContainer>

        <Separator />

        <ButtonsContainer>
          <AmountInvested
            onClick={handleSeeWallets}
            revision={"secondary"}
          >
            <WlltIcon />
            Carteiras
          </AmountInvested>
          {!isSmallDevice && (
            <StyledTooltip
              title={'Adicionar Carteira'}
            >
              <AddWalletButton
                id="add-wallet-button"
                revision={'secondary'}
                onClick={handleAddWallet}
              >
                <FiPlus />
              </AddWalletButton>
            </StyledTooltip>
          )}
          {isSmallDevice && (
            <OpenMenuButton
              onClick={handleOpenMenu}
            >
              {isMenuOpen ? (
                <FiX size={"1.6rem"} />
                ) : (
                <FiMenu size={"1.6rem"} />
              )}
            </OpenMenuButton>
          )}
        </ButtonsContainer>

        <SidebarMenu>
          {
            (!isSmallDevice || isMenuOpen) && (
              Object.entries(sidebarNavigation).map(([key, value]) => (
                <Dropdown
                  dropdownId={SideBarNavigationTypes[key as SideBarNavigationIndex]}
                  key={key}
                  icon={value.icon}
                  sectionName={key}
                  onClick={() => handleDropdownClick(key as SideBarNavigationIndex)}
                />
              ))
            )
          }
        </SidebarMenu>
      </Container>
    </Wrapper>
  );
};

export default SideBar;
