import React, { useCallback, useRef, useState } from 'react'
import { FiSearch } from 'react-icons/fi'
import { useTheme } from 'styled-components'
import useEventListener from '../../hooks/useEventListener'
import { WalletType } from '../../store/modules/wallet/types'
import { Box } from '../Box'
import Typography from '../Typography'

import * as Styled from './styles'

interface WalletTypeInputProps {
  type: WalletType | null
  setType?: (type: WalletType | null) => void
  setTypeName?: (type: string) => void
}

export const WalletTypeInput = ({ setType, type, setTypeName }: WalletTypeInputProps) => {
  const theme = useTheme()

  const walletTypes = [
    {
      type: WalletType['Ações, BDRs, ETFs Nacionais'],
      name: 'Ações, BDRs, ETFs Nacionais',
    },
    {
      type: WalletType['Tesouro Direto'],
      name: 'Tesouro Direto',
    },
    {
      type: WalletType['Fundos Imobiliários'],
      name: 'Fundos Imobiliários',
    },
    {
      type: WalletType['Personalizada'],
      name: 'Personalizada',
    },
  ]

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [walletTypeName, setWalletTypeName] = useState<string>(walletTypes.find(wallet => wallet.type === type)?.name || 'Selecione um tipo de carteira');

  const handleCloseDropdown = useCallback(({ target }: Event): void => {
    if (dropdownRef.current?.contains(target as Node)) {
      return;
    }

    setIsDropdownVisible(false);
  }, [setIsDropdownVisible]);

  useEventListener('click', handleCloseDropdown, {
    enabled: isDropdownVisible,
  });

  const handleDropdownVisibility = (): void => {
    setIsDropdownVisible(true);
  };


  return (
    <Styled.Container>
      <Styled.ExpandDropdown hasType={!!type}>
        <button onClick={handleDropdownVisibility}>
          <Box p={'0.1rem'}>
            <FiSearch
              color={isDropdownVisible || walletTypeName ? theme.colors.primary : theme.colors.grey}
            />
          </Box>
          <Typography type='body3' color={walletTypeName !== 'Selecione um tipo de carteira' ? theme.colors.primary : theme.colors.grey}>
            {walletTypeName}
          </Typography>
        </button>
      </Styled.ExpandDropdown>
      {isDropdownVisible && (
        <Styled.Dropdown ref={dropdownRef}>
          {walletTypes.map((walletType) => (
            <Styled.DropdownItem onClick={() => {
              setWalletTypeName(walletType.name)
              if (setType) {
                setType(walletType.type)
              }

              if (setTypeName) {
                setTypeName(walletType.name)
              }
              setIsDropdownVisible(false);
            }}>
              {walletType.name}
            </Styled.DropdownItem>
          ))}
        </Styled.Dropdown>
      )}
    </Styled.Container>
  )
}
