import { Reducer } from "redux";
import { IThemeState, IThemeTypes } from "./types";
import produce from 'immer';
import { storageKey } from "../../../hooks/useLocalStorage";

const INITIAL_THEME_STATE = {
  isLightMode: true,
  realizationIsEnabled: false,
}

const themeSelector: Reducer<IThemeState> = (state = INITIAL_THEME_STATE, action) => {
  return produce(state, draft => {
    switch(action.type) {
      case IThemeTypes.CHANGE_ACTIVE_THEME: {
        const isLightModeActive = action.payload.key;

        localStorage.setItem(storageKey('isLightMode'), JSON.stringify(action.payload.key))

        draft.isLightMode = isLightModeActive;

        return draft;
      }
      case IThemeTypes.CHANGE_REALIZATION: {
        const isRealizationModeEnabled = action.payload.key;

        localStorage.setItem(storageKey('realizationIsEnabled'), JSON.stringify(action.payload.key))

        draft.realizationIsEnabled = isRealizationModeEnabled;

        return draft;
      }
      default: {
        return draft;
      }
    }
  });
}

export default themeSelector;
