import { createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer from './modules/rootReducer';

import { ISideBarState } from './modules/sideBarNavigator/types';
import { IThemeState } from './modules/themeSelector/types';
import { IUserState } from './modules/user/types';
import { IValuationState } from './modules/valuation/types';
import { IWalletState } from './modules/wallet/types';
import { IActivesState } from './modules/actives/types';
import { ILoadingState } from './modules/loading/types';
import { IProfitState } from './modules/profit/types';
import { IToastState } from './modules/toast/types';
import { ITesouroWalletState } from './modules/tesouroWallet/types';
import { ICryptoWalletsState } from './modules/crypto/types';
import { IUiState } from './modules/ui/types';
import { PersonalizedWalletState } from './modules/personalized_wallets/types';

export interface IState {
  sideBarNavigator: ISideBarState;
  themeSelector: IThemeState;
  user: IUserState;
  wallet: IWalletState;
  valuations: IValuationState;
  actives: IActivesState;
  loading: ILoadingState;
  profit: IProfitState;
  toast: IToastState;
  tesouro: ITesouroWalletState;
  crypto: ICryptoWalletsState;
  personalizedWallet: PersonalizedWalletState;
  ui: IUiState;
}

const store = createStore(
  rootReducer,
  composeWithDevTools()
);

export default store;
