import { motion } from "framer-motion";
import { transparentize } from "polished";
import styled, { css } from "styled-components";

export const Container = styled(motion.div)`
${({ theme }) => css`
  height: 100%;
  width: 100%;
  max-width: ${theme.spacing.mainWidth};

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(28rem, auto));
  row-gap: 0.8rem;

  > div {
    margin-right: 0.8rem;
  }

  @media (max-width: 670px) {
    display: flex;
    flex-direction: column;
    max-width: 100%;
  }
`}
`;

export const AddButton = styled.div<{ maleft?: number }>`
${({ theme, maleft }) => css`
  min-height: 12rem;
  min-width: 16rem;

  margin-left: ${maleft || 0}rem;

  cursor: pointer;

  background: ${transparentize(0.05, theme.colors.background)};

  box-shadow: ${theme.shadows.default};

  border-radius: 1.6rem 0.4rem 0.8rem 1.6rem;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  padding: 0.8rem;

  > div {
    > input {
      width: 100%;
      background: ${theme.colors.lightGrey};
      border: 0;

      margin-top: 0.8rem;

      padding: 0.8rem 0.4rem;

      color: ${theme.colors.grey};
    }
  }

  > button {
    height: 6.4rem;
    width: 6.4rem;

    display: flex;
    align-items: center;
    justify-content: center;

    background: ${theme.colors.lightGrey};

    border-radius: 50%;

    margin-bottom: 0.8rem;

    > svg {
      color: ${theme.colors.primary};
    }
  }

  > span {
    color: ${theme.colors.grey};
  }

  @media (max-width: 670px) {
    margin-left: 1.8rem;
  }
`}
`;


export const AddWalletButton = styled.button`
${({ theme }) => css`
  background: ${theme.colors.primary};
  color: ${theme.colors.lightGrey};
  margin: 0.8rem 0;

  border-radius: 0.4rem;

  padding: 0.8rem;
`}
`;
