import * as amplitude from '@amplitude/analytics-browser';


class LogProvider {
  constructor() {
    amplitude.init('16c64e6332f9224b838fbed80dfe39d2', undefined, { defaultTracking: { sessions: true, pageViews: true, formInteractions: true, fileDownloads: true }});
  }

  logEvent(name: string, properties?: object) {
    amplitude.track(name, properties)

    if (true) {
      console.log(`[SENDING EVENT] ${name} ${properties ? JSON.stringify(properties) : ''}`)
    }
  }

  setUserProperty(key: string, value: string) {
    if (true) {
      console.log(`[SETTING USER PROPERTY] ${key} ${value}`)
    }

    const user = new amplitude.Identify();
    user.set(key, value);
    amplitude.identify(user);
  }
}

export const logger = new LogProvider()
