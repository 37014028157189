import React from 'react';
import { Box, CardTitle, Percentage, Currency, Button } from '../../../../../components';

import { motion } from 'framer-motion';
import { getContainerAnimation } from '../../../../../resources/animations';

import * as Styled from './styles';
import { FiTrash2 } from 'react-icons/fi';
import { ImArrowUpRight2, ImArrowDownRight2 } from 'react-icons/im';
import { useTheme } from 'styled-components';
import { useWallet } from '..';

interface WalletHeaderProps {
  setIsExcludeWalletModalOn: (s: boolean) => void
}

export const WalletHeader = ({ setIsExcludeWalletModalOn }: WalletHeaderProps) => {
  const theme = useTheme()
  const { wallet } = useWallet();

  return (
    <Box
      flexDirection={'column'}
    >
      <Box
        alignItems={'center'}
        justifyContent={'space-between'}
        mb={'1.6rem'}
      >
        <Styled.LeftHeader
          positiveProfit={wallet.profit > 0}
        >
          <motion.div
            variants={getContainerAnimation(0.1)}
          >
            <CardTitle title={wallet.name} />
          </motion.div>

          <Box mr={'0.8rem'} />

          <motion.div
            variants={getContainerAnimation(0.2)}
          >
            <Percentage
              value={wallet.profit_in_percentage}
              type='variation'
            />
          </motion.div>

          <Box mr={'0.8rem'} />

          <motion.div
            variants={getContainerAnimation(0.3)}
          >
            <Currency
              value={wallet.profit}
              type='variation'
            />
          </motion.div>
        </Styled.LeftHeader>

        <Styled.RightHeader>
          <Button
            onClick={() => setIsExcludeWalletModalOn(true)}
          >
            <FiTrash2 />
          </Button>
        </Styled.RightHeader>
      </Box>

      <Styled.WalletValue>
        <motion.div
          variants={getContainerAnimation(0.4)}
        >
          <Currency
            value={wallet.current_wallet_value}
          />
        </motion.div>

        <motion.div
          variants={getContainerAnimation(0.5)}
        >
          {wallet.current_wallet_value > 0 && (
            <ImArrowUpRight2
              size={56}
              color={theme.colors.success}
            />
          )}

          {wallet.current_wallet_value < 0 && (
            <ImArrowDownRight2
              size={56}
              color={theme.colors.error}
            />
          )}
        </motion.div>
      </Styled.WalletValue>
    </Box>
  )
}
