import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

export const Container = styled(motion.div)`
${({ theme }) => css`
  max-width: ${theme.spacing.mainWidth};
  width: 100%;

  background: 0;
  border-radius: 0.8rem;

  display: flex;
  flex-direction: column;

  @media (max-width: 670px) {

  }
`}
`;
