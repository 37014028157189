import React from 'react';
import { useSellsProfits } from '../../../../hooks/react-query/useSellsProfits';
import { Box } from '../../../../components/Box';
import CardTitle from '../../../../components/CardTitle';
import { useTheme } from 'styled-components';
import { MainProfit } from './styles';
import Currency from '../../../../components/Currency';
import SellsProfitsList from './SellsProfitsList';
import useWindowSize from '../../../../hooks/useWindowWidth';

export const SellsProfits = () => {
  const { data: profits, isLoading } = useSellsProfits()
  const theme = useTheme()

  if (isLoading || !profits) {
    return null;
  }

  return (
    <Box
      bg={theme.colors.background}
      flexDirection={'column'}
      ml={'1.6rem'}
      mt={'0rem'}
      w={'100%'}
      p={'1.6rem'}
      borderRadius={'0.8rem'}
    >
      <Box alignItems='flex-start' mb={'1.6rem'}>
        <CardTitle title={'Suas vendas'} type={'secondary'} />
      </Box>

      <MainProfit>
        <span>{profits?.stockSells?.length} vendas totais</span>
        <Currency
          type='variation'
          value={profits.totalProfit || 0}
          color={profits.totalProfit > 0 ? theme.colors.success : theme.colors.error}
        />
      </MainProfit>

      <SellsProfitsList />
    </Box>
  )
}
