import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { transparentize } from 'polished';

export const Container = styled(motion.div)`
${({ theme }) => css`
  height: 100%;
  width: 100%;
  max-width: ${theme.spacing.mainWidth};

  border-radius: 0.8rem;

  @media (max-width: 670px) {
    padding: 0.8rem;
    max-width: 100%;

    > div:nth-child(2) {
      > div:first-child {
        flex-direction: column;
        flex: 1;
      }

      > div:nth-child(2) {
        flex-direction: column;
        flex: 1;
      }
    }
  }
`}
`;

export const Header = styled.div`
${({ theme }) => css`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 1.6rem;
`}
`;

export const TesourosList = styled(motion.table)`
${({ theme }) => css`
  width: 100%;
  background: ${theme.colors.background};
  border-radius: 0.8rem;

  padding: 0.8rem;
`}
`;

export const TesouroLabelContainer = styled.thead`
${({ theme }) => css`
  display: block;
  width: 100%;
  padding-right: 1.6rem;
`}
`

export const TesouroListBody = styled.tbody`
  max-height: 60rem;
  overflow-y: scroll;
  overflow-x: hidden;
  display: block;
  width: 100%;
`;
