import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { ResponsiveSankey } from '@nivo/sankey'

import { animations } from '../../../resources';
import { useHistory } from 'react-router-dom';

import {
  Container,
  Actives,
  Header,
} from './styles';

import { useTheme } from 'styled-components';
import { FiChevronLeft } from 'react-icons/fi';

const ActivesChart = ({ isDarkTheme, wallets }) => {
  const theme = useTheme();
  const history = useHistory();

  const colors = useMemo(() => {
    return [
      theme.colors.primary,
      theme.colors.softGrey,
      theme.colors.success,
      theme.colors.darkBluishGrey,
    ]
  }, [theme]);

  const totalWalletsValue = useMemo(() => {
    let total = 0;
    wallets.forEach(wallet => total += wallet.current_wallet_value);
    return total;
  }, [wallets]);

  const chartData = useMemo(() => {
    let nodes = [];
    let links = [];
    wallets.forEach((wallet, index) => {
      nodes.push({
        id: wallet.name,
        color: colors[index],
      });

      wallet.stocks.forEach(stock => {
        links.push({
          source: wallet.name,
          target: stock.name,
          value: wallet.current_wallet_value ? `${Number((stock.totalCurrentValue / totalWalletsValue * 100).toFixed(2))}`  : 0
        });

        nodes.push({
          id: stock.name,
          color: colors[index],
        });
      })
    });

    links.sort((a, b) => {
      if (a.value < b.value) {
        return -1;
      }
      if (a.value > b.value) {
        return 1;
      }
      return 0;
    })

    return {
      nodes,
      links,
    }
  }, [wallets, totalWalletsValue, colors]);

  return (
    <Container
      variants={animations.containerAnimation}
    >
      <Header>
        <FiChevronLeft
          color={theme.colors.primary}
          onClick={() => history.push('/dashboard/actives')}
        />
        <h1>Meus Ativos</h1>
      </Header>

      <Actives>
        {!!chartData.nodes.length && !!chartData.links.length && (
        <ResponsiveSankey
          data={chartData}
          margin={{ top: 40, right: 160, bottom: 40, left: 150 }}
          align="justify"
          colors={{ datum: 'color' }}
          sort="descending"
          nodeOpacity={1}
          nodeThickness={18}
          nodeInnerPadding={3}
          nodeSpacing={10}
          nodeBorderWidth={0}
          nodeBorderColor={{ from: 'color', modifiers: [ [ 'darker', 0.8 ] ] }}
          linkOpacity={0.35}
          linkHoverOthersOpacity={0.1}
          linkBlendMode="normal"
          enableLinkGradient={true}
          labelPosition="outside"
          labelOrientation="horizontal"
          labelPadding={16}
          label={d => {
            const isWalletLabel = wallets.find(wallet => wallet.name === d.id);

            if (isWalletLabel) {
              return `${d.id}  ${Math.round(isWalletLabel.current_wallet_value / totalWalletsValue * 100)}%`
            }

            return `${d.id}   ${d.value}%`}
          }
        />)}
      </Actives>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  wallets: state.wallet.wallets,
  isDarkTheme: !state.themeSelector.isLightMode,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ActivesChart)
