import { motion } from 'framer-motion';
import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

export const Container = styled(motion.button)<{ isSmallInput?: boolean }>`
${({ theme, isSmallInput }) => css`
  display: flex;
  align-items: center;

  ${isSmallInput && css`
    flex-direction: column;
    align-items: flex-start;
  `}

  justify-content: space-between;

  text-align: left;

  position: relative;

  margin-bottom: 0.8rem;

  padding: 1.2rem 2.4rem;

  border-radius: 0.8rem;

  background-color: ${theme.colors.background};
  border: 1px solid ${theme.colors.lightGrey};

  border-top-left-radius: 0.8rem;
  border-top-right-radius: 0.8rem;

  width: 100%;

  > svg {
    stroke-width: 0.3rem;
  }

  transition: 0.4s;

  &:hover {
    border: 1px solid ${theme.colors.primary};
  }

  cursor: pointer;
`}
`;

export const Infos = styled.div`
${({ theme }) => css`
  display: flex;
  flex-direction: column;

  flex: 1;

  > h1 {
    color: ${theme.colors.white};
    font-size: ${theme.fontSizes.default};
    font-weight: normal;

    letter-spacing: 0.015em;

    margin-bottom: 0.2rem;
  }

  > span {
    font-weight: normal;
    font-size: ${theme.fontSizes.default};
    letter-spacing: 0.015em;

    color: ${theme.colors.grey};
  }
`}
`;
