import React, { useEffect, useState } from 'react';

import DashboardRoute from '../../routes/DashboardRoute';

import Header from './Header';
import Sidebar from './SideBar';
import Footer from './Footer';

import {
  Container,
  Wrapper,
} from './styles';
import { connect, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { IState } from '../../store';
import { useLocation } from 'react-router-dom';
import { changeActiveDropdown } from '../../store/modules/sideBarNavigator/actions';
import Onboarding from './Onboarding';
import { useOrders } from '../../hooks/react-query/useOrders';
import { useSellsProfits } from '../../hooks/react-query/useSellsProfits';
import { useStocksTodayRank } from '../../hooks/react-query/useStocksTodayRank';
import { useWallets } from '../../hooks/react-query/useWallets';
import { usePatrimonyChart } from '../../hooks/react-query/usePatrimonyChart';

const animation = {
  unMounted: { opacity: 0 },
  mounted: {
    opacity: 1,
  },
}

interface DashboardProps {
  changeActiveDropdown(key: string): void;
}

const Dashboard: React.FC<DashboardProps> = ({ changeActiveDropdown }) => {
  useOrders({})
  useWallets()
  useSellsProfits()
  useStocksTodayRank()
  usePatrimonyChart()

  const shouldNotShowOnboarding = useSelector<IState, boolean | undefined>(state => state.user.user?.hasDismissedOnboarding);

  const [isOnboardingOn, setIsOnboardingOn] = useState(true);
  const location = useLocation();

  useEffect(() => {
    changeActiveDropdown(location.pathname.split('/')[2])
  }, [location]);

  return (
    <>
      <Header />
      <Container>
        <Wrapper
          variants={animation}
          initial="unMounted"
          animate="mounted"
          exit="exit"
        >
          <Sidebar />
          <DashboardRoute />
        </Wrapper>
      </Container>
      <Footer />

      <Onboarding
        setIsOnboardingOn={setIsOnboardingOn}
        isOnboardingOn={isOnboardingOn && !shouldNotShowOnboarding}
      />
    </>
  );
};

const mapStateToProps = (state: IState) => ({})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  changeActiveDropdown: (key: string) => dispatch(changeActiveDropdown(key)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
