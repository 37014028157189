import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { v4 as uuid } from 'uuid';

import { FiFile } from 'react-icons/fi';
import GenericSearchBar from '../../../../../../components/SearchBars/GenericSearchBar';


import {
  Container,
  DropContainer,
  FileDetails,
  HeaderText,
  FinanceProductSelectionContainer,
  Title,
} from './styles';

import { ImportFunctionByFormat } from './importHandlers';
import Loading from '../../../../../../components/Loading';
import { useTheme } from 'styled-components';

interface ImportFileProps {
  setFileContentRaw(s: any): void;
  file: File | null;
  setFile(file: File | null): void;
  setFinancialProduct(s: any): void;
  financialProduct: string;
  setIsNextStepEnabled(s: any): void;
}

const ImportFile: React.FC<ImportFileProps> = ({
  setFileContentRaw,
  file,
  setFile,
  setFinancialProduct,
  financialProduct,
  setIsNextStepEnabled,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const theme = useTheme();

  useEffect(() => {
    const fileMimeType = file?.name.split('.').pop() as string;
    const importFunction = ImportFunctionByFormat[fileMimeType];

    if (!financialProduct) {
      setIsNextStepEnabled({
        message: 'Escolha um produto financeiro para continuar!',
        isEnabled: false,
      });
    } else if (!file) {
      setIsNextStepEnabled({
        message: 'Você deve selecionar um arquivo para continuar!',
        isEnabled: false,
      });
    } else if (!importFunction) {
      setIsNextStepEnabled({
        message: 'O formato do arquivo selecionado é inválido :(',
        isEnabled: false,
      });
    } else {
      setIsNextStepEnabled({
        message: '',
        isEnabled: true,
      });
    }
  }, [file, financialProduct]);

  const onDrop = useCallback(async (acceptedFiles) => {
    const readFile = () => {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        const file = acceptedFiles[0];

        reader.onloadend = () => {
          const file_content = reader.result as string;

          if (!file_content) return;

          const fileMimeType = file.name.split('.').pop() as string;

          const importFunction = ImportFunctionByFormat[fileMimeType];

          if (!importFunction) {
            setIsNextStepEnabled({
              isEnabled: false,
              message: 'Tipo de arquivo selecionado inválido',
            });

            return;
          }

          const result = importFunction(file_content);

          const resultWithEmptyColumn = result.map((r: any) => ({
            ...r,
            '': '',
            id: uuid(),
          }))

          if (resultWithEmptyColumn.length) {
            resolve(resultWithEmptyColumn);
          }
        }


        reader.readAsBinaryString(file);
        setFile(acceptedFiles[0]);

      });
    }

    setIsLoading(true);

    const result = await readFile();
    setFileContentRaw(result);

    setIsLoading(false);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const financialProducts = useMemo(() => {
    return [
      {
        id: 'Ações, FIIs, BDRs, ETFs Nacionais',
        name: 'Ações, FIIs, BDRs, ETFs Nacionais'
      },
    ]
  }, []);

  return (
    <Container>
      <HeaderText>Importação Manual</HeaderText>

      <FinanceProductSelectionContainer>
        <Title>Selecione o produto financeiro</Title>
        <GenericSearchBar
          valueId={financialProduct}
          callback={(e) => setFinancialProduct(e.option.name)}
          options={financialProducts}
          placeholder="Escolha o produto financeiro"
          type={"primary"}
        />
      </FinanceProductSelectionContainer>

      <DropContainer {...getRootProps() as any}>
        <input {...getInputProps() as any} accept='.xlsx, .xls, .csv' />
        {
          isDragActive && !file && (<p><strong>Pode soltar!</strong></p>)
        }
        {
          !isDragActive && !file && (<p>Arraste para cá, ou clique para enviar arquivos <strong>.csv</strong>, <strong>.xls</strong> ou <strong>.xlsx</strong></p>)
        }
        {
          file && (
            <FileDetails>
              <FiFile />
              <span>{file.name}</span>
            </FileDetails>
          )
        }
        {
          isLoading && (
            <Loading
              size={8}
              color={theme.colors.success}
            />
          )
        }
      </DropContainer>
    </Container>
  )
}

export default ImportFile;
