import React, { useMemo, useState } from 'react';
import NumberFormat from 'react-number-format';
import { connect, useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';
import CurrencyInput from '../../../../../../../components/CurrencyInput';
import Input from '../../../../../../../components/Input';
import Loading from '../../../../../../../components/Loading';
import CryptoSearchBar, { ICryptoNameOption } from '../../../../../../../components/SearchBars/CryptoSearchBar';
import Tooltip from '../../../../../../../components/Tooltip';
import { animations } from '../../../../../../../resources';
import api from '../../../../../../../services/api';
import { fetchData } from '../../../../../../../services/data';
import { IState } from '../../../../../../../store';
import { ICryptoWallet } from '../../../../../../../store/modules/crypto/types';
import { createToastMessage } from '../../../../../../../store/modules/toast/actions';
import { IWallet } from '../../../../../../../store/modules/wallet/types';

import {
  Container,
  SelectStockContainer,
  DateContainer,
  AmountContainer,
  PriceContainer,
  ButtonsContainer,
  BuyButton,
  SellButton,
} from './styles';


interface StockFormsProps {
  wallet_id: string;
  crypto_wallets: ICryptoWallet[];
}

const containerAnimation = {
  ...animations.containerAnimation,
  mounted: {
    ...animations.containerAnimation.mounted,
    transition: {
      ...animations.containerAnimation.mounted.transition,
      delay: 0.1,
      duration: undefined,
    }
  }
}

const CryptoForm: React.FC<StockFormsProps> = ({ wallet_id, crypto_wallets }) => {
  const [dateString, setDateString] = useState('');
  const [price, setPrice] = useState('');
  const [amount, setAmount] = useState('');
  const [selectedCrypto, setSelectedCrypto] = useState<ICryptoNameOption | null>(null);

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const theme = useTheme();

  const handleChangeAmount = (n: string) => {
    const isNumber = !Number.isNaN((Number(n)));
    const isNumberWithDot = !Number.isNaN((Number(n.replace(',', '.'))));

    if (isNumber || isNumberWithDot) {
      setAmount(n.replace('.', ','))
    }
  }

  const handleExecuteOrder = async (type: string) => {
    if (!selectedCrypto) return

    const selectedWallet = crypto_wallets.find(wallet => wallet.id === wallet_id);

    if (!selectedWallet) return

    setIsLoading(true);

    const cryptoInWallet = selectedWallet.cryptos.find(crypto => crypto.name === selectedCrypto.name && crypto.is_active);
    const averagePrice = cryptoInWallet && cryptoInWallet.totalPapers ? Math.round(cryptoInWallet.totalPurchaseValue / cryptoInWallet.totalPapers) : 0;

    const [day, month, year] = dateString.split('/');
    const stockDate = new Date(`${year}-${month}-${day} 00:00:00`);
    const correctAmount = amount.replace(',', '.');

    const crypto = {
      name: selectedCrypto.name,
      cryptocurrency_id: selectedCrypto.id,
      price: +price.replace(',', '.'),
      averagePrice,
      amount: correctAmount,
      type,
      date: dateString ? stockDate : new Date()
    }

    try {
      await api.post('/crypto/create-orders', {
        wallet_id,
        cryptos: [crypto]
      });

      dispatch(createToastMessage({
        title: `Ordem adicionada.`,
        content: `Adicionamos sua ordem de ${amount} papéis de ${selectedCrypto.name}`,
        created_at: new Date(),
        isPined: false,
        type: 'success',
      }));

      setDateString('');
      setPrice('');
      setAmount('');
    } catch (err) {
      dispatch(createToastMessage({
        title: `Erro ao criar ordem.`,
        content: (err as any)?.response?.data?.message || `Ops, ocorreu um erro ao criar sua ordem, por favor chame o nosso time para te ajudar!`,
        created_at: new Date(),
        isPined: false,
        type: 'info',
      }));
    }

    setIsLoading(false);
    fetchData({ dispatch });
  }

  const isButtonsDisabled = useMemo(() => {
    if (!selectedCrypto || !selectedCrypto.id) {
      return true;
    }

    if (isLoading) {
      return true;
    }

    return false;
  }, [isLoading, selectedCrypto]);

  const buttonsTooltipTitle = useMemo(() => {
    if (!selectedCrypto || !selectedCrypto.id) {
      return 'Por favor, selecione um ativo para realizar a ordem';
    }

    return '';
  }, [selectedCrypto]);

  return (
    <Container
      variants={containerAnimation}
    >
      <SelectStockContainer>
        <CryptoSearchBar
          callback={({ crypto }) => setSelectedCrypto(crypto)}
          placeholder="Selecione seu Ativo"
        />
      </SelectStockContainer>

      <DateContainer>
        <span>Data da Operação</span>

        <NumberFormat
          format="##/##/####"
          value={dateString}
          placeholder="dd/mm/aaaa"
          mask={['d', 'd', 'm', 'm', 'a', 'a', 'a', 'a']}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setDateString(e.target.value)
          }}
        />
      </DateContainer>

      <AmountContainer>
        <span>Quantidade</span>

        <Input
          callback={(e) => handleChangeAmount(e)}
          value={amount}
          inputType='secondary'
        />
      </AmountContainer>

      <PriceContainer>
        <span>Cotação</span>

        <CurrencyInput
          callBack={(e) => setPrice(e.replace('.', ','))}
          defaultValue={price}
          dollar
        />
      </PriceContainer>

      <Tooltip
        title={buttonsTooltipTitle}
      >
        <ButtonsContainer>
          <BuyButton
            onClick={() => handleExecuteOrder('BUY')}
            disabled={isButtonsDisabled}
          >
            {isLoading ? (
              <Loading
                size={12}
                color={theme.colors.grey}
              />
            ): (
              <span>Compra</span>
            )}
          </BuyButton>
          <SellButton
            onClick={() => handleExecuteOrder('SELL')}
            disabled={true}
          >
            {isLoading ? (
              <Loading
                size={12}
                color={theme.colors.grey}
              />
            ): (
              <span>Venda</span>
            )}
          </SellButton>
        </ButtonsContainer>
      </Tooltip>
    </Container>
  );
}

const mapStateToProps = (state: IState) => ({
  crypto_wallets: state.crypto.crypto_wallets,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(CryptoForm)
