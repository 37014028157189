import React from 'react';
import { FiFileText, FiEdit } from 'react-icons/fi'
import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components';
import Currency from '../../../../components/Currency';
import Percentage from '../../../../components/Percentage';

import {
  Container,
  Header,
} from './styles';

interface ValuationStockProps {
  name: string;
  valuation: number;
  currentPrice: number;
  dividend_yield: number;
  style?: any;
}

const cardsAnimation = {
  unMounted: { y: 0, opacity: 1 },
  mounted: {
    y: 0,
    opacity: 1,
  },
};

const ValuationStock: React.FC<ValuationStockProps> = ({ style, name, valuation, currentPrice, dividend_yield }) => {
  const theme = useTheme();
  const isSmallDevice = useMediaQuery({ query: '(max-width: 670px)' });

  return (
    <Container
      key={`card-${name}`}
      variants={cardsAnimation}
      whileHover={{ y: -1 }}
      whileTap={{ y: 1 }}
      style={style}
    >
      <Header>
        <div>
          <FiFileText />
          <h1>{name}</h1>
        </div>
      </Header>

      <td>
        <Currency
          value={currentPrice}
          color={valuation > currentPrice ? theme.colors.success : theme.colors.darkGrey}
        />
      </td>
      <td>
        <Currency
          value={valuation}
        />
      </td>
      {!isSmallDevice && (
        <td>
          <Percentage
            value={dividend_yield}
          />
        </td>
      )}
      <td>
        <Percentage
          value={(valuation - currentPrice) / currentPrice * 1000}
          color={valuation > currentPrice ? theme.colors.success : theme.colors.darkGrey}
        />
      </td>
    </Container>
  );
};

export default ValuationStock;
