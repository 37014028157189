import { addDays, addHours, addMilliseconds, endOfMonth, isAfter, isBefore, startOfMonth } from 'date-fns';
import React, { useMemo } from 'react';
import { ImArrowUpRight2 } from 'react-icons/im';
import { connect } from 'react-redux';
import { useTheme } from 'styled-components';
import CardTitle from '../../../../components/CardTitle';
import Currency from '../../../../components/Currency';
import Tooltip from '../../../../components/Tooltip';
import { IState } from '../../../../store';
import { IProfit } from '../../../../store/modules/profit/types';

import {
  Container,
  TopInfos,
  ReceivedEarnings,
} from './styles';

interface EarningsProps {
  profits: IProfit[]
}

const Earnings: React.FC<EarningsProps> = ({ profits }) => {
  const theme = useTheme();

  const totalEarnedDividends = useMemo(() => {
    return profits.reduce((acc, profit) => {
      const thisMonth = endOfMonth(new Date());

      if (isAfter(thisMonth, new Date(profit.created_at))) {
        return acc + profit.profit;
      } else {
        return acc;
      }
    }, 0);
  }, [profits]);

  const totalEarnedDividendsInCurrentMonth = useMemo(() => {
    let currentMonthProfit = profits.reduce((acc, profit) => {
      const thisMonthStart = addMilliseconds(startOfMonth(new Date()), -1);
      const today = new Date();

      if (isAfter(new Date(profit.created_at), thisMonthStart) && isBefore(new Date(profit.created_at), today)) {
        return acc + profit.profit;
      } else {
        return acc;
      }
    }, 0);

    if (!currentMonthProfit) {
      currentMonthProfit = profits.reduce((acc, profit) => {
        const lastMonth = addMilliseconds(startOfMonth(addDays(new Date(), -30)), -1);
        const lastMonthEnd = addMilliseconds(endOfMonth(addDays(new Date(), -30)), 1);

        if (isAfter(new Date(profit.created_at), lastMonth) && isBefore(new Date(profit.created_at), lastMonthEnd)) {
          return acc + profit.profit;
        } else {
          return acc;
        }
      }, 0);
    }

    return currentMonthProfit;
  }, [profits]);

  return (
    <Container>
      <TopInfos>
        <CardTitle title='Proventos'/>
        <Tooltip
          color={theme.colors.success}
          textColor={theme.colors.white}
          title='Proventos recebidos no mês'
        >
          <Currency value={totalEarnedDividendsInCurrentMonth} type='variation' />
        </Tooltip>
      </TopInfos>

      <ReceivedEarnings>
        <Currency value={totalEarnedDividends} />
        <ImArrowUpRight2
          size={56}
          color={theme.colors.success}
        />
      </ReceivedEarnings>
    </Container>
  )
}

const mapStateToProps = (state: IState) => ({
  profits: state.profit.profits
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Earnings)
