import React from 'react';

import {
  FiBarChart2,
  FiDollarSign,
  FiPieChart,
  FiList,
  FiFile,
  FiUploadCloud,
} from 'react-icons/fi';

export default {
  'Meus ativos': {
    icon: <FiList />,
  },
  'Importações': {
    icon: <FiUploadCloud />,
  },
  // 'Investir': {
  //   icon: <FiBarChart2 />,
  // },
  'Valuation': {
    icon: <FiPieChart />,
  },
  'Proventos': {
    icon: <FiDollarSign />,
  },
  'Extrato': {
    icon: <FiFile />,
  }
} as const;

export enum SideBarNavigationTypes {
  'Meus ativos' = 'actives',
  'Importações' = 'import',
  'Investir' = 'investir',
  'Valuation' = 'valuation',
  'Proventos' = 'earnings',
  'Extrato' = 'extract',
}

export type SideBarNavigationIndex = 'Meus ativos' | 'Importações' | 'Investir' | 'Valuation' | 'Proventos' | 'Extrato';
