import { motion } from 'framer-motion';
import { transparentize } from 'polished';
import styled, { css } from 'styled-components';
import Button from '../../../components/Button';

export const Container = styled(motion.div)`
${({ theme }) => css`
  height: 100%;
  width: 100%;
  max-width: ${theme.spacing.mainWidth};

  box-shadow: ${theme.shadows.default};

  background: ${transparentize(0.05, theme.colors.background)};

  border-radius: 0.2rem 1.6rem 1.6rem 0.2rem;
  padding: 2.4rem;

  @media (max-width: 670px) {
    max-width: 100%;
  }
`}
`;

export const Actives = styled(motion.table)`
${({ theme }) => css`
  overflow-x: hidden;

  table-layout:fixed;
  width: 100%;
`}
`;

export const Header = styled.div`
${({ theme }) => css`
  margin-bottom: 2.4rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  > svg {

  }
`}
`;

export const StyledButton = styled(Button)`
${({ theme }) => css`
  transition: 0.45s;
  margin-top: auto;

  p {
    font-size: ${theme.fontSizes.small};
  }

  &:hover {
      transform: scale(1.008);
      box-shadow: 0px 0.2rem 0.2rem ${transparentize(0.72, theme.colors.secondary)};
  }
`}
`;

export const ActivesContent = styled.tbody`
${({ theme }) => css`
  height: 60rem;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
`
}`

export const ActivesHeader = styled.thead<{ tbodyScrollWidth: number }>`
${({ tbodyScrollWidth }) => css`
  display: block;
  width: 100%;
  padding-right: ${tbodyScrollWidth}px;
`}
`
