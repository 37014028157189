import { IWallet, WalletActionTypes } from "./types";

export function setLoadingGetWallets() {
  return {
    type: WalletActionTypes.SET_LOADING_WALLETS,
  }
}

export function getWalletsRequest() {
  return {
    type: WalletActionTypes.GET_WALLETS_REQUEST,
  }
}

export function getWalletsSuccess(wallets: IWallet[]) {
  return {
    type: WalletActionTypes.GET_WALLETS_SUCCESS,
    payload: {
      wallets,
    }
  }
}

export function getWalletFailure(error: string) {
  return {
    type: WalletActionTypes.GET_WALLETS_FAILURE,
    payload: {
      error,
    }
  }
}
