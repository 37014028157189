import React, { useEffect, useState } from 'react';
import { FiFileText } from 'react-icons/fi';
import { connect } from 'react-redux';
import Modal from '../../../../components/Modal';

import { IState } from '../../../../store';
import AddOrderForm from './AddOrderForm';

import {
  StyledButton
} from './styles';


interface AddOrderButtonProps {
  isOrderModalActive: boolean;
}

const AddOrderButton: React.FC<AddOrderButtonProps> = ({ isOrderModalActive }) => {
  const [isAddOrderModalOn, setIsOrderModalOn] = useState(false);

  useEffect(() => {
    setIsOrderModalOn(isOrderModalActive);
  }, [isOrderModalActive]);


  return (
    <>
      <StyledButton
        revision="primary"
        onClick={() => setIsOrderModalOn(true)}
      >
        <FiFileText />
        Nova Ordem
      </StyledButton>

      <Modal
        isVisible={isAddOrderModalOn}
        closeModal={() => setIsOrderModalOn(false)}
      >
        <AddOrderForm
          closeModal={() => setIsOrderModalOn(false)}
        />
      </Modal>
    </>
  );
}

const mapStateToProps = (state: IState) => ({
  isOrderModalActive: state.ui.isOrderModalActive,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(AddOrderButton)
