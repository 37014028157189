import React, { memo, useEffect, useMemo, useRef, useState } from 'react';


import {
  Container,
} from './styles';

import Currency from '../../../../components/Currency';
import { connect } from 'react-redux';
import { IState } from '../../../../store';
import { Dispatch } from 'redux';
import { ITesouroWallet } from '../../../../store/modules/tesouroWallet/types';
import { IWallet } from '../../../../store/modules/wallet/types';
import { animate } from 'framer-motion';
import { ICryptoWallet } from '../../../../store/modules/crypto/types';

interface ITotalPatrimonyProps {
  wallets: IWallet[];
  tesouro_wallets: ITesouroWallet[];
  crypto_wallets: ICryptoWallet[];
}

const TotalPatrimony: React.FC<ITotalPatrimonyProps> = ({ wallets, tesouro_wallets, crypto_wallets }) => {
  const [patrimonyAnimated, setPatrimonyAnimated] = useState(0);
  const patrimonyRef = useRef(0);

  const patrimony = useMemo(() => {
    let total = 0;

    wallets.forEach(wallet => {
      total += wallet.current_wallet_value;
    });

    tesouro_wallets.forEach(tesouroWallet => {
      total += tesouroWallet.current_wallet_value;
    });

    crypto_wallets.forEach(cryptoWallet => {
      total += cryptoWallet.current_wallet_value * 100;
    });

    return total;
  }, [wallets, tesouro_wallets, crypto_wallets]);

  useEffect(() => {
    const controls = animate(patrimonyRef.current, patrimony, {
      ease: 'easeOut',
      duration: 1,
      onUpdate(value) {
       setPatrimonyAnimated(value);
      }
    });

    patrimonyRef.current = patrimony;

    return () => controls.stop();
 }, [patrimony]);

  return (
    <Container>
      <span>
        Patrimônio Total
      </span>
      <Currency
        value={patrimonyAnimated}
      />
    </Container>
  );
};

const mapStateToProps = (state: IState) => ({
  wallets: state.wallet.wallets,
  tesouro_wallets: state.tesouro.tesouro_wallets,
  crypto_wallets: state.crypto.crypto_wallets,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(memo(TotalPatrimony))
