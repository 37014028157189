import React from 'react';
import Button from '../../../../components/Button';
import WlltIcon from '../../../../assets/images/icons/wllt';

import * as Styled from './styles';
import { FiCheck, FiDownload, FiDownloadCloud } from 'react-icons/fi';
import IntegrationOption from './IntegrationOption';
import { Box } from '../../../../components/Box';
import { useHistory } from 'react-router-dom';

interface IntegrateInvestmentsProps {
  handleCloseOnboarding: () => void
}

const IntegrateInvestments: React.FC<IntegrateInvestmentsProps> = ({ handleCloseOnboarding }) => {
  const history = useHistory()

  const handleGoToImport = () => {
    history.push('/dashboard/import')
    handleCloseOnboarding()
  }

  return (
    <>
    <Styled.Header>
      <Styled.WlltIconContainer>
        <WlltIcon
          height={48}
          width={59}
        />
      </Styled.WlltIconContainer>

      <Styled.StepContainer>
        <Styled.Step />
        <Styled.StepSeparator />
        <Styled.Step active />
      </Styled.StepContainer>

      <Styled.Title>Último Passo: <strong>Adicionar Ativos</strong></Styled.Title>
      <Styled.Subtitle>Se você já investe, chegou o momento de trazer eles para o Wllt. Você pode se conectar com sua corretora favorita ou importar seus ativos com uma planilha</Styled.Subtitle>
    </Styled.Header>

    <Styled.Content>
      <Box
        mr={'1.6rem'}
      >
        <IntegrationOption
          onClick={handleGoToImport}
          icon={<FiDownload size={48} />}
          iconText={'Importação por Planilhas'}
          description={'Faça upload da planilha com todos os seus investimentos, da área logada da B3, ou qualquer outra planilha que desejar. Nossa tecnologia vai te ajudar a organizar toda a informação.'}
        />
      </Box>
      <IntegrationOption
        onClick={() => {}}
        disabled
        icon={<FiDownloadCloud size={48} />}
        iconText={'Integração Automática'}
        description={'Integre sua conta da Wllt com o CEI e nossas parcerias para atualizar seus investimentos automaticamente.'}
      />
    </Styled.Content>

    <Styled.Footer>
      <Button
        onClick={handleCloseOnboarding}
        revision='primary'
      >
        <Styled.ButtonContainer>
            <FiCheck />
            <span>Concluir Tutorial</span>
        </Styled.ButtonContainer>
      </Button>
      <Button
        onClick={handleCloseOnboarding}
        revision='secondary'
      >
        <Styled.ButtonContainer>
          <span>Pular Passo</span>
        </Styled.ButtonContainer>
      </Button>
    </Styled.Footer>
  </>
  )
}

export default IntegrateInvestments
