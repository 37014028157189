export enum ValuationActionTypes {
  GET_VALUATION_REQUEST = 'GET_VALUATION_REQUEST',
  GET_VALUATION_SUCCESS = 'GET_VALUATION_SUCCESS',
  GET_VALUATION_FAILURE = 'GET_VALUATION_FAILURE',
  SET_LOADING_VALUATIONS = 'SET_LOADING_VALUATIONS',
}

export interface IValuation {
  id: string;
  name: string;
  valuation: number;
  stock_id: string;
  div_yield: number;
  created_at: string;
  updated_at: string;
  price: number;
  previous_close: number;
}

export interface IValuationState {
  valuations: IValuation[];
  isLoadingValuation: boolean;
}
