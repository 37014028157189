import styled, { css } from 'styled-components';
import Button from '../../../components/Button';

import { motion } from 'framer-motion';
import { transparentize } from 'polished';
import Tooltip from '../../../components/Tooltip';

export const Container = styled(motion.div)`
${({ theme }) => css`
  max-width: ${theme.spacing.mainWidth};
  width: 100%;

  background: ${theme.colors.background};
  padding: 3.2rem;
  border-radius: 0.8rem;

  box-shadow: ${theme.shadows.default};

  > h1 {
    font-weight: normal;

    margin-bottom: 3.2rem;
  }

  @media (max-width: 670px) {
    max-width: 100%;
  }
`}
`;

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RightHeaderContent = styled.div`
${({ theme }) => css`
  display: flex;
  flex-direction: column;

  margin-bottom: 6.4rem;

  > div {
    display: flex;
    margin-bottom: 1.6rem;

    > div + div {
      margin-left: 0.8rem;
    }

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      padding: 1.2rem;

      background: ${theme.colors.lightGrey};
      border-radius: 0.8rem;

      > label {
        margin-bottom: 0.8rem;
      }

      > h6 {
        color: ${theme.colors.darkGrey};
        font-size: ${theme.fontSizes.huge};
      }

      > input {
        background: ${theme.colors.background};
        border: 0;

        border-radius: 0.2rem;
        padding: 0.6rem 0.8rem;

        color: ${theme.colors.grey};
      }
    }

  }

  h6 {
      color: ${theme.colors.darkGrey};
      font-size: ${theme.fontSizes.huge};
  }
`}
`;

export const StockList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledButton = styled(Button) <{ disabled: boolean }>`
${({ theme, disabled }) => css`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;

  transition: 0.5s;

  > p {
    font-size: ${theme.fontSizes.large};
    color: ${theme.colors.secondary};
  }

  > h6 {
    font-size: ${theme.fontSizes.large};
    color: ${theme.colors.secondary};

    margin-left: 0.8rem;
  }

  opacity: ${disabled ? 0.3 : 1};

  :hover {
    transform: scale(1.008);
    box-shadow: 0px 0.2rem 0.2rem ${transparentize(0.72, theme.colors.secondary)};
  }
`}
`;

export const StyledTooltip = styled(Tooltip)<{ disabled: boolean }>`
${({ theme, disabled }) => css`
  width: 100%;
  /* display: flex;
  align-items: center;
  justify-content: center;

  transition: 0.5s;

  > p {
    font-size: ${theme.fontSizes.large};
    color: ${theme.colors.secondary};
  }

  > h6 {
    font-size: ${theme.fontSizes.large};
    color: ${theme.colors.secondary};

    margin-left: 0.8rem;
  }

  opacity: ${disabled ? 0.3 : 1};

  :hover {
    transform: scale(1.008);
    box-shadow: 0px 0.2rem 0.2rem ${transparentize(0.72, theme.colors.secondary)};
  } */
`}
`;

export const TopHeaderContent = styled.div`
${({ theme }) => css`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  margin-bottom: 6.4rem;

  > div:first-child {
    display: flex;
    flex-direction: column;

    > p {
      margin-bottom: 1.6rem;
      max-width: 24rem;
    }

    > label {
      font-size: ${theme.fontSizes.default};
      margin-bottom: 0.8rem;
    }

    > input {
      background: ${theme.colors.lightGrey};
      border: 0;
      padding: 0.8rem 0.8rem;

      color: ${theme.colors.grey};
    }

    > div:last-child {
      display: flex;
      align-items: center;

      margin-top: 1.2rem;

      > p {
        line-height: 2.2rem;
      }

      > input {
        margin-right: 0.8rem;
      }
    }
  }

  @media (max-width: 670px) {
    flex-direction: column;

    > div:last-child {
      margin-bottom: 1.6rem;
    }

    > div:first-child {
      margin-bottom: 1.6rem;
    }
  }
`}
`;

export const BottomHeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-bottom: 3.2rem;
`;

export const RightBottomHeaderContent = styled.div`
${({ theme }) => css`
  flex: 1;
  margin-left: 3.2rem;

  input {
    background: ${theme.colors.lightGrey};
    color: ${theme.colors.grey};
    border: 0;
    padding: 0.8rem;
    margin-top: 0.8rem;

    max-width: 6rem;
  }
`}
`;

export const RightBottomHeaderInfos = styled.div<{ pm_selected: boolean; balance_selected: boolean; valorization_selected: boolean }>`
${({ theme, pm_selected, balance_selected, valorization_selected }) => css`
  display: flex;
  justify-content: space-between;

  ${!pm_selected && css`
    > div:nth-child(1) {
      > button {
        opacity: 0.3;
      }
    }
  `}

  ${!balance_selected && css`
    > div:nth-child(1) {
      > button {
        opacity: 0.3;
      }
    }
  `}

  ${!valorization_selected && css`
    > div:nth-child(3) {
      > button {
        opacity: 0.3;
      }
    }
  `}

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin-bottom: 3.2rem;

    padding: 1.6rem;

    > button {
      background: 0;
      border: 0;
      color: ${theme.colors.primaryDark};
      margin-bottom: 0.8rem;
      font-size: ${theme.fontSizes.default};

      transition: 0.2s;
    }

    > div {
      display: flex;
      align-items: center;

      > p {
        font-size: ${theme.fontSizes.huge};
        color: ${theme.colors.darkGrey};
      }

      > h6 {
        margin-left: 0.8rem;
        font-size: ${theme.fontSizes.large};
      }
    }
  }

`}
`;

