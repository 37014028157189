import React, { useMemo, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Chart from "react-apexcharts";
import { useTheme } from 'styled-components';

import { animations } from '../../../../resources';

import {
  Container,
  GraphContainer,
  NoContentWrapper,
} from './styles';

import { IState } from '../../../../store';
import { IWalletProfit } from '../../../../store/modules/actives/types';
import { format } from 'date-fns';
import formatCurrency from '../../../../utils/formatCurrency';
import { utcToZonedTime } from 'date-fns-tz';
import { Box } from '../../../../components/Box';
import { usePatrimonyChart } from '../../../../hooks/react-query/usePatrimonyChart';

const containerAnimation = {
  ...animations.containerAnimation,
  mounted: {
    ...animations.containerAnimation.mounted,
    transition: {
      ...animations.containerAnimation.mounted.transition,
      duration: undefined,
      staggerChildren: undefined,
      delay: 0.9,
    }
  },
}

interface ProfitGraphProps {
  wallets_profits: IWalletProfit[];
  isProfitGraphExpanded: boolean;
}

const ProfitGraph:React.FC<ProfitGraphProps> = ({ wallets_profits, isProfitGraphExpanded }) =>  {
  const [display, setDisplay] = useState(false);
  const { data: patrimonyData, isLoading: isLoadingPatrimony } = usePatrimonyChart()

  useEffect(() => {
    setTimeout(() => setDisplay(true), 1);
  }, [])

  const theme = useTheme();

  const chartData = useMemo(() => {
    const patrimony = [...patrimonyData?.totalPatrimony?.patrimony || []]
    const invested = [...patrimonyData?.totalPatrimony?.invested || []]

    return [
      {
        name: 'Valor aportado',
        // data: invested.slice(-60),
        data: invested,
      },
      {
        name: 'Patrimônio',
        // data: patrimony.slice(-60),
        data: patrimony,
      },
    ]
  }, [patrimonyData]);

  const maxAndMinsForProfitGraph = useMemo(() => {
    const patrimonyLowestValue = chartData[1].data.reduce((acc, curr) => {
      if (curr.y < acc.y) {
        return curr;
      }

      return acc
    }, { y: chartData[1].data?.length ? Infinity : 0 });

    return {
      min: patrimonyLowestValue.y - patrimonyLowestValue.y * 0.1,
      max: patrimonyData?.totalPatrimony?.maxValue,
    }
  }, [chartData]);

  const linearChartColor = useMemo(() => {
    const lastItemIndex = chartData[0].data.length - 1;

    if (lastItemIndex === -1) {
      return [theme.colors.success, theme.colors.primary];
    }

    if (chartData[0].data[lastItemIndex].y > chartData[1].data[lastItemIndex].y) {
      return [theme.colors.success, theme.colors.error];
    }

    return [theme.colors.success, theme.colors.primary];
  }, [chartData]);

  const chartLabelColors = useMemo(() => {
    const colors = chartData[0].data.map((s: any) => theme.colors.grey);

    return colors;
  }, [chartData]);

  const chartDataIsEmpty = useMemo(() => {
    return !patrimonyData?.totalPatrimony?.maxValue;
  }, [chartData]);

  if(!display) {
    return <Box h={'51.5rem'}/>;
  }

  if (isLoadingPatrimony) {
    return <Box h={'51.5rem'}/>;
  }

  return (
    <Container
      isProfitGraphExpanded={isProfitGraphExpanded}
      variants={containerAnimation}
      adjustHeight={!!chartData[0].data.length}
    >
      <h1>Evolução do Patrimônio</h1>

      {!chartDataIsEmpty ? (
        <GraphContainer>
            <Chart
              options={{
                stroke: {
                  width: 2,
                },
                colors: linearChartColor,
                fill: {
                  type: 'gradient',
                  gradient: {
                    shade: 'dark',
                    opacityFrom: 0.6,
                    opacityTo: 0.25,
                  }
                },
                dataLabels: {
                  enabled: false,
                  distributed: true,
                  offsetY: -8,
                  style: {
                    colors: [linearChartColor],
                  }
                },
                tooltip: {
                  shared: true,
                  enabled: true,
                  x: {
                    show: true,
                    formatter: (x) => `${format(utcToZonedTime(new Date(x), 'America/Sao_Paulo'), 'dd-MM-yyyy')}`,
                  },
                  y: [
                    {
                      formatter: (val) => formatCurrency(val),
                      title: {
                        formatter: (seriesName) => seriesName
                      }
                    },
                    {
                      formatter: (val) => formatCurrency(val),
                      title: {
                        formatter: (seriesName) => seriesName
                      }
                    }
                  ],
                  marker: {
                    show: false,
                  }
                },
                chart: {
                  animations: {
                    enabled: true,
                    easing: 'easeinout',
                    speed: 500,
                  },
                  id: "linear-chart",
                  zoom: {
                    enabled: true,
                  },
                  toolbar: {
                    show: false,
                    offsetX: 0,
                    offsetY: 0,
                    autoSelected: 'pan',
                    tools: {
                      selection: false,
                      pan: false,
                      download: false,
                      zoom: false,
                      zoomin: false,
                      zoomout: false,
                      reset: false,
                    },
                  },
                },
                legend: {
                  offsetY: 8,
                  labels: {
                    colors: chartLabelColors,
                  },
                  markers: {
                    fillColors: linearChartColor
                  }
                },
                xaxis: {
                  type: 'datetime',
                  tooltip: {
                    enabled: false,
                  },
                  axisBorder: {
                    show: true,
                    color: theme.colors.grey,
                  },
                  labels: {
                    style: {
                      fontSize: '1.2rem',
                      colors: chartLabelColors,
                    },
                    rotate: -45,
                  }
                },
                yaxis: {
                  min: maxAndMinsForProfitGraph.min,
                  max: maxAndMinsForProfitGraph.max,
                  title: {
                    text: 'Valor Aportado',
                  },
                  seriesName: 'Valor Aportado',
                  axisBorder: {
                    show: false,
                  },
                  labels: {
                    formatter: (val) => formatCurrency(val),
                    style: {
                      fontSize: '1.2rem',
                      colors: chartLabelColors,
                    },
                  }
                },
                grid: {
                  borderColor: theme.colors.lightGrey,
                }
              }}
              series={chartData}
              type="area"
              width="100%"
              height="100%"
            />
        </GraphContainer>
      ) : (
        <NoContentWrapper>
          <p>Aqui aparecerão seus lucros diários, assim que eles estiverem disponíveis :)</p>
        </NoContentWrapper>
      )}
    </Container>
  );
};

const mapStateToProps = (state: IState) => ({
  wallets_profits: state.actives.wallets_profits,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ProfitGraph)
