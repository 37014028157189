import { transparentize } from "polished";
import styled, { css } from "styled-components";
import Button from "../../../../components/Button";

export const StyledButton = styled(Button)`
${({ theme }) => css`
  padding: 1.6rem 1.6rem;
  height: 5rem;

  > svg {
    margin-right: 0.8rem;
  }

  transition: 0.45s;

  &:hover {
      transform: scale(1.004);
      box-shadow: 0px 0.1rem 0.1rem ${transparentize(0.05, theme.colors.secondary)};
  }
`}
`;
