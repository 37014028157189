import React, { useMemo, useState } from 'react';
import { FiX } from 'react-icons/fi';
import { connect } from 'react-redux';
import { useTheme } from 'styled-components';
import Chart from "react-apexcharts";
import { useHistory } from 'react-router-dom';

import { animations } from '../../../../resources';


import {
  Container,
  ChartContainer,
  NoContentWrapper,
} from './styles';

import { IState } from '../../../../store';
import { IWallet } from '../../../../store/modules/wallet/types';
import { ITesouroWallet } from '../../../../store/modules/tesouroWallet/types';
import formatCurrency from '../../../../utils/formatCurrency';
import getPallete from '../../../../utils/colors';
import Button from '../../../../components/Button';

interface MoneyBalanceProps {
  wallets: IWallet[];
  tesouro_wallets: ITesouroWallet[];
  isDarkTheme: boolean;
}

const containerAnimation = {
  ...animations.containerAnimation,
  mounted: {
    ...animations.containerAnimation.mounted,
    transition: {
      ...animations.containerAnimation.mounted.transition,
      duration: undefined,
      staggerChildren: undefined,
      delay: 0.6,
    }
  },
}

const MoneyBalance: React.FC<MoneyBalanceProps> = ({ wallets, tesouro_wallets, isDarkTheme }) =>  {
  const theme = useTheme();
  const history = useHistory();

  const handleGoToCreateWallet = () => {
    history.push('/dashboard/create-wallet');
  }

  const pieChartDataLabels = useMemo(() => {
    const labels = [] as string[];

    wallets.forEach(wallet => { labels.push(wallet.name) });
    tesouro_wallets.forEach(tesouro_wallet => { labels.push(tesouro_wallet.name) });

    return labels;
  }, [wallets, tesouro_wallets]);

  const pieChartColors = useMemo(() => {
    let stockWalletsAmount = wallets.length;
    let tesouroWalletsAmount = tesouro_wallets.length;

    const stockWalletColors = getPallete(theme.colors.secondary, theme.colors.primaryDark, stockWalletsAmount);
    const tesouroWalletColors = getPallete('#27187E', theme.colors.success, tesouroWalletsAmount);

    let walletColorIndex = 0;
    let tesouroWalletColorIndex = 0;

    const colors = [] as string[];

    wallets.forEach(() => {
      walletColorIndex += 1;

      colors.push(stockWalletColors[walletColorIndex - 1]);
    });

    tesouro_wallets.forEach(() => {
      tesouroWalletColorIndex += 1;

      colors.push(tesouroWalletColors[tesouroWalletColorIndex - 1]);
    });

    return colors;
  }, [wallets, tesouro_wallets]);

  const pieChartData = useMemo(() => {
    const chartData = [] as number[];

    wallets.forEach(wallet => { chartData.push(wallet.current_wallet_value) });
    tesouro_wallets.forEach(tesouro_wallet => { chartData.push(tesouro_wallet.current_wallet_value) });

    return chartData;
  }, [wallets, tesouro_wallets]);

  return (
    <Container
      variants={containerAnimation}
    >
      <h1>Balanço Patrimonial</h1>

      <ChartContainer>
        {!!pieChartData.length && (
          <Chart
            options={{
              tooltip: {
                y: {
                  formatter: function(val) {
                    return formatCurrency(val / 100)
                  },
                  title: {
                    formatter: function (seriesName) {
                      return seriesName;
                    },
                  },
                }
              },
              labels: pieChartDataLabels,
              legend: {
                // floating: true,
                position: 'bottom',
                offsetY: 8,
                height: 36,
                show: true,
                labels: {
                  colors: [theme.colors.grey],
                }
              },
              states: {
                active: {
                  filter: {
                    type: 'none'
                  },
                },
                hover: {
                  filter: {
                    type: 'none'
                  },
                },
              },
              chart: {
                id: 'main-page-pie-chart',
                events: {
                  dataPointSelection: function(event, chartContext, config) {
                    // handleGoToWallet(config.selectedDataPoints);
                  },
                }
              },
              colors: pieChartColors,
              stroke: {
                show: false,
              },
              dataLabels: {
                dropShadow: {
                  opacity: 0.8
                },
                formatter: (val, opt) => {
                  return `${Number(val).toFixed(2)} %`;
                },
              },
              plotOptions: {
                pie: {
                  expandOnClick: false,
                  dataLabels: {
                    offset: -5,
                  },
                  donut: {
                    background: 'transparent',
                  },
                },
              }
            }}
            series={pieChartData}
            type="donut"
            width={'100%'}
            height={'100%'}
            animations={{ initialAnimation: { enabled: true }}}
          />
        )}
      </ChartContainer>
      {!pieChartData.length && (
        <NoContentWrapper>
          <p>O balanceamento de suas carteiras aparecerão aqui quando criá-las!</p>
          <Button
            onClick={handleGoToCreateWallet}
          >
            Criar Carteira
          </Button>
        </NoContentWrapper>
      )}
    </Container>
  );
};

const mapStateToProps = (state: IState) => ({
  wallets: state.wallet.wallets,
  tesouro_wallets: state.tesouro.tesouro_wallets,
  balance: state.actives.balance,
  isDarkTheme: !state.themeSelector.isLightMode,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(MoneyBalance)
