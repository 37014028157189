import styled, { css } from 'styled-components';

export const Container = styled.div<{
  width?: number;
  color?: string;
  textColor?: string;
  position?: string;
}>`
${({ theme, width, color, textColor, position }) => css`
  position: relative;

  > span {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    ${width && css`
      width: ${width}px;
    `}

    background: ${theme.colors.primary};
    padding: 8px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    opacity: 0;
    transition: opacity 0.4s;
    visibility: hidden;

    z-index: 999;

    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    color: ${theme.colors.background};

    ${position === 'top' && css`
      bottom: calc(100% + 12px);
    `}

    ${position === 'bottom' && css`
      top: calc(100% + 12px);
    `}

    ${position === 'right' && css`
      transform: translateX(0%);
      top: -5.6rem;
      left: calc(100% + 12px);
    `}

    ${color && css`
      background: ${color};
    `}

    ${textColor && css`
      color: ${textColor};
    `}

    &::before {
      content: '';
      border-style: solid;
      border-color: ${theme.colors.primary} transparent;

      position: absolute;
      left: 50%;
      transform: translateX(-50%);

      ${position === 'top' && css`
        top: 100%;
        border-width: 6px 6px 0px 6px;
      `}

      ${position === 'bottom' && css`
        bottom: 100%;
        border-width: 0px 6px 6px 6px;
      `}

      ${position === 'right' && css`
        left: 0%;
        border-width: 0px 6px 6px 6px;
      `}

      ${color && css`
        border-color: ${color} transparent;
      `}
    }
  }

  &:hover span {
    opacity: 1;
    visibility: visible;
  }
`}
`;
