import { motion } from "framer-motion";
import { transparentize } from "polished";
import styled, { css } from "styled-components";

export const Container = styled(motion.div)`
${({ theme }) => css`
  background: ${theme.colors.background};
  border-radius: 0.4rem;

  z-index: 100;
`}
`;

export const SelectStockContainer = styled.div`
${({ theme }) => css`
  height: 6rem;

  > div {
    height: 100%;
  }
`}
`;

export const DateContainer = styled.div`
${({ theme }) => css`
  padding: 2rem 2.4rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  > span {
    color: ${theme.colors.grey};
    font-size: ${theme.fontSizes.default};
  }

  > input {
    background: ${theme.colors.lightGrey};
    border: 0;

    color: ${theme.colors.white};

    padding: 0.8rem 1.6rem;

    max-width: 50%;
  }
`}
`;

export const AmountContainer = styled.div`
${({ theme }) => css`
  padding: 2rem 2.4rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  > span {
    color: ${theme.colors.grey};
    font-size: ${theme.fontSizes.default};
  }

  > div {
    max-width: 50%;

    > input {
      background: ${theme.colors.lightGrey};
      border: 0;

      color: ${theme.colors.white};

      padding: 0.8rem 1.6rem;
    }
  }
`}
`;

export const PriceContainer = styled.div`
${({ theme }) => css`
  padding: 2rem 2.4rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  > span {
    color: ${theme.colors.grey};
    font-size: ${theme.fontSizes.default};
  }

  > input {
    max-width: 50%;
    background: ${theme.colors.lightGrey};
    border: 0;

    color: ${theme.colors.white};

    padding: 0.8rem 1.6rem;
  }
`}
`;


export const ButtonsContainer = styled.div`
${({ theme }) => css`
  display: flex;


  height: 7rem;
`}
`;

export const BuyButton = styled.button<{ disabled: boolean }>`
${({ theme, disabled }) => css`
  width: 100%;

  background: ${theme.colors.success};

  > span {
    color: ${theme.colors.success};
    padding: 0.4rem;

    background: ${theme.colors.background};

    border-radius: 0.4rem;
  }

  ${disabled && css`
    cursor: not-allowed;
    opacity: 0.5;
  `}
`}
`;

export const SellButton = styled.button<{ disabled: boolean }>`
${({ theme, disabled }) => css`
  width: 100%;

  background: ${theme.colors.error};

  > span {
    color: ${theme.colors.error};
    padding: 0.4rem;

    background: ${theme.colors.background};

    border-radius: 0.4rem;
  }

  ${disabled && css`
    opacity: 0.5;
    cursor: not-allowed;
  `}
`}
`;
