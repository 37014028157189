import React from 'react';
import { FiCheck, FiCreditCard } from 'react-icons/fi';
import { connect } from 'react-redux';
import { useTheme } from 'styled-components';
import WlltIcon from '../../../../assets/images/icons/wllt';
import { Loading, Show, Typography } from '../../../../components';
import { Box } from '../../../../components/Box';

import Button from '../../../../components/Button';
import { ModalTypesEnum, useModals } from '../../../../contexts/modals';
import { useWallets } from '../../../../hooks/react-query/useWallets';

import { IState } from '../../../../store';

import * as Styled from './styles';

interface OnboardingCreateWalletsProps {
  handleNextStep: () => void;
  handleCloseOnboarding: () => void;
}

const OnboardingCreateWallets: React.FC<OnboardingCreateWalletsProps> = ({ handleCloseOnboarding, handleNextStep }) => {
  const theme = useTheme()
  const { turnModalOn } = useModals()
  const { data: wallets, isLoading: isLoadingWallets } = useWallets()

  const handleOpenWalletCreationModal = () => {
    turnModalOn(ModalTypesEnum.CREATE_WALLET)
  }

  return (
  <>
    <Styled.Header>
      <Styled.WlltIconContainer>
        <WlltIcon
          height={48}
          width={59}
        />
      </Styled.WlltIconContainer>

      <Styled.StepContainer>
        <Styled.Step active/>
        <Styled.StepSeparator />
        <Styled.Step />
      </Styled.StepContainer>

      <Styled.Title>Primeiro Passo: <strong>Criar suas carteiras</strong></Styled.Title>
      <Styled.Subtitle>Sua carteira vai carregar todos os seus ativos e informará dados de performance que serão essenciais para o seu crescimento como investidor. </Styled.Subtitle>
      <Styled.ThirdTitle>Defina um nome que resuma que tipo de ativos você vai inserir nela, como por exemplo: Dividendos, Valor, Small Caps, FIIs, Tesouro, Criptomoedas...</Styled.ThirdTitle>
    </Styled.Header>

    <Styled.Content>
      <Box justifyContent='center' alignItems='center' w='100%'>
        <Button revision='primary' onClick={handleOpenWalletCreationModal}>Criar Carteira</Button>
      </Box>

      <Show when={wallets.length}>
        <Box flexDirection='column'>
          <Box mt={'2.4rem'} />
          <Typography>Minhas Carteiras</Typography>
          <Box mb={'0.8rem'} />
          <Box style={{ flexWrap: 'wrap' }} maxWidth={'102rem'}>
            {wallets.map((wallet) => (
              <Box
                mr={'1.6rem'}
                mb={'0.8rem'}
                key={wallet.id}
                borderColor={theme.colors.primary}
                borderRadius={'0.8rem'}
                p={'0.8rem'}
                flexDirection='column'
              >
                <Box alignItems='center'>
                  <FiCreditCard stroke={theme.colors.primary} />
                  <Box mr={'0.8rem'} />
                  <Typography type='body3' color={theme.colors.primary}>
                    {wallet.name}
                  </Typography>
                </Box>
                <Box>
                  <Typography type='body2'>{wallet.typeName}</Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Show>
      <Show when={!wallets.length && !isLoadingWallets}>
        <Box w={'100%'} justifyContent='center' mt={'2.4rem'}>
          <Typography>Sem carteiras criadas no momento</Typography>
        </Box>
      </Show>
      <Box mb={'0.8rem'} />
      <Show when={isLoadingWallets}>
        <Loading color={theme.colors.primary} size={8} />
      </Show>

    </Styled.Content>

    <Styled.Footer>
      <Button
        onClick={handleNextStep}
        revision='primary'
        disabled={!wallets.length}
        disableMessage={!wallets.length ? 'Tente criar sua primeira carteira' : ''}
      >
        <Styled.ButtonContainer>
          <FiCheck />
          <span>Próximo Passo</span>
        </Styled.ButtonContainer>
      </Button>
      <Box  mr={'1.6rem'} />
      <Button
        onClick={handleCloseOnboarding}
        revision='secondary'
      >
        <Styled.ButtonContainer>
          <span>Eu já sei usar o Wllt</span>
        </Styled.ButtonContainer>
      </Button>
    </Styled.Footer>
  </>
  )
}

const mapStateToProps = (state: IState) => ({ })

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingCreateWallets)
