import { transparentize } from 'polished'
import styled, { css } from 'styled-components'

export const Container = styled.div`
${({ theme }) => css`
  background-color: ${transparentize(0.05, theme.colors.background)};
  border-radius: 1.6rem;
  padding: 1.6rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 80%;

  @media (max-width: 670px) {
    padding: 0.8rem;
    max-width: 95%;
    margin: 0 auto;
    width: 100%;
    margin-bottom: 1.6rem;
  }
`}
`

export const GraphContainer = styled.div`
${({ theme }) => css`
  display: flex;
  width: 100%;

  > svg {
    margin: auto 0;
    cursor: pointer;

    transition: 0.45s;

    margin-left: 0.8rem;
  }

  > svg:hover {
    color: ${theme.colors.primary};
  }

  > div {
    flex: 1;
  }
`}
`
