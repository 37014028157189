import { format, isEqual, startOfDay, startOfMonth } from 'date-fns';
import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import Currency from '../../../../../components/Currency';

import { IState } from '../../../../../store';
import { IProfit, ProfitTypeTranslator } from '../../../../../store/modules/profit/types';
import { getMonthObject } from '../../../../../utils/dateUtils';

import * as Styles from './styles';

interface FutureEarningsMonths {
  date: Date;
  label: string;
  value: number;
  month_profits: {
    date: Date;
    label: string;
    value: number;
    day_profits: {
      label: string;
      value: number;
    }[];
  }[];
}

interface FutureEarningsListProps {
  profits: IProfit[]
}

const FutureEarningsList: React.FC<FutureEarningsListProps> = ({ profits }) => {
  const futureEarningsGroupedByMonth = useMemo(() => {
    const futureEarnings = profits.filter(profit => new Date(profit.created_at) > new Date())

    const futureEarningMonths: FutureEarningsMonths[] = []

    for (const profit of futureEarnings) {
      const profitMonth = startOfMonth(new Date(profit.created_at));
      const profitDay = startOfDay(new Date(profit.created_at));

      const futureMonth = futureEarningMonths.find(f => isEqual(f.date, profitMonth));

      if (futureMonth) {
        const futureMonthDate = futureMonth.month_profits.find(p => isEqual(p.date, profitDay));
        futureMonth.value += profit.profit;

        if (futureMonthDate) {
          futureMonthDate.day_profits.push({
            label: `${ProfitTypeTranslator[profit.type]} de ${profit.name}`,
            value: profit.profit,
          });

          futureMonthDate.value += profit.profit;
        } else {
          futureMonth.month_profits.push({
            date: startOfMonth(new Date(profit.created_at)),
            label: `${format(new Date(profit.created_at), 'dd/MM/yyyy')}`,
            value: profit.profit,
            day_profits: [{
              label: `${ProfitTypeTranslator[profit.type]} de ${profit.name}`,
              value: profit.profit,
            }],
          });
        }
      } else {
        const monthObject = getMonthObject(new Date(profit.created_at).getMonth())

        futureEarningMonths.push({
          date: startOfMonth(new Date(profit.created_at)),
          label: `${monthObject.monthString} de ${new Date(profit.created_at).getFullYear()}`,
          value: profit.profit,
          month_profits: [{
            date: startOfDay(new Date(profit.created_at)),
            label: `${format(new Date(profit.created_at), 'dd/MM/yyyy')}`,
            value: profit.profit,
            day_profits: [{
              label: `${ProfitTypeTranslator[profit.type]} de ${profit.name}`,
              value: profit.profit,
            }],
          }]
        })
      }
    }

    const sortedEarnings = futureEarningMonths.sort((a, b) => a.date.getTime() - b.date.getTime());
    futureEarningMonths.forEach((futureEarningMonth) => {
      futureEarningMonth.month_profits.sort((a, b) => a.date.getTime() - b.date.getTime());
    })

    return sortedEarnings;
  }, [profits]);

  return (
  <Styles.Container>
    {futureEarningsGroupedByMonth.map(futureEarningMonth => (
      <Styles.MonthContainer>
        <Styles.Heading>
          <h1>{futureEarningMonth.label}</h1>
          <Currency
            value={futureEarningMonth.value}
          />
        </Styles.Heading>

        <Styles.Body>
          {
            futureEarningMonth.month_profits.map((dayOfMonthProfit) => (
              <Styles.MonthContent>
                <Styles.SideBar />
                <Styles.DayOfMonthContainer>
                  <Styles.DayOfMonthDot />
                  <Styles.DayOfMonthHeading>
                    {dayOfMonthProfit.label}
                  </Styles.DayOfMonthHeading>

                  <Styles.EarningsList>
                    {dayOfMonthProfit.day_profits.map((dayEarning) => (
                      <Styles.DayEarning>
                        <Styles.DayEarningLabel>
                          {dayEarning.label}
                        </Styles.DayEarningLabel>
                        <Currency
                          value={dayEarning.value}
                        />
                      </Styles.DayEarning>
                    ))}
                  </Styles.EarningsList>
                </Styles.DayOfMonthContainer>
              </Styles.MonthContent>
            ))
          }
        </Styles.Body>
      </Styles.MonthContainer>
    ))}
  </Styles.Container>
  )
}

const mapStateToProps = (state: IState) => ({
  profits: state.profit.profits,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(FutureEarningsList)
