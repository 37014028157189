import React, { useState, useEffect } from 'react';
import { Switch, useLocation, Route, Redirect } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import PrivateRoute from './PrivateRoute';

import Dashboard from '../pages/Dashboard';
import LogIn from '../pages/LogIn';
import { useSelector } from 'react-redux';
import { IState } from '../store';

import LoadingComponent from '../components/LoadingComponent';
import Register from '../pages/Register';

const Routes: React.FC = () => {
  const isLoadingWallets = useSelector<IState, boolean>(state => state.wallet.isLoadingWallets);
  const isLoadingValuation = useSelector<IState, boolean>(state => state.valuations.isLoadingValuation);
  const isLoadingUser = useSelector<IState, boolean>(state => state.user.isLoadingUser);
  const isLoadingActives = useSelector<IState, boolean>(state => state.actives.isLoadingActives);
  const isLoadingBalance = useSelector<IState, boolean>(state => state.actives.isLoadingBalance);
  const isLoadingRating = useSelector<IState, boolean>(state => state.actives.isLoadingRating);
  const isLoadingTesouroWallets = useSelector<IState, boolean>(state => state.tesouro.isLoadingTesouroWallets);
  const isLoadingCryptoWallets = useSelector<IState, boolean>(state => state.crypto.isLoadingCryptoWallets);

  const location = useLocation();
  const [key,] = useState(Math.random());
  const [alreadyShownAnimation, setAlreadyShownAnimation] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setAlreadyShownAnimation(true);
    }, 3500);
  }, []);

  console.log(isLoadingWallets, isLoadingValuation, isLoadingUser, isLoadingActives, isLoadingBalance, isLoadingRating, isLoadingTesouroWallets, isLoadingCryptoWallets);

  const isLoading = isLoadingWallets || isLoadingValuation || isLoadingUser || isLoadingActives || isLoadingBalance || isLoadingRating || isLoadingTesouroWallets || isLoadingCryptoWallets;

  if (isLoading || !alreadyShownAnimation) {
    return (
      <LoadingComponent />
    )
  }

  return (
    <AnimatePresence>
      <Switch location={location} key={key}>
        <PrivateRoute exact path="/" component={LogIn} />
        <PrivateRoute exact path="/register" component={Register} />
        <PrivateRoute path="/dashboard" component={Dashboard} isPrivate />

        <Route render={() => <Redirect to="/" />} />
      </Switch>
    </AnimatePresence>
  )
}

export default Routes;
