import React, { useCallback, useRef, useState } from 'react';
import { FiCheck, FiChevronDown } from 'react-icons/fi';
import useEventListener from '../../../../../../../hooks/useEventListener';

import {
  Container,
  DropdownContainer,
  DropdownOption,
  OptionLabel,
} from './styles';

interface SelectColumnInputProps {
  setNewColumnName(newMatch: string): void;
  options: string[];
  matchingColumn: string;
}

const SelectColumnInput: React.FC<SelectColumnInputProps> = ({
  setNewColumnName,
  options,
  matchingColumn,
}) => {
  const [isOpened, setIsOpened] = useState(false);
  const dropdownRef = useRef<HTMLButtonElement>(null);

  const handleCloseDropdown = useCallback(({ target }: Event): void => {
    if (dropdownRef.current?.contains(target as Node)) {
      return;
    }

    setIsOpened(false);
  }, [setIsOpened]);

  useEventListener('click', handleCloseDropdown, {
    enabled: isOpened,
  });

  return (
    <Container
      ref={dropdownRef}
      onClick={() => setIsOpened(state => !state)}
      bold={matchingColumn === ''}
    >
      <span>
        {matchingColumn === '' ? 'Deixar em branco' : matchingColumn}
      </span>
      <FiChevronDown
        size={24}
      />

      {isOpened && (
        <DropdownContainer>
          <DropdownOption
            onClick={() => setNewColumnName('')}
          >
            <OptionLabel
              bold
            >
              Deixar em branco
            </OptionLabel>
            {matchingColumn === '' && (
              <FiCheck
                size={24}
              />
            )}
          </DropdownOption>
          {
            options.map((option) => (
              <DropdownOption
                onClick={() => setNewColumnName(option)}
              >
                <OptionLabel>
                  {option}
                </OptionLabel>
                {option === matchingColumn && (
                  <FiCheck
                    size={24}
                  />
                )}
              </DropdownOption>
            ))
          }
        </DropdownContainer>
      )}
    </Container>
  )
}

export default SelectColumnInput;
