import React, { useCallback, useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';

import { NumberFormatStyled } from './styles';

interface CurrencyInputProps {
  callBack(param: string): void;
  dollar?: boolean;
  defaultValue?: string;
}

interface IInputValues {
  formattedValue: string;
  value: string;
}

const CurrencyInput: React.FC<CurrencyInputProps> = ({ dollar, callBack, defaultValue = '' }) => {
  const [inputValue, setInputValue] = useState(defaultValue);

  useEffect(() => {
    setInputValue(defaultValue);
  }, [defaultValue]);

  const handleChangeInputValue = useCallback(({ value, formattedValue }: IInputValues) => {
    setInputValue(formattedValue);
    callBack(value);
  }, [callBack]);

  const prefix = dollar ? '$ ' : 'R$ '

  return (
    <NumberFormatStyled
      thousandSeparator={"."}
      decimalSeparator={","}
      decimalScale={2}
      prefix={prefix}
      value={inputValue}
      placeholder={`${prefix} 0,00`}
      onValueChange={handleChangeInputValue}
      defaultValue={defaultValue}
    />
  );
};

export default CurrencyInput;
