import styled, { css } from 'styled-components';

import { motion } from 'framer-motion';

export const Container = styled(motion.div)`
${({ theme }) => css`
  position: relative;

  background: ${theme.colors.background};
  width: 60%;

  border-radius: 0.8rem;
  padding: 1.6rem;

  box-shadow: ${theme.shadows.default};

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  span {
    margin-bottom: 0.8rem;
  }

  > h1 {
    padding: 0.4rem 0.8rem;
    background: ${theme.colors.extremeLightGrey};

    border-radius: 0.8rem;

    color: ${theme.colors.primary};
    font-size: ${theme.fontSizes.huge};

    margin-bottom: 0rem;
  }

  @media (max-width: 670px) {
    margin-left: 0rem;
    width: 100%;
  }
`}
`;

export const GraphContainer = styled.div`
${({ theme }) => css`
  width: 100%;
  height: 100%;

  flex: 1;

  border-radius: 0.8rem;

  @media (max-width: 670px) {
    overflow: hidden;
    padding: 0.8rem;
  }
`}
`;

export const NoContentWrapper = styled.div`
${({ theme }) => css`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  > p {
    text-align: center;
  }

  > button {
    padding: 1.6rem;
  }
`}
`;
