import { IValuation } from "../../../store/modules/valuation/types";

function orderByName(valuation: IValuation[], bigger: number) {
  const newStocks = valuation.sort((a, b) => {
    if (a.name > b.name) {
      return bigger;
    }

    if (a.name < b.name) {
      return -1 * bigger;
    }

    return 0;
  });

  return newStocks;
}

function orderByValuation(valuation: IValuation[], bigger: number) {
  const newStocks = valuation.sort((a, b) => {
    if (a.valuation > b.valuation) {
      return bigger;
    }

    if (a.valuation < b.valuation) {
      return -1 * bigger;
    }

    return 0;
  });

  return newStocks;
}

function orderByPrice(valuation: IValuation[], bigger: number) {
  const newStocks = valuation.sort((a, b) => {
    if (a.price > b.price) {
      return bigger;
    }

    if (a.price < b.price) {
      return -1 * bigger;
    }

    return 0;
  });

  return newStocks;
}

function orderByValorization(valuation: IValuation[], bigger: number) {
  const newStocks = valuation.sort((a, b) => {
    const firstValorization = a.valuation - a.price;
    const secondValorization = b.valuation - b.price;

    if (firstValorization > secondValorization) {
      return bigger;
    }

    if (firstValorization < secondValorization) {
      return -1 * bigger;
    }

    return 0;
  });

  return newStocks;
}

function orderByPercentage(valuation: IValuation[], bigger: number) {
  const newStocks = valuation.sort((a, b) => {
    const firstValorization = (a.valuation - a.price) / a.price;
    const secondValorization = (b.valuation - b.price) / b.price;

    if (firstValorization > secondValorization) {
      return bigger;
    }

    if (firstValorization < secondValorization) {
      return -1 * bigger;
    }

    return 0;
  });

  return newStocks;
}

function orderByDividendYield(valuation: IValuation[], bigger: number) {
  const newStocks = valuation.sort((a, b) => {
    if (a.div_yield > b.div_yield) {
      return bigger;
    }

    if (a.div_yield < b.div_yield) {
      return -1 * bigger;
    }

    return 0;
  });

  return newStocks;
}

export default function orderValuations(valuations: IValuation[], order_string: string, bigger: number) {
  if (order_string === 'name') {
    return orderByName(valuations, bigger);
  }

  if (order_string === 'valuation') {
    return orderByValuation(valuations, bigger);
  }

  if (order_string === 'price') {
    return orderByPrice(valuations, bigger);
  }

  if (order_string === 'valorization') {
    return orderByValorization(valuations, bigger);
  }

  if (order_string === 'percentage') {
    return orderByPercentage(valuations, bigger);
  }

  if (order_string === 'div_yield') {
    return orderByDividendYield(valuations, bigger);
  }

  return orderByName(valuations, bigger);
}
