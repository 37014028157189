import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled.div`
${({ theme }) => css`
  display: flex;
  flex-direction: column;

  background: ${theme.colors.background};

  border-radius: 0.4rem;
  padding: 2.4rem;

  width: 30%;

  > button {
    color: ${theme.colors.error};
    border-color: ${theme.colors.error};

    > svg {
      margin-right: 0.8rem;
    }
  }

  > p {
    margin-bottom: 1.6rem;
  }
`}
`;

export const Heading = styled.div`
${({ theme }) => css`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 1.6rem;

  > div:first-child {
    display: flex;
    align-items: center;

    > svg {
      color: ${theme.colors.primary};
      margin-right: 0.8rem;
    }

    > h1 {
      font-size: ${theme.fontSizes.huge};
    }
  }

  > div:nth-child(2) {
    display: flex;
    align-items: center;

    > svg {
      cursor: pointer;
    }
  }

`}
`;

export const Body = styled.div`
${({ theme }) => css`
  margin-bottom: 0.8rem;

  > p {
    margin-bottom: 0.4rem;
  }
`}
`;
