import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { FiPlus } from 'react-icons/fi';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { IState } from '../../../store';
import { IWallet } from '../../../store/modules/wallet/types';

import { DEFAULT_TRANSITION } from '../../../constants';
import { animations } from '../../../resources';

import WalletCard from './WalletCard';

import {
  Container,
  AddButton,
} from './styles';

import { ITesouroWallet } from '../../../store/modules/tesouroWallet/types';
import { ICryptoWallet } from '../../../store/modules/crypto/types';
import { useModals } from '../../../contexts/modals';

const cardsAnimation = {
  unMounted: { y: 25, opacity: 0 },
  mounted: {
    y: 0,
    opacity: 1,
  },
}

const AnimatedCard = motion.div;

interface WalletListProps {
  wallets: IWallet[];
  tesouro_wallets: ITesouroWallet[];
  crypto_wallets: ICryptoWallet[];
}

const WalletList: React.FC<WalletListProps> = ({ wallets, tesouro_wallets, crypto_wallets }) => {
  const history = useHistory();
  const { turnModalOn } = useModals()

  const navigateToWallet = (wallet: any) => {
    if (wallet.stocks) {
      history.push(`/dashboard/wallet/${wallet.id}`);
      return;
    }

    if (wallet.tesouros) {
      history.push(`/dashboard/tesouro-wallet/${wallet.id}`);
      return;
    }

    if (wallet.cryptos) {
      return;
    }
  }

  const navigateToCreateWallet = () => {
    turnModalOn('CREATE_WALLET')
  }

  return (
    <AnimatePresence>
      <Container
        variants={animations.containerAnimation}
        transition={DEFAULT_TRANSITION}
      >
        {
          wallets.map(wallet => (
            <AnimatedCard
              key={`card-${wallet.id}`}
              variants={cardsAnimation}
              transition={DEFAULT_TRANSITION}
              whileHover={{ y: -1 }}
              whileTap={{ y: 5 }}
            >
              <WalletCard
                type={"STOCK"}
                name={wallet.name}
                description="Carteira com as principais ações e bla bla bla, pode colocar qualquer descrição."
                amountInvested={wallet.total_wallet_value}
                value={wallet.current_wallet_value}
                profit={wallet.profit}
                profit_in_percentage={wallet.profit_in_percentage}
                onClick={() => navigateToWallet(wallet)}
              />
            </AnimatedCard>
          ))
        }

        {
          tesouro_wallets.map(wallet => (
            <AnimatedCard
              key={`card-${wallet.id}`}
              variants={cardsAnimation}
              transition={DEFAULT_TRANSITION}
              whileHover={{ y: -1 }}
              whileTap={{ y: 5 }}
            >
              <WalletCard
                type={"TESOURO"}
                name={wallet.name}
                description="Carteira com as principais ações e bla bla bla, pode colocar qualquer descrição."
                amountInvested={wallet.total_wallet_value}
                value={wallet.current_wallet_value}
                profit={wallet.profit}
                profit_in_percentage={wallet.profit_in_percentage}
                onClick={() => navigateToWallet(wallet)}
              />
            </AnimatedCard>
          ))
        }

        {
          crypto_wallets.map(wallet => (
            <AnimatedCard
              key={`card-${wallet.id}`}
              variants={cardsAnimation}
              transition={DEFAULT_TRANSITION}
              whileHover={{ y: -1 }}
              whileTap={{ y: 5 }}
            >
              <WalletCard
                type={"CRYPTO"}
                name={wallet.name}
                description="Carteira com as principais ações e bla bla bla, pode colocar qualquer descrição."
                amountInvested={wallet.total_wallet_value * 100}
                value={wallet.current_wallet_value * 100}
                profit={wallet.profit * 100}
                profit_in_percentage={wallet.profit_in_percentage * 1000}
                onClick={() => navigateToWallet(wallet)}
              />
            </AnimatedCard>
          ))
        }

        <AnimatedCard
          key={`card-${7}`}
          variants={cardsAnimation}
          transition={{ ...DEFAULT_TRANSITION, mass: 0.5 }}
          whileHover={{ y: -1 }}
          whileTap={{ y: 5 }}
          style={{ display: 'flex' }}
        >
          <AddButton
            onClick={navigateToCreateWallet}
            maleft={0.8}
          >
            <button>
              <FiPlus size={28} />
            </button>

            <span>Criar Carteira</span>
          </AddButton>
        </AnimatedCard>
      </Container>
    </AnimatePresence>
  );
}

const mapStateToProps = (state: IState) => ({
  wallets: state.wallet.wallets,
  tesouro_wallets: state.tesouro.tesouro_wallets,
  crypto_wallets: state.crypto.crypto_wallets,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(WalletList)
