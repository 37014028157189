import React, { useState } from 'react';
import { connect } from 'react-redux';

import { IState } from '../../../../store';
import { IWallet } from '../../../../store/modules/wallet/types';

import { animations } from '../../../../resources';

import { ITesouroWallet } from '../../../../store/modules/tesouroWallet/types';
import WalletSummary from './WalletSummary';

import {
  Container
} from './styles';
import { useHistory } from 'react-router-dom';
import { ICryptoWallet } from '../../../../store/modules/crypto/types';
import { PersonalizedWallet } from '../../../../store/modules/personalized_wallets/types';
import UpdatePersonalizedWalletModal from '../../../../contexts/modals/UpdatePersonalizedWalletModal';

const containerAnimation = (delay: number) => ({
  ...animations.containerAnimation,
  mounted: {
    ...animations.containerAnimation.mounted,
    transition: {
      ...animations.containerAnimation.mounted.transition,
      delay,
    }
  }
})

interface WalletsSummaryListProps {
  wallets: IWallet[];
  tesouro_wallets: ITesouroWallet[];
  crypto_wallets: ICryptoWallet[];
  personalized_wallets: PersonalizedWallet[];
}

const WalletsSummaryList: React.FC<WalletsSummaryListProps> = ({ personalized_wallets, wallets, tesouro_wallets, crypto_wallets }) =>  {
  const history = useHistory();
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [selectedWallet, setSelectedWallet] = useState<PersonalizedWallet | null>(null);

  const navigateToWallet = (walletId: string) => {
    history.push(`/dashboard/wallet/${walletId}`)
  }

  const handlePersonalizedWalletClick = (wallet: PersonalizedWallet) => {
    setSelectedWallet(wallet);
    setUpdateModalOpen(true);
  }

  return (
    <Container>
      {wallets.map((wallet, index) => (
        <WalletSummary
          key={wallet.id}
          today_variation={wallet.variation}
          onClick={() => navigateToWallet(wallet.id)}
          animation={containerAnimation(0.1 * index + 0.3)}
          total_current_value={wallet.current_wallet_value}
          total_wallet_value={wallet.total_wallet_value}
          name={wallet.name}
          profit={wallet.profit}
          profit_in_percentage={wallet.profit_in_percentage}
          infos={wallet.stocks.map(stock => {
            const profit_in_percentage = stock.totalPurchaseValue ? (stock.totalCurrentValue - stock.totalPurchaseValue) / (stock.totalPurchaseValue) * 1000 : 0;

            return {
              id: stock.id,
              name: stock.name,
              profit_in_percentage,
            }
          })}
        />
      ))}

      {tesouro_wallets.map((wallet, index) => (
        <WalletSummary
          key={wallet.id}
          onClick={() => history.push(`/dashboard/tesouro-wallet/${wallet.id}`)}
          animation={containerAnimation(0.1 * index + wallets.length * 0.1 + 0.3)}
          total_current_value={wallet.current_wallet_value}
          total_wallet_value={wallet.total_wallet_value}
          name={wallet.name}
          profit={wallet.profit}
          profit_in_percentage={wallet.profit_in_percentage}
          infos={wallet.tesouros.map(tesouro => {
            const profit_in_percentage = tesouro.profit_in_percentage;

            return {
              id: tesouro.id,
              name: tesouro.name,
              profit_in_percentage,
            }
          })}
        />
      ))}

      {crypto_wallets.map((wallet, index) => (
        <WalletSummary
          key={wallet.id}
          onClick={() => {}}
          today_variation={wallet.variation * 1000}
          animation={containerAnimation(0.1 * index + wallets.length * 0.1 + 0.3)}
          total_current_value={wallet.current_wallet_value * 100}
          total_wallet_value={wallet.total_wallet_value * 100}
          name={wallet.name}
          profit={wallet.profit * 100}
          profit_in_percentage={wallet.profit_in_percentage * 1000}
          infos={wallet.cryptos.map(crypto => {
            const profit_in_percentage = crypto.totalPurchaseValue ? (crypto.totalCurrentValue - crypto.totalPurchaseValue) / (crypto.totalPurchaseValue) * 1000 : 0;

            return {
              id: crypto.id,
              name: crypto.name,
              profit_in_percentage,
            }
          })}
        />
      ))}

      {personalized_wallets.map((wallet, index) => (
        <WalletSummary
          key={wallet.id}
          onClick={() => handlePersonalizedWalletClick(wallet)}
          today_variation={0}
          animation={containerAnimation(0.1 * index + wallets.length * 0.1 + 0.3)}
          total_current_value={wallet.current_value * 100}
          total_wallet_value={wallet.starting_value * 100}
          name={wallet.name}
          profit={(wallet.current_value - wallet.starting_value) * 100}
          profit_in_percentage={((wallet.current_value - wallet.starting_value) / (wallet.starting_value || 1)) * 1000 || 0}
          infos={[]}
        />
      ))}

      {selectedWallet && (
        <UpdatePersonalizedWalletModal
          isOpen={updateModalOpen}
          setIsOpen={() => setUpdateModalOpen(false)}
          wallet={selectedWallet}
        />
      )}
    </Container>
  );
};

const mapStateToProps = (state: IState) => ({
  wallets: state.wallet.wallets,
  tesouro_wallets: state.tesouro.tesouro_wallets,
  crypto_wallets: state.crypto.crypto_wallets,
  personalized_wallets: state.personalizedWallet.personalizedWallets,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(WalletsSummaryList)
