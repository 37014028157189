import React, { useMemo, useState } from 'react'
import { useTable } from 'react-table'

import * as Styled from './styles';

import Currency from '../../../../../components/Currency';
import { Box } from '../../../../../components/Box';
import Percentage from '../../../../../components/Percentage';
import { IOrder } from '../../../../../hooks/react-query/useOrders';
import { Show } from '../../../../../components/Show';
import { StockEmblem } from '../../../../../components/StockEmblem';
import { useWallet } from '..';
import { useTheme } from 'styled-components';
import { WidgetCard } from '../../../../../components/WidgetCard';


export const StocksProfitList: React.FC = () => {
  const [orderToDelete, setOrderToDelete] = useState<IOrder | null>(null)
  const [orderToEdit, setOrderToEdit] = useState<IOrder | null>(null)

  const { wallet } = useWallet()

  const theme = useTheme()

  const [reverseFilter, setReverseFilter] = useState(false);
  const [filter, setFilter] = useState('totalProfitPercentage');

  const columns = useMemo(() => {
    return (
      [
        {
          Header: 'Código',
          accessor: 'name',
        },
        {
          Header: 'Papéis',
          accessor: 'totalPapers',
          collapse: true,
        },
        {
          Header: 'Preço Médio',
          accessor: 'averagePrice',
        },
        {
          Header: 'Cotação Atual',
          accessor: 'currentPrice',
        },
        {
          Header: 'Variação Diária',
          accessor: 'todayVariation',
        },
        {
          Header: 'Lucro (R$)',
          accessor: 'totalProfit',
        },
        {
          Header: 'Lucro (%)',
          accessor: 'totalProfitPercentage',
        },
      ]
    )
  }, []);

  const stocksData = useMemo(() => {
    return wallet.stocks.map((stock) => ({
      image_url: stock.image_url,
      name: stock.name,
      totalPapers: stock.totalPapers,
      averagePrice: stock.averagePrice,
      currentPrice: stock.current_value,
      todayVariation: stock.todayVariation,
      totalProfit: stock.totalProfit,
      totalProfitPercentage: stock.totalProfitInPercentage,
    }))
  }, [wallet])

  const ordersOrdered = useMemo(() => {
    if (!stocksData) return []

    const stocksOrdered = [...stocksData]

    stocksOrdered.sort((a: any, b: any) => {
      const bigger = reverseFilter ? 1 : -1;
      const smaller = bigger * -1;

      if (a[filter] > b[filter]) {
        return bigger;
      }
      if (a[filter] < b[filter]) {
        return smaller;
      }

      return 0;
    });


    return stocksOrdered
  }, [stocksData, reverseFilter, filter]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
  } = useTable(
    {
      columns: columns as any,
      data: ordersOrdered,
    }
  )

  const orderTableByColumn = (columnName: string) => {
    const column = columns.find(c => c.accessor === columnName)

    if (filter === columnName) {
      setReverseFilter(v => !v)
    }

    if (column) {
      setFilter(columnName);
    }
  }

  const handleDeleteOrder = (order: IOrder) => {
    setOrderToDelete(order)
  }

  const handleEditOrder = (order: IOrder) => {
    setOrderToEdit(order)
  }

  if (!rows.length) {
    return null
  }

  return (
    <WidgetCard
      title="Suas posições"
      w='100%'
    >
      <Styled.Container>
        <Styled.TableWrap className="tableWrap">
          <Styled.Table {...getTableProps()}>
            <Styled.Head>
              {headerGroups.map(headerGroup => (
                <Styled.HeadRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => (
                    <Styled.HeadData
                      {...column.getHeaderProps({
                        className: column.collapse ? 'collapse' : '',
                      })}
                    >
                      <Styled.HeaderLabel onClick={() => orderTableByColumn(column.id)}>
                        <Show when={!column.hide}>
                          {column.render('Header')}
                        </Show>
                      </Styled.HeaderLabel>
                    </Styled.HeadData>
                  ))}
                </Styled.HeadRow>
              ))}
            </Styled.Head>
            <Styled.TableBody {...getTableBodyProps()}>
              {rows.map((row: any, i: number) => {
                prepareRow(row)
                return (
                  <Styled.HeadRow {...row.getRowProps()}>
                    {row.cells.map((cell: any) => {
                      return (
                        <Styled.Content
                          {...cell.getCellProps({
                            className: cell.column.collapse ? 'collapse' : '',
                          })}
                        >
                          {cell.column.Header === 'Código' && (
                            <Box alignItems='center'>
                              <Show when={row.original?.image_url}>
                                <StockEmblem
                                  image_url={row.original?.image_url}
                                />
                              </Show>
                              <span>{cell.value}</span>
                            </Box>
                          )}

                          {cell.column.Header === 'Preço Médio' && (
                            <Currency
                              value={cell.value}
                              color={theme.colors.white}
                            />
                          )}

                          {cell.column.Header === 'Papéis' && (
                            <span>{cell.value}</span>
                          )}

                          {cell.column.Header === 'Cotação Atual' && (
                            <Currency
                              value={cell.value}
                              color={row.original?.averagePrice > row.original?.currentPrice ? theme.colors.error : theme.colors.success}
                            />
                          )}

                          {cell.column.Header === 'Lucro (R$)' && (
                            <Styled.SellResult>
                              <Currency
                                color={cell.value > 0 ? theme.colors.success : theme.colors.error}
                                value={cell.value}
                                type='flat'
                              />
                            </Styled.SellResult>
                          )}

                          {cell.column.Header === 'Lucro (%)' && (
                            <Styled.SellResult>
                              <Percentage
                                color={cell.value > 0 ? theme.colors.success : theme.colors.error}
                                value={cell.value}
                                type='variation'
                              />
                            </Styled.SellResult>
                          )}

                          {cell.column.Header === 'Variação Diária' && (
                            <Styled.SellResult>
                              <Percentage
                                color={cell.value > 0 ? theme.colors.success : theme.colors.error}
                                value={cell.value}
                                type='flat'
                              />
                            </Styled.SellResult>
                          )}
                        </Styled.Content>
                      )
                    })}
                  </Styled.HeadRow>
                )
              })}
            </Styled.TableBody>
          </Styled.Table>
        </Styled.TableWrap>
      </Styled.Container>
    </WidgetCard>
  )
}
