export enum UserActionTypes {
  LOGIN_REQUEST = 'LOGIN_REQUEST',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_FAILURE = 'LOGIN_FAILURE',
  LOGOUT_REQUEST = 'LOGOUT_REQUEST',
  LOGOUT_SUCCESS = 'LOGOUT_SUCCESS',
  LOGOUT_FAILURE = 'LOGOUT_FAILURE',
  SET_LOADING_USER = 'SET_LOADING_USER',
}


export interface IUser {
  id: string;
  email: string;
  phone: string;
  hasDismissedOnboarding: boolean;
}

export interface IUserState {
  user: IUser | null;
  isLoadingUser: boolean;
}

export interface ILoginType {
  email: string;
  phone: string;
}
