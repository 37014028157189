import styled, { css } from 'styled-components';

import { motion } from 'framer-motion';
import { transparentize } from 'polished';

export const Container = styled(motion.div)`
${({ theme }) => css`
  width: 100%;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  row-gap: 0.8rem;

  margin-bottom: 2.4rem;

  @media (max-width: 670px) {
    display: flex;
    flex-direction: column;

    width: 100%;

    > div {
      width: 100%;
    }
  }
`}
`;
