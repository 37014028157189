import { format, isAfter } from 'date-fns';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { Currency, Percentage } from '../../../../components';
import { TableWidget } from '../../../../components/Widgets/TableWidget';
import { IState } from '../../../../store';
import { useStockProfile } from '../hooks';

export const DividendsTable = () => {
  const location = useLocation();
  const theme = useTheme();

  const profits = useSelector((state: IState) => state.profit.profits)
  const isLoadingProfits = useSelector((state: IState) => state.profit.isLoadingProfits)

  const [id] = location.pathname.split('/').reverse();
  const { data: profile, isLoading: isLoadingProfile } = useStockProfile(id)

  const columns = useMemo(() => {
    return [
      {
        Header: 'Data',
        accessor: 'date',
      },
      {
        Header: 'Cotas',
        accessor: 'amount',
      },
      {
        Header: 'Distribuição',
        accessor: 'value',
      },
      {
        Header: 'D. Yield',
        accessor: 'yield',
      },
    ]
  }, [])

  const data = useMemo(() => {
    const stockEarnings = profits.filter((p) => p.name === profile?.name)
    stockEarnings.sort((a, b) => isAfter(new Date(a.created_at), new Date(b.created_at)) ? -1 : 1)

    return stockEarnings.map((earning) => ({
      date: earning.created_at,
      amount: earning.amount,
      value: earning.unit_sell_price,
      yield: earning.dividendDistribution.dividend_yield,
    }))
  }, [profits, profile])

  const renderCells = useCallback(({ headerName, columnValue }) => {
    return (
      <>
        {headerName === 'Data' && (
          <span>{format(new Date(columnValue), 'dd/MM/yyyy')}</span>
        )}

        {headerName === 'Cotas' && (
          <span>{columnValue}</span>
        )}

        {headerName === 'Distribuição' && (
          <Currency
            value={columnValue}
          />
        )}

        {headerName === 'D. Yield' && (
          <Percentage
            value={columnValue / 10}
          />
        )}
      </>
    )
  }, []);

  return (
    <TableWidget
      isLoading={isLoadingProfile || isLoadingProfits}
      title={`Proventos de ${profile?.name}`}
      columns={columns}
      data={data}
      renderCells={renderCells}
    />
  )
}
