import { Reducer } from "redux";
import { IUiState, UiTypes } from "./types";
import produce from 'immer';

const INITIAL_TOAST_STATE = {
  isOrderModalActive: false,
  isAnyModalOn: false,
}

const uiReducer: Reducer<IUiState> = (state = INITIAL_TOAST_STATE, action) => {
  return produce(state, draft => {
    switch(action.type) {
      case UiTypes.TOGGLE_ORDER_MODAL: {
        draft.isOrderModalActive = !draft.isOrderModalActive;

        return draft;
      }
      case UiTypes.TOGGLE_IS_ANY_MODAL_ACTIVE: {
        draft.isAnyModalOn = !draft.isAnyModalOn;

        return draft;
      }
      default: {
        return draft;
      }
    }
  });
}

export default uiReducer;
