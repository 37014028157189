import React from 'react';

import {
  Container,
} from './styles';

import ActivesInfos from './ActivesInfos';
import MoneyBalance from './MoneyBalance';
import ProfitGraph from './ProfitGraph';
import MonthVariation from './MonthVariation';
import TotalInvestedInfos from './TotalInvestedInfos';
import WalletsSummaryList from './WalletsSummaryList';
import { SellsProfits } from './SellsProfits';
import { Box } from '../../../components/Box';
import DailyProfitGraph from './DailyProfitGraph';

const MainPage: React.FC = () => {
  return (
    <Container>
      <div>
        <TotalInvestedInfos />
      </div>
      <div>
        <WalletsSummaryList />
      </div>
      <Box>
        <MoneyBalance />
        <ActivesInfos />
      </Box>
      <div>
        <MonthVariation />
        <SellsProfits />
      </div>
      <div>
        <ProfitGraph
          isProfitGraphExpanded={false}
        />
      </div>
      <div>
        <DailyProfitGraph />
      </div>
    </Container>
  );
};

export default MainPage;
