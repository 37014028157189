import React, { useEffect } from 'react';

import GlobalStyle from './styles/global';

import ToastMessageContainer from './components/ToastMessageContainer';

import Routes from './routes';
import HelpButton from './components/HelpButton';
import { IState } from './store';
import { connect } from 'react-redux';
import { logger } from './services/logger';
import { useAuth } from './contexts/auth';

const App: React.FC = () => {
  const { isAuthenticated } = useAuth()

  useEffect(() => {
    if (isAuthenticated) {
      logger.logEvent('Entrou no wllt')
    } else {
      logger.logEvent('Abriu tela de autenticação')
    }
  }, [isAuthenticated])

  return (
    <ToastMessageContainer>
      <Routes />
      <HelpButton
        url={'teste'}
        stayInCornerBottom
      />
      <GlobalStyle />
    </ToastMessageContainer>
  );
}

const mapStateToProps = (state: IState) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(App)
