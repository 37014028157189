import styled, { css } from 'styled-components';
import Select from 'react-dropdown-select';

export const Container = styled.div<{ minWidth?: number }>`
${({ theme, minWidth }) => css`
  ${minWidth && css`
    min-width: ${minWidth}px;
  `}

  input {
    color: ${({ theme }) => theme.colors.grey};
  }
`}
`;

export const SearchAndToggle = styled.div`
  display: flex;
  flex-direction: column;

  input {
    margin: 10px 10px 0;
    line-height: 30px;
    padding: 0 20px;
    border: 1px solid #ccc;
    border-radius: 3px;
    :focus {
      outline: none;
      border: 1px solid ${({ color }) => color};
    }

    color: ${({ theme }) => theme.colors.grey};
  }
`;

export const StyledSelect = styled(Select)`
  ${({ dropdownRenderer }) =>
    dropdownRenderer &&
    `
    .react-dropdown-select-dropdown {
        overflow: initial;
    }
	`}
`;

export const Item = styled.div`
${({ theme }) => css`
  padding: 0.8rem;
  color: ${theme.colors.grey};

  :hover {
    color: ${theme.colors.darkGrey};
  }
`}
`;
