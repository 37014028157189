import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

export const Container = styled.div`
${({ theme }) => css`
  height: 50%;
  border-radius: 0.3rem 1.5rem 1rem 1.5rem;
  background: ${transparentize(0.05, theme.colors.background)};

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: 2rem;

  > div {
    margin-bottom: 1.6rem;
  }
`}
`;

export const GraphContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end;
`;
