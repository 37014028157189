import React from 'react';
import ImportCentral from './ImportCentral';
import ImportHistory from './ImportHistory';

import { Container } from './styles';

const Import = () => {
  return (
    <Container>
      <ImportCentral />
      {/* <ImportHistory /> */}
    </Container>
  )
}

export default Import;
