import { Dispatch } from "redux";
import { getActivesFailure, getBalanceInfoFailure, getStockRatingFailure } from "../store/modules/actives/actions";
import { setIsNotLoadingData } from "../store/modules/loading/actions";
import { getProfitsFailure } from "../store/modules/profit/actions";
import { loginFailure } from "../store/modules/user/actions";
import { getValuationsFailure } from "../store/modules/valuation/actions";
import { getWalletFailure } from "../store/modules/wallet/actions";
import { getTesouroWalletFailure } from "../store/modules/tesouroWallet/actions";
import { getCryptoWalletFailure } from "../store/modules/crypto/actions";

export default function setIsNotLoadingAllData(dispatch: Dispatch<any>) {
  dispatch(getWalletFailure('error'));
  dispatch(getValuationsFailure('error'));
  dispatch(loginFailure('error'));
  dispatch(getProfitsFailure('error'));
  dispatch(getActivesFailure('error'));
  dispatch(getBalanceInfoFailure());
  dispatch(getStockRatingFailure());
  dispatch(getTesouroWalletFailure('error'));
  dispatch(getCryptoWalletFailure('error'));
  dispatch(setIsNotLoadingData());
}
