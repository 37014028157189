import React, { useMemo, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Chart from "react-apexcharts";
import { useTheme } from 'styled-components';

import { animations } from '../../../../resources';

import {
  Container,
  GraphContainer,
  NoContentWrapper,
} from './styles';

import { IState } from '../../../../store';
import { format } from 'date-fns';
import { formatPercentage } from '../../../../utils/formatCurrency';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { Box } from '../../../../components/Box';
import { useDailyProfits } from '../../../../hooks/react-query/useDailyProfits';

const containerAnimation = {
  ...animations.containerAnimation,
  mounted: {
    ...animations.containerAnimation.mounted,
    transition: {
      ...animations.containerAnimation.mounted.transition,
      duration: undefined,
      staggerChildren: undefined,
      delay: 0.9,
    }
  },
}

const ProfitGraph:React.FC = () =>  {
  const [display, setDisplay] = useState(false);
  const { data: dailyProfits, isLoading: isLoadingDailyProfits } = useDailyProfits()

  useEffect(() => {
    setTimeout(() => setDisplay(true), 1);
  }, [])

  const theme = useTheme();

  const chartData = useMemo(() => {
    const profitability = [...dailyProfits?.totalProfitability?.profitability || []]
    const bovespa = [...dailyProfits?.totalProfitability?.bovespa || []]

    return [
      {
        name: 'Lucro',
        data: profitability,
      },
      {
        name: 'Ibovespa',
        data: bovespa,
      },
    ]
  }, [dailyProfits]);

  const maxAndMinsForProfitGraph = useMemo(() => {
    return {
      min: dailyProfits?.totalProfitability?.minValue,
      max: dailyProfits?.totalProfitability?.maxValue,
    }
  }, [chartData]);

  const linearChartColor = useMemo(() => {
    return [theme.colors.success, theme.colors.warning];
  }, [chartData]);

  const chartLabelColors = useMemo(() => {
    const colors = chartData[0].data.map((s: any) => theme.colors.grey);

    return colors;
  }, [chartData]);

  const chartDataIsEmpty: boolean = useMemo(() => {
    return !(dailyProfits?.totalProfitability?.profitability?.length);
  }, [dailyProfits]);

  if(!display) {
    return <Box h={'51.5rem'}/>;
  }

  if (isLoadingDailyProfits) {
    return <Box h={'51.5rem'}/>;
  }

  return (
    <Container
      variants={containerAnimation}
      adjustHeight={!!chartData[0].data.length}
    >
      <h1>Evolução do Patrimônio</h1>

      {!chartDataIsEmpty ? (
        <GraphContainer>
            <Chart
              options={{
                stroke: {
                  width: 2,
                },
                colors: linearChartColor,
                fill: {
                  type: 'gradient',
                  gradient: {
                    shade: 'dark',
                    opacityFrom: 0.6,
                    opacityTo: 0.25,
                  }
                },
                dataLabels: {
                  enabled: false,
                  distributed: true,
                  offsetY: -8,
                  style: {
                    colors: [linearChartColor],
                  }
                },
                tooltip: {
                  shared: true,
                  enabled: true,
                  x: {
                    show: true,
                    formatter: (x) => {
                      return `${format(zonedTimeToUtc(new Date(x), 'America/Sao_Paulo'), 'dd-MM-yyyy')}`
                    },
                  },
                  y: [
                    {
                      formatter: (val) => formatPercentage(val),
                      title: {
                        formatter: (seriesName) => seriesName
                      }
                    },
                    {
                      formatter: (val) => formatPercentage(val),
                      title: {
                        formatter: (seriesName) => seriesName
                      }
                    }
                  ],
                  marker: {
                    show: false,
                  }
                },
                chart: {
                  animations: {
                    enabled: true,
                    easing: 'easeinout',
                    speed: 500,
                  },
                  id: "daily-profit-linear-chart",
                  zoom: {
                    enabled: true,
                  },
                  toolbar: {
                    show: false,
                    offsetX: 0,
                    offsetY: 0,
                    autoSelected: 'pan',
                    tools: {
                      selection: false,
                      pan: false,
                      download: false,
                      zoom: false,
                      zoomin: false,
                      zoomout: false,
                      reset: false,
                    },
                  },
                },
                legend: {
                  offsetY: 8,
                  labels: {
                    colors: chartLabelColors,
                  },
                  markers: {
                    fillColors: linearChartColor
                  }
                },
                xaxis: {
                  type: 'datetime',
                  tooltip: {
                    enabled: false,
                  },
                  axisBorder: {
                    show: true,
                    color: theme.colors.grey,
                  },
                  labels: {
                    style: {
                      fontSize: '1.2rem',
                      colors: chartLabelColors,
                    },
                    rotate: -45,
                  }
                },
                yaxis: {
                  min: maxAndMinsForProfitGraph.min,
                  max: maxAndMinsForProfitGraph.max,
                  title: {
                    text: 'Lucro',
                  },
                  seriesName: 'Lucro',
                  axisBorder: {
                    show: false,
                  },
                  labels: {
                    formatter: (val) => formatPercentage(val),
                    style: {
                      fontSize: '1.2rem',
                      colors: chartLabelColors,
                    },
                  }
                },
                grid: {
                  borderColor: theme.colors.lightGrey,
                }
              }}
              series={chartData}
              type="area"
              width="100%"
              height="100%"
            />
        </GraphContainer>
      ) : (
        <NoContentWrapper>
          <p>Aqui aparecerão seus lucros diários, assim que eles estiverem disponíveis :)</p>
        </NoContentWrapper>
      )}
    </Container>
  );
};

const mapStateToProps = (state: IState) => ({
  wallets_profits: state.actives.wallets_profits,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ProfitGraph)
