import { Dispatch } from "redux";
import { setIsLoadingActives, setIsLoadingBalance, setIsLoadingRating } from "../store/modules/actives/actions";
import { setIsLoadingProfits } from "../store/modules/profit/actions";
import { setLoadingValuations } from "../store/modules/valuation/actions";
import { setLoadingGetWallets } from "../store/modules/wallet/actions";

export default function setIsLoadingAllData(dispatch: Dispatch<any>) {
  dispatch(setLoadingGetWallets());
  dispatch(setLoadingValuations());
  dispatch(setIsLoadingProfits());
  dispatch(setIsLoadingBalance());
  dispatch(setIsLoadingRating());
  dispatch(setIsLoadingActives());
}
