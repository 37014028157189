import { format } from 'date-fns';
import React from 'react';

import {
  Container,
  Infos,
  Prices,
} from './styles';

interface TesouroOptionsProps {
  name: string;
  expiration: string;
  type: string;
  isSmallInput?: boolean;
  onClick(): void;
}

const TesouroOption:React.FC<TesouroOptionsProps> = ({
  name,
  expiration,
  type,
  onClick,
  isSmallInput,
}) => {
  return (
    <Container
      whileHover={{ scale: 1.0005 }}
      onClick={onClick}
      isSmallInput={isSmallInput}
    >
      <Infos>
        <h1>{name}</h1>
        <span>{format(new Date(expiration), 'dd-MM-yyyy')}</span>
      </Infos>

      <Prices>
        <p>{type}</p>
      </Prices>
    </Container>
  )
}

export default TesouroOption;
