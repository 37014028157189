import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { useLocation } from 'react-router-dom';

import { IState } from '../../../../store';
import { IWallet } from '../../../../store/modules/wallet/types';
import { GraphWidget } from '../../../../components/Widgets/GraphWidget';
import { useStockHistoricalValue, useStockProfile } from '../hooks';

export const StockPatrimonyGraph: React.FC = () => {
  const location = useLocation();
  const theme = useTheme();

  const [id] = location.pathname.split('/').reverse();

  const { data: historicalValue, isLoading: isLoadingHistoricalValue } = useStockHistoricalValue(id)
  const { data: profile, isLoading: isLoadingProfile } = useStockProfile(id)

  const linearChartData = useMemo(() => {
    return [
      {
        name: `${profile?.name} - Valor Investido`,
        data: historicalValue?.historicalInvestment.values || [],
      },
      {
        name: `${profile?.name} - Patrimônio`,
        data: historicalValue?.historicalValues.values || [],
      },
    ];
  }, [historicalValue]);

  const linearChartColor = useMemo(() => {
    if (!historicalValue) {
      return [theme.colors.extremeLightGrey, theme.colors.success];
    }

    const lastItemIndex = historicalValue.historicalInvestment.values.length - 1;

    const invested = historicalValue.historicalInvestment.values[lastItemIndex].y;
    const currentValue = historicalValue.historicalValues.values[lastItemIndex].y;

    if (currentValue > invested) {
      return [theme.colors.extremeLightGrey, theme.colors.success];
    }

    return [theme.colors.extremeLightGrey, theme.colors.error];
  }, [historicalValue]);

  const shouldRenderChart = useMemo(() => {
    return !!linearChartData[0].data.length;
  }, [linearChartData]);

  return (
    <GraphWidget
      isLoading={isLoadingHistoricalValue || isLoadingProfile || !shouldRenderChart}
      title={`Variação de ${profile?.name}`}
      type={'timestampLinearChart'}
      linearChartData={linearChartData}
      linearChartColor={linearChartColor}
      serieName={`Patrimônio em ${profile?.name}`}
    />
  );
};
