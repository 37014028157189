import { AnimatePresence } from 'framer-motion';
import React, { useEffect } from 'react';

import { Container } from './styles';

interface ModalProps {
  isVisible: boolean;
  closeModal(): void;
}

const Modal: React.FC<ModalProps> = ({ isVisible, closeModal, children }) => {
  useEffect(() => {
    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        closeModal();
      }
    })
  }, []);

  return (
    <AnimatePresence
      exitBeforeEnter
    >
      {isVisible && (
        <Container
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          {children}
        </Container>
      )}
    </AnimatePresence>
  );
};

export default Modal;
