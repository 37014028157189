import styled, { css } from 'styled-components'

export const Container = styled.div`
${({ theme }) => css`
  position: relative;
`}
`

export const ExpandDropdown = styled.div<{ hasType?: boolean }>`
${({ theme, hasType }) => css`
  > button {
    height: 6rem;
    width: 100%;
    background-color: ${theme.colors.lightGrey};
    flex: 1;

    display: flex;
    justify-content: flex-start;

    font-weight: normal;

    > div {
      text-align: left;

      margin-left: 1.6rem;
    }
  }
`}
`

export const Dropdown = styled.div`
${({ theme }) => css`
  position: absolute;
  width: 100%;
`}
`

export const DropdownItem = styled.button`
${({ theme }) => css`
  background: transparent;
  color: ${theme.colors.white};
  padding: 1.6rem;
  background-color: ${theme.colors.lightGrey};
  width: 100%;

  display: flex;
  justify-content: flex-start;

  &:hover {
    background-color: ${theme.colors.lightestGrey};
  }
`}
`
