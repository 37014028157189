import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

export const Container = styled(motion.div)`
${({ theme }) => css`
  height: 100%;
  width: 100%;
  max-width: ${theme.spacing.mainWidth};

  border-radius: 0.8rem;

  @media (max-width: 670px) {
    padding: 0.8rem;
    max-width: 100%;
  }
`}
`;

export const FirstRow = styled.div`
${({ theme }) => css`
  margin-bottom: 1.6rem;
  display: flex;

  @media (max-width: 670px) {
    flex-direction: column;

    > div:first-child {
      margin-bottom: 1.6rem;
    }

    > div {
      width: 95%;
      min-height: 40rem;
      margin: 0 auto;
    }
  }
`}
`;

export const SecondRow = styled.div`
${({ theme }) => css`
  display: flex;
  width: 100%;
  margin-bottom: 1.6rem;

  @media (max-width: 670px) {
    flex-direction: column;

    > div:first-child {
      margin-bottom: 1.6rem;
    }

    > div {
      width: 95%;
      min-height: 40rem;
      margin: 0 auto;
    }
  }
`}
`;

export const ThirdRow = styled.div`
${({ theme }) => css`
  display: flex;
  width: 100%;
  margin-bottom: 1.6rem;

  @media (max-width: 670px) {
    flex-direction: column;

    > div:first-child {
      margin-bottom: 1.6rem;
    }

    > div {
      width: 95%;
      min-height: 40rem;
      margin: 0 auto;
    }
  }
`}
`

