export enum PersonalizedWalletActionTypes {
  GET_PERSONALIZED_WALLETS_REQUEST = 'GET_PERSONALIZED_WALLETS_REQUEST',
  GET_PERSONALIZED_WALLETS_SUCCESS = 'GET_PERSONALIZED_WALLETS_SUCCESS',
  GET_PERSONALIZED_WALLETS_FAILURE = 'GET_PERSONALIZED_WALLETS_FAILURE',
  SET_LOADING_PERSONALIZED_WALLETS = 'SET_LOADING_PERSONALIZED_WALLETS',
}

export interface PersonalizedWallet {
  id: number;
  name: string;
  starting_value: number;
  current_value: number;
}

export interface PersonalizedWalletState {
  personalizedWallets: PersonalizedWallet[];
  isLoadingPersonalizedWallets: boolean;
}
