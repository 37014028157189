import styled, { css } from 'styled-components';

import { motion } from 'framer-motion';
import { transparentize } from 'polished';

export const Container = styled(motion.div)`
${({ theme }) => css`
  background: 0;

  border-radius: 0.8rem;
  margin-left: 0.8rem;
  padding: 0rem 2rem 5rem 2rem;
  /* padding: 5rem 2rem; */

  overflow-x: hidden;

  display: flex;
  flex-direction: column;
`}
`;

export const Header = styled.div<{ rentability: number }>`
${({ theme, rentability }) => css`
  display: flex;
  align-items: center;

  > div:not(:nth-child(4)) {
    > h6 {
      color: ${theme.colors.white};
      padding: 0.4rem 1.6rem;
      font-size: ${theme.fontSizes.huge};
      background: ${rentability > 0 ? theme.colors.success : theme.colors.error};

      border-radius: 0.8rem;
      box-shadow: 3px 2px 9px ${rentability > 0 ? 'rgba(66, 141, 242, 0.38)' : transparentize(0.62, theme.colors.error)};

      margin-right: 0.8rem;
    }
  }

  @media (max-width: 670px) {
    flex-direction: column;
    align-items: flex-start;

    margin-bottom: 1.6rem;

    > h1 {
      font-size: ${theme.fontSizes.large};
    }

    > div {
      margin-bottom: 0.8rem;
      > h6 {
        font-size: ${theme.fontSizes.large};
      }
    }
  }
`}
`;

export const Invested = styled(motion.div)<{ rentability: number }>`
${({ theme, rentability }) => css`
  display: flex;
  align-items: center;

  > svg {
    margin-left: 3.2rem;
    color: ${rentability > 0 ? theme.colors.success : theme.colors.error};
  }

  > h6 {
    > p {
      margin-right: 2.4rem;
      letter-spacing: 0em;
    }

    letter-spacing: 0.03em;
    font-size: 10rem;
    color: ${theme.colors.white};
  }

  @media (max-width: 670px) {
    > svg {
      height: 3.6rem;
      margin-left: 0.8rem;
      color: ${rentability > 0 ? theme.colors.success : theme.colors.error};
    }

    > h6 {
      > p {
        margin-right: 2.4rem;
        letter-spacing: 0em;
      }

      letter-spacing: 0.03em;
      font-size: ${theme.fontSizes.giant};
      color: ${theme.colors.white};
    }
  }
`}
`;

export const VariationContainer = styled(motion.div)<{ variation: number }>`
${({ theme, variation }) => css`
  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid ${variation > 0 ? theme.colors.success : theme.colors.error};
  border-radius: 0.8rem;
  padding: 0.4rem 1.6rem;
  box-shadow: 3px 2px 9px ${variation > 0 ? 'rgba(66, 141, 242, 0.38)' : transparentize(0.62, theme.colors.error)};

  color: ${variation > 0 ? theme.colors.success : theme.colors.error};
  font-size: ${theme.fontSizes.huge};

  > h6 {
    color: ${variation > 0 ? theme.colors.success : theme.colors.error};
    font-size: ${theme.fontSizes.huge};

    ${variation > 0 && css`
      margin-left: 0.4rem;
    `}
  }
`}
`;

export const TotalPatrimonyContainer = styled(motion.div)`
${({ theme }) => css`
  background-color: ${theme.colors.lightGrey};
  padding: 0.4rem 1.6rem;
  border-radius: 0.8rem;
  margin-right: 1.6rem;

  > h1 {
    color: ${theme.colors.primary};
    font-size: ${theme.fontSizes.huge};
  }
`}
`
