import { transparentize } from 'polished';
import styled, { css } from 'styled-components';
import Button from '../../../../components/Button';

import { motion } from 'framer-motion';

export const Container = styled(motion.div)`
${({ theme }) => css`
  background: ${theme.colors.background};

  border-radius: 0.8rem;
  margin-right: 0.8rem;
  padding: 1.6rem;

  width: 40%;
  height: 100%;

  box-shadow: ${theme.shadows.default};

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > h1 {
    padding: 0.4rem 0.8rem;
    background: ${theme.colors.extremeLightGrey};

    border-radius: 0.8rem;

    color: ${theme.colors.primary};
    font-size: ${theme.fontSizes.huge};

    margin-bottom: 1.6rem;
  }

  @media (max-width: 670px) {
    margin-bottom: 1.6rem;
    width: 100%;
    height: 30rem;
  }
`}
`;

export type CustomTooltipProps = {
  rightArrow?: boolean;
  leftArrow?: boolean;
};


export const CustomTooltip = styled.div<CustomTooltipProps>`
${({ theme, rightArrow, leftArrow }) => css`
  background: ${theme.colors.lightGrey};
  padding: 0.4rem 0.8rem;
  border-radius: 0.1rem;
  font-size: ${theme.fontSizes.tiny};
  text-align: center;
  box-shadow: 0 -0.1rem 0.2rem rgba(0, 0, 0, 0.28);

  > h1 {
    font-size: ${theme.fontSizes.small};
  }

  > h6 {
    font-size: ${theme.fontSizes.large};
  }

  :before {
    content: '';
    display: block;
    height: 0;
    left: ${rightArrow ? `100%` : (leftArrow && `2%`) || `50%`};
    bottom: -22%;
    position: absolute;
    transform: ${rightArrow
      ? `translate3d(0, -175%, 0)`
      : (leftArrow && `translate3d(-100%, -175%, 0)`) ||
        `translate3d(-50%, 25%, 0)`};
    border-color: ${rightArrow
      ? `transparent transparent transparent ${theme.colors.lightGrey}`
      : (leftArrow &&
          `transparent ${theme.colors.lightGrey} transparent transparent`) ||
        `${theme.colors.lightGrey} transparent transparent
      transparent`};
    border-style: solid;
    border-width: 0.8rem;
  }

  :after {
    content: '';
    display: block;
  }
`}
`;

export const ChartContainer = styled.div`
${({ theme }) => css`
  height: 90%;
  width: 100%;
`}
`;

export const NoContentWrapper = styled.div`
${({ theme }) => css`
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  > p {
    text-align: center;
  }

  > button {
    padding: 1.6rem;
  }
`}
`;
