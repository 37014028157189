import React from 'react';

import {
  Container,
  Wrapper,
} from './styles';

const TopFooter: React.FC = () => {
  return (
    <Container>
      <Wrapper>
        <div>
          <h4>Fale conosco</h4>
          Ligue (45) 99109 1975 (Regiões Metropolitanas) | Horário de Atendimento: 08:00h às 22:00h.
        </div>
        <p>® Todos os direitos reservados</p>
      </Wrapper>
    </Container>
  );
};

export default TopFooter;
