import React from 'react';
import { useTheme } from 'styled-components';
import Chart from "react-apexcharts";

import { format } from 'date-fns';
import formatCurrency from '../../../utils/formatCurrency';
import { IPoint, usePatrimonyChart } from '../../../hooks/react-query/usePatrimonyChart';

export interface ILinearChartData {
  name: string
  data: IPoint[]
}

export interface TimestampLinearChartProps {
  linearChartData: ILinearChartData[]
  linearChartColor: string[]
  serieName: string
  valueFormatter?: (x: number) => string
}

export const TimestampLinearChart: React.FC<TimestampLinearChartProps> = ({ valueFormatter, serieName, linearChartData, linearChartColor }) => {
  const theme = useTheme();
  const formatter = !!valueFormatter ? valueFormatter : (x: number) => formatCurrency(x / 100);

  return (
    <Chart
      options={{
        stroke: {
          curve: 'smooth',
        },
        colors: linearChartColor,
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            opacityFrom: 0.6,
            opacityTo: 0.25,
          }
        },
        dataLabels: {
          enabled: false,
          distributed: true,
          offsetY: -8,
          style: {
            colors: linearChartColor,
          }
        },
        tooltip: {
          enabled: true,
          x: {
            show: true,
            formatter: (x) => `${format(new Date(x), 'dd-MM-yyyy')}`,
          },
          y: {
            formatter: (val) => formatter(val),
            title: {
              formatter: (seriesName) => seriesName
            }
          },
          marker: {
            show: false,
          }
        },
        chart: {
          id: "linear-chart",
          animations: {
            enabled: false,
          },
          zoom: {
            enabled: true,
          },
          toolbar: {
            show: false,
            offsetX: 0,
            offsetY: 0,
            autoSelected: 'pan',
            tools: {
              selection: false,
              pan: false,
              download: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              reset: false,
            },
          },
        },
        legend: {
          offsetY: 8,
          labels: {
            colors: theme.colors.grey,
          },
          markers: {
            fillColors: linearChartColor
          }
        },
        xaxis: {
          type: 'datetime',
          tooltip: {
            enabled: false,
          },
          axisBorder: {
            show: true,
            color: theme.colors.grey,
          },
          labels: {
            style: {
              colors: theme.colors.grey,
            },
            rotate: -45,
          }
        },
        yaxis: {
          seriesName: serieName,
          axisBorder: {
            show: false,
          },
          labels: {
            formatter: (val) => formatter(val),
            style: {
              colors: [theme.colors.grey],
            },
          }
        },
        grid: {
          borderColor: theme.colors.lightGrey,
        }
      }}
      series={linearChartData}
      type="area"
      width="100%"
      height="370"
      animations={{ initialAnimation: { enabled: false }}}
    />
  );
};
