import React, { useState } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { useTheme } from 'styled-components';
import { FilterTypes } from '..';

import {
  Container,
  Header,
  ActualPrice,
  MediumPrice,
  TotalPercentage,
  TotalInvested,
  TotalValue,
  ProfitPercentage,
} from './styles';

interface LabelRowProps {
  handleChangeFilter(i: FilterTypes): void;
  selectedLabel: string;
  isReversed: boolean;
}

const LabelRow: React.FC<LabelRowProps> = ({ handleChangeFilter, selectedLabel, isReversed }) => {
  const [currentFilter, setCurrentFilter] = useState<FilterTypes | undefined>();

  const theme = useTheme();

  const handleFilter = (type: FilterTypes) => {
    handleChangeFilter(type);
    setCurrentFilter(type);
  }

  return (
    <Container>
      <Header
        onClick={() => handleFilter(FilterTypes.NAME)}
        isSelected={FilterTypes.NAME === currentFilter}
      >
        {selectedLabel === FilterTypes.NAME && isReversed && (
          <FiChevronDown
            color={theme.colors.primary}
            size={16}
          />
        )}
        {selectedLabel === FilterTypes.NAME && !isReversed && (
          <FiChevronUp
            size={16}
            color={theme.colors.primary}
          />
        )}
        {selectedLabel !== FilterTypes.NAME && (
          <FiChevronDown
            size={16}
            color={theme.colors.grey}
          />
        )}
        <h1>Ativo</h1>
      </Header>

      <ActualPrice
        onClick={() => handleFilter(FilterTypes.ACTUAL_PRICE)}
        isSelected={FilterTypes.ACTUAL_PRICE === currentFilter}
      >
        {selectedLabel === FilterTypes.ACTUAL_PRICE && isReversed && (
          <FiChevronDown
            size={16}
            color={theme.colors.primary}
          />
        )}
        {selectedLabel === FilterTypes.ACTUAL_PRICE && !isReversed && (
          <FiChevronUp
            size={16}
            color={theme.colors.primary}
          />
        )}
        {selectedLabel !== FilterTypes.ACTUAL_PRICE && (
          <FiChevronDown
            size={16}
            color={theme.colors.grey}
          />
        )}
        Preço atual
      </ActualPrice>

      <MediumPrice>
        Preço Médio
      </MediumPrice>

      <TotalPercentage
        onClick={() => handleFilter(FilterTypes.ACTIVE_PERCENTAGE)}
        isSelected={FilterTypes.ACTIVE_PERCENTAGE === currentFilter}
      >
        {selectedLabel === FilterTypes.ACTIVE_PERCENTAGE && isReversed && (
          <FiChevronDown
            size={16}
            color={theme.colors.primary}
          />
        )}
        {selectedLabel === FilterTypes.ACTIVE_PERCENTAGE && !isReversed && (
          <FiChevronUp
            size={16}
            color={theme.colors.primary}
          />
        )}
        {selectedLabel !== FilterTypes.ACTIVE_PERCENTAGE && (
          <FiChevronDown
            size={16}
            color={theme.colors.grey}
          />
        )}
        % Ativo
      </TotalPercentage>

      <TotalInvested
        onClick={() => handleFilter(FilterTypes.INVESTED_AMOUNT)}
        isSelected={FilterTypes.INVESTED_AMOUNT === currentFilter}
      >
        {selectedLabel === FilterTypes.INVESTED_AMOUNT && isReversed && (
          <FiChevronDown
            size={16}
            color={theme.colors.primary}
          />
        )}
        {selectedLabel === FilterTypes.INVESTED_AMOUNT && !isReversed && (
          <FiChevronUp
            size={16}
            color={theme.colors.primary}
          />
        )}
        {selectedLabel !== FilterTypes.INVESTED_AMOUNT && (
          <FiChevronDown
            size={16}
            color={theme.colors.grey}
          />
        )}
        Investido
      </TotalInvested>

      <TotalValue
        onClick={() => handleFilter(FilterTypes.CURRENT_AMOUNT)}
        isSelected={FilterTypes.CURRENT_AMOUNT === currentFilter}
      >
        {selectedLabel === FilterTypes.CURRENT_AMOUNT && isReversed && (
          <FiChevronDown
            size={16}
            color={theme.colors.primary}
          />
        )}
        {selectedLabel === FilterTypes.CURRENT_AMOUNT && !isReversed && (
          <FiChevronUp
            size={16}
            color={theme.colors.primary}
          />
        )}
        {selectedLabel !== FilterTypes.CURRENT_AMOUNT && (
          <FiChevronDown
            size={16}
            color={theme.colors.grey}
          />
        )}
        Montante
      </TotalValue>

      <ProfitPercentage
        onClick={() => handleFilter(FilterTypes.PROFIT_PERCENTAGE)}
        isSelected={FilterTypes.PROFIT_PERCENTAGE === currentFilter}
      >
        {selectedLabel === FilterTypes.PROFIT_PERCENTAGE && isReversed && (
          <FiChevronDown
            size={16}
            color={theme.colors.primary}
          />
        )}
        {selectedLabel === FilterTypes.PROFIT_PERCENTAGE && !isReversed && (
          <FiChevronUp
            size={16}
            color={theme.colors.primary}
          />
        )}
        {selectedLabel !== FilterTypes.PROFIT_PERCENTAGE && (
          <FiChevronDown
            size={16}
            color={theme.colors.grey}
          />
        )}
        <p>
          Lucro (%)
        </p>
      </ProfitPercentage>

      <ProfitPercentage
        onClick={() => handleFilter(FilterTypes.PROFIT)}
        isSelected={FilterTypes.PROFIT === currentFilter}
      >
        {selectedLabel === FilterTypes.PROFIT && isReversed && (
          <FiChevronDown
            size={16}
            color={theme.colors.primary}
          />
        )}
        {selectedLabel === FilterTypes.PROFIT && !isReversed && (
          <FiChevronUp
            size={16}
            color={theme.colors.primary}
          />
        )}
        {selectedLabel !== FilterTypes.PROFIT && (
          <FiChevronDown
            size={16}
            color={theme.colors.grey}
          />
        )}
        <p>
          Lucro (R$)
        </p>
      </ProfitPercentage>
    </Container>
  );
};

export default LabelRow;
