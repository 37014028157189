import { useSelector } from "react-redux"
import { IState } from "../../store"

export const useAuth = () => {
  const user = useSelector((state: IState) => state.user);
  const isLoading = useSelector((state: IState) => state.user.isLoadingUser);

  return {
    data: user,
    isLoading,
  }
}
