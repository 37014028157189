import { Fragment } from 'react';
import { motion } from 'framer-motion';
import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

export const Container = styled.div`
${({ theme }) => css`
  width: 100%;
  flex: 1;

  display: flex;
  flex-direction: column;

  > div:first-child {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 1.6rem;

    > h1 {
      font-size: ${theme.fontSizes.huge};
      color: ${theme.colors.success};
    }

    > div:nth-child(2) {
      width: 35%;
    }
  }

  > div:not(:last-child):not(:first-child) {
    flex: 2;
    max-height: 40rem;
    overflow-y: hidden;
    overflow-x: hidden;
  }
`}
`;

export const SelectOrdersTable = styled.div`
${({ theme }) => css`
  width: 100%;
  border-collapse: collapse;
  height: 40rem;

  display: flex;
  flex-direction: column;

  overflow-x: hidden;
`}
`;

export const ColumnSelectionRow = styled.div`
${({ theme }) => css`
  display: grid;
  grid-template-columns: repeat(6, 1fr);

  margin-right: 12.4px;

  > td {
    padding: 0.8rem;
    color: ${theme.colors.success};
    font-weight: bold;
    font-size: ${theme.fontSizes.default};
    background: ${theme.colors.background};
  }
`}
`;

export const ColumnToMatchRow = styled.div`
${({ theme }) => css`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  margin-right: 12.4px;

  z-index: 1;

  > td {
    height: 100%;

    padding: 0.4rem 0.8rem;
    background: ${theme.colors.success};
    color: ${transparentize(0.3, theme.colors.white)};
    font-weight: bold;
    font-size: ${theme.fontSizes.default};
  }
`}
`;

export const TableContent = styled.tbody`
${({ theme }) => css`
  height: 40rem;
`}
`;

export const ValuesRow = styled(motion.tr)<{ hasError?: boolean }>`
${({ theme, hasError }) => css`
  display: grid;
  grid-template-columns: repeat(6, 1fr);

  > td {
    height: 100%;

    display: flex;
    align-items: center;

    border: solid 0.2rem ${transparentize(0.95, theme.colors.success)};
    padding: 0.8rem;

    background: ${transparentize(0.95, theme.colors.success)};
    color: ${theme.colors.white};
    font-size: ${theme.fontSizes.default};

    > h6 {
      color: ${theme.colors.white};
      font-size: ${theme.fontSizes.default};
    }

    > div {
      display: flex;
      align-items: center;

      svg {
        margin-right: 0.8rem;
      }
    }

    ${hasError && css`
      background: ${transparentize(0.95, theme.colors.warning)};
    `}
  }
`}
`;

export const PatrimonyInfosContainer = styled.div`
${({ theme }) => css`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
`}
`;

export const PreviousPatrimony = styled.div`
${({ theme }) => css`
  display: flex;
  align-items: center;

  margin-right: 0.8rem;

  > span {
    font-size: ${theme.fontSizes.huge};
    font-weight: bold;
    color: ${theme.colors.white};
    margin-right: 0.8rem;
  }

  > h6 {
    font-size: ${theme.fontSizes.huge};
    font-weight: bold;
  }
`}
`;

export const ImportTableData = styled.div`
${({ theme }) => css`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    display: flex;
    align-items: center;
  }
`}
`

export const StyledButton = styled.button`
${({ theme }) => css`
  display: flex;
  align-items: center;

  padding: 0 0.4rem;
  border: solid 0.1rem ${theme.colors.success};
  border-radius: 0.4rem;

  background: 0;
  color: ${theme.colors.success};

  font-size: ${theme.fontSizes.small};

  > svg {
    margin-right: 0.4rem;
  }

  > svg {
    margin-right: 0.4rem;
  }
`}
`
