import { useQuery } from "@tanstack/react-query"
import api from "../../services/api"
import { IPoint } from "./usePatrimonyChart"

interface IDailyProfitData {
  totalProfitability: {
    maxValue: number
    minValue: number
    profitability: IPoint[]
    bovespa: IPoint[]
  }
}

export const useDailyProfits = () => {
  const query = useQuery(['daily-profits'], async () => {
    const response = await api.get<IDailyProfitData>('/dashboard/get-daily-profits')

    return response.data
  })

  return query
}
