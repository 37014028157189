import React, { useState } from 'react';
import { FiDownload } from 'react-icons/fi';
import Button from '../../../../components/Button';
import ImportCentralModal from './ImportCentralModal';

import { animations } from '../../../../resources';

import {
  Container,
  StyledButton,
} from './styles';

const ImportCentral = () => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <>
      <Container
        variants={animations.containerAnimation}
      >
        <h1>Central de Importações</h1>

        <div>
          <StyledButton
            onClick={() => setIsVisible(true)}
          >
            <FiDownload
              size={32}
            />
            Iniciar Módulo de Importação
          </StyledButton>
        </div>
      </Container>

      <ImportCentralModal
        isVisible={isVisible}
        closeModal={() => setIsVisible(false)}
      />
    </>
  )
}

export default ImportCentral;
