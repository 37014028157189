import { Dispatch } from "redux";
import { getProfitsSuccess, getProfitsFailure, getProfitGraphSuccess, getProfitGraphFailure, getProfitsRequest, getProfitGraphRequest, getDividendMonthlyGraphRequest, getDividendMonthlyGraphSuccess, getDividendMonthlyGraphFailure } from "../store/modules/profit/actions";
import api from "./api";

export const fetchProfit = async (dispatch: Dispatch<any>, async?: boolean) => {
  console.log('fetching profits...', Date.now());

  if (async) {
    dispatch(getProfitsRequest());
  }

  try {
    const response = await api.get('/profit');

    dispatch(getProfitsSuccess(response.data));
  } catch (err) {
    dispatch(getProfitsFailure('error'));
  }
}

export const fetchProfitGraph = async (dispatch: Dispatch<any>, async?: boolean) => {
  console.log('fetching profit graph...');

  if (async) {
    dispatch(getProfitGraphRequest());
  }

  try {
    const response = await api.get('/profit/month-profit');

    dispatch(getProfitGraphSuccess(response.data));
  } catch (err) {
    dispatch(getProfitGraphFailure('error'));
  }
}

export const fetchDividendsMonthlyGraph = async (dispatch: Dispatch<any>, async?: boolean) => {
  console.log('fetching profit graph...');

  if (async) {
    dispatch(getDividendMonthlyGraphRequest());
  }

  try {
    const response = await api.get('/dividends/get-earnings-by-month');

    dispatch(getDividendMonthlyGraphSuccess(response.data));
  } catch (err) {
    dispatch(getDividendMonthlyGraphFailure('error'));
  }
}
