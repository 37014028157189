import { IWalletTesouro } from "../../../../store/modules/tesouroWallet/types";

export function sortTesourosByName(tesouros: IWalletTesouro[], bigger: number) {
  const newStocks = tesouros.sort((a, b) => {
    if (a.name > b.name) {
      return bigger;
    }

    if (a.name < b.name) {
      return -1 * bigger;
    }

    return 0;
  });

  return newStocks;
}

export default function sortStocksByFilter(filter: string, tesouros: IWalletTesouro[], walletValue: number, bigger: number) {
  if (filter === 'name') {
    const sortedByName = sortTesourosByName(tesouros, bigger);

    return sortedByName;
  }

  return tesouros;
}
