import { useQuery } from '@tanstack/react-query'
import api from '../../services/api'

interface IStockProfitSell {
  stock_id: string;
  averagePrice: number;
  stockName: string;
  sellPrice: number;
  date: Date;
  profit: number;
  profitInPercentage: number;
  amount: number;
}

interface IStockProfitSells {
  totalProfit: number;
  totalBuyPrice: number;
  totalSellPrice: number;
  stockSells: IStockProfitSell[]
}


export const useSellsProfits = () => {
  const query = useQuery(['sellsProfits'], async () => {
    const response = await api.get<IStockProfitSells>('/dashboard/get-sells-profits')

    return response.data
  })

  return query
}
