const theme = {
  colors: {
    primary: '#F14452',
    primaryDark: '#E02041',
    secondary: '#E02041',
    grey: '#8C91A5',
    lightGrey: '#F5F6FA',
    extremeLightGrey: '#222327',
    darkGrey: '#6C757D',
    softGrey:'#8A99B6',
    success: '#20C05C',
    error: '#FD4F48',
    background: '#fff',
    yellow: '#FFED00',
    darkBluishGrey: '#011627',
    lightGreen: '#7EE081',
    white: '#FFFFFF',
  },
  fontSizes: {
    enourmous: '8rem',
    giant: '4.2rem',
    medium_large: '3.2rem',
    huge: '2.2rem',
    big: '1.8rem',
    large: '1.6rem',
    default: '1.4rem',
    small: '1.2rem',
    tiny: '1.0rem',
  },
  spacing: {
    default: '1.6rem',
    vertical: '1.6rem 0',
    horizontal: '0 1.6rem',
    large: '2.4rem',
    tiny: '0.5rem',
    mainWidth: '78%',
    middleWrapperSpacing: '85%',
  },
  transition: {
    default: '180ms ease-in-out',
  },
  radius: {
    default: '1.6rem',
    small: '0.4rem'
  },
  shadows: {
    default: '0px 0.5rem 2rem rgba(0, 0, 0, 0.08)',
    new_default: '0px 0rem 1rem rgba(0, 0, 0, 0.2)',
    flat: '0, 0.2rem 0.2rem rgba(0, 0, 0, 0.08)',
  },
} as const;

export default theme;
