import styled, { css } from "styled-components";

export const Container = styled.tr`
${({ theme }) => css`
  margin-bottom: 0.8rem;
  margin-top: 3.2rem;

  padding: 0 1.6rem;

  display: flex;
  justify-content: space-between;

  > th:first-child {
    color: ${theme.colors.grey};
    background: 0;
  }

  > th {
    font-size: ${theme.fontSizes.default};
    color: ${theme.colors.grey};
    background: 0;
    width: 100%;
    text-align: left;
    cursor: pointer;
  }
`}
`;
