import { useQuery } from '@tanstack/react-query'
import api from '../../services/api'
import { useIsFocused } from '../useIsFocused';

export interface IPatrimony {
  totalPatrimony: IPatrimonyInfos;
  walletsPatrimony: {
    [key: string]: IPatrimonyInfos;
  }
}

export interface IPoint {
  x: string;
  y: number;
}

export interface IPatrimonyInfos {
  maxValue: number;
  invested: IPoint[];
  patrimony: IPoint[];
}


interface useOrdersProps {
  type?: 'day' | 'month' | 'year'
}

export const usePatrimonyChart = (props?: useOrdersProps) => {
  const focused = useIsFocused()

  const query = useQuery(['patrimony-chart'], async () => {
    console.log('fetching patrimony charts...', Date.now())
    const response = await api.get<IPatrimony>('/dashboard/patrimony-charts');

    return response.data
  }, {
    enabled: focused
  })

  return query
}
