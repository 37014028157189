import { motion } from 'framer-motion';
import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

export const Container = styled(motion.tr)<{ isDarkTheme: boolean }>`
${({ theme, isDarkTheme }) => css`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;

  padding: 0.8rem;

  background: 0;

  margin-bottom: 0.8rem;
  border-radius: 0.4rem;

  box-shadow: ${theme.shadows.new_default};

  ${isDarkTheme && css`
    border: 0.1rem solid ${transparentize(0.85, theme.colors.softGrey)};
  `}

  @media (max-width: 670px) {
    flex-direction: column;
    align-items: flex-start;
  }
`}
`;

export const Header = styled.td`
${({ theme }) => css`
  display: flex;
  align-items: center;

  width: 100%;

  > h1 {
    font-size: ${theme.fontSizes.default};
    color: ${theme.colors.darkGrey};
  }

  > svg {
    color: ${theme.colors.primary};
    margin-right: 0.4rem;
  }

  @media (max-width: 670px) {
    width: 100%;
    margin-bottom: 1.6rem;
  }
`}
`;

export const ActualPrice = styled.td`
${({ theme }) => css`
  width: 100%;

  > h6 {
    color: ${theme.colors.softGrey};
    font-weight: bold;
    font-size: ${theme.fontSizes.default};
  }
`}
`;

export const MediumPrice = styled.td`
${({ theme }) => css`
  width: 100%;

  > h6 {
    color: ${theme.colors.darkGrey};
    font-size: ${theme.fontSizes.default};
  }
`}
`;

export const WalletPercentage = styled.td``;

export const TotalPercentage = styled.td`
${({ theme }) => css`
  width: 100%;

  > h6 {
    font-size: ${theme.fontSizes.default};
  }
`}
`;

export const TotalInvested = styled.td`
${({ theme }) => css`
  width: 100%;

  > h6 {
    font-size: ${theme.fontSizes.large};
  }
`}
`;

export const TotalValue = styled.td`
${({ theme }) => css`
  width: 100%;

  > h6 {
    font-weight: bold;
    color: ${theme.colors.softGrey};
    font-size: ${theme.fontSizes.large};
  }
`}
`;

export const ProfitPercentage = styled.td`
${({ theme }) => css`
  display: flex;
  align-items: center;
  width: 100%;

  > p {
    font-weight: bold;
    color: ${theme.colors.success};
  }

  > span {
    font-weight: bold;
    color: ${theme.colors.grey};
  }

  > h6 {
    font-size: ${theme.fontSizes.default};
  }
`}
`;
