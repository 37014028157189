import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

import Button from '../../../components/Button';

export const Container = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 10.4rem;

  background: ${({ theme }) => theme.colors.background};

  @media (max-width: 670px) {
    padding: 0 2.4rem;
  }
`;

export const Wrapper = styled(motion.div)`
${({ theme }) => css`
  max-width: ${theme.spacing.middleWrapperSpacing};
  width: 100%;

  display: flex;
  justify-content: space-between;

  > div:nth-child(2) {
    width: 45rem;
  }
`}
`;

export const LeftNav = styled.button`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;

  background: 0;

  span {
    font-size: ${({ theme }) => theme.fontSizes.small};
    margin-bottom: ${({ theme }) => theme.spacing.tiny};

    color: ${({ theme }) => theme.colors.grey};
  }

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    margin-right: ${({ theme }) => theme.spacing.default};

    > img {
      width: 20rem;
    }

    > h1 {
    color: ${({ theme }) => theme.colors.primary};
    }

    h1 + h1 {
      color: ${({ theme }) => theme.colors.darkGrey};
      font-weight: normal;
    }
  }


  font-size: ${({ theme }) => theme.fontSizes.large};
  font-weight: 700;
`;

export const RightNav = styled.div`
  display: flex;
  align-items: center;

  justify-content: flex-end;

  > button {
    font-size: ${({ theme }) => theme.fontSizes.default};
  }

  @media (max-width: 670px) {
    > button:first-child {
      display: none;
    }
  }
`;

export const StyledButton = styled(Button)`
${({ theme }) => css`
  transition: transform 0.5s, box-shadow 0.5s, color 0.8s, background 0.8s;

  :hover {
    transform: scale(1.008);
    box-shadow: 0px 0.2rem 0.2rem ${transparentize(0.72, theme.colors.secondary)};
    color: ${theme.colors.background};
    background: ${theme.colors.primary};
  }
`}
`;

export const AccountBalance = styled.div`
${({ theme }) => css`
  margin-right: 1.6rem;

  span {
    font-size: ${theme.fontSizes.small};
  }

  h6 {
    font-size: ${theme.fontSizes.large};
  }
`}
`;
