import React from 'react'
import { Span } from './styles';

export type TypographyType = 'body1' | 'title' | 'body2' | 'body3'

interface TypographyProps {
  color?: string;
  children: any;
  type?: TypographyType
}

const Typography = ({ children, color, type = 'body1' }: TypographyProps) => {
  return (
    <Span
      color={color}
      type={type}
    >
      {children}
    </Span>
  )
}

export default Typography;
