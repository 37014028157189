import NumberFormat from 'react-number-format';
import styled, { css } from 'styled-components';

export const NumberFormatStyled = styled(NumberFormat)`
${({ theme }) => css`
  padding: 0.8rem 0.8rem;
  border: 1px solid ${theme.colors.grey};
  background: ${theme.colors.lightGrey};

  color: ${theme.colors.grey};

  border-radius: 0.8rem;
  font-size: ${theme.fontSizes.default};
`}
`;
