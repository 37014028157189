export enum ActivesActionTypes {
  GET_ACTIVES_REQUEST = 'GET_ACTIVES_REQUEST',
  GET_ACTIVES_SUCCESS = 'GET_ACTIVES_SUCCESS',
  GET_ACTIVES_FAILURE = 'GET_ACTIVES_FAILURE',
  GET_ORDERS_REQUEST = 'GET_ORDERS_REQUEST',
  GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS',
  GET_ORDERS_FAILURE = 'GET_ORDERS_FAILURE',
  GET_BALANCE_INFO_REQUEST = 'GET_BALANCE_INFO_REQUEST',
  GET_BALANCE_INFO_SUCCESS = 'GET_BALANCE_INFO_SUCCESS',
  GET_BALANCE_INFO_FAILURE = 'GET_BALANCE_INFO_FAILURE',
  GET_STOCK_RATING_REQUEST = 'GET_STOCK_RATING_REQUEST',
  GET_STOCK_RATING_SUCCESS = 'GET_STOCK_RATING_SUCCESS',
  GET_STOCK_RATING_FAILURE = 'GET_STOCK_RATING_FAILURE',
  SET_IS_LOADING_ACTIVES = 'SET_IS_LOADING_ACTIVES',
  SET_IS_LOADING_BALANCE = 'SET_IS_LOADING_BALANCE',
  SET_IS_LOADING_RATING = 'SET_IS_LOADING_RATING',
}

export interface IActive {
  name: string;
  totalPapers: number;
  totalPurchaseValue: number;
  totalCurrentValue: number;
  totalPercentage: number;
  currentPrice: number;
  profit: number;
  profit_in_percentage: number;
}

export interface IOrder {
  id: string;
  wallet_id: string;
  price: number;
  amount: number;
  stock: IStock;
  wallet_name: string;
  created_at: string;
}

interface IStock {
  id: string;
  complete_name: string;
  name: string;
  sector: string;
  type: string;
}

interface IBalanceWallet {
  name: string;
  current_value: number;
  total_invested: number;
  current_percentage: number;
}

export interface IStockInfos {
  bestStocks: IStockRating[];
  worstStocks: IStockRating[];
}

export interface IActivesState {
  actives: IActive[];
  balance: IBalance;
  orders: IOrder[];
  stock_infos: IStockInfos;
  wallets_profits: IWalletProfit[];
  isLoadingActives: boolean;
  isLoadingBalance: boolean;
  isLoadingRating: boolean;
  isLoadingOrders: boolean;
}

export interface IBalance {
  balance: IBalanceWallet[];
}

export interface IStockRating {
  valuation_stock_id: string;
  name: string;
  price: number;
  previous_close: number;
  growth: number;
}

export interface IWalletProfit {
  name: string;
  wallet_id: string;
  date: string[];
  profits: number[];
  profits_in_percentage: number[];
}
