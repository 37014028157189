export enum ProfitActionTypes {
  GET_PROFITS_REQUEST = 'GET_PROFITS_REQUEST',
  GET_PROFITS_SUCCESS = 'GET_PROFITS_SUCCESS',
  GET_PROFITS_FAILURE = 'GET_PROFITS_FAILURE',
  SET_LOADING_PROFIT = 'SET_LOADING_PROFIT',
  GET_PROFIT_GRAPH_REQUEST = 'GET_PROFIT_GRAPH_REQUEST',
  GET_PROFIT_GRAPH_SUCCESS = 'GET_PROFIT_GRAPH_SUCCESS',
  GET_PROFIT_GRAPH_FAILURE = 'GET_PROFIT_GRAPH_FAILURE',
  GET_DIVIDEND_MONTHLY_REQUEST = 'GET_DIVIDEND_MONTHLY_REQUEST',
  GET_DIVIDEND_MONTHLY_SUCCESS = 'GET_DIVIDEND_MONTHLY_SUCCESS',
  GET_DIVIDEND_MONTHLY_FAILURE = 'GET_DIVIDEND_MONTHLY_FAILURE',
  SET_LOADING_PROFIT_GRAPH = 'SET_LOADING_PROFIT_GRAPH',
}

export interface IProfit {
  id:                 string;
  valuation_stock_id: string;
  wallet_id:          string;
  wallet_stock_id:    string;
  user_id:            string;
  name:               string;
  average_price:      number;
  unit_sell_price:    number;
  amount:             number;
  profit:             number;
  type:               string;
  sector:               string;
  dividendDistribution: {
    dividend_yield: number
  }
  wallet_name:        string;
  created_at:         string;
  updated_at:         string;
}

export interface IProfitState {
  profits: IProfit[];
  profitGraph: ProfitGraph;
  earningsByMonth: EarningsByMonth | null;
  isLoadingEarningsByMonth: boolean;
  isLoadingProfits: boolean;
  isLoadingProfitGraph: boolean;
}

export interface ProfitByMonth {
  date: string;
  dateString: string;
  [key: string]: any;
}

export interface ProfitGraph {
  months_profits: ProfitByMonth[];
  wallet_names: string[];
  wallets_stocks_names?: {
    [key: string]: string[];
  }
}

export interface DividendMonthlyChartSerie {
  name: string;
  type: string;
  data: number[]
}

export interface EarningsByMonth {
  chartSeries: {
    [key: string]: DividendMonthlyChartSerie[]
  };
  monthsList: string[];
  monthsLabels: string[];
}

export interface IPoint {
  x: Date;
  y: number;
}

export interface IPatrimonyInfos {
  maxValue: number;
  invested: IPoint[];
  patrimony: IPoint[];
}

export interface IPatrimony {
  totalPatrimony: IPatrimonyInfos;
  walletsPatrimony: {
    [key: string]: IPatrimonyInfos;
  }
}

export enum ProfitTypes {
  SELL = 'SELL',
  DIVIDENDS = 'DIVIDENDS',
  JCP = 'JUROS SOBRE CAPITAL PROPRIO'
}

export enum ProfitTypeToString {
  DIVIDENDS = 'Dividendos',
  SELL = 'Venda',
  'JUROS SOBRE CAPITAL PROPRIO' = 'JCP'
}

export const ProfitTypeTranslator = {
  DIVIDENDS: 'Dividendos',
  SELL: 'Venda',
  'JUROS SOBRE CAPITAL PROPRIO': 'JCP',
  'RENDIMENTO': 'Rendimento',
  'AMORTIZAÇÃO': 'Amortização',
  'RENDIMENTO TRIBUTADO': 'Rendimento Tributado',
} as any
