import { format } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useTheme } from 'styled-components';
import { IState } from '../../../../store';
import { DividendMonthlyChartSerie, EarningsByMonth, IProfit } from '../../../../store/modules/profit/types';
import { IWallet } from '../../../../store/modules/wallet/types';
import { getMonthObject } from '../../../../utils/dateUtils';

import {
  Container,
  Infos,
  Info,
  Heading,
} from './styles';

import Currency from '../../../../components/Currency';
import Percentage from '../../../../components/Percentage';
import Graph from './Graph';
import { useMediaQuery } from 'react-responsive';
import WalletSelection from './WalletSelection';

interface IProfitProps {
  profits: IProfit[];
  wallets: IWallet[];
  earningsByMonth: EarningsByMonth | null;
}

const EarningsByMonthGraph: React.FC<IProfitProps> = ({ wallets, earningsByMonth }) => {
  const isSmallDevice = useMediaQuery({ query: '(max-width: 1300px)' });
  const [walletFilter, setWalletFilter] = useState(wallets.map(w => w.id));

  const dividendYieldFromCurrentMonth = useMemo(() => {
    const totalSpent = wallets.reduce((acc, wallet) => {
      if (!walletFilter.includes(wallet.id)) {
        return acc;
      }

      const spent = wallet.total_wallet_value

      return acc + spent;
    }, 0);

    let totalEarningsInMonth = 0;

    wallets.forEach((wallet) => {
      if (!walletFilter.includes(wallet.id)) {
        return;
      }

      const totalEarnedFromStocks = earningsByMonth?.chartSeries[wallet.id]

      if (!totalEarnedFromStocks) {
        return;
      }

      let totalEarnedFromWalletInMonth = 0;
      totalEarnedFromStocks.forEach((stockEarnings) => {
        totalEarnedFromWalletInMonth += stockEarnings.data[stockEarnings.data.length - 1]
      })

      totalEarningsInMonth += totalEarnedFromWalletInMonth
    });

    return 1000 * (totalEarningsInMonth / totalSpent)
  }, [wallets, earningsByMonth, walletFilter]);

  const dividendYieldFromLastMonth = useMemo(() => {
    const totalSpent = wallets.reduce((acc, wallet) => {
      if (!walletFilter.includes(wallet.id)) {
        return acc;
      }

      const spent = wallet.total_wallet_value

      return acc + spent;
    }, 0);

    let totalEarningsInMonth = 0;

    wallets.forEach((wallet) => {
      if (!walletFilter.includes(wallet.id)) {
        return;
      }

      const totalEarnedFromStocks = earningsByMonth?.chartSeries[wallet.id]

      if (!totalEarnedFromStocks) {
        return;
      }

      let totalEarnedFromWalletInMonth = 0;
      totalEarnedFromStocks.forEach((stockEarnings) => {
        totalEarnedFromWalletInMonth += stockEarnings.data[stockEarnings.data.length - 2]
      })

      totalEarningsInMonth += totalEarnedFromWalletInMonth
    });

    return 1000 * (totalEarningsInMonth / totalSpent)
  }, [wallets, earningsByMonth, walletFilter]);

  const averageEarningsByMonth = useMemo(() => {
    const numberOfMonths = earningsByMonth?.monthsList.length || 1;

    let totalEarningsInMonth = 0;

    wallets.forEach((wallet) => {
      if (!walletFilter.includes(wallet.id)) {
        return;
      }

      const totalEarnedFromStocks = earningsByMonth?.chartSeries[wallet.id]

      if (!totalEarnedFromStocks) {
        return;
      }

      let totalEarnedFromWalletInMonth = 0;
      totalEarnedFromStocks.forEach((stockEarnings) => {
        totalEarnedFromWalletInMonth += stockEarnings.data.reduce((acc, earning) => {
          return acc + earning;
        }, 0);
      })

      totalEarningsInMonth += totalEarnedFromWalletInMonth
    });

    return totalEarningsInMonth / numberOfMonths
  }, [wallets, earningsByMonth, walletFilter]);

  const averageEarningsByMonthLast3Months = useMemo(() => {
    const numberOfMonths = 3;

    let totalEarningsInMonth = 0;

    wallets.forEach((wallet) => {
      if (!walletFilter.includes(wallet.id)) {
        return;
      }

      const totalEarnedFromStocks = earningsByMonth?.chartSeries[wallet.id]

      if (!totalEarnedFromStocks) {
        return;
      }

      let totalEarnedFromWalletInMonth = 0;
      totalEarnedFromStocks.forEach((stockEarnings) => {
        const data = [...stockEarnings.data].reverse()

        data.forEach((value, index) => {
          if (index < numberOfMonths) {
            totalEarnedFromWalletInMonth += value;
          }
        });
      })

      totalEarningsInMonth += totalEarnedFromWalletInMonth
    });

    return totalEarningsInMonth / numberOfMonths
  }, [wallets, earningsByMonth, walletFilter]);

  if (isSmallDevice) {
    return null;
  }

  return (
    <Container>
      <Heading>
        <WalletSelection
          walletFilter={walletFilter}
          setWalletFilter={setWalletFilter}
        />
      </Heading>

      <Graph
        walletFilter={walletFilter}
      />

      <Infos>
        <Info>
          <span>Yield on Cost (Mês Atual)</span>
          <Percentage value={dividendYieldFromCurrentMonth}/>
        </Info>
        <Info>
          <span>Yield on Cost (Último Mês)</span>
          <Percentage value={dividendYieldFromLastMonth}/>
        </Info>
        <Info>
          <span>Média (3 meses)</span>
          <Currency value={averageEarningsByMonthLast3Months}/>
        </Info>
        <Info>
          <span>Média (Período Completo)</span>
          <Currency value={averageEarningsByMonth}/>
        </Info>
      </Infos>
    </Container>
  )
}

const mapStateToProps = (state: IState) => ({
  profits: state.profit.profits,
  earningsByMonth: state.profit.earningsByMonth,
  wallets: state.wallet.wallets,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(EarningsByMonthGraph)
