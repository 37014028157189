import React from 'react';
import { connect } from 'react-redux';
import CardTitle from '../../../../components/CardTitle';

import { IState } from '../../../../store';
import FutureEarningsList from './FutureEarningsList';

import {
  Container,
} from './styles';

interface FutureEarningsProps { }

const FutureEarnings: React.FC<FutureEarningsProps> = () => {
  return (
  <Container>
    <CardTitle
      title='Receitas Futuras'
      type='secondary'
    />

    <FutureEarningsList />
  </Container>
  )
}

const mapStateToProps = (state: IState) => ({ })

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(FutureEarnings)
