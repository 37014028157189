import { Dispatch } from "redux";
import { getTesouroWalletFailure, getTesouroWalletsRequest, getTesouroWalletsSuccess } from "../store/modules/tesouroWallet/actions";
import api from "./api";

export const fetchTesouroWallets = async (dispatch: Dispatch<any>, async?: boolean) => {
  console.log('fetching tesouro wallets...')

  if (async) {
    dispatch(getTesouroWalletsRequest());
  }

  try {
    const response = await api.get('/tesouro/list');

    dispatch(getTesouroWalletsSuccess(response.data));
  } catch (err) {
    console.log(err);
    dispatch(getTesouroWalletFailure('error'));
  }
}
