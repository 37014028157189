import { format } from "date-fns";
import { isValid } from "date-fns/esm";

export const validateOrders = () => {
  return false;
}

export const getStringDatesFromDates = (dates: Date[]) => {
  const formattedDateStrings = dates.map((date) => {
    return getStringDateFromDate(date)
  });

  return formattedDateStrings;
}

export const getStringDateFromDate = (date: Date) => {
  if (isValid(date)) {
    return format(date, 'dd/MM/yyyy');
  } else {
    return 'Data Inválida';
  }
}
