import React from 'react';
import Currency from '../../../Currency';
import Percentage from '../../../Percentage';

import {
  Container,
  Infos,
  Prices,
} from './styles';

interface CryptoOptionsProps {
  complete_name: string;
  short_name: string;
  price: number;
  variation: number;
  isSmallInput?: boolean;
  onClick(): void;
}

const StockOption:React.FC<CryptoOptionsProps> = ({
  complete_name,
  short_name,
  price,
  variation,
  onClick,
  isSmallInput,
}) => {
  return (
    <Container
      whileHover={{ scale: 1.0005 }}
      onClick={onClick}
      isSmallInput={isSmallInput}
    >
      <Infos>
        <h1>{short_name} </h1>
        <span>{complete_name && `${complete_name}`}</span>
      </Infos>

      <Prices
        negativePercentage={variation < 0}
        positivePercentage={variation > 0}
      >
        <Currency
          value={price * 100}
          dollar
        />

        {!isSmallInput && (
          <Percentage
            value={variation * 1000}
          />
        )}
      </Prices>
    </Container>
  )
}

export default StockOption;
