import { motion } from 'framer-motion';
import React from 'react';
import { FiFileText } from 'react-icons/fi';
import { useTheme } from 'styled-components';
import Currency from '../../../../components/Currency';
import Percentage from '../../../../components/Percentage';

import {
  Container,
  Header,
  ActualPrice,
  MediumPrice,
  TotalPercentage,
  TotalInvested,
  TotalValue,
  ProfitPercentage,
} from './styles';

type ActiveProps = {
  isDarkTheme: boolean;
  name: string;
  currentPrice: number;
  averagePrice: number;
  totalPercentage: number;
  totalPurchaseValue: number;
  totalCurrentValue: number;
  profit: number;
  profit_in_percentage: number;

  style: any;
}

const cardsAnimation = {
  unMounted: { x: 0, opacity: 1 },
  mounted: {
    x: 0,
    opacity: 1,
  },
}

const Active: React.FC<ActiveProps> = ({
  isDarkTheme = false,
  name,
  currentPrice,
  averagePrice,
  totalPercentage,
  totalPurchaseValue,
  totalCurrentValue,
  profit,
  profit_in_percentage,
  style,
}) => {
  const theme = useTheme();

  return (
    <Container
    style={{ ...style }}
      layout
      isDarkTheme={isDarkTheme}
      key={`card-${name}`}
      variants={cardsAnimation}
      whileHover={{ x: -2 }}
    >
      <Header>
        <FiFileText size={18} />
        <h1>{name}</h1>
      </Header>

      <ActualPrice>
        <Currency value={currentPrice} />
      </ActualPrice>

      <MediumPrice>
        <Currency value={averagePrice} />
      </MediumPrice>

      <TotalPercentage>
        <Percentage value={totalPercentage} />
      </TotalPercentage>

      <TotalInvested>
        <Currency value={totalPurchaseValue} />
      </TotalInvested>

      <TotalValue>
        <Currency value={totalCurrentValue} />
      </TotalValue>

      <ProfitPercentage>
        <Percentage
          value={profit_in_percentage}
          color={profit_in_percentage < 0 ? theme.colors.error : theme.colors.success}
        />
      </ProfitPercentage>

      <ProfitPercentage>
        <Currency
          value={profit}
          color={profit < 0 ? theme.colors.error : theme.colors.success}
        />
      </ProfitPercentage>
    </Container>
  );
};

export default Active;
