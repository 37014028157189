import { isValid } from "date-fns";
import api from "../../../services/api";

export interface IStockNameOption {
  id: string;
  complete_name: string;
  name: string;
  sector: string;
  type: string;
}

export interface IStockWallet {
  id: number;
  name: string;
  averagePrice: string;
  papers: string;
  date: string;
  percentage: string;
  valuation: string;
}

export interface ITesouroWallet {
  id: number;
  tesouro_active_id: string;
  tesouro_active_name: string;
  tesouro_active_technical_name: string;
  purchase_date: string;
  price: string;
  amount: string;
}

export interface ICryptoWallet {
  id: number;
  name: string;
  percentage: string;
  cryptocurrency_id: string;
}

interface CreateWalletParameters {
  walletName: string;
  stocksInWallet?: IStockWallet[]
  tesourosInWallet?: ITesouroWallet[]
  cryptosInWallet?: ICryptoWallet[]
}

const toNumberPercentage = (n: string) => {
  return Number(n.replace('%', '').replace(',', '.'));
}

const createStockWallet = async ({ walletName, stocksInWallet = [] }: CreateWalletParameters) => {
  if (!stocksInWallet) return;

  const stocksFiltered = stocksInWallet.filter((stock) => !!stock.name);

  const data = {
    name: walletName,
    stocks: stocksFiltered.map(stock => {
      const [day, month, year] = stock.date.split('/');
      const stockDate = new Date(`${year}-${month}-${day} 00:00:00`);

      const idealPercentage = Math.round(toNumberPercentage(stock.percentage) * 10);

      return {
        name: stock.name,
        papers: stock.papers,
        averagePrice: Math.round(Number(stock.averagePrice) * 100),
        idealPercentage,
        valuation: Math.round(Number(stock.valuation) * 100),
        date: isValid(stockDate) ? stockDate : '',
      }
    }),
  }

  await api.post('/wallet/create', { ...data });

  return data;
}

const createTesouroWallet = async ({ walletName, tesourosInWallet = [] }: CreateWalletParameters) => {
  if (!tesourosInWallet) return;

  const tesourosFiltered = tesourosInWallet.filter((tesouro) => !!tesouro.tesouro_active_id);

  const data = {
    name: walletName,
    tesouros: tesourosFiltered.map(tesouro => {
      const [day, month, year] = tesouro.purchase_date.split('/');
      const tesouroPurchaseDate = new Date(`${year}-${month}-${day} 00:00:00`);

      return {
        tesouro_active_id: tesouro.tesouro_active_id,
        tesouro_active_name: tesouro.tesouro_active_name,
        tesouro_active_technical_name: tesouro.tesouro_active_technical_name,
        price: Number(tesouro.price) * 100,
        amount: Math.round(+tesouro.amount * 100) / 100,
        purchase_date: isValid(tesouroPurchaseDate) ? `${year}-${month}-${day} 00:00:00` : '',
      }
    }),
  }
  await api.post('/tesouro/create', { ...data });

  return data;
}

const createCryptoWallet = async ({ walletName, cryptosInWallet = [] }: CreateWalletParameters) => {
  if (!cryptosInWallet) return;

  const cryptosWithNames = cryptosInWallet.filter((crypto) => !!crypto.cryptocurrency_id);

  const data = {
    name: walletName,
    cryptos: cryptosWithNames.map((crypto) => ({
      ...crypto,
      percentage: toNumberPercentage(crypto.percentage)
    })),
  }

  await api.post('/crypto/create-wallet', { ...data });

  return data;
}

const createPersonalizedWallet = async ({ walletName }: CreateWalletParameters) => {
  const data = {
    name: walletName,
    starting_value: 0,
    current_value: 0,
  }

  await api.post('/personalized-wallets', data);

  return data;
}

export const submitFormFunctionsByType = {
  'Ações, BDRs, ETFs Nacionais': createStockWallet,
  'Fundos Imobiliários': createStockWallet,
  'Tesouro Direto': createTesouroWallet,
  'Criptomoedas e Stablecoins': createCryptoWallet,
  'Personalizada': createPersonalizedWallet,
}
