import React, { useState, useEffect } from 'react';
import { Box } from '../../../../components/Box';
import Modal from '../../../../components/Modal';
import { useTheme } from 'styled-components';
import CardTitle from '../../../../components/CardTitle';
import Typography from '../../../../components/Typography';
import { IOrder, useOrders } from '../../../../hooks/react-query/useOrders';
import { Show } from '../../../../components/Show';
import { StockEmblem } from '../../../../components/StockEmblem';
import { FiX } from 'react-icons/fi';
import Button from '../../../../components/Button';
import { ValueContainer } from './styles';
import { format } from 'date-fns';
import { useAuth } from '../../../../hooks/react-query/useAuth';
import api from '../../../../services/api';
import { fetchData } from '../../../../services/data';
import { useDispatch } from 'react-redux';
import { createToastMessage } from '../../../../store/modules/toast/actions';
import CurrencyInput from '../../../../components/CurrencyInput';
import Input from '../../../../components/Input';
import { DateInput } from '../../../../components/DateInput';

interface DeleteOrderModal {
  setOrderToDelete: (o: IOrder | null) => void;
  orderToDelete: IOrder | null;
}

export const UpdateOrderModal = ({ orderToDelete, setOrderToDelete }: DeleteOrderModal) => {
  const { refetch } = useOrders({})

  const [isLoading, setIsLoading] = useState(false);

  const [dateString, setDateString] = useState(orderToDelete?.created_at || '')
  const [price, setPrice] = useState(orderToDelete?.price || 0)
  const [amount, setAmount] = useState(orderToDelete?.amount || 0)

  const { data: user } = useAuth();

  const dispatch = useDispatch()
  const theme = useTheme()

  useEffect(() => {
    if (orderToDelete) {
      setPrice(orderToDelete.price)
      setAmount(orderToDelete.amount)
      setDateString(format(new Date(orderToDelete.created_at), 'dd/MM/yyyy'))
    }
  }, [orderToDelete])

  const handleUpdateOrder = async () => {
    if (!orderToDelete?.id) return;

    const [day, month, year] = dateString.split('/');

    const data = {
      price: price,
      amount: amount,
      date: new Date(+year, +month - 1, +day),
    }

    try {
      setIsLoading(true);

      await api.put(`/invest/update-order/${orderToDelete.id}`, data);

      await fetchData({ dispatch });
      await refetch();

      dispatch(createToastMessage({
        title: `Ordem editada com sucesso.`,
        created_at: new Date(),
        isPined: false,
        type: 'success',
      }));

      setIsLoading(false);
      setOrderToDelete(null);
    } catch (err) {
      dispatch(createToastMessage({
        title: `Erro ao editar ordem.`,
        content: (err as any)?.response?.data?.message || `Ops, ocorreu um erro ao criar sua ordem, por favor chame o nosso time para te ajudar!`,
        created_at: new Date(),
        isPined: false,
        type: 'error',
      }));
    }
  }

  return (
    <Modal
      isVisible={!!orderToDelete}
      closeModal={() => setOrderToDelete(null)}
    >
      <Box bg={theme.colors.background} p={'2.4rem'} flexDirection='column' w={'25%'}>
        <Box mb={'1.6rem'} justifyContent='space-between'>
          <CardTitle title={'Excluir Ordem'} />
          <Button
            style={{ padding: 8}}
            revision={'secondary'}
            onClick={() => setOrderToDelete(null)}
          >
            <FiX />
          </Button>
        </Box>

        <Box flexDirection='column'>
          <Box alignItems='center' mb={'1.6rem'}>
            <Show when={(orderToDelete?.entity as any)?.stockEnterprisesInfos?.image_url}>
              <StockEmblem
                image_url={(orderToDelete?.entity as any)?.stockEnterprisesInfos?.image_url}
              />
            </Show>
            <Typography>{orderToDelete?.entity.name}</Typography>
          </Box>

          <Show when={orderToDelete?.created_at}>
            <ValueContainer>
              <label>Data</label>
              <DateInput
                setDateString={setDateString}
                dateString={dateString}
              />
            </ValueContainer>
          </Show>

          <ValueContainer>
            <label>Valor</label>
            <CurrencyInput
              callBack={(e) => setPrice(Number(e) * 100)}
              defaultValue={String(price / 100).replace('.', ',')}
            />
          </ValueContainer>

          <ValueContainer>
            <label>Quantidade</label>
            <Input
              callback={(e) => setAmount(Number(e))}
              value={String(amount)}
            />
          </ValueContainer>

          <Box mb={'1.6rem'} />

          <Button revision='primary' onClick={handleUpdateOrder} isLoading={isLoading}>
            Atualizar Ordem
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}
