import { IWalletCrypto, CryptoWalletActionTypes } from "./types";

export function getCryptoWalletsRequest() {
  return {
    type: CryptoWalletActionTypes.GET_CRYPTO_WALLETS_REQUEST,
  }
}

export function getCryptoWalletsSuccess(wallets: IWalletCrypto[]) {
  return {
    type: CryptoWalletActionTypes.GET_CRYPTO_WALLETS_SUCCESS,
    payload: {
      wallets,
    }
  }
}

export function getCryptoWalletFailure(error: string) {
  return {
    type: CryptoWalletActionTypes.GET_CRYPTO_WALLETS_FAILURE,
    payload: {
      error,
    }
  }
}
