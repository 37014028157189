import styled from 'styled-components'

export interface BoxProps {
  pt?: string
  pb?: string
  pl?: string
  pr?: string
  bg?: string | 'transparent'
  px?: string
  py?: string
  p?: string
  m?: string
  mt?: string
  mb?: string
  ml?: string
  mr?: string
  mx?: string
  my?: string
  h?: string | string
  minHeight?: string | string
  maxWidth?: string | string
  w?: string | string
  flex?: string | number
  flexDirection?: 'row' | 'column' | 'row-reverse' | 'column-reverse' | undefined
  justifyContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly' | undefined
  alignItems?: 'flex-start' | 'flex-end' | 'center' | 'stretch' | 'baseline' | undefined
  borderRadius?: string
  borderColor?: string
}

export const Box = styled.div<BoxProps>`
  display: flex;

  ${(p) => p.bg && `background-color: ${p.bg}`};

  ${(p) => p.flex && `flex: ${p.flex};`}
  ${(p) => p.flexDirection && `flex-direction: ${p.flexDirection}`};
  ${(p) => p.justifyContent && `justify-content: ${p.justifyContent}`};
  ${(p) => p.alignItems && `align-items: ${p.alignItems}`};

  ${(p) => p.h && `height: ${p.h}`};
  ${(p) => p.w && `width: ${p.w};`}
  ${(p) => p.minHeight && `min-height: ${p.minHeight};`}
  ${(p) => p.maxWidth && `max-width: ${p.maxWidth};`}
  ${(p) => p.borderRadius && `border-radius: ${p.borderRadius}`};
  ${(p) => p.borderColor && `
    border: solid 1px ${p.borderColor};
  `};

  ${(p) => p.m && `margin: ${p.m}`};
  ${(p) => p.mb && `margin-bottom: ${p.mb}`};
  ${(p) => p.ml && `margin-left: ${p.ml}`};
  ${(p) => p.mr && `margin-right: ${p.mr}`};
  ${(p) => p.mt && `margin-top: ${p.mt}`};
  ${(p) => p.mx && `margin-horizontal: ${p.mx}`};
  ${(p) => p.my && `margin-vertical: ${p.my}`};

  ${(p) => p.p && `padding: ${p.p}`};
  ${(p) => p.pb && `padding-bottom: ${p.pb}`};
  ${(p) => p.pl && `padding-left: ${p.pl}`};
  ${(p) => p.pr && `padding-right: ${p.pr}`};
  ${(p) => p.pt && `padding-top: ${p.pt}`};
  ${(p) => p.px && `padding-horizontal: ${p.px}`};
  ${(p) => p.py && `padding-vertical: ${p.py}`};
`
