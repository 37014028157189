import React, { useMemo } from 'react';
import { useTheme } from 'styled-components';

import * as Styled from './styles';

import { WidgetCard } from '../../../components/WidgetCard';
import { Box, CardTitle, Loading } from '../../../components';
import { TimestampLinearChart, TimestampLinearChartProps } from '../../Graphs/TimestampLinearChart';
import { BarChartProps, TimestampBarChart } from '../../Graphs/TimestampBarChart';

type IGraphWidget = IGraphWidgetDefaultProps & Partial<TimestampLinearChartProps> & Partial<BarChartProps>

interface IGraphWidgetDefaultProps {
  isLoading: boolean
  title: string
  type: "timestampLinearChart" | "timestampBarChart"
}

export const GraphWidget: React.FC<IGraphWidget> = ({
  linearChartColor,
  linearChartData,
  serieName,
  type,
  isLoading,
  title,
  barChartData,
  annotations,
  valueFormatter,
  labels,
}) => {
  const theme = useTheme()

  if (isLoading) {
    return (
      <WidgetCard w='100%' minHeight='40rem'>
        <CardTitle
          title={title}
          type='secondary'
        />
        <Box mb={'1.6rem'} />
        <Box w='100%' flex='1' alignItems='center' justifyContent='center'>
          <Loading color={theme.colors.primary} size={20} />
        </Box>
      </WidgetCard>
    )
  }

  return (
    <WidgetCard w='100%'>
      <CardTitle
        title={title}
        type='secondary'
      />
      <Box mb={'1.6rem'} />
      <Styled.GraphContainer>
        {type === 'timestampLinearChart' && (
          <TimestampLinearChart
            linearChartData={linearChartData!}
            linearChartColor={linearChartColor!}
            serieName={serieName!}
            valueFormatter={valueFormatter}
          />
        )}
        {type === 'timestampBarChart' && (
          <TimestampBarChart
            barChartData={barChartData!}
            labels={labels!}
            annotations={annotations}
          />
        )}
      </Styled.GraphContainer>
    </WidgetCard>
  );
};
