import { useQuery } from '@tanstack/react-query'
import api from '../../services/api'
import { useMemo } from 'react'

interface ITesouroDiretoActive {
  id: string;
  name: string;
  lowercased_name: string;
  technical_name: string;
  rentability: number;
  current_buy_price: number;
  current_sell_price: number;
  yesterday_buy_price: number;
  yesterday_sell_price: number;
  type: string;
  expiration: Date;
  created_at: Date;
  updated_at: Date;
}

interface IStock {
  id: string;
  complete_name: string;
  name: string;
  sector: string;
  type: string;
}

export interface IOrder {
  type: 'stock' | 'tesouro' | 'provent'
  id: string;
  wallet_identifier: keyof Omit<IOrder, "wallet_identifier">;
  wallet_entity_identifier: string;
  price: number;
  amount: number;
  entity: ITesouroDiretoActive | IStock,
  wallet_name?: string;
  wallet_id?: string;
  created_at: string;
}

interface useOrdersProps {
  wallet_identifier?: string[]
  types?: string[]
}

export const useOrders = ({ wallet_identifier, types }: useOrdersProps) => {
  const query = useQuery(['orders'], async () => {
    const response = await api.get<IOrder[]>('/orders/')

    return response.data
  })

  const data = useMemo(() => {
    return query.data?.filter((order) => {
      let finalData = true;

      if (wallet_identifier && wallet_identifier.length) {
        finalData = wallet_identifier.includes(String(order[order.wallet_identifier]))
      }

      if (types) {
        finalData = finalData && types.includes(order.type)
      }

      return finalData
    })
  }, [query, wallet_identifier])

  return {
    ...query,
    data,
  }
}
