import React from 'react';
import Currency from '../../../Currency';
import Percentage from '../../../Percentage';

import {
  Container,
  Infos,
  Prices,
} from './styles';

interface WalletOptionsProps {
  name: string;
  value: number;
  profit: number;
  dollar: boolean;
  isSmallInput?: boolean;
  onClick(): void;
}

const WalletOption:React.FC<WalletOptionsProps> = ({
  name,
  value,
  profit,
  dollar,
  onClick,
  isSmallInput,
}) => {
  return (
    <Container
      whileHover={{ scale: 1.0005 }}
      onClick={onClick}
      isSmallInput={isSmallInput}
    >
      <Infos>
        <h1>{name}</h1>
      </Infos>

      <Prices
        negativePercentage={profit < 0}
        positivePercentage={profit > 0}
      >
        <Currency
          value={value}
          dollar={dollar}
        />

        {!isSmallInput && (
          <Percentage
            value={profit}
          />
        )}
      </Prices>
    </Container>
  )
}

export default WalletOption;
