import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

export const Container = styled.div<{ rentability: number }>`
${({ theme, rentability }) => css`
  background: ${theme.colors.lightGrey};
  border-radius: 0.8rem;

  padding: 0.8rem 2.4rem;

  margin-right: 1.6rem;

  > span {
    font-size: ${theme.fontSizes.small};
    letter-spacing: 0.03em;
    margin-bottom: 0.4rem;

    font-weight: normal;
    font-style: normal;

    line-height: 15px;
  }

  > div {
    display: flex;
    align-items: center;
    color: ${theme.colors.success};

    > h6 {
      ${rentability > 0 && css`
        margin-left: 0.4rem;
      `}
      font-size: ${theme.fontSizes.large};
    }
  }
`}
`;
