import { Dispatch } from "redux";
import { getCryptoWalletFailure, getCryptoWalletsRequest, getCryptoWalletsSuccess } from "../store/modules/crypto/actions";
import api from "./api";

export const fetchCryptoWallets = async (dispatch: Dispatch<any>, async?: boolean) => {
  console.log('fetching crypto wallets...')

  if (async) {
    dispatch(getCryptoWalletsRequest());
  }

  try {
    const response = await api.get('/crypto/list-wallets');

    dispatch(getCryptoWalletsSuccess(response.data));
  } catch (err) {
    console.log(err);
    dispatch(getCryptoWalletFailure('error'));
  }
}
