import { isValid } from "date-fns";

export function getMonthObject(monthNumber: number) {
  const months = [
    { monthString: 'Janeiro', monthAbbrev: 'Jan' },
    { monthString: 'Fevereiro', monthAbbrev: 'Fev' },
    { monthString: 'Março', monthAbbrev: 'Mar' },
    { monthString: 'Abril', monthAbbrev: 'Abr' },
    { monthString: 'Maio', monthAbbrev: 'Mai' },
    { monthString: 'Junho', monthAbbrev: 'Jun' },
    { monthString: 'Julho', monthAbbrev: 'Jul' },
    { monthString: 'Agosto', monthAbbrev: 'Ago' },
    { monthString: 'Setembro', monthAbbrev: 'Set' },
    { monthString: 'Outubro', monthAbbrev: 'Out' },
    { monthString: 'Novembro', monthAbbrev: 'Nov' },
    { monthString: 'Dezembro', monthAbbrev: 'Dez' },
  ];

  return months[monthNumber];
}

export function hasDateStringError(dateString: string) {
  if (!dateString) return false;

  const [day, month, year] = dateString.split('/');

  if (day.includes('d') || month.includes('m') || year.includes('a')) return false;

  const date = new Date(`${year}-${+month}-${day} 00:00:00`);

  return !isValid(date);
}
