import React from 'react';
import NumberFormat from 'react-number-format';

import { Container } from './styles';

interface CurrencyProps {
  value: number;
  color?: string;
  dollar?: boolean;
  type?: 'flat' | 'variation'
}

const Currency: React.FC<CurrencyProps> = ({
  value,
  color,
  dollar,
  type = 'flat'
}) => {
  const prefixes = dollar ? '$ ' : 'R$ '

  return (
    <Container
      color={color}
      type={type}
      value={value}
    >
      <NumberFormat
        value={value / 100}
        displayType={'text'}
        thousandSeparator={"."}
        decimalSeparator={","}
        fixedDecimalScale={true}
        decimalScale={2}
        renderText={fValue => (
          <>
            {value < 0 && (
              <span>-</span>
            )}
            <p>{prefixes} </p>
            {value < 0 ? fValue.replace('-', '') : fValue}
          </>
        )}
      />
    </Container>
  );
};

export default Currency;
