import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

export const Container = styled.div`
${({ theme }) => css`
  width: 100%;
`}
`;

export const Header = styled.div`
${({ theme }) => css`
  > h1 {
    font-size: ${theme.fontSizes.huge};
    color: ${theme.colors.success};
    margin-bottom: 1.6rem;
  }
`}
`;

export const SelectOrdersTable = styled.table`
${({ theme }) => css`
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;

  overflow-y: scroll;
`}
`;

export const TableHead = styled.thead`
${({ theme }) => css`
  border-radius: 10px 10px 0 0;
  position: sticky;
  z-index: 999;
  top: 0;
`}
`;

export const ColumnSelectionRow = styled.tr`
${({ theme }) => css`
  > td {
    padding: 0.8rem;
    color: ${theme.colors.success};
    font-weight: bold;
    font-size: ${theme.fontSizes.default};
    background: ${theme.colors.background};
  }
`}
`;

export const TableContent = styled.tbody`
${({ theme }) => css`
  overflow-y: scroll;
`}
`;

interface ValuesRowProps {
  hasSelectedWallet: boolean;
}

export const ValuesRow = styled.tr<ValuesRowProps>`
${({ theme, hasSelectedWallet }) => css`
  > td {
    border: solid 0.2rem ${transparentize(0.95, theme.colors.success)};
    padding: 0.8rem;

    background: ${transparentize(0.95, theme.colors.success)};
    color: ${theme.colors.white};
    font-size: ${theme.fontSizes.default};

    > h6 {
      color: ${theme.colors.white};
      font-size: ${theme.fontSizes.default};
    }

    ${!hasSelectedWallet && css`
      border: solid 0.2rem ${transparentize(0.95, theme.colors.warning)};
      background: ${transparentize(0.9, theme.colors.warning)};
    `}
  }
`}
`;

export const TableContainer = styled.div`
  height: 40rem;
  overflow-y: scroll;
  overflow-x: hidden;

  padding: 0 0 16rem 0;
`;

export const SelectWalletTableData = styled.td`
${({ theme }) => css`
  > div {
    height: 4rem;

    > svg {
      color: ${theme.colors.success} !important;
    }

    > div {
      > button:hover {
        border-color: ${theme.colors.success};
      }
    }
  }
`}
`

export const StatusContainer = styled.div`
  display: flex;
  align-items: center;

  > div {
    display: flex;
  }

  svg {
    margin-right: 0.8rem;
  }
`;
