import styled, { css } from 'styled-components';

export const Container = styled.div`
${({ theme }) => css`
  height: 100vh;
  width: 100vw;

  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;

  font-family: Lato;

  > h2 {
    color: #888;
    font-size: 32px;
  }

  > div {
    display: flex;
    margin-bottom: 32px;

    > img {
      width: 30rem;
    }
  }

  .lds-hourglass {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }

  .lds-hourglass:after {
    content: " ";
    display: block;
    border-radius: 50%;
    width: 0;
    height: 0;
    margin: 8px;
    box-sizing: border-box;
    border: 32px solid ${theme.colors.primary};
    border-color: ${theme.colors.primary} transparent ${theme.colors.primary} transparent;
    animation: lds-hourglass 1.2s infinite;
  }

  @keyframes lds-hourglass {
    0% {
      transform: rotate(0);
      animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    50% {
      transform: rotate(900deg);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    100% {
      transform: rotate(1800deg);
    }
  }
`}
`;
