import React, { useState, createContext, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { IWallet } from '../../../../store/modules/wallet/types';
import { IState } from '../../../../store';

import * as Styled from './styles';

import ExcludeWalletModal from './ExcludeWalletModal';
import { getContainerAnimation } from '../../../../resources/animations';
import { WalletHeader } from './WalletHeader';
import { Box } from '../../../../components';
import PatrimonyChart from './PatrimonyChart';
import StocksPieChart from './StocksPieChart';
import { StocksProfitList } from './StocksProfitList';
import WalletEarningsByMonthGraph from './WalletEarningsByMonthGraph';
import { StockOrdersList } from './StocksOrderList';
import ValuationGraph from './ValuationGraph';
import TopDividendPayerGraph from './TopDividendPayersGraph';

interface WalletContextData {
  wallet: IWallet;
}

const WalletContext = createContext<WalletContextData>({} as WalletContextData);

const Wallet: React.FC = () => {
  const location = useLocation();

  const wallet = useSelector<IState, IWallet>(state => {
    const [id] = location.pathname.split('/').reverse();

    const wallet = state.wallet.wallets.find(wallet => wallet.id === id);

    if (!wallet) {
      return state.wallet.wallets[0];
    }

    return wallet;
  });

  const [isExcludeWalletModalOn, setIsExcludeWalletModalOn] = useState(false);

  return (
    <WalletContext.Provider
      value={{ wallet }}
    >
      <Styled.Container
        variants={getContainerAnimation(0)}
      >
        <WalletHeader
          setIsExcludeWalletModalOn={() => null}
        />

        <Box w={'100%'} flexDirection='column'>
          <Styled.FirstRow>
            <PatrimonyChart />
          </Styled.FirstRow>
          <Styled.SecondRow>
            <Box mr={'1.6rem'} w={'50%'}>
              <StocksPieChart />
            </Box>
            <Box w={'50%'}>
              <ValuationGraph />
            </Box>
          </Styled.SecondRow>
          <Box mb={'1.6rem'} w={'100%'}>
            <StocksProfitList />
          </Box>
          <Styled.ThirdRow>
             {/* <Box mr={'1.6rem'} w={'40%'}>
              <TopDividendPayerGraph />
            </Box> */}
            <Box flex={1}>
              <WalletEarningsByMonthGraph />
            </Box>
          </Styled.ThirdRow>
          <Box>
            <StockOrdersList />
          </Box>
        </Box>

        <ExcludeWalletModal
          closeModal={() => setIsExcludeWalletModalOn(false)}
          isVisible={isExcludeWalletModalOn}
          name={wallet.name}
          id={wallet.id}
        />
      </Styled.Container>
    </WalletContext.Provider>
  );
};

export function useWallet(): WalletContextData {
  const context = useContext(WalletContext);

  return context;
}

export default Wallet;
