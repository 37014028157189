import { UiTypes } from "./types";

export function toggleOrderModal() {
  return {
    type: UiTypes.TOGGLE_ORDER_MODAL,
  }
}

export function toggleModalOn() {
  return {
    type: UiTypes.TOGGLE_IS_ANY_MODAL_ACTIVE,
  }
}
