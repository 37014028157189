import styled, { css } from 'styled-components'

export const ValueContainer = styled.div`
${({ theme }) => css`
  display: flex;
  flex-direction: column;

  margin-bottom: 1.6rem;

  > label {
    margin-bottom: 0.8rem;
  }

  > div {
    display: flex;
    flex-direction: column;

    display: flex;

    height: 4rem;

    padding: 0.8rem 0.8rem;
    border-radius: 0.8rem;

    background: ${theme.colors.lightGrey};

    border: solid 1px ${theme.colors.grey};
  }
`}
`
