import React from 'react';
import { connect } from 'react-redux';
import CardTitle from '../../../../components/CardTitle';

import { IState } from '../../../../store';
import HistoryTable from './HistoryTable';

import {
  Container,
} from './styles';

interface EarningsHistoryProps { }

const EarningsHistory: React.FC<EarningsHistoryProps> = () => {
  return (
  <Container>
    <CardTitle
      title='Histórico'
      type='secondary'
    />

    <HistoryTable />
  </Container>
  )
}

const mapStateToProps = (state: IState) => ({ })

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(EarningsHistory)
