import React from 'react';
import { IStockNameOption } from '..';
import Currency from '../../../Currency';
import Percentage from '../../../Percentage';

import {
  Container,
  Infos,
  Prices,
  ImageContainer,
} from './styles';

interface StockOptionsProps {
  complete_name: string;
  short_name: string;
  sector: string;
  price: number;
  variation: number;
  image_url: string;
  isSmallInput?: boolean;
  isMediumSizeInput?: boolean;
  onClick(): void;
}

const StockOption:React.FC<StockOptionsProps> = ({
  complete_name,
  short_name,
  price,
  sector,
  variation,
  onClick,
  isSmallInput,
  isMediumSizeInput,
  image_url,
}) => {
  const complete_name_formatted = complete_name?.length > 16 ? `${complete_name.slice(0, 16)}...` : complete_name;
  const shouldDisplayCompleteName = complete_name && !isMediumSizeInput && !isSmallInput;

  return (
    <Container
      whileHover={{ scale: 1.0005 }}
      onClick={onClick}
      isSmallInput={isSmallInput}
    >
      <ImageContainer>
        <img
          src={image_url}
        />
      </ImageContainer>

      <Infos>
        <h1>{short_name} {shouldDisplayCompleteName && `(${complete_name_formatted})`}</h1>
        <span>{sector}</span>
      </Infos>

      <Prices
        negativePercentage={variation < 0}
        positivePercentage={variation > 0}
      >
        <Currency
          value={price}
        />

        {!isSmallInput && (
          <Percentage
            value={variation}
          />
        )}
      </Prices>
    </Container>
  )
}

export default StockOption;
