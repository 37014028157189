import { isValid } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import NumberFormat from 'react-number-format';
import { connect, useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';
import Currency from '../../../../../../../components/Currency';
import CurrencyInput from '../../../../../../../components/CurrencyInput';
import Input from '../../../../../../../components/Input';
import Loading from '../../../../../../../components/Loading';
import StockSearchBar from '../../../../../../../components/SearchBars/StockSearchBar';
import TesouroSearchBar, { ITesouroNameOption } from '../../../../../../../components/SearchBars/TesouroSearchBar';
import Tooltip from '../../../../../../../components/Tooltip';
import { animations } from '../../../../../../../resources';
import api from '../../../../../../../services/api';
import { fetchData } from '../../../../../../../services/data';
import { IState } from '../../../../../../../store';
import { ITesouroWallet } from '../../../../../../../store/modules/tesouroWallet/types';
import { createToastMessage } from '../../../../../../../store/modules/toast/actions';
import { hasDateStringError } from '../../../../../../../utils/dateUtils';

import {
  Container,
  SelectStockContainer,
  DateContainer,
  AmountContainer,
  PriceContainer,
  ButtonsContainer,
  BuyButton,
  SellButton,
} from './styles';


interface TesouroFormsProps {
  wallet_id: string;
  tesouro_wallets: ITesouroWallet[];
}

const containerAnimation = {
  ...animations.containerAnimation,
  mounted: {
    ...animations.containerAnimation.mounted,
    transition: {
      ...animations.containerAnimation.mounted.transition,
      delay: 0.1,
      duration: undefined,
    }
  }
}

const TesouroForm: React.FC<TesouroFormsProps> = ({ wallet_id, tesouro_wallets }) => {
  const [dateString, setDateString] = useState('');
  const [price, setPrice] = useState('');
  const [totalInvested, setTotalInvested] = useState('');
  const [amount, setAmount] = useState(0);
  const [selectedTesouro, setSelectedTesouro] = useState<ITesouroNameOption | null>(null);

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const theme = useTheme();

  const hasDateError = useMemo(() => {
    return hasDateStringError(dateString)
  }, [dateString]);

  useEffect(() => {
    if (hasDateError || !selectedTesouro || !selectedTesouro.id) return;

    const [day, month, year] = dateString.split('/');

    if (!dateString.length) return;
    if (hasDateError) return;
    if (day?.includes('d') || month?.includes('m') || year?.includes('a')) return;

    async function getTesouroPriceAtDate() {
      if (!selectedTesouro) return;

      try {
        const tesouroPriceAtDate = await api.get('/tesouro/get-price', {
          params: {
            date: `${year}-${month}-${day}`,
            tesouro_active_id: selectedTesouro.id,
          },
        });

        setPrice(String(tesouroPriceAtDate.data.purchase_value / 100).replace('.', ','));
      } catch (e) {
        dispatch(createToastMessage({
          title: `Falha ao encontrar preço.`,
          content: `Não encontramos o preço do ${selectedTesouro.name} no dia ${dateString}, por favor preencha manualmente`,
          created_at: new Date(),
          isPined: false,
          type: 'info',
        }));
      }
    }

    getTesouroPriceAtDate();
  }, [dateString, hasDateError]);


  const handleExecuteOrder = async (type: string) => {
    if (!selectedTesouro) return

    const selectedWallet = tesouro_wallets.find(wallet => wallet.id === wallet_id);

    if (!selectedWallet) return

    setIsLoading(true);

    const [day, month, year] = dateString.split('/');
    const tesouroDate = new Date(`${year}-${month}-${day} 00:00:00`);

    const tesouro = {
      tesouro_active_id: selectedTesouro.id,
      tesouro_active_name: selectedTesouro.name,
      tesouro_active_technical_name: selectedTesouro.technical_name,
      purchase_date: tesouroDate,
      price: Number(price.replace(',', '.')) * 100,
      amount,
      type,
    }

    const data = {
      wallet_id,
      tesouros: [tesouro],
    }

    try {
      await api.post('/tesouro/create-order', data);

      dispatch(createToastMessage({
        title: `Ordem adicionada.`,
        content: `Adicionamos sua ordem de ${amount} ${selectedTesouro.name}`,
        created_at: new Date(),
        isPined: false,
        type: 'success',
      }));

      setDateString('');
      setPrice('');
      setAmount(0);
      setTotalInvested('');
    } catch (err) {
      dispatch(createToastMessage({
        title: `Erro ao criar ordem.`,
        content: (err as any)?.response?.data?.message || `Ops, ocorreu um erro ao criar sua ordem, por favor chame o nosso time para te ajudar!`,
        created_at: new Date(),
        isPined: false,
        type: 'info',
      }));
    }

    setIsLoading(false);
    fetchData({ dispatch });
  }

  const isButtonsDisabled = useMemo(() => {
    if (!selectedTesouro || !selectedTesouro.id) {
      return true;
    }

    if (isLoading) {
      return true;
    }

    return false;
  }, [isLoading, selectedTesouro]);

  const buttonsTooltipTitle = useMemo(() => {
    if (!selectedTesouro || !selectedTesouro.id) {
      return 'Por favor, selecione um ativo para realizar a ordem';
    }

    return '';
  }, [selectedTesouro]);

  return (
    <Container
      variants={containerAnimation}
    >
      <SelectStockContainer>
        <TesouroSearchBar
          callback={({ tesouro }) => setSelectedTesouro(tesouro)}
          placeholder="Selecione seu Ativo"
        />
      </SelectStockContainer>

      <DateContainer>
        <span>Data</span>

        <NumberFormat
          format="##/##/####"
          placeholder="dd/mm/aaaa"
          value={dateString}
          mask={['d', 'd', 'm', 'm', 'a', 'a', 'a', 'a']}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setDateString(e.target.value)
          }}
        />
      </DateContainer>

      <DateContainer>
        <span>Preço do Tesouro</span>

        <CurrencyInput
          callBack={(e) => setPrice(e.replace('.', ','))}
          defaultValue={price}
        />
      </DateContainer>

      <AmountContainer>
        <span>Quantidade</span>

        <div>
          <NumberFormat
            placeholder="0.00"
            decimalSeparator={','}
            decimalScale={2}
            value={amount}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const tesouroPriceNumber = Number(price.replace(',', '.'));
              const amountNumber = Number(e.target.value.replace(',', '.'));

              const totalInvestment = Math.round(tesouroPriceNumber * amountNumber * 100) / 100

              setTotalInvested(String(totalInvestment).replace('.', ','));
              setAmount(amountNumber)
            }}
          />
        </div>
      </AmountContainer>

      <PriceContainer>
        <span>Total Investido</span>

        <NumberFormat
          thousandSeparator={"."}
          decimalSeparator={","}
          decimalScale={2}
          prefix={'R$ '}
          value={totalInvested}
          placeholder="R$ 0,00"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setTotalInvested(e.target.value);
            const tesouroPriceNumber = Number(price.replace(',', '.'));

            const amount = (Number(e.target.value.replace('R$ ', '').replace('.', '').replace(',', '.')) * 100) / tesouroPriceNumber;
            const amountRounded = (Math.round(amount) / 100)

            setAmount(amountRounded)
          }}
        />
      </PriceContainer>

      <Tooltip
        title={buttonsTooltipTitle}
      >
        <ButtonsContainer>
          <BuyButton
            onClick={() => handleExecuteOrder('buy')}
            disabled={isButtonsDisabled}
          >
            {isLoading ? (
              <Loading
                size={12}
                color={theme.colors.grey}
              />
            ): (
              <span>Compra</span>
            )}
          </BuyButton>
          <SellButton
            onClick={() => handleExecuteOrder('sell')}
            disabled={true}
          >
            {isLoading ? (
              <Loading
                size={12}
                color={theme.colors.grey}
              />
            ): (
              <span>Venda</span>
            )}
          </SellButton>
        </ButtonsContainer>
      </Tooltip>
    </Container>
  );
}

const mapStateToProps = (state: IState) => ({
  tesouro_wallets: state.tesouro.tesouro_wallets,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(TesouroForm)
