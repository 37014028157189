import { motion } from "framer-motion";
import { transparentize } from "polished";
import styled, { css } from "styled-components";

export const Container = styled.div<{ isDarkTheme: boolean, type: string }>`
${({ theme, isDarkTheme, type }) => css`
  padding: 2.4rem;
  background: ${theme.colors.background};
  box-shadow: ${theme.shadows.new_default};

  position: relative;

  ${isDarkTheme && css`
    border: 0.1rem solid ${transparentize(0.65, theme.colors.softGrey)};
  `}

  ${type === 'error' && css`
    border: 0.1rem solid ${transparentize(0.44, theme.colors.error)};
  `}

  ${type === 'success' && css`
    border: 0.1rem solid ${transparentize(0.44, theme.colors.success)};
  `}

  ${type === 'wallet-investment' && css`
    border: 0.1rem solid ${transparentize(0.44, theme.colors.primary)};
  `}

  margin-right: 1.6rem;
  margin-top: 1.6rem;

  border-radius: 0.8rem;

  width: 400px;

  display: flex;
  flex-direction: column;

  @media (max-width: 670px) {
    width: 100%;
  }
`}
`;

export const Info = styled.div<{ marginBottom: boolean }>`
${({ theme, marginBottom }) => css`
  display: flex;
  flex-direction: column;
  justify-content: center;

  height: 100%;

  margin-right: 2.4rem;

  > div {
    display: flex;
    align-items: center;

    ${marginBottom && css`
      margin-bottom: 0.8rem;
    `}

    > h1 {
      margin-left: 0.8rem;
      font-size: ${theme.fontSizes.large};
    }
  }

  > span {
    font-size: ${theme.fontSizes.default};
  }

  width: 90%;
`}
`;
export const Options = styled.div<{ marginBottom: boolean }>`
${({ theme, marginBottom }) => css`
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  > svg {
    cursor: pointer;
  }

  ${marginBottom && css`
    > svg:first-child {
      margin-bottom: 0.8rem;
    }
  `}
`}
`;

export const ProgressBar = styled(motion.div)<{ color?: string }>`
${({ theme, color }) => css`
  background: ${color || 'grey'};
  height: 0.1rem;

  position: absolute;
  bottom: 0;
  right: 0;
`}
`

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
`;
