import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

export const Container = styled.div<{ isInvalid: boolean, isDarkTheme: boolean, hasPapers: boolean }>`
${({ theme, isInvalid, isDarkTheme, hasPapers }) => css`
  display: flex;
  align-items: center;

  margin-bottom: 0.8rem;

  background: ${theme.colors.background};
  box-shadow: ${theme.shadows.new_default};

  padding: 1.6rem;

  ${isDarkTheme && css`
    border: 0.1rem solid ${transparentize(0.65, theme.colors.softGrey)};
  `}


  border-radius: 0.8rem;

  & {
    margin-right: 0.8rem;
  }

  opacity: ${isInvalid ? '0.3' : '1'};
  ${!hasPapers && css`
    opacity: 0.5;
  `}

  @media (max-width: 670px) {
    max-width: 100%;
    margin-right: 0;
  }
`}
`;

export const Header = styled.div`
${({ theme }) => css`
  display: flex;
  align-items: center;

  margin-right: 1.6rem;

  min-width: 10rem;

  > div {
    display: flex;
    align-items: center;

    > h1 {
      font-weight: bold;
      font-size: ${theme.fontSizes.default};
    }

    > svg {
      color: ${theme.colors.primary};
      margin-right: 0.8rem;
    }
  }
`}
`;

export const Content = styled.div<{ inputIsGreen?: boolean, maleft?: number }>`
${({ theme, inputIsGreen, maleft }) => css`
  display: flex;
  align-items: center;

  ${maleft && css`
    margin-left: ${maleft}rem;
  `}

  > h6 {
    margin-right: 0.8rem;
    font-size: ${theme.fontSizes.small};
    margin-left: 0.8rem;
  }

  > span {
    margin-left: 0.8rem;
    color: ${theme.colors.success};
    font-size: ${theme.fontSizes.default};
  }

  > label {
    font-size: ${theme.fontSizes.default};
  }

  > input {
    text-align: right;

    font-size: ${theme.fontSizes.default};

    width: 10rem;
    background: ${theme.colors.lightGrey};
    border: 0.2rem;
    padding: 0.6rem 0.8rem;

    color: ${inputIsGreen ? theme.colors.success : theme.colors.grey};
  }
`}
`;

export const MainContent = styled.div`
${({ theme }) => css`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
`}
`;

export const FakeInput = styled.div`
${({ theme }) => css`
  text-align: right;

  font=size: ${theme.fontSizes.default};

  width: 10rem;
  background: ${theme.colors.lightGrey};
  border: 0.2rem;
  padding: 0.9rem 0.8rem;

  color: ${theme.colors.grey};
`}
`;
