import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

export const Container = styled.div`
${({ theme }) => css`
  background: ${transparentize(0.05, theme.colors.background)};
  width: 100%;
  padding: 2.4rem;

  border-radius: 1.5rem 0.3rem 1rem 1.5rem;
  margin-right: 2.4rem;

  @media (max-width: 670px) {
    margin-bottom: 0.8rem;
  }
`}
`;

export const GraphContainer = styled.div`

`;

export const Infos = styled.div`
${({ theme }) => css`
  display: flex;
  align-items: center;
  justify-content: space-between;

  background: ${theme.colors.lightGrey};
  padding: 1.2rem 3.2rem;
  border-radius: 0.8rem;
`}
`;

export const Info = styled.div`
${({ theme }) => css`
  display: flex;
  flex-direction: column;
  align-items: center;

  > span {
    font-size: ${theme.fontSizes.small};
    color: ${theme.colors.grey};
    font-weight: 500;
    letter-spacing: 0.03em;
    margin-bottom: 0.4rem;
  }

  > h6 {
    font-size: ${theme.fontSizes.large};
    color: ${theme.colors.white};

    > p {
      color: ${theme.colors.grey};
    }
  }
`}
`;

export const Heading = styled.div`
${({ theme }) => css`
  margin-bottom: 3.2rem;
`}
`;
