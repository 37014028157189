import { motion } from 'framer-motion';
import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

export const Container = styled(motion.tr)<{ isDarkTheme: boolean }>`
${({ theme, isDarkTheme }) => css`
  background: ${transparentize(0.05, theme.colors.background)};

  margin-bottom: 0.8rem;
  border-radius: 0.4rem;

  margin-top: 0.8rem;

  width: 100%;

  box-shadow: ${theme.shadows.new_default};

  ${isDarkTheme && css`
    border: 0.1rem solid ${transparentize(0.85, theme.colors.softGrey)};
  `}

  padding: 0.8rem;

  display: flex;
  align-items: center;

  @media (max-width: 670px) {
    flex-direction: column;
    align-items: flex-start;

    max-width: 100%;
  }
`}
`;

export const Header = styled.div`
${({ theme }) => css`
  width: 100%;
  display: flex;
  align-items: center;

  > h1 {
    font-size: ${theme.fontSizes.default};
    margin-left: 0.8rem;
    color: ${theme.colors.darkGrey};
  }

  > svg {
    color: ${theme.colors.primary};
  }

  @media (max-width: 670px) {
    width: 100%;
    margin-bottom: 1.6rem;
  }
`}
`;

export const ActualPrice = styled.div`
${({ theme }) => css`
  color: ${theme.colors.softGrey};
  width: 100%;
  font-weight: bold;
`}
`;

export const TotalPercentage = styled.div`
  width: 100%;
`;

export const TotalInvested = styled.div`
${({ theme }) => css`
  width: 100%;

  > h6 {
    font-size: ${theme.fontSizes.large};
  }
`}
`;

export const TotalValue = styled.div`
${({ theme }) => css`
  font-weight: bold;
  width: 100%;
  color: ${theme.colors.softGrey};

  > h6 {
    font-size: ${theme.fontSizes.large};
  }
`}
`;

export const ProfitPercentage = styled.div`
${({ theme }) => css`
  width: 100%;
  display: flex;
  align-items: center;

  > p {
    font-weight: bold;
    color: ${theme.colors.success};
  }

  > span {
    margin: 0 0.8rem;

    font-weight: bold;
    color: ${theme.colors.grey};
  }

  > h6 {
    font-size: ${theme.fontSizes.default};
  }
`}
`;
