import styled, { css } from 'styled-components';

export const Container = styled.div`
${({ theme }) => css`
  max-width: ${theme.spacing.mainWidth};
  width: 100%;

  @media (max-width: 670px) {
    max-width: 100%;
  }
`}
`;
