import React, { useMemo, useState } from 'react'
import { useTable } from 'react-table'

import * as Styled from './styles';

import { endOfDay, format, isBefore } from 'date-fns';
import { IProfit, ProfitTypeTranslator } from '../../../../../store/modules/profit/types';
import { IState } from '../../../../../store';
import { connect } from 'react-redux';
import Currency from '../../../../../components/Currency';
import { useSellsProfits } from '../../../../../hooks/react-query/useSellsProfits';
import { useTheme } from 'styled-components';
import { Box } from '../../../../../components/Box';
import Percentage from '../../../../../components/Percentage';
import { useOrders, IOrder } from '../../../../../hooks/react-query/useOrders';
import { FiTrash2, FiEdit } from 'react-icons/fi';
import Button from '../../../../../components/Button';
import { Show } from '../../../../../components/Show';
import { StockEmblem } from '../../../../../components/StockEmblem';
import { useWallet } from '..';
import { WidgetCard } from '../../../../../components/WidgetCard';
import { useModals } from '../../../../../contexts/modals';


export const StockOrdersList: React.FC = () => {
  const { wallet } = useWallet()

  const { turnModalOn } = useModals()

  const { data: orders } = useOrders({
    wallet_identifier: [wallet.id],
    types: ['stock']
  })
  const theme = useTheme()

  const [reverseFilter, setReverseFilter] = useState(false);
  const [filter, setFilter] = useState('created_at');

  const columns = useMemo(() => {
    return (
      [
        {
          Header: 'Data',
          accessor: 'created_at',
        },
        {
          Header: 'Código',
          accessor: 'entity.name',
        },
        {
          Header: 'Qtd.',
          accessor: 'amount',
        },
        {
          Header: 'Preço',
          accessor: 'price',
        },
        {
          Header: 'Valor da Order',
          accessor: 'entity.current_buy_price',
        },
        {
          Header: 'DeleteButton',
          accessor: '',
          hide: true,
          collapse: true,
        },
        {
          Header: 'EditButton',
          accessor: '',
          hide: true,
          collapse: true,
        }
      ]
    )
  }, []);

  const ordersOrdered = useMemo(() => {
    if (!orders) return []

    const ordersList = [...orders]

    ordersList.sort((a: any, b: any) => {
      const bigger = reverseFilter ? 1 : -1;
      const smaller = bigger * -1;

      if (a[filter] > b[filter]) {
        return bigger;
      }
      if (a[filter] < b[filter]) {
        return smaller;
      }

      return 0;
    });


    return ordersList
  }, [orders, reverseFilter, filter]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
  } = useTable(
    {
      columns: columns as any,
      data: ordersOrdered,
    }
  )

  const orderTableByColumn = (columnName: string) => {
    const column = columns.find(c => c.accessor === columnName)

    if (filter === columnName) {
      setReverseFilter(v => !v)
    }

    if (column) {
      setFilter(columnName);
    }
  }

  const handleDeleteOrder = (order: IOrder) => {
    turnModalOn('DELETE_ORDER', {
      order,
    })
  }

  const handleEditOrder = (order: IOrder) => {
    turnModalOn('UPDATE_ORDER', {
      order,
    })
  }

  return (
    <WidgetCard
      title="Ordens da Carteira"
      w={'100%'}
      withoutContent={!rows.length}
      withoutContentText='Sua carteira ainda não possui nenhuma ordem'
      minHeight='40rem'
    >
      <Styled.Container>
        <Styled.TableWrap className="tableWrap">
          <Styled.Table {...getTableProps()}>
            <Styled.Head>
              {headerGroups.map(headerGroup => (
                <Styled.HeadRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => (
                    <Styled.HeadData
                      {...column.getHeaderProps({
                        className: column.collapse ? 'collapse' : '',
                      })}
                    >
                      <Styled.HeaderLabel onClick={() => orderTableByColumn(column.id)}>
                        <Show when={!column.hide}>
                          {column.render('Header')}
                        </Show>
                      </Styled.HeaderLabel>
                    </Styled.HeadData>
                  ))}
                </Styled.HeadRow>
              ))}
            </Styled.Head>
            <Styled.TableBody {...getTableBodyProps()}>
              {rows.map((row: any, i: number) => {
                prepareRow(row)
                return (
                  <Styled.HeadRow {...row.getRowProps()}>
                    {row.cells.map((cell: any) => {
                      return (
                        <Styled.Content
                          {...cell.getCellProps({
                            className: cell.column.collapse ? 'collapse' : '',
                          })}
                        >
                          {cell.column.Header === 'Data' && (
                            <span>{cell.value ? format(new Date(cell.value), 'dd/MM/yyyy') : '-'}</span>
                          )}

                          {cell.column.Header === 'Código' && (
                            <Box alignItems='center'>
                              <Show when={row.original?.entity?.stockEnterprisesInfos?.image_url}>
                                <StockEmblem
                                  image_url={row.original?.entity?.stockEnterprisesInfos?.image_url}
                                />
                              </Show>
                              <span>{cell.value}</span>
                            </Box>
                          )}

                          {cell.column.Header === 'Preço' && (
                            <Currency
                              value={cell.value}
                              color={theme.colors.white}
                            />
                          )}

                          {cell.column.Header === 'Valor da Order' && (
                            <Currency
                              value={row.original?.price * row.original?.amount}
                              color={theme.colors.white}
                            />
                          )}

                          {cell.column.Header === 'Qtd.' && (
                            <span>{cell.value}</span>
                          )}

                          {cell.column.Header === 'Lucro' && (
                            <Styled.SellResult>
                              <Currency
                                color={cell.value > 0 ? theme.colors.success : theme.colors.error}
                                value={cell.value}
                                type='flat'
                              />
                            </Styled.SellResult>
                          )}

                          {cell.column.Header === 'Lucro (%)' && (
                            <Styled.SellResult>
                              <Percentage
                                color={cell.value > 0 ? theme.colors.success : theme.colors.error}
                                value={cell.value * 1000}
                                type='flat'
                              />
                            </Styled.SellResult>
                          )}

                          {
                            cell.column.Header === 'DeleteButton' && (
                              <Button
                                onClick={() => handleDeleteOrder(cell.row.original)}
                              >
                                <FiTrash2
                                  size={22}
                                />
                              </Button>
                            )
                          }

                          {
                            cell.column.Header === 'EditButton' && (
                              <Button
                                onClick={() => handleEditOrder(cell.row.original)}
                              >
                                <FiEdit
                                  size={22}
                                />
                              </Button>
                            )
                          }
                        </Styled.Content>
                      )
                    })}
                  </Styled.HeadRow>
                )
              })}
            </Styled.TableBody>
          </Styled.Table>
        </Styled.TableWrap>
      </Styled.Container>
    </WidgetCard>
  )
}
