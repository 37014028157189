import React from 'react';

import {
  Container,
  Header,
  Content,
  MainContent,
} from './styles';

const LabelRow: React.FC = () => {
  return (
    <Container>
      <Header>
        Nome
      </Header>

      <MainContent>
        <Content>
          Papéis
        </Content>

        <Content>
          Preço
        </Content>

        <Content>
          Total
        </Content>

        <Content>
          Mudança no Lucro
        </Content>

        <Content>
          Mudança na Porcentagem
        </Content>
      </MainContent>
    </Container>
  );
};

export default LabelRow;
