import { motion } from 'framer-motion';
import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

const AnimatedButton = motion.button;

export const ImportOption = styled(AnimatedButton)<{ disabled?: boolean }>`
${({ theme, disabled }) => css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin-bottom: 1.6rem;
  border-radius: 0.8rem;

  border: solid 0.1rem ${theme.colors.success};

  background: ${transparentize(0.95, theme.colors.success)};

  padding: 2.4rem;

  ${disabled && css`
    background: ${transparentize(0.95, theme.colors.extremeLightGrey)};
    border-color: ${theme.colors.extremeLightGrey};

    cursor: not-allowed;

    > div {
      > h3 {
        color: ${theme.colors.extremeLightGrey};
      }

      > svg {
        color: ${theme.colors.extremeLightGrey};
      }
    }

    > p {
      color: ${theme.colors.extremeLightGrey};
    }
  `}
`}
`;

export const ImportOptionHeader = styled.div`
${({ theme }) => css`
  display: flex;
  align-items: center;

  margin-bottom: 0.8rem;


  > h3 {
    color: ${theme.colors.success};
    font-size: ${theme.fontSizes.default};
  }

  > svg {
    color: ${theme.colors.success};
    margin-right: 0.8rem;
  }
`}
`;

export const ImportOptionContent = styled.p`
${({ theme }) => css`
  max-width: 50%;
  color: ${theme.colors.white};

  text-align: left;

  font-size: ${theme.fontSizes.default};
`}
`;
