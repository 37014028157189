import React, { useMemo, useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';

import { VscGraph } from "react-icons/vsc";

import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

import { animations } from '../../../resources';

import { IState } from '../../../store';
import { IThemeState } from '../../../store/modules/themeSelector/types';
import { IActive } from '../../../store/modules/actives/types';

import Active from './Active';
import LabelRow from './LabelRow';
import TotalRow from './TotalRow';

import {
  Container,
  Actives,
  Header,
  StyledButton,
  ActivesContent,
  ActivesHeader,
} from './styles';
import { useHistory } from 'react-router-dom';

const AnimatedCard = motion.tr;

export enum FilterTypes {
  NAME = 'name',
  ACTUAL_PRICE = 'currentPrice',
  ACTIVE_PERCENTAGE = 'totalPercentage',
  INVESTED_AMOUNT = 'totalPurchaseValue',
  CURRENT_AMOUNT = 'totalCurrentValue',
  PROFIT = 'profit',
  PROFIT_PERCENTAGE = 'profit_in_percentage',
}

const ActiveList: React.FC = () => {
  const themeStateObject = useSelector<IState, IThemeState>(state => state.themeSelector);
  const actives = useSelector<IState, IActive[]>(state => state.actives.actives);
  const history = useHistory();

  const [filter, setFilter] = useState<FilterTypes>(FilterTypes.NAME);
  const [reverseFilter, setReverseFilter] = useState(false);
  const [tbodyScrollWidth, setTBodyScrollWidth] = useState(0);

  const tbodyRef= useRef<HTMLTableSectionElement>(null);

  useEffect(() => {
    if (tbodyRef.current) {
      const scrollBarWidth = tbodyRef.current.offsetWidth - tbodyRef.current.clientWidth;
      setTBodyScrollWidth(scrollBarWidth)
    }
  })

  const handleChangeFilter = (newFilter: FilterTypes) => {
    if (filter === newFilter) {
      setReverseFilter(!reverseFilter);
    }

    setFilter(newFilter);
  }

  const handleGoToActiveChart = () => {
    history.push('/dashboard/actives/chart');
  }

  const isDarkTheme = useMemo(() => {
    if (themeStateObject.isLightMode) {
      return false;
    } else {
      return true;
    }
  }, [themeStateObject]);

  const totalInvested = useMemo(() => {
    let totalInvested = 0;

    actives.forEach(active => totalInvested += active.totalPurchaseValue);

    return totalInvested;
  }, [actives]);

  const totalAccountMoney = useMemo(() => {
    let totalInvested = 0;

    actives.forEach(active => totalInvested += active.totalPurchaseValue);

    return totalInvested;
  }, [actives]);

  const totalCurrent = useMemo(() => {
    let totalCurrent = 0;

    actives.forEach(active => totalCurrent += active.totalCurrentValue);

    return totalCurrent;
  }, [actives]);

  const activesOrdered = useMemo(() => {
    const newActives = [...actives];

    newActives.sort(function (a, b) {
      const bigger = reverseFilter ? 1 : -1;
      const smaller = bigger * -1;

      if (a[filter] > b[filter]) {
        return bigger;
      }
      if (a[filter] < b[filter]) {
        return smaller;
      }

      return 0;
    })

    return newActives;
  }, [actives, filter, reverseFilter]);

  const ActiveRenderer = ({ index, style }: any) => {
    const active = activesOrdered[index];

    return (
      <Active
        style={{
          ...style,
          top: style.top + 8,
          height: style.height - 8
        }}
        key={`card-${active.name}`}
        isDarkTheme={isDarkTheme}
        name={active.name}
        currentPrice={active.currentPrice}
        averagePrice={active.totalPurchaseValue / active.totalPapers}
        totalPercentage={active.totalCurrentValue / totalAccountMoney * 1000}
        totalPurchaseValue={active.totalPurchaseValue}
        totalCurrentValue={active.totalCurrentValue}
        profit={active.profit}
        profit_in_percentage={active.profit_in_percentage}
      />
    )
  }

  return (
    <Container
      variants={animations.containerAnimation}
    >
      <Header>
        <h1>Meus Ativos</h1>
        <StyledButton
          revision={'primary'}
          onClick={handleGoToActiveChart}
        >
          <VscGraph />
        </StyledButton>
      </Header>

      <Actives>
        <ActivesHeader
          tbodyScrollWidth={tbodyScrollWidth}
        >
          <LabelRow
            selectedLabel={filter}
            isReversed={reverseFilter}
            handleChangeFilter={handleChangeFilter}
          />
        </ActivesHeader>

        <ActivesContent
          ref={tbodyRef}
        >
          <AutoSizer>
            {({ height, width }) => (
              <List
                height={height}
                width={width}
                itemCount={activesOrdered.length}
                itemSize={48}
              >
                {ActiveRenderer}
              </List>
            )}
          </AutoSizer>
        </ActivesContent>

        <TotalRow
          isDarkTheme={isDarkTheme}
          totalInvested={totalInvested}
          totalCurrent={totalCurrent}
          totalProfit={(totalCurrent - totalInvested)}
          totalProfitInPercentage={(((totalCurrent - totalInvested) / totalInvested) * 1000)}
        />
      </Actives>
    </Container>
  );
};

export default ActiveList;
