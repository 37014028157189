import React from 'react';
import NumberFormat from 'react-number-format';

import { Container } from './styles';

interface PercentageProps {
  value: number;
  type?: 'flat' | 'variation'
  color?: string;
}
const Percentage: React.FC<PercentageProps> = ({ value, color, type }) => {
  return (
    <Container
      color={color}
      type={type}
      value={value}
    >
      <NumberFormat
        value={value / 10}
        displayType={'text'}
        thousandSeparator={"."}
        decimalSeparator={","}
        fixedDecimalScale={true}
        decimalScale={2}
        suffix={'%'}
        renderText={value => (
          <>
            {value}
          </>
        )}
      />
    </Container>
  );
};

export default Percentage;
