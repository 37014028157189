import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Loading from '../../components/Loading';

import { createToastMessage } from '../../store/modules/toast/actions';

import Button from '../../components/Button';

import LogoSrc from '../../assets/images/illustrations/logo.png';

import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { Box, Input } from '../../components';
import { ButtonsContainer, Form, Logo, RotatedBox, RotatedBox2 } from '../Register/styles';
import { useTheme } from 'styled-components';
import * as Styled from './styles'
import { useAuth } from '../../contexts/auth';

const LogIn: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme()
  const { signIn } = useAuth()

  async function handleLogin(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault();

    try {
      setIsLoading(true);

      await signIn({
        email,
        password,
      })
    } catch (err) {
      if (axios.isAxiosError(err)) {
        setIsLoading(false);

        dispatch(createToastMessage({
          title: 'Erro no login',
          content: err?.response?.data?.message || 'Erro em nosso servidor, por favor entre em contato para mais informações',
          created_at: new Date(),
          isPined: false,
          type: 'error',
        }));
      }
    }
  }

  return (
    <Styled.Container>
      <Box alignItems='center' justifyContent='center' p={'16px'} flexDirection='column'>
        <Logo>
          <img src={LogoSrc} alt="wllt" />
          <h1>Entre em sua conta</h1>
          <span>Bem-vindo ao wllt, com seus investimentos na palma da sua mão</span>
        </Logo>

        <Form>
          <Input
            value={email}
            placeholder='Seu e-mail'
            callback={e => setEmail(e)}
          />
          <Box mb={'16px'} />
          <Input
            type='password'
            value={password}
            placeholder='Senha'
            callback={(e) => setPassword(e)}
          />
          <Box mb={'16px'} />
          <ButtonsContainer>
            <Button
              type='submit'
              revision='primary'
              onClick={handleLogin}
              disabled={isLoading}
            >{
            isLoading ? (
              <Loading color={theme.colors.background} size={8} />
            ) : 'Entrar'
          }</Button>
          </ButtonsContainer>
        </Form>

        <Box mb={'0.8rem'} />
        <Button onClick={() => history.push('/register')}>Criar uma conta</Button>
      </Box>
      <Box bg={theme.colors.primaryDark} flex={1} style={{ position: 'relative', overflow: 'hidden' }}>
        <Box borderRadius='100%' style={{ position: 'absolute', bottom: -50, left: -50 }} w={'250px'} h={'250px'} bg={theme.colors.primary} />
        <RotatedBox />
        <RotatedBox2 />
      </Box>
    </Styled.Container>
  );
};

export default LogIn;
