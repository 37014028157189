import styled, { css } from 'styled-components';

import { motion } from 'framer-motion';

export const Container = styled(motion.div)<{ adjustHeight: boolean }>`
${({ theme, adjustHeight }) => css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  background: ${theme.colors.background};
  width: 100%;

  ${adjustHeight && css`
    min-height: 70rem;
  `}

  box-shadow: ${theme.shadows.default};

  padding: 1.6rem 1.6rem 3.2rem 1.6rem;
  border-radius: 0.8rem;

  > h1 {
    padding: 0.4rem 0.8rem;
    background: ${theme.colors.extremeLightGrey};

    border-radius: 0.8rem;

    color: ${theme.colors.primary};
    font-size: ${theme.fontSizes.huge};

    margin-bottom: 0.8rem;
  }

  > div:nth-child(2) {
    height: 70rem !important;
    margin-bottom: auto;
    width: 100%;
  }

  @media (max-width: 670px) {
    padding: 1.6rem 0rem 3.2rem 0rem;

    min-height: 40rem;

    > div:nth-child(2) {
      height: 40rem !important;
      margin-bottom: auto;
      width: 100%;
    }

    > h1 {
      padding: 0 0 0 1.6rem;
    }
  }
`}
`;

export type CustomTooltipProps = {
  rightArrow?: boolean;
  leftArrow?: boolean;
};

export const CustomTooltip = styled.div<CustomTooltipProps>`
${({ theme, rightArrow, leftArrow }) => css`
  background: ${theme.colors.lightGrey};
  padding: 0.4rem 0.8rem;
  border-radius: 0.1rem;
  font-size: ${theme.fontSizes.tiny};
  text-align: center;
  box-shadow: 0 -0.1rem 0.2rem rgba(0, 0, 0, 0.28);

  > h1 {
    font-size: ${theme.fontSizes.small};
  }

  > h6 {
    font-size: ${theme.fontSizes.large};
  }

  > p {
    margin-top: 0.4rem;
  }

  :before {
    content: '';
    display: block;
    height: 0;
    left: ${rightArrow ? `100%` : (leftArrow && `2%`) || `50%`};
    bottom: -18%;
    position: absolute;
    transform: ${rightArrow
      ? `translate3d(0, -175%, 0)`
      : (leftArrow && `translate3d(-100%, -175%, 0)`) ||
        `translate3d(-50%, 25%, 0)`};
    border-color: ${rightArrow
      ? `transparent transparent transparent ${theme.colors.lightGrey}`
      : (leftArrow &&
          `transparent ${theme.colors.lightGrey} transparent transparent`) ||
        `${theme.colors.lightGrey} transparent transparent
      transparent`};
    border-style: solid;
    border-width: 0.8rem;
  }

  :after {
    content: '';
    display: block;
  }
`}
`;

export const NoContentWrapper = styled.div`
${({ theme }) => css`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`}
`;

export const GraphContainer = styled.div`
${({ theme }) => css`
  display: flex;
  width: 100%;

  > svg {
    margin: auto 0;
    cursor: pointer;

    transition: 0.45s;

    margin-left: 0.8rem;
  }

  > svg:hover {
    color: ${theme.colors.primary};
  }

  > div {
    flex: 1;
  }
`}
`;
