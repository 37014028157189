import theme from './theme';

export default {
  ...theme,
  colors: {
    primary: '#95EDB0',
    primaryDark: '#386150',
    secondary: '#45D688',
    extremeLightGrey: '#26272D',
    lightestGrey: '#161616',
    lightGrey: '#1D1E23',
    grey: '#8C91A5',
    background: '#1B1B1B',
    softGrey:'#8A99B6',
    success: '#3476fb',
    error: '#FD4F48',
    darkGrey: '#D9D9D9',
    yellow: '#FFED00',
    darkBluishGrey: '#011627',
    lightGreen: '#7EE081',
    white: '#FFFFFF',
    warning: '#F4802C',
  },
} as const;
