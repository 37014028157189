import styled, { css } from 'styled-components';

export const Container = styled.h1<{ type: string }>`
${({ theme, type }) => css`
  background-color: ${theme.colors.lightGrey};
  padding: 0.4rem 1.6rem;
  border-radius: 0.8rem;

  color: ${theme.colors.primary};
  font-size: ${theme.fontSizes.huge};

  ${type === 'secondary' && css`
    font-size: ${theme.fontSizes.huge};
    background-color: ${theme.colors.extremeLightGrey};

    padding: 0.4rem 0.8rem;
  `}
`}
`;
