import { Reducer } from "redux";
import { IToastState, ToastTypes } from "./types";
import produce from 'immer';

const INITIAL_TOAST_STATE = {
  toastMessages: [],
}

const toastReducer: Reducer<IToastState> = (state = INITIAL_TOAST_STATE, action) => {
  return produce(state, draft => {
    switch(action.type) {
      case ToastTypes.CREATE_TOAST_MESSAGE: {
        draft.toastMessages.push(action.payload.toast)

        return draft;
      }
      case ToastTypes.DELETE_TOAST_MESSAGE: {
        const index = draft.toastMessages.findIndex(toast => toast.created_at.getTime() === action.payload.toastCreatedAt.getTime());

        if (index !== -1) {
          draft.toastMessages.splice(index, 1)
        }

        return draft;
      }
      case ToastTypes.TOGGLE_PIN_TOAST: {
        const index = draft.toastMessages.findIndex(toast => toast.created_at.getTime() === action.payload.toastCreatedAt.getTime());

        if (index !== -1) {
          draft.toastMessages[index].isPined = !draft.toastMessages[index].isPined
        }

        return draft;
      }
      default: {
        return draft;
      }
    }
  });
}

export default toastReducer;
