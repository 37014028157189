import { Reducer } from "redux";
import { IValuation, IValuationState, ValuationActionTypes } from "./types";
import produce from 'immer';

const INITIAL_THEME_STATE = {
  valuations: [] as IValuation[],
  isLoadingValuation: true,
}

const valuationReducer: Reducer<IValuationState> = (state = INITIAL_THEME_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case ValuationActionTypes.GET_VALUATION_REQUEST: {
        draft.isLoadingValuation = true;

        break;
      }
      case ValuationActionTypes.GET_VALUATION_SUCCESS: {
        draft.valuations = action.payload.valuations;
        draft.isLoadingValuation = false;

        break;
      }
      case ValuationActionTypes.GET_VALUATION_FAILURE: {
        draft.isLoadingValuation = false;

        break;
      }
      case ValuationActionTypes.SET_LOADING_VALUATIONS: {
        draft.isLoadingValuation = true;

        break;
      }
      default: {
        return draft;
      }
    }
  });
}

export default valuationReducer;
