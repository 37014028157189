import React, { useMemo, useState } from 'react';
import NumberFormat from 'react-number-format';
import { connect, useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';
import CurrencyInput from '../../../../../../../components/CurrencyInput';
import Input from '../../../../../../../components/Input';
import Loading from '../../../../../../../components/Loading';
import StockSearchBar from '../../../../../../../components/SearchBars/StockSearchBar';
import Tooltip from '../../../../../../../components/Tooltip';
import { animations } from '../../../../../../../resources';
import api from '../../../../../../../services/api';
import { fetchData } from '../../../../../../../services/data';
import { IState } from '../../../../../../../store';
import { createToastMessage } from '../../../../../../../store/modules/toast/actions';
import { IWallet } from '../../../../../../../store/modules/wallet/types';
import { IStockNameOption } from '../../../../../CreateWallet/submitFormsByType';

import {
  Container,
  SelectStockContainer,
  DateContainer,
  AmountContainer,
  PriceContainer,
  ButtonsContainer,
  BuyButton,
  SellButton,
} from './styles';


interface StockFormsProps {
  wallet_id: string;
  wallets: IWallet[];
}

const containerAnimation = {
  ...animations.containerAnimation,
  mounted: {
    ...animations.containerAnimation.mounted,
    transition: {
      ...animations.containerAnimation.mounted.transition,
      delay: 0.1,
      duration: undefined,
    }
  }
}

const StockForm: React.FC<StockFormsProps> = ({ wallet_id, wallets }) => {
  const [dateString, setDateString] = useState('');
  const [price, setPrice] = useState('');
  const [amount, setAmount] = useState(0);
  const [selectedStock, setSelectedStock] = useState<IStockNameOption | null>(null);

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const theme = useTheme();

  const handleExecuteOrder = async (type: string) => {
    if (!selectedStock) return

    const selectedWallet = wallets.find(wallet => wallet.id === wallet_id);

    if (!selectedWallet) return

    setIsLoading(true);

    const walletStock = selectedWallet.stocks.find(stock => stock.name === selectedStock.name && stock.is_active);
    const averagePrice = walletStock && walletStock.totalPapers ? Math.round(walletStock?.totalPurchaseValue / walletStock.totalPapers) : 0;

    const [day, month, year] = dateString.split('/');
    const stockDate = new Date(`${year}-${month}-${day} 00:00:00`);

    const stock = {
      name: selectedStock.name,
      stock_id: selectedStock.id,
      price: Math.round(+price.replace(',', '.') * 100),
      averagePrice,
      amount,
      type,
      date: dateString ? stockDate : new Date()
    }

    try {
      await api.post('/stocks/create-stocks-purchases', {
        wallet_id,
        stocks: [stock]
      });

      const isSell = type === 'sell';

      dispatch(createToastMessage({
        title: `Ordem adicionada.`,
        content: `${isSell ? 'Venda' : 'Compra'} de ${amount} papéis de ${selectedStock.name} registrada!`,
        created_at: new Date(),
        isPined: false,
        type: 'success',
      }));

      setDateString('');
      setPrice('');
      setAmount(0);
    } catch (err) {
      dispatch(createToastMessage({
        title: `Erro ao criar ordem.`,
        content: (err as any)?.response?.data?.message || `Ops, ocorreu um erro ao criar sua ordem, por favor chame o nosso time para te ajudar!`,
        created_at: new Date(),
        isPined: false,
        type: 'info',
      }));
    }


    setIsLoading(false);
    fetchData({ dispatch });
  }

  const isButtonsDisabled = useMemo(() => {
    if (!selectedStock || !selectedStock.id) {
      return true;
    }

    if (isLoading) {
      return true;
    }

    return false;
  }, [isLoading, selectedStock]);

  const buttonsTooltipTitle = useMemo(() => {
    if (!selectedStock || !selectedStock.id) {
      return 'Por favor, selecione um ativo para realizar a ordem';
    }

    return '';
  }, [selectedStock]);

  return (
    <Container
      variants={containerAnimation}
    >
      <SelectStockContainer>
        <StockSearchBar
          callback={({ stock }) => setSelectedStock(stock)}
          placeholder="Selecione seu Ativo"
        />
      </SelectStockContainer>

      <DateContainer>
        <span>Data da Operação</span>

        <NumberFormat
          format="##/##/####"
          value={dateString}
          placeholder="dd/mm/aaaa"
          mask={['d', 'd', 'm', 'm', 'a', 'a', 'a', 'a']}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setDateString(e.target.value)
          }}
        />
      </DateContainer>

      <AmountContainer>
        <span>Quantidade</span>

        <Input
          callback={(e) => setAmount(Number(e))}
          value={String(amount)}
          type="number"
          inputType='secondary'
        />
      </AmountContainer>

      <PriceContainer>
        <span>Cotação</span>

        <CurrencyInput
          callBack={(e) => setPrice(e.replace('.', ','))}
          defaultValue={price}
        />
      </PriceContainer>

      <Tooltip
        title={buttonsTooltipTitle}
      >
        <ButtonsContainer>
          <BuyButton
            onClick={() => handleExecuteOrder('buy')}
            disabled={isButtonsDisabled}
          >
            {isLoading ? (
              <Loading
                size={12}
                color={theme.colors.grey}
              />
            ): (
              <span>Compra</span>
            )}
          </BuyButton>
          <SellButton
            onClick={() => handleExecuteOrder('sell')}
            disabled={isButtonsDisabled}
          >
            {isLoading ? (
              <Loading
                size={12}
                color={theme.colors.grey}
              />
            ): (
              <span>Venda</span>
            )}
          </SellButton>
        </ButtonsContainer>
      </Tooltip>
    </Container>
  );
}

const mapStateToProps = (state: IState) => ({
  wallets: state.wallet.wallets,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(StockForm)
