import { Reducer } from "redux";
import { ILoadingState, LoadingActionTypes } from "./types";
import produce from 'immer';

const INITIAL_THEME_STATE = {
  loading: true,
}

const loadingReducer: Reducer<ILoadingState> = (state = INITIAL_THEME_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case LoadingActionTypes.SET_IS_LOADING_DATA: {
        draft.loading = true;

        break;
      }
      case LoadingActionTypes.SET_IS_NOT_LOADING_DATA: {
        draft.loading = false;

        break;
      }
      default: {
        return draft;
      }
    }
  });
}

export default loadingReducer;
