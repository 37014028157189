import styled, { css } from 'styled-components'

export const GraphContainer = styled.div`
${({ theme }) => css`
  display: flex;
  width: 100%;
  height: 100%;

  padding: 0 1.6rem 0 0;

  flex: 1;

  > svg {
    margin: auto 0;
    cursor: pointer;

    transition: 0.45s;

    margin-left: 0.8rem;
  }

  > svg:hover {
    color: ${theme.colors.primary};
  }

  > div {
    flex: 1;
  }
`}
`
