import styled, { css } from 'styled-components';

export const Container = styled.div`
${({ theme }) => css`
  height: 100%;
  width: 100%;
  max-width: ${theme.spacing.mainWidth};

  padding: 0 2.4rem;

  @media (max-width: 670px) {
    max-width: 100%;
  }
`}
`;

export const Content = styled.div`
${({ theme }) => css`

`}
`;

export const FirstRow = styled.div`
  display: flex;
  margin-bottom: 2.4rem;

  @media (max-width: 670px) {
    flex-direction: column;
  }
`;

export const SecondRow = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  @media (max-width: 670px) {
    flex-direction: column;
  }
`;

export const SideColumn = styled.div`
  max-width: 30%;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 670px) {
    max-width: 1000000rem;
  }
`;

export const MainColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  flex: 1;

  margin-right: 2.4rem;
`;
