import axios, { CancelTokenSource } from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { useTheme } from 'styled-components';
import { DEFAULT_TRANSITION } from '../../../constants';
import useEventListener from '../../../hooks/useEventListener';
import api from '../../../services/api';
import { IState } from '../../../store';
import Loading from '../../Loading';
import StockOption from './StockOption';

import {
  Container,
  Input,
  OptionsContainer,
  ActiveTypes,
  Tag,
} from './styles';

interface IStockSearchBarResponse {
  stock: IStockNameOption;
  inputValue: string;
}

interface StockSearchBarProps {
  placeholder?: string;
  callback(d: IStockSearchBarResponse): void;
  shouldShowClasses?: boolean;
  type?: StockSearchBarTypes;
  maxContainerHeight?: number;
  shouldHideOptions?: boolean;
}

type StockSearchBarTypes = "primary" | "secondary";

export interface IStockNameOption {
  id: string;
  complete_name: string;
  name: string;
  sector: string;
  type: string;
  image_url: string;
  current_price: number;
  current_profit: number;
}

const optionsAnimation = {
  unMounted: { height: '0', opacity: 0 },
  mounted: {
    height: 'auto',
    opacity: 1,
    transition: {
      ...DEFAULT_TRANSITION,
    }
  },
}

const StockSearchBar:React.FC<StockSearchBarProps> = ({
  shouldShowClasses,
  callback,
  placeholder,
  type: SearchBarType,
  maxContainerHeight,
  shouldHideOptions,
}) => {
  const theme = useTheme();

  const cancelRequestToken = useRef<CancelTokenSource | undefined>();

  const [isOpened, setIsOpened] = useState(false);
  const [stockNamesOptions, setStockNamesOptions] = useState<IStockNameOption[]>([] as IStockNameOption[]);
  const [inputTextValue, setInputTextValue] = useState('');
  const [hasStockSelected, setHasStockSelected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  const [isSmallInput, setIsSmallInput] = useState(false);
  const [isMediumSizeInput, setIsMediumSizeInput] = useState(false);

  const onFocus = () => {
    if (!hasStockSelected) {
      setIsOpened(true);
      getAutoCompleteNames(inputTextValue);
    }
  }

  async function getAutoCompleteNames(name: string) {
    setIsLoading(true)

    if (cancelRequestToken.current) {
      cancelRequestToken.current.cancel();
    }

    cancelRequestToken.current = axios.CancelToken.source();

    try {
      const response = await api.get(`/stocks/autocomplete?name=${name.toUpperCase()}`, {
        headers: {
          cancelToken: cancelRequestToken.current.token,
        }
      });

      setIsLoading(false)
      setStockNamesOptions(response.data);
    } catch (err) {

    }
  }

  useEffect(() => {
    if (dropdownRef.current) {
      const searchBarWidth = dropdownRef.current.clientWidth;
      const isSmallSearchBar = searchBarWidth < 300;
      const isMediumSearchBar = !isSmallSearchBar && searchBarWidth < 400

      setIsSmallInput(isSmallSearchBar);
      setIsMediumSizeInput(isMediumSearchBar);
    }
  }, [])

  const handleCloseDropdown = useCallback(({ target }: Event): void => {
    if (dropdownRef.current?.contains(target as Node)) {
      return;
    }

    setIsOpened(false);
  }, [setIsOpened]);

  useEventListener('click', handleCloseDropdown, {
    enabled: isOpened,
  });

  const handleEditInput = (e: any) => {
    setInputTextValue(e.target.value);
    setHasStockSelected(false);
    getAutoCompleteNames(e.target.value);
    setIsOpened(true);

    callback({
      stock: {
        id: '',
        name: '',
        complete_name: '',
        sector: '',
        current_price: 0,
        current_profit: 0,
        type: '',
        image_url: '',
      },
      inputValue: e.target.value,
    });
  }

  const handleClickStock = (stockOption: IStockNameOption) => {
    callback({
      stock: stockOption,
      inputValue: inputTextValue,
    });

    setHasStockSelected(true);
    setInputTextValue(stockOption.name);
    setIsOpened(false);
  }

  return (
    <Container
      ref={dropdownRef}
      type={SearchBarType}
      isOpened={!!stockNamesOptions.length && isOpened}
    >
      <FiSearch
        color={isOpened || inputTextValue ? theme.colors.primary : theme.colors.grey}
      />
      <Input
        hasSelected={hasStockSelected}
        onFocus={onFocus}
        placeholder={placeholder}
        value={inputTextValue}
        onChange={e => handleEditInput(e)}
      />

      {isLoading && (
        <Loading
          color={theme.colors.primary}
          size={8}
        />
      )}

      {!shouldHideOptions && isOpened && !hasStockSelected && (
        <OptionsContainer
          variants={optionsAnimation}
          padding={isOpened}
          type={SearchBarType}
          isOpened={!!stockNamesOptions.length && isOpened}
          maxContainerHeight={maxContainerHeight}
        >
          {shouldShowClasses && (
            <ActiveTypes>
              <Tag active>Em alta</Tag>
              <Tag>Tudo</Tag>
              <Tag>Ativos</Tag>
            </ActiveTypes>
          )}

          {!!stockNamesOptions.length && (
            <p>Ativos</p>
          )}

          {stockNamesOptions.map(stock => (
            <StockOption
              key={stock.id}
              onClick={() => handleClickStock(stock)}
              complete_name={isSmallInput ? '' : stock.complete_name}
              short_name={stock.name}
              sector={isSmallInput ? '' : stock.sector}
              price={stock.current_price}
              variation={stock.current_profit}
              isSmallInput={isSmallInput}
              isMediumSizeInput={isMediumSizeInput}
              image_url={stock.image_url}
            />
          ))}
        </OptionsContainer>
      )}
    </Container>
  )
}

const mapStateToProps = (state: IState) => ({})

const mapDispatchToProps = (dispatch: Dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(StockSearchBar)
