import { transparentize } from 'polished';
import styled, { css } from 'styled-components';
import Button from '../../../../../components/Button';

export const Container = styled.div`
${({ theme }) => css`
  display: flex;
  flex-direction: column;

  background: ${theme.colors.background};
  width: 80%;
  height: 80%;

  padding: 6rem;

  border-radius: 0.8rem;
`}
`;

export const Header = styled.div`
${({ theme }) => css`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 2rem;
`}
`;

export const ModalTitle = styled.h1`
${({ theme }) => css`
  color: ${theme.colors.white};
  font-weight: bold;
  font-size: ${theme.fontSizes.huge};
`}
`;

export const CloseButton = styled(Button)`
${({ theme }) => css`
  border: solid 0.1rem ${theme.colors.error};
  padding: 0.2rem;

  border-radius: 0.2rem;

  > svg {
    color: ${theme.colors.error};
  }
`}
`;

export const FinalConclusionButton = styled.div`
${({ theme }) => css`
  width: 100%;
  display: flex;

  justify-content: center;

  > button {
    width: 40%;
    background: ${theme.colors.success};
    color: ${theme.colors.white};
    font-weight: bold;
    height: 4.8rem;
  }
`}
`;
