import { Reducer } from "redux";
import { IPatrimony, IProfitState, ProfitActionTypes } from "./types";
import produce from 'immer';

const INITIAL_THEME_STATE = {
  profits: [],
  profitGraph: {
    months_profits: [],
    wallet_names: [],
    wallet_stocks_names: {},
  },
  earningsByMonth: null,
  isLoadingEarningsByMonth: true,
  isLoadingProfits: true,
  isLoadingProfitGraph: true,
}

const profitReducer: Reducer<IProfitState> = (state = INITIAL_THEME_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case ProfitActionTypes.GET_PROFITS_REQUEST: {
        draft.isLoadingProfits = true;

        break;
      }
      case ProfitActionTypes.GET_PROFITS_SUCCESS: {
        draft.profits = action.payload.profits;
        draft.isLoadingProfits = false;

        break;
      }
      case ProfitActionTypes.GET_PROFITS_FAILURE: {
        draft.isLoadingProfits = false;

        break;
      }
      case ProfitActionTypes.SET_LOADING_PROFIT: {
        draft.isLoadingProfits = true;

        break;
      }
      case ProfitActionTypes.GET_PROFIT_GRAPH_REQUEST: {
        draft.isLoadingProfitGraph = true;

        break;
      }
      case ProfitActionTypes.GET_PROFIT_GRAPH_SUCCESS: {
        draft.profitGraph = action.payload.profitGraph;
        draft.isLoadingProfitGraph = false;

        break;
      }
      case ProfitActionTypes.GET_PROFIT_GRAPH_FAILURE: {
        draft.isLoadingProfitGraph = false;

        break;
      }
      case ProfitActionTypes.GET_DIVIDEND_MONTHLY_REQUEST: {
        draft.isLoadingEarningsByMonth = true;

        break;
      }
      case ProfitActionTypes.GET_DIVIDEND_MONTHLY_SUCCESS: {
        draft.earningsByMonth = action.payload.earningsByMonth;
        draft.isLoadingEarningsByMonth = false;

        break;
      }
      case ProfitActionTypes.GET_DIVIDEND_MONTHLY_FAILURE: {
        draft.isLoadingEarningsByMonth = false;

        break;
      }
      default: {
        return draft;
      }
    }
  });
}

export default profitReducer;
