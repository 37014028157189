import styled, { css } from 'styled-components';
import Button from '../../../../components/Button';
import { motion } from "framer-motion"

export const Container = styled.div`
/* background: linear-gradient(270deg, #95EDB0 0%, rgba(149, 237, 176, 0) 100%); */

  && {
    ${({ theme }) => css`
      :not(:last-child) {
        border-bottom: 0.1rem solid ${theme.colors.extremeLightGrey};
      }
    `}
  }
`;

export const MainContent = styled(Button)<{
  isSelected: boolean
}>`
  && {
    ${({ theme, isSelected }) => css`
    background: transparent;
    width: 100%;

    justify-content: flex-start;

    transition: 0.5s;

    color: ${isSelected ? theme.colors.primary : theme.colors.grey};
    font-weight: ${isSelected ? 'bold' : 'normal'};
    font-size: ${theme.fontSizes.default};
    padding: 2.4rem 0;

    letter-spacing: 0.03em;

    > div {
      :first-child {
        height: 3.2rem;
        width: 3.2rem;

        background: ${isSelected ? theme.colors.primary : theme.colors.lightGrey};

        border-radius: 50%;

        display: flex;
        align-items: center;
        justify-content: center;

        margin-right: 1.2rem;
      }

      :last-child {
        margin-left: auto;
      }

      > svg {
        stroke: ${isSelected ? theme.colors.extremeLightGrey : theme.colors.primary};
        stroke-width: ${isSelected ? '0.3rem' : '0.2rem'};
      }
    }

    > svg {
      stroke-width: 0.2rem;
      margin-left: auto;
    }

  `}
  }
`;

export const Expansion = styled(motion.div)`
${({ theme }) => css`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 0.8rem;

  > input {
    background: ${theme.colors.lightGrey};
    border: 0;
    border-radius: 0.2rem;
    min-height: 4rem;

    margin-right: 0.8rem;

    max-width: 65%;

    padding: 0 0.8rem;

    color: ${theme.colors.darkGrey};
  }

  > input::placeholder {
        color: ${theme.colors.softGrey};
  }
`}
`;

export const StyledButton = styled(Button)<{ noPadding: boolean }>`
${({ theme, noPadding }) => css`
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: ${theme.fontSizes.small};
  background: ${theme.colors.primary};

  ${noPadding && css`
    padding: 0rem;
  `}

  color: ${theme.colors.background};

  max-width: 30%;
  width: 100%;
`}
`;
