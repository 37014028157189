import React, { memo, useEffect, useMemo, useRef, useState } from 'react';


import {
  Container,
} from './styles';

import { useHistory } from 'react-router-dom';
import Currency from '../../../../components/Currency';
import Percentage from '../../../../components/Percentage';
import { useTheme } from 'styled-components';
import { IState } from '../../../../store';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { ITesouroWallet } from '../../../../store/modules/tesouroWallet/types';
import { IWallet } from '../../../../store/modules/wallet/types';
import { ICryptoWallet } from '../../../../store/modules/crypto/types';

const animation = {
  unMounted: { y: -150, scale: 0.9 },
  mounted: {
    y: 0,
    scale: 1,
  }
}
interface IRentabilityProps {
  wallets: IWallet[];
  tesouro_wallets: ITesouroWallet[];
  crypto_wallets: ICryptoWallet[];
}

const Rentability: React.FC<IRentabilityProps> = ({
  wallets,
  tesouro_wallets,
  crypto_wallets
}) => {
  const theme = useTheme();

  const rentability = useMemo(() => {
    let invested = 0;
    let current_value = 0;

    wallets.forEach(wallet => {
      current_value += wallet.current_wallet_value;
      invested += wallet.total_wallet_value;
    });

    tesouro_wallets.forEach(tesouroWallet => {
      current_value += tesouroWallet.current_wallet_value;
      invested += tesouroWallet.total_wallet_value;
    });

    crypto_wallets.forEach(tesouroWallet => {
      current_value += tesouroWallet.current_wallet_value * 100;
      invested += tesouroWallet.total_wallet_value * 100;
    });

    return (current_value - invested) / invested * 1000;
  }, [wallets, tesouro_wallets]);


  return (
    <>
      {!!rentability && (
        <Container
          rentability={rentability}
        >
            <>
              <span>
                Rentabilidade
              </span>
              <div>
                {rentability > 0 && '+'}
                <Percentage
                  color={rentability > 0 ? theme.colors.success : theme.colors.error}
                  value={rentability}
                />
              </div>
            </>
        </Container>
      )}
    </>
  );
};

const mapStateToProps = (state: IState) => ({
  wallets: state.wallet.wallets,
  tesouro_wallets: state.tesouro.tesouro_wallets,
  crypto_wallets: state.crypto.crypto_wallets,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(memo(Rentability))
