import { IActive, ActivesActionTypes, IBalance, IStockInfos } from "./types";

export function setIsLoadingBalance() {
  return {
    type: ActivesActionTypes.SET_IS_LOADING_BALANCE,
  }
}

export function setIsLoadingRating() {
  return {
    type: ActivesActionTypes.SET_IS_LOADING_RATING,
  }
}

export function setIsLoadingActives() {
  return {
    type: ActivesActionTypes.SET_IS_LOADING_ACTIVES,
  }
}

export function getActivesRequest() {
  return {
    type: ActivesActionTypes.GET_ACTIVES_REQUEST,
  }
}

export function getActivesSuccess(actives: IActive[]) {
  return {
    type: ActivesActionTypes.GET_ACTIVES_SUCCESS,
    payload: {
      actives,
    }
  }
}

export function getActivesFailure(error: string) {
  return {
    type: ActivesActionTypes.GET_ACTIVES_FAILURE,
    payload: {
      error,
    }
  }
}

export function getBalanceInfoRequest() {
  return {
    type: ActivesActionTypes.GET_BALANCE_INFO_REQUEST,
  }
}

export function getBalanceInfoSuccess(balance: IBalance) {
  return {
    type: ActivesActionTypes.GET_BALANCE_INFO_SUCCESS,
    payload: {
      balance,
    }
  }
}

export function getBalanceInfoFailure() {
  return {
    type: ActivesActionTypes.GET_BALANCE_INFO_FAILURE,
  }
}

export function getStockRatingRequest() {
  return {
    type: ActivesActionTypes.GET_STOCK_RATING_REQUEST,
  }
}

export function getStockRatingSuccess(stock_infos: IStockInfos) {
  return {
    type: ActivesActionTypes.GET_STOCK_RATING_SUCCESS,
    payload: {
      stock_infos,
    }
  }
}

export function getStockRatingFailure() {
  return {
    type: ActivesActionTypes.GET_STOCK_RATING_FAILURE,
  }
}

export function getOrdersRequest() {
  return {
    type: ActivesActionTypes.GET_ORDERS_REQUEST,
  }
}

export function getOrdersSuccess(orders: IActive[]) {
  return {
    type: ActivesActionTypes.GET_ORDERS_SUCCESS,
    payload: {
      orders,
    }
  }
}

export function getOrdersFailure(error: string) {
  return {
    type: ActivesActionTypes.GET_ORDERS_FAILURE,
    payload: {
      error,
    }
  }
}
