import React, { useMemo } from 'react';
import { FiCheck } from 'react-icons/fi';
import { connect } from 'react-redux';
import WlltIcon from '../../../../assets/images/icons/wllt';

import CardExample1 from '../../../../assets/images/illustrations/CardExample1.png'
import CardExample2 from '../../../../assets/images/illustrations/CardExample2.png'
import CardExample3 from '../../../../assets/images/illustrations/CardExample3.png'
import Button from '../../../../components/Button';

import { IState } from '../../../../store';
import ExampleCard from './ExampleCard';

import * as Styled from './styles';

interface WelcomePageProps {
  handleNextStep: () => void;
  handleCloseOnboarding: () => void;
}

const WelcomePage: React.FC<WelcomePageProps> = ({ handleCloseOnboarding, handleNextStep }) => {
  const items = useMemo(() => {
    return [
      {
        id: 1,
        img: CardExample1,
        description: 'Crie carteiras para segmentar seus ativos da forma que você preferir! Por exemplo: separar suas ações nacionais em carteiras focadas em dividendos, valorização, small caps...',
      },
      {
        id: 2,
        img: CardExample2,
        description: 'Assim que criar sua carteira, você deve definir que tipo ativo será inserido na mesma. Não é possível misturar ativos.',
      },
      {
        id: 3,
        img: CardExample3,
        description: 'Você pode adicionar ativos e ordens na sua carteira de maneira manual, ordem por ordem, importando planilhas ou sincronizando com parceiros (em breve).',
      },
    ]
  }, [])

  return (
  <>
    <Styled.Header>
      <WlltIcon
        height={48}
        width={59}
      />
      <Styled.Title>Seja bem vindo ao Wllt!</Styled.Title>
      <Styled.Subtitle>Vamos configurar sua conta e prepará-lo para gerenciar seus ativos com a nossa tecnologia.</Styled.Subtitle>
      <Styled.ThirdTitle>É nas suas carteiras que os seus investimentos vão morar. Vamos te ajudar a criar sua primeira carteira e, caso você já invista, vamos te auxiliar no processo de importar todos os seus ativos para o Wllt!</Styled.ThirdTitle>
    </Styled.Header>

    <Styled.Content>
      {items.map((item) => (
        <ExampleCard
          key={item.id}
          img={item.img}
          description={item.description}
        />
      ))}
    </Styled.Content>

    <Styled.Footer>
      <Button
        onClick={handleNextStep}
        revision='primary'
      >
        <Styled.ButtonContainer>
          <FiCheck />
          <span>Próximo Passo</span>
        </Styled.ButtonContainer>
      </Button>
      <Button
        onClick={handleCloseOnboarding}
        revision='secondary'
      >
        <Styled.ButtonContainer>
          <span>Eu já sei usar o Wllt</span>
        </Styled.ButtonContainer>
      </Button>
    </Styled.Footer>
  </>
  )
}

const mapStateToProps = (state: IState) => ({ })

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(WelcomePage)
