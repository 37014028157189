import React from 'react';

import {
  Container,
  Infos,
} from './styles';

interface StockOptionsProps {
  name: string;
  description?: string;
  icon?: JSX.Element | null;
  isSmallInput?: boolean;
  onClick(): void;
}

const StockOption:React.FC<StockOptionsProps> = ({
  name,
  description,
  icon,
  onClick,
  isSmallInput,
}) => {
  return (
    <Container
      whileHover={{ scale: 1.0005 }}
      onClick={onClick}
      isSmallInput={isSmallInput}
    >
      {!!icon && (
        icon
      )}

      <Infos>
        <h1>{name}</h1>
        {!!description && (
          <span>{description}</span>
        )}
      </Infos>
    </Container>
  )
}

export default StockOption;
