import React from 'react'
import { useAuth } from '../auth'

export const AuthenticatedModal: React.FC = ({ children }) => {
  const { isAuthenticated } = useAuth()

  if (!isAuthenticated) {
    return null
  }

  return (
    <React.Fragment>
      {children}
    </React.Fragment>
  )
}
