import { ILoginType, UserActionTypes, IUserState } from "./types";

export function setIsLoadingUser() {
  return {
    type: UserActionTypes.SET_LOADING_USER,
  }
}

export function loginRequest() {
  return {
    type: UserActionTypes.LOGIN_REQUEST,
  }
}

export function loginSuccess(user: IUserState) {
  return {
    type: UserActionTypes.LOGIN_SUCCESS,
    payload: {
      user,
    }
  }
}

export function loginFailure(loginData: string) {
  return {
    type: UserActionTypes.LOGIN_FAILURE,
    payload: {
      loginData,
    }
  }
}

export function logoutRequest() {
  return {
    type: UserActionTypes.LOGOUT_REQUEST,
    payload: {}
  }
}

export function logoutSuccess() {
  return {
    type: UserActionTypes.LOGOUT_SUCCESS,
    payload: { },
  }
}

export function logoutFailure() {
  return {
    type: UserActionTypes.LOGOUT_FAILURE,
    payload: { },
  }
}
