import styled, { css } from 'styled-components';

import { motion } from 'framer-motion';

export const Container = styled(motion.div)`
${({ theme }) => css`
  background: ${theme.colors.background};
  width: 100%;

  border-radius: 0.8rem;
  margin-left: 0.8rem;
  padding: 1.6rem;

  box-shadow: ${theme.shadows.default};

  > div:first-child {
    display: flex;
    justify-content: space-between;

    margin-bottom: 3.2rem;

    > h1 {
      padding: 0.4rem 0.8rem;
      background: ${theme.colors.extremeLightGrey};

      border-radius: 0.8rem;

      color: ${theme.colors.primary};
      font-size: ${theme.fontSizes.huge};

      margin-bottom: 0.8rem;
    }
  }

  @media (max-width: 670px) {
    margin-left: 0rem;
    width: 100%;
  }
`}
`;

export const NoContentWrapper = styled.div`
${({ theme }) => css`
  width: 100%;
  height: 70%;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  > p {
    text-align: center;
  }

  > button {
    padding: 1.6rem;
  }
`}
`;

export const StocksContainer = styled.div`
${({ theme }) => css`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;

  > div {
    width: 48%;
  }

  > div {
    > p {
      text-align: center;
      font-size: ${theme.fontSizes.default};
    }
  }

  @media (max-width: 670px) {
    flex-direction: column;

    > div {
      margin-bottom: 0.8rem;
      width: 100%;
    }
  }
`}
`;
