import React, { useMemo, useState } from 'react'
import { useTable } from 'react-table'

import * as Styled from './styles';

import { endOfDay, format, isBefore } from 'date-fns';
import { IProfit, ProfitTypeTranslator } from '../../../../../store/modules/profit/types';
import { IState } from '../../../../../store';
import { connect } from 'react-redux';
import Currency from '../../../../../components/Currency';
import { useSellsProfits } from '../../../../../hooks/react-query/useSellsProfits';
import { useTheme } from 'styled-components';
import { Box } from '../../../../../components/Box';
import { useStocksTodayRank } from '../../../../../hooks/react-query/useStocksTodayRank';
import Percentage from '../../../../../components/Percentage';

interface ActiveRankListProps {
  type: 'positive' | 'negative'
}

const ActiveRankList= ({ type }: ActiveRankListProps) => {
  const { data: rank } = useStocksTodayRank()
  const theme = useTheme()

  const [reverseFilter, setReverseFilter] = useState(type === 'negative');
  const [filter, setFilter] = useState('todayGrowthInPercentage');

  const currentRank = useMemo(() => {
    if (type === 'positive') {
      return rank?.positiveRank
    }  else {
      return rank?.negativeRank
    }
  }, [rank])

  const columns = useMemo(() => {
    return (
      [
        {
          Header: 'Ativo',
          accessor: 'name',
        },
        {
          Header: 'Preço',
          accessor: 'price',
        },
        {
          Header: 'Crescimento (R$)',
          accessor: 'todayProfit',
        },
        {
          Header: 'Crescimento (%)',
          accessor: 'todayGrowthInPercentage',
        },
      ]
    )
  }, []);

  const orderedActives = useMemo(() => {
    if (!currentRank) return []

    const activesList = [...currentRank]

    activesList.sort((a: any, b: any) => {
      const bigger = reverseFilter ? 1 : -1;
      const smaller = bigger * -1;

      if (a[filter] > b[filter]) {
        return bigger;
      }
      if (a[filter] < b[filter]) {
        return smaller;
      }

      return 0;
    });

    return activesList;
  }, [currentRank, reverseFilter, filter]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
  } = useTable(
    {
      columns: columns as any,
      data: orderedActives,
    }
  )

  const orderTableByColumn = (columnName: string) => {
    const column = columns.find(c => c.accessor === columnName)

    if (filter === columnName) {
      setReverseFilter(v => !v)
    }

    if (column) {
      setFilter(columnName);
    }
  }

  return (
    <Styled.Container>
      <Styled.TableWrap className="tableWrap">
        <Styled.Table {...getTableProps()}>
          {/* <Styled.Head>
            {headerGroups.map(headerGroup => (
              <Styled.HeadRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <Styled.HeadData
                    {...column.getHeaderProps({
                      className: column.collapse ? 'collapse' : '',
                    })}
                  >
                    <Styled.HeaderLabel onClick={() => orderTableByColumn(column.id)}>
                      {column.render('Header')}
                    </Styled.HeaderLabel>
                  </Styled.HeadData>
                ))}
              </Styled.HeadRow>
            ))}
          </Styled.Head> */}
          <Styled.TableBody {...getTableBodyProps()}>
            {rows.map((row: any, i: number) => {
              prepareRow(row)
              return (
                <Styled.HeadRow {...row.getRowProps()}>
                  {row.cells.map((cell: any) => {
                    return (
                      <Styled.Content
                        {...cell.getCellProps({
                          className: cell.column.collapse ? 'collapse' : '',
                        })}
                      >
                        {cell.column.Header === 'Ativo' && (
                          <span><strong>{cell.value}</strong></span>
                        )}

                        {cell.column.Header === 'Preço' && (
                          <Currency
                            value={cell.value}
                            color={theme.colors.white}
                          />
                        )}

                        {cell.column.Header === 'Crescimento (R$)' && (
                          <Currency
                            value={cell.value}
                            color={cell.value > 0 ? theme.colors.success : theme.colors.error}
                          />
                        )}

                        {cell.column.Header === 'Crescimento (%)' && (
                          <Percentage
                            value={cell.value}
                            color={theme.colors.darkGrey}
                            type= {'variation'}
                          />
                        )}
                      </Styled.Content>
                    )
                  })}
                </Styled.HeadRow>
              )
            })}
          </Styled.TableBody>
        </Styled.Table>
      </Styled.TableWrap>
    </Styled.Container>
  )
}

export default ActiveRankList
