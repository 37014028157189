import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

export const Container = styled.div`
${({ theme }) => css`
  width: 100%;
  flex: 1;
`}
`;

export const SelectPredefinedContent = styled.div`
${({ theme }) => css`
  max-width: 30%;
  background: ${theme.colors.lightestGrey};
  padding: 2.4rem;

  display: flex;
  flex-direction: column;

  border-radius: 1.6rem;
  margin-bottom: 3.2rem;

  > div:not(:first-child) {
    height: 4.8rem;

    > svg {
      color: ${theme.colors.success} !important;
    }

    > div {
      > button:hover {
        border-color: ${theme.colors.success};
      }
    }
  }
`}
`;

export const Heading = styled.div`
${({ theme }) => css`
  margin-bottom: 1.6rem;
  color: ${theme.colors.success};
`}
`;

export const Infos = styled.div`
${({ theme }) => css`
  margin-bottom: 1.6rem;

  > h1 {
    font-size: ${theme.fontSizes.huge};
    color: ${theme.colors.white};
    margin-bottom: 0.4rem;
  }

  > span {
    color: ${theme.colors.success};
    font-weight: bold;
  }
`}
`;

export const MatchTable = styled.table`
${({ theme }) => css`
  width: 100%;
  border-collapse: collapse;
`}
`;

export const TableHead = styled.thead`
${({ theme }) => css`
  border-radius: 10px 10px 0 0;
`}
`;

export const ColumnSelectionRow = styled.tr`
${({ theme }) => css`
  > td {
    padding: 0.8rem;
    background: ${theme.colors.white};
    color: ${theme.colors.success};
    font-weight: bold;
    font-size: ${theme.fontSizes.default};

    border-left: 0.2rem solid ${theme.colors.white};
    border-right: 0.2rem solid ${theme.colors.white};
  }
`}
`;

export const ColumnToMatchRow = styled.tr`
${({ theme }) => css`
> td {
    padding: 0.4rem 0.8rem;
    background: ${theme.colors.success};
    color: ${transparentize(0.3, theme.colors.white)};
    font-weight: bold;
    font-size: ${theme.fontSizes.default};

    border-left: 0.2rem solid ${theme.colors.success};
    border-right: 0.2rem solid ${theme.colors.success};
  }
`}
`;

export const TableContent = styled.tbody`
${({ theme }) => css`
`}
`;

export const ValuesRow = styled.tr`
${({ theme }) => css`
  > td {
    border: solid 0.2rem ${theme.colors.white};
    padding: 0.8rem;

    background: ${transparentize(0.95, theme.colors.white)};
    color: ${theme.colors.white};
    font-size: ${theme.fontSizes.default};

    > h6 {
      color: ${theme.colors.white};
      font-size: ${theme.fontSizes.default};
    }
  }
`}
`;
