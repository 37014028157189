import styled, { css } from "styled-components"

export const MainProfit = styled.div`
${({ theme }) => css`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 1.6rem;

  width: 100%;

  > span {
    margin-bottom: 0.8rem;
    color: ${theme.colors.white};
  }

  > h6 {
    font-size: ${theme.fontSizes.huge};

    > p {
      font-weight: 300;
    }
  }
`}
`
