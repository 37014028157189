import React from 'react';
import styled, { css } from 'styled-components'
import { BoxProps, Box } from '../Box'
import { transparentize } from 'polished'
import { Button, CardTitle, Show } from '..';
import { ButtonProps } from '../Button/Button';

const InfoText = styled.span`
${({ theme }) => css`
  text-align: center;
`}
`

const WidgetCardContainer = styled(Box)<BoxProps>`
${({ theme, ...p }) => css`
  background: ${transparentize(0.05, theme.colors.background)};
  border-radius: ${'1.6rem'};
  padding: ${'1.6rem'};
  flex-direction: ${'column'};
  align-items: ${'flex-start'};

  display: flex;

  ${p.bg && `background-color: ${p.bg}`};

  ${p.flex && `flex: ${p.flex};`}
  ${p.flexDirection && `flex-direction: ${p.flexDirection}`};
  ${p.justifyContent && `justify-content: ${p.justifyContent}`};
  ${p.alignItems && `align-items: ${p.alignItems}`};

  ${p.h && `height: ${p.h}`};
  ${p.w && `width: ${p.w};`}
  ${p.minHeight && `min-height: ${p.minHeight};`}
  ${p.maxWidth && `max-width: ${p.maxWidth};`}
  ${p.borderRadius && `border-radius: ${p.borderRadius}`};
  ${p.borderColor && `
    border: solid 1px ${p.borderColor};
  `};

  ${p.m && `margin: ${p.m}`};
  ${p.mb && `margin-bottom: ${p.mb}`};
  ${p.ml && `margin-left: ${p.ml}`};
  ${p.mr && `margin-right: ${p.mr}`};
  ${p.mt && `margin-top: ${p.mt}`};
  ${p.mx && `margin-horizontal: ${p.mx}`};
  ${p.my && `margin-vertical: ${p.my}`};

  ${p.p && `padding: ${p.p}`};
  ${p.pb && `padding-bottom: ${p.pb}`};
  ${p.pl && `padding-left: ${p.pl}`};
  ${p.pr && `padding-right: ${p.pr}`};
  ${p.pt && `padding-top: ${p.pt}`};
  ${p.px && `padding-horizontal: ${p.px}`};
  ${p.py && `padding-vertical: ${p.py}`};
`}
`

interface IWidgetCardProps extends BoxProps {
  title?: string
  children: React.ReactNode
  withoutContent?: boolean
  withoutContentText?: string
  CTAs?: Array<ButtonProps>
}

export const WidgetCard = ({ title, children, withoutContent, withoutContentText, CTAs, ...p }: IWidgetCardProps) => {
  if (withoutContent) {
    return (
      <WidgetCardContainer {...p}>
        <Show when={title}>
          <CardTitle title={title!} type='secondary' />
        </Show>

        <Box
          flex={1}
          w={'100%'}
          alignItems='center'
          justifyContent='center'
          flexDirection='column'
        >
          <InfoText>{withoutContentText}</InfoText>
          {CTAs?.map((cta, index) => (
            <Button key={index} {...cta} />
          ))}
        </Box>
      </WidgetCardContainer>
    )
  }

  return (
    <WidgetCardContainer {...p}>
      <Show when={title}>
        <CardTitle title={title!} type='secondary' />
      </Show>
      {children}
    </WidgetCardContainer>
  )
}
