import React from 'react'
import { useTheme } from 'styled-components'
import { useStockInfos } from '..'
import { Box, CardTitle, Currency, Loading, Percentage } from '../../../../components'
import { useStockHistoricalValue, useStockProfile } from '../hooks'

import * as Styled from './styles'

export const HeaderInfos = () => {
  const { stock_id } = useStockInfos()
  const theme = useTheme()

  const { data: profile, isLoading: isLoadingProfile } = useStockProfile(stock_id)
  const { data: values, isLoading: isLoadingValues } = useStockHistoricalValue(stock_id)

  if (isLoadingProfile || !profile || isLoadingValues || !values) {
    return (
      <Box minHeight={'30rem'} flex={1} alignItems='center' justifyContent='center'>
        <Loading color={theme.colors.background} size={16} />
      </Box>
    )
  }

  return (
    <Styled.Container>
      <img src={profile.image_url ?? "https://wllt-static-files.s3.amazonaws.com/fii.png"} />
      <Styled.Content>
        <Box>
          <CardTitle title={`${profile.name} - ${profile.complete_name}`}/>
          <Box mr={'0.8rem'}/>
          <Currency value={values.totalCurrentValue - values.totalInvestedValue} type='variation' />
          <Box mr={'0.8rem'}/>
          <Percentage value={values.profitabilityInPercent} type='variation' />
        </Box>

        <Styled.PatrimonyContainer>
          <Currency value={values.totalCurrentValue} />
        </Styled.PatrimonyContainer>

      </Styled.Content>
    </Styled.Container>
  )
}
