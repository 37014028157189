import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import Chart from "react-apexcharts";

import { useTheme } from 'styled-components';
import { format } from 'date-fns';
import { useMediaQuery } from 'react-responsive';
import { DividendMonthlyChartSerie, EarningsByMonth, IPoint, IProfit } from '../../../store/modules/profit/types';
import { IWallet } from '../../../store/modules/wallet/types';
import { getMonthObject } from '../../../utils/dateUtils';
import { Box, Loading } from '../..';
import formatCurrency from '../../../utils/formatCurrency';
import { graphPallete } from '../../../utils/colors';
import { IState } from '../../../store';
import { useWallets } from '../../../hooks/react-query/useWallets';

export interface IBarChartProps {
  name: string
  data: IPoint[]
}

export interface BarChartProps {
  barChartData: DividendMonthlyChartSerie[]
  annotations?: PointAnnotations[]
  labels: string[]
}

export const TimestampBarChart: React.FC<BarChartProps> = ({ labels, barChartData, annotations }) => {
  const theme = useTheme();

  const labelFormatter = (date: number) => {
    const month = new Date(date).getMonth();
    const year = format(new Date(date), 'yyyy');

    const monthString = getMonthObject(month).monthAbbrev;

    return `${monthString}/${year}`;
  }

  const chartColors = useMemo(() => {
    return barChartData.map(() => theme.colors.grey);
  }, [barChartData]);

  const chartLegendColor = useMemo(() => {
    return barChartData.map(() => theme.colors.grey);
  }, [barChartData]);


  return (
    <Chart
      options={{
        colors: graphPallete,
        annotations: {
          points: annotations,
        },
        states: {
          active: {
            filter: {
              type: 'none'
            }
          },
          hover: {
            filter: {
              type: 'none'
            }
          },
          normal: {
            filter: {
              type: 'none',
            },
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
          }
        },
        dataLabels: {
          enabled: false,
          style: {
            fontSize: '10px',
          },
          dropShadow: {
            enabled: true,
            left: 2,
            top: 2,
            opacity: 0.5
          },
        },
        tooltip: {
          theme: 'dark',
          onDatasetHover: {
            highlightDataSeries: false,
          },
          enabled: true,
          x: {
            show: true,
            formatter: labelFormatter,
          },
          y: {
            formatter: (val) => `${formatCurrency(val / 100)}`,
            title: {
              formatter: (seriesName) => seriesName
            }
          },
          marker: {
            show: true,
          },
        },
        chart: {
          animations: {
            enabled: true,
            easing: 'linear',
            speed: 1000,
          },
          id: "bar-chart",
          type: 'bar',
          stacked: true,
          toolbar: {
            show: false,
            offsetX: 0,
            offsetY: 0,
            autoSelected: 'pan',
            tools: {
              selection: false,
              pan: false,
              download: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              reset: false,
            },
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: 'bottom',
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        legend: {
          show: false,
          showForZeroSeries: false,
          itemMargin: {
            horizontal: 8,
            vertical: 4,
          },
          position: 'right',
          offsetY: -10,
          labels: {
            colors: chartLegendColor,
          }
        },
        xaxis: {
          type: 'datetime',
          tooltip: {
            enabled: false,
          },
          axisBorder: {
            show: true,
            color: theme.colors.grey,
          },
          crosshairs: {
            show: false,
          },
          categories: labels,
          labels: {
            style: {
              colors: chartColors,
            },
            rotate: -45,
          }
        },
        yaxis:
          {
            seriesName: 'Dividendos',
            axisBorder: {
              show: false,
            },
            labels: {
              formatter: (val) => `${formatCurrency(val / 100)}`,
              style: {
                colors: chartColors,
              },
            },

          },
        grid: {
          borderColor: theme.colors.lightGrey,
        },
      }}
      series={barChartData}
      width={'100%'}
      height="400"
      type="bar"
    />
  )
}
