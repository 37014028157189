import { IValuation, ValuationActionTypes } from "./types";

export function setLoadingValuations() {
  return {
    type: ValuationActionTypes.SET_LOADING_VALUATIONS,
  }
}

export function getValuationsRequest() {
  return {
    type: ValuationActionTypes.GET_VALUATION_REQUEST,
  }
}

export function getValuationsSuccess(valuations: IValuation[]) {
  return {
    type: ValuationActionTypes.GET_VALUATION_SUCCESS,
    payload: {
      valuations,
    }
  }
}

export function getValuationsFailure(error: string) {
  return {
    type: ValuationActionTypes.GET_VALUATION_FAILURE,
    payload: {
      error,
    }
  }
}
