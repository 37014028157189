import { LoadingActionTypes } from "./types";

export function setIsLoadingData() {
  return {
    type: LoadingActionTypes.SET_IS_LOADING_DATA,
  }
}

export function setIsNotLoadingData() {
  return {
    type: LoadingActionTypes.SET_IS_NOT_LOADING_DATA,
  }
}
