import { PredefinedFileTypes } from ".";

export const handleIdentifyPredefinedFile = (columns: string[]): PredefinedFileTypes | null => {
  const CLEAR_COLUMNS = ['Código', 'Ativo', 'Tipo', 'Natureza', 'Status', 'Preço', 'Quantidade', 'Qtd. Executada', 'Preço Médio', 'Criado em', 'Módulo', 'Criado por', 'Atualizado em'];
  const CEI_COLUMNS = ['Entrada/Saída', 'Data', 'Movimentação', 'Produto', 'Instituição', 'Quantidade', 'Preço unitário', 'Valor da Operação'];

  const FILE_POSSIBLE_TYPES = [
    {
      name: PredefinedFileTypes.CLEAR,
      values: CLEAR_COLUMNS,
    },
    {
      name: PredefinedFileTypes.CEI,
      values: CEI_COLUMNS,
    },
  ];

  let possibleFileType = null;

  FILE_POSSIBLE_TYPES.forEach((columnsToMatch) => {
    let isMatch = true;

    columnsToMatch.values.forEach((fileColumnName) => {
      isMatch = isMatch && columns.includes(fileColumnName);
    });

    if (isMatch) {
      possibleFileType = columnsToMatch.name;
    }
  });

  return possibleFileType;
}

export const handleClearFile = () => {
  return {
    'Status': 'Natureza',
    'Data da Operação': 'Criado em',
    'Ativo': 'Ativo',
    'Quantidade': 'Qtd. Executada',
    'Valor': 'Preço Médio',
  }
}

export const handleCeiFile = () => {
  return {
    'Status': 'Entrada/Saída',
    'Data da Operação': 'Data',
    'Ativo': 'Produto',
    'Quantidade': 'Quantidade',
    'Valor': 'Preço unitário',
  }
}

export const predefinedHandlerFunctions = {
  CLEAR: handleClearFile,
  CEI: handleCeiFile,
}
