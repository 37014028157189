import { format } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import { FiAlertTriangle } from 'react-icons/fi';
import { connect } from 'react-redux';
import { useTheme } from 'styled-components';
import { FormattedOrder, StatusType } from '..';
import Currency from '../../../../../../components/Currency';
import WalletSearchBar from '../../../../../../components/SearchBars/WalletSearchBar';
import Tooltip from '../../../../../../components/Tooltip';
import { IState } from '../../../../../../store';
import { IWallet } from '../../../../../../store/modules/wallet/types';

import {
  Container,
  Header,
  SelectOrdersTable,
  TableHead,
  ColumnSelectionRow,
  TableContent,
  ValuesRow,
  TableContainer,
  SelectWalletTableData,
  StatusContainer,
} from './styles';

interface SelectWalletsProps {
  formattedOrders: FormattedOrder[];
  setFormattedOrders(x: any): void;
  setIsNextStepEnabled(x: any): void;
  statusKeywords: StatusType;

  wallets: IWallet[];
}

const SelectWallets: React.FC<SelectWalletsProps> = ({
  formattedOrders,
  setFormattedOrders,
  setIsNextStepEnabled,
  wallets,
  statusKeywords,
}) => {
  const theme = useTheme();

  useEffect(() => {
    const hasOrdersWithoutWallet =  formattedOrders.find((order) => order.isChecked && !order.wallet_id);

    if (hasOrdersWithoutWallet) {
      setIsNextStepEnabled({
        isEnabled: false,
        message: 'Por favor, selecione as carteiras de todos ativos que serão importados',
      });
    } else {
      setIsNextStepEnabled({
        isEnabled: true,
        message: '',
      });
    }
  }, [formattedOrders]);

  const formattedConsolidatedOrders = useMemo(() => {
    const consolidatedOrders = [] as FormattedOrder[];

    formattedOrders.forEach((order) => {
      if (!order.isChecked) return;

      const consolidatedOrder = consolidatedOrders.find((o) => o.name === order.name);

      if (!consolidatedOrder) {
        const isSellOrder = statusKeywords.SELL.includes(order.status.toLowerCase());
        const operator = isSellOrder ? -1 : 1;
        const orderAmount = operator * Number(order.amount);

        consolidatedOrders.push({
          ...order,
          amount: orderAmount,
        })
      } else {
        const isSellOrder = statusKeywords.SELL.includes(order.status.toLowerCase());

        const operator = isSellOrder ? -1 : 1;

        const orderAmount = operator * Number(order.amount);
        const totalAmount = Number(consolidatedOrder.amount) + orderAmount;
        const avgPrice = totalAmount ? (Number(consolidatedOrder.amount) * Number(consolidatedOrder.price) + orderAmount * Number(order.price)) / totalAmount : 0;

        consolidatedOrder.amount = totalAmount;
        consolidatedOrder.price = avgPrice;
      }
    });

    const sortedByMissingWalletIdOrders = consolidatedOrders.sort((a, b) => {
      if (a.wallet_id && !b.wallet_id) {
        return 1;
      }

      if (!a.wallet_id && b.wallet_id) {
        return -1;
      }

      return 1;
    })

    return sortedByMissingWalletIdOrders;
  }, [formattedOrders, statusKeywords]);

  const handleSelectWallet = (name: string, wallet_id: string) => {
    if (!wallet_id) return;

    setFormattedOrders((state: any) => {
      const newState = [...state];

      const stateWithWalletIds = newState.map((order) => {
        if (order.name === name) {
          return {
            ...order,
            wallet_id,
          }
        } else {
          return {
            ...order,
          };
        }
      })

      return stateWithWalletIds;
    })
  }

  useEffect(() => {
    const newFormattedOrders = [] as any;

    formattedOrders.forEach((formattedOrder) => {
      if (formattedOrder.wallet_id) {
        newFormattedOrders.push(formattedOrder);
        return;
      };

      let walletIdThatHasStock = '';

      wallets.forEach(wallet => {
        wallet.stocks.forEach(stock => {
          if (stock.name === formattedOrder.name) {
            walletIdThatHasStock = wallet.id;
            return;
          }
        })

        if (walletIdThatHasStock) {
          return;
        }
      });

      if (!formattedOrder.wallet_id) {
        newFormattedOrders.push({
          ...formattedOrder,
          wallet_id: walletIdThatHasStock,
        })
      } else {
        newFormattedOrders.push(formattedOrder)
      }
    });

    setFormattedOrders(newFormattedOrders);
  }, [])

  return (
    <Container>
      <Header>
        <h1>Selecione as carteiras de destino de suas ordens</h1>
      </Header>

      <TableContainer>
        <SelectOrdersTable>
          <TableHead>
            <ColumnSelectionRow>
              <td>
                <span>
                  Compra/Venda
                </span>
              </td>
              <td>
                <span>
                  Data da Operação
                </span>
              </td>
              <td>
                <span>
                  Ativo
                </span>
              </td>
              <td>
                <span>
                  Quantidade
                </span>
              </td>
              <td>
                <span>
                 Preço Médio
                </span>
              </td>
              <td>
                <span>
                  Carteira
                </span>
              </td>
            </ColumnSelectionRow>
          </TableHead>

          <TableContent>
            {
              formattedConsolidatedOrders.map((content: any) => (
                <ValuesRow
                  key={content.name}
                  hasSelectedWallet={!!content.wallet_id}
                >
                  <td>
                    <StatusContainer>
                      {!content.wallet_id && (
                        <Tooltip
                          title={'Por favor, selecione uma carteira para cada um dos seus ativos'}
                          color={theme.colors.warning}
                          position={'right'}
                          width={200}
                        >
                          <FiAlertTriangle
                            color={theme.colors.warning}
                          />
                        </Tooltip>
                      )}
                      {content.status}
                    </StatusContainer>
                  </td>
                  <td>{format(content.date, 'dd/MM/yyyy')}</td>
                  <td>{content.name}</td>
                  <td><strong>{content.amount}</strong></td>
                  <td>
                    <Currency
                      value={content.price}
                    />
                  </td>
                  <SelectWalletTableData>
                    <WalletSearchBar
                      type={'tertiary'}
                      valueId={content.wallet_id}
                      callback={({ wallet }) => handleSelectWallet(content.name, wallet.id)}
                    />
                  </SelectWalletTableData>
                </ValuesRow>
              ))
            }
          </TableContent>
        </SelectOrdersTable>
      </TableContainer>
    </Container>
  )
}

const mapStateToProps = (state: IState) => ({
  wallets: state.wallet.wallets,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(SelectWallets)
