import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

export const Container = styled.div`
${({ theme }) => css`
  display: flex;
  align-items: center;

  margin-bottom: 0.8rem;

  padding: 1.6rem;

  border-radius: 0.8rem;

  & {
    margin-right: 0.8rem;
  }

  @media (max-width: 670px) {
    max-width: 100%;
    margin-right: 0;
  }
`}
`;

export const Header = styled.div`
${({ theme }) => css`
  display: flex;
  align-items: center;

  margin-right: 1.6rem;

  min-width: 10rem;

  > div {
    display: flex;
    align-items: center;

    > h1 {
      font-weight: bold;
      font-size: ${theme.fontSizes.default};
    }

    > svg {
      color: ${theme.colors.primary};
      margin-right: 0.8rem;
    }
  }
`}
`;

export const Content = styled.div<{ inputIsGreen?: boolean, maleft?: number }>`
${({ theme, inputIsGreen, maleft }) => css`
  display: flex;
  align-items: center;

  font-size: ${theme.fontSizes.small};

  ${maleft && css`
    margin-left: ${maleft}rem;
  `}

  > p {
    margin-right: 0.8rem;
  }

  > span {
    margin-left: 0.8rem;
    color: ${theme.colors.success};
  }

  > label {
    font-size: ${theme.fontSizes.default};
  }

  > input {
    text-align: right;

    width: 70%;
    background: ${theme.colors.lightGrey};
    border: 0.2rem;
    padding: 0.6rem 0.8rem;

    color: ${inputIsGreen ? theme.colors.success : theme.colors.grey};
  }
`}
`;

export const MainContent = styled.div`
${({ theme }) => css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`}
`;
