import styled, { css } from 'styled-components';
import { CheckboxTypes } from '.';

interface CheckboxProps {
  checkboxType?: CheckboxTypes;
}

export const Container = styled.button<CheckboxProps>`
${({ theme, checkboxType }) => css`
  width: 2.4rem;
  height: 2.4rem;

  border-radius: 0.8rem;

  background: ${theme.colors.white};

  ${checkboxType === 'primary' && css`
    border: solid 0.2rem ${theme.colors.primary};
  `}

  ${checkboxType === 'secondary' && css`
    border: solid 0.2rem ${theme.colors.success};
  `}

  ${checkboxType === 'error' && css`
    border: solid 0.2rem ${theme.colors.error};
  `}

  &:hover {
    opacity: 1;
  }
`}
`;

export const getIconColorByType = (theme: any, type: CheckboxTypes) => {
  if (type === 'primary') {
    return theme.colors.primaryDark;
  } else if (type === 'secondary') {
    return theme.colors.success
  } else if (type === 'error') {
    return theme.colors.error;
  }

  return theme.primary.primary;
}
