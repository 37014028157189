import React, { InputHTMLAttributes } from 'react';

import {
  Container,
} from './styles';

export type InputTypes = 'primary' | 'secondary' | "quaternary";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  inputType?: InputTypes;
  value: string;
  icon?: any;
  callback(x: string): void;
}

const Input:React.FC<InputProps> = ({
  label,
  callback,
  icon,
  value,
  inputType = 'primary',
  ...props
}) => {
  return (
    <Container
      icon={icon}
      type={inputType}
    >
      {label && (<label>{label}</label>)}
      <div>
        {!!icon && icon}
        <input
          value={value}
          onChange={(e) => callback(e.target.value)}
          {...props as any}
        />
      </div>
    </Container>
  )
}

export default Input;
