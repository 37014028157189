import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > img {
    width: 100px;
    height: 100px;
    border-radius: 100%;

    margin-right: 3.2rem;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const PatrimonyContainer = styled.div`
${({ theme }) => css`
  > h6 {
    font-size: ${theme.fontSizes.enourmous};
    color: ${theme.colors.white};
  }
`}
`
