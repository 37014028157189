import React, { useEffect, useMemo, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import axios, { CancelTokenSource } from 'axios';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import Slider from '@material-ui/core/Slider';
import { useHistory, useLocation } from 'react-router-dom';

import { IState } from '../../../store';
import { IThemeState } from '../../../store/modules/themeSelector/types';
import { IWallet } from '../../../store/modules/wallet/types';
import { createToastMessage } from '../../../store/modules/toast/actions';

import { DEFAULT_TRANSITION } from '../../../constants';
import { animations } from '../../../resources';

import useQueryParam from '../../../hooks/useQueryParam';

import CurrencyInput from '../../../components/CurrencyInput';
import Currency from '../../../components/Currency';
import Loading from '../../../components/Loading';
import Dropdown from '../../../components/Dropdown';

import InvestmentStock from './InvestmentStock';
import calculateNewPrice from './calculateNewPrice';
import LabelRow from './LabelRow';

import {
  Container,
  HeaderContent,
  RightHeaderContent,
  StockList,
  StyledButton,
  StyledTooltip,
  TopHeaderContent,
  BottomHeaderContent,
  RightBottomHeaderContent,
  RightBottomHeaderInfos,
} from './styles';

import api from '../../../services/api';
import { fetchData } from '../../../services/data';

interface IInvestmentResponse {
  name: string;
  stock_id: string;
  wallet_stock_id: string;
  totalPapersToInvest: number;
  price: number;
  totalInvestment: number;
}
interface IInvestment {
  investment_from_pm: IInvestmentResponse[],
  investment_from_balance: IInvestmentResponse[],
  investment_from_valorization: IInvestmentResponse[],
  totalInvestment: number;
  new_wallet?: IWallet;
}

export interface IInvestmentInfos {
  id: string;
  stock_id: string;
  name: string;
  unitPrice: number;
  papers: number;
}

const cardsAnimation = {
  unMounted: { y: 25, opacity: 0 },
  mounted: {
    y: 0,
    opacity: 1,
  },
}

const AnimatedCard = motion.div;

interface InvestProps {
  realizationIsEnabled: boolean;
}

const Invest: React.FC<InvestProps> = ({ realizationIsEnabled }) => {
  const query = useQueryParam();
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const themeStateObject = useSelector<IState, IThemeState>(state => state.themeSelector);
  const wallets = useSelector<IState, IWallet[]>(state => state.wallet.wallets);

  const email = useSelector<IState, string | undefined>(state => state.user.user?.email);
  const [walletValue, setWalletValue] = useState(0);
  const [investmentInput, setInvestmentInput] = useState('');
  const [walletId, setWalletId] = useState<string | null>(null);
  const [investValue, setInvestValue] = useState(0);
  const [isSimulation,] = useState(Boolean(query.get('simulation')))
  const [stocksInfos, setStocksInfos] = useState<IInvestmentInfos[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPurchaseInfos, setIsLoadingPurchaseInfos] = useState(false);

  const [pmSelected, setIsPmSelected] = useState(true);
  const [balanceSelected, setIsBalanceSelected] = useState(true);
  const [valorizationSelected, setIsValorizationSelected] = useState(true);

  const [pmInvestment, setPmInvestment] = useState<IInvestmentResponse[]>([]);
  const [balanceInvestment, setBalanceInvestment] = useState<IInvestmentResponse[]>([]);
  const [valorizationInvestment, setValorizationInvestment] = useState<IInvestmentResponse[]>([]);

  const [newWallet, setNewWallet] = useState<IWallet>({} as IWallet);

  const [stocksToInvestInPM, setStocksToInvestInPM] = useState(3);
  const [stocksToInvestInValuation, setStocksToInvestInValuation] = useState(3);

  const [investmentRange, setInvestmentRange] = useState(50);

  const cancelRequestToken = useRef<CancelTokenSource | undefined>();

  useEffect(() => {
    const [wallet_id] = location.pathname.split('/').reverse();

    if (wallet_id) {
      const wallet = wallets.find(wallet => wallet.id === wallet_id);

      if (wallet) {
        handleChangeWallet(wallet);
      }
    }
  }, [location.pathname, wallets]);

  useEffect(() => {
    const lastStockInfos = [...stocksInfos];
    const newState = [] as IInvestmentInfos[];

    lastStockInfos.forEach((stockInfo) => {
      let investmentStockFromPm = pmInvestment.find(_ => stockInfo.name === _.name);
      let investmentStockFromBalance = balanceInvestment.find(_ => stockInfo.name === _.name);
      let investmentStockFromValorization = valorizationInvestment.find(_ => stockInfo.name === _.name);

      let total_papers = 0;

      if (investmentStockFromPm && pmSelected) {
        total_papers += investmentStockFromPm.totalPapersToInvest;
      }

      if (investmentStockFromBalance && balanceSelected) {
        total_papers += investmentStockFromBalance.totalPapersToInvest;
      }

      if (investmentStockFromValorization && valorizationSelected) {
        total_papers += investmentStockFromValorization.totalPapersToInvest;
      }

      newState.push({
        ...stockInfo,
        papers: total_papers,
      });
    });

    setStocksInfos(newState);
  }, [pmSelected, balanceSelected, valorizationSelected]);

  const handleChangeInvestmentValue = async (value: string) => {
    if (!stocksInfos.length) {
      return;
    }

    if (cancelRequestToken.current) {
      cancelRequestToken.current.cancel();
    }

    setInvestmentInput(value);
    setIsLoadingPurchaseInfos(true);
    setIsLoading(true);

    const value_to_pm = (0) * Number(value);
    const value_to_balance = (investmentRange) * Number(value);
    const value_to_valorization = (100 - investmentRange) * Number(value);

    try {
      cancelRequestToken.current = axios.CancelToken.source();

      const response = await api.post<IInvestment>(`/invest/simulate-multi-strategies`, {
        wallet_id: walletId,
        value_to_pm,
        value_to_balance,
        value_to_valorization,
        stocks_in_pm: stocksToInvestInPM,
        stocks_in_valorization: stocksToInvestInValuation,
      }, {
        cancelToken: cancelRequestToken.current.token,
      });

      const { investment_from_pm, investment_from_balance, investment_from_valorization, totalInvestment, new_wallet } = response.data;

      if (new_wallet) {
        setNewWallet(new_wallet);
      }

      setPmInvestment(investment_from_pm);
      setBalanceInvestment(investment_from_balance);
      setValorizationInvestment(investment_from_valorization);

      const lastStockInfos = [...stocksInfos];
      const newState = [] as IInvestmentInfos[];

      lastStockInfos.forEach((stockInfo) => {
        let investmentStockFromPm = investment_from_pm.find(_ => stockInfo.name === _.name);
        let investmentStockFromBalance = investment_from_balance.find(_ => stockInfo.name === _.name);
        let investmentStockFromValorization = investment_from_valorization.find(_ => stockInfo.name === _.name);

        let total_papers = 0;

        if (investmentStockFromPm) {
          total_papers += investmentStockFromPm.totalPapersToInvest;
        }

        if (investmentStockFromBalance) {
          total_papers += investmentStockFromBalance.totalPapersToInvest;
        }

        if (investmentStockFromValorization) {
          total_papers += investmentStockFromValorization.totalPapersToInvest;
        }

        newState.push({
          ...stockInfo,
          papers: total_papers,
        });
      });

      setStocksInfos(newState);

      setInvestValue(totalInvestment);
      setIsLoading(false);
      setIsLoadingPurchaseInfos(false);
    } catch (err) {
      console.log('Error in request, or new request entered pipeline');
    }
  }

  const findNewPercentageInNewWallet = (stockName: string) => {
    if (!newWallet) return 0;

    if (!newWallet.stocks) return 0;

    const stock = newWallet.stocks.find(_ => _.name === stockName);

    if (!stock) return 0;

    return stock.totalCurrentValue / newWallet.current_wallet_value * 1000;
  }

  const findOldPercentageInNewWallet = (stockName: string) => {
    if (!walletId) return 0;

    const wallet = wallets.find(wallet => wallet.id === walletId);

    if (!wallet) return 0;

    const stock = wallet.stocks.find(_ => _.name === stockName);

    if (!stock) return 0;

    return stock.totalCurrentValue / wallet.current_wallet_value * 1000;
  }

  const findNewProfitInNewWallet = (stockName: string) => {
    if (!newWallet) return 0;

    if (!newWallet.stocks) return 0;

    const stock = newWallet.stocks.find(_ => _.name === stockName);

    if (!stock) return 0;

    return (stock.totalCurrentValue - stock.totalPurchaseValue) / stock.totalPurchaseValue * 1000;
  }

  const findOldProfitInNewWallet = (stockName: string) => {
    if (!walletId) return 0;

    const wallet = wallets.find(wallet => wallet.id === walletId);

    if (!wallet) return 0;

    const stock = wallet.stocks.find(_ => _.name === stockName);

    if (!stock) return 0;

    return (stock.totalCurrentValue - stock.totalPurchaseValue) / stock.totalPurchaseValue * 1000;
  }

  const handleChangePapers = (name: string, papers: number) => {
    const lastStocksInfos = [...stocksInfos];
    const newState = [] as IInvestmentInfos[];

    lastStocksInfos.forEach((stockInfo) => {
      let stock_papers = stockInfo.papers;

      if (stockInfo.name === name) {
        stock_papers = papers;
      }

      newState.push({
        ...stockInfo,
        papers: stock_papers,
      });
    });

    setStocksInfos(newState);
    setInvestValue(calculateNewPrice(newState));
  }

  const handleChangeStockPrice = (name: string, price: number) => {
    const lastStocksInfos = [...stocksInfos];
    const newState = [] as IInvestmentInfos[];

    lastStocksInfos.forEach((stockInfo) => {
      let stock_price = stockInfo.unitPrice;

      if (stockInfo.name === name) {
        stock_price = price;
      }

      newState.push({
        ...stockInfo,
        unitPrice: stock_price,
      });
    });

    setStocksInfos(newState);
    setInvestValue(calculateNewPrice(newState));
  }

  function valuetext(value: number, index: number) {
    return `${value}`
  }

  const handleChangeWallet = (wallet: IWallet | undefined) => {
    if (!wallet) {
      setWalletValue(0);
      setWalletId(null);
      setStocksInfos([]);

      return;
    }

    const newStockInfos = wallet.stocks.map(stock => ({
      id: stock.id,
      stock_id: stock.stock_id,
      name: stock.name,
      unitPrice: stock.current_value,
      papers: 0,
    }));

    setWalletValue(wallet.current_wallet_value);
    setWalletId(wallet.id);
    setStocksInfos(newStockInfos);
  }

  const isButtonDisabled = useMemo(() => {
    if (!investValue) {
      return true;
    }

    if (!realizationIsEnabled) {
      return true;
    }

    return false;
  }, [investValue, realizationIsEnabled]);

  const submitButtonValue = useMemo(() => {
    return investValue;
  }, [investValue]);

  const initialSelected = useMemo<IWallet[]>(() => {
    const [wallet_id] = location.pathname.split('/').reverse();

    if (wallet_id) {
      const wallet = wallets.find(wallet => wallet.id === wallet_id);

      if (wallet) {
        return [wallet] as IWallet[];
      } else {
        return [] as IWallet[];
      }
    }

    return [] as IWallet[];
  }, [wallets, location.pathname]);

  const isDarkTheme = useMemo(() => {
    if (themeStateObject.isLightMode) {
      return false;
    } else {
      return true;
    }
  }, [themeStateObject]);

  const tooltipTitle = useMemo(() => {
    return 'Recomendamos que as operações sejam feitas pelo campo "Importar Ordens", e por isso bloqueamos esse botão por padrão. Você pode desbloqueá-lo em seu perfil!'
  }, []);

  return (
    <Container
      variants={animations.containerAnimation}
      transition={DEFAULT_TRANSITION}
    >

      <HeaderContent>
        <TopHeaderContent>
          <div>
            <h1>{isSimulation && '[Simulação]'} Investir</h1>
            <p>Realizar investimentos {true && 'e simulações de investimentos'}.</p>
            <label>Carteira</label>
            <Dropdown
              options={wallets}
              callback={handleChangeWallet}
              placeholder={"Escolha sua Carteira"}
              loading={false}
              values={initialSelected}
            />
          </div>

          <RightHeaderContent>
            <div>
              <div>
                <label>Investimento</label>
                <CurrencyInput
                  callBack={handleChangeInvestmentValue}
                />
              </div>
              <div>
                <label>Carteira</label>
                <Currency value={walletValue} />
              </div>
            </div>
            <div>
              {
                isLoading ? (
                  <Loading
                    color={theme.colors.primary}
                    size={8}
                  />
                )
                :
                (
                  <>
                    <Currency
                      value={submitButtonValue}
                    />
                  </>
                )
              }
            </div>
          </RightHeaderContent>
        </TopHeaderContent>
        <BottomHeaderContent>
          <RightBottomHeaderContent>
            <RightBottomHeaderInfos
              pm_selected={pmSelected}
              balance_selected={balanceSelected}
              valorization_selected={valorizationSelected}
            >
              <div>
                <button
                  onClick={() => setIsBalanceSelected(!balanceSelected)}
                  >
                  Balanceamento
                </button>
                <div>
                  <p>{investmentRange} % - </p>
                  <Currency
                    value={(investmentRange) * Number(investmentInput)}
                    />
                </div>
              </div>
              <div>
                {isLoadingPurchaseInfos && (
                  <Loading
                    size={14}
                    color={theme.colors.primary}
                  />
                )}
              </div>
              <div>
                <button
                  onClick={() => setIsValorizationSelected(!valorizationSelected)}
                >
                  Potencial de Valorização
                </button>
                <div>
                  <p>{100 - investmentRange} % - </p>
                  <Currency
                    value={(100 - investmentRange) * Number(investmentInput)}
                  />
                </div>
                <div>
                  <input type="number" value={stocksToInvestInValuation} onChange={e => setStocksToInvestInValuation(Number(e.target.value))} />
                </div>
              </div>
            </RightBottomHeaderInfos>
            <Slider
              min={0}
              max={100}
              value={investmentRange}
              color={"primary"}
              style={{ color: theme.colors.primary }}
              onChange={(event, newValue) => {
                handleChangeInvestmentValue(investmentInput)
                setInvestmentRange(newValue as number);
              }}
              valueLabelFormat={(value: number, index: number) => (`${value}%`)}
              valueLabelDisplay="on"
              getAriaValueText={valuetext}
            />
          </RightBottomHeaderContent>
        </BottomHeaderContent>
      </HeaderContent>

      {!!stocksInfos && !!stocksInfos.length && (
        <LabelRow />
      )}

      <StockList>
        {stocksInfos && stocksInfos.map(stock => (
          <AnimatedCard
            key={`card-${stock.id}`}
            variants={cardsAnimation}
            transition={DEFAULT_TRANSITION}
            whileHover={{ y: -1 }}
            whileTap={{ y: 5 }}
          >
            <InvestmentStock
              isDarkTheme={isDarkTheme}
              name={stock.name}
              numberOfPapers={stock.papers}
              unitPrice={stock.unitPrice}
              changePapers={(papers) => handleChangePapers(stock.name, papers)}
              changePrice={(price) => handleChangeStockPrice(stock.name, price)}
              newPercentage={findNewPercentageInNewWallet(stock.name)}
              oldPercentage={findOldPercentageInNewWallet(stock.name)}
              newProfit={findNewProfitInNewWallet(stock.name)}
              oldProfit={findOldProfitInNewWallet(stock.name)}
            />
          </AnimatedCard>
        ))}
        {(!stocksInfos.length) && (<p>Escolha uma Carteira :)</p>)}
      </StockList>
    </Container>
  );
};
const mapStateToProps = (state: IState) => ({
  realizationIsEnabled: state.themeSelector.realizationIsEnabled,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Invest)
