import React, { useMemo, useState } from 'react'
import { useTable } from 'react-table'

import * as Styled from './styles';

import { endOfDay, format, isBefore } from 'date-fns';
import { IProfit, ProfitTypeTranslator } from '../../../../../store/modules/profit/types';
import { IState } from '../../../../../store';
import { connect } from 'react-redux';
import Currency from '../../../../../components/Currency';

interface HistoryTableProps {
  profits: IProfit[];
}

const HistoryTable: React.FC<HistoryTableProps> = ({ profits }) => {
  const [reverseFilter, setReverseFilter] = useState(false);
  const [filter, setFilter] = useState('created_at');

  const columns: any = useMemo(() => {
    return (
      [
        {
          Header: 'Data',
          accessor: 'created_at',
        },
        {
          Header: 'Carteira',
          accessor: 'wallet_name',
        },
        {
          Header: 'Código',
          accessor: 'name',
        },
        {
          Header: 'Tipo',
          accessor: 'type',
        },
        {
          Header: 'Valor',
          accessor: 'unit_sell_price',
        },
        {
          Header: 'Qtde.',
          accessor: 'amount',
        },
        {
          Header: 'Receita',
          accessor: 'total',
        }
      ]
    )
  }, []);

  const orderedEarnings = useMemo(() => {
    const today = endOfDay(new Date());

    let newProfits = profits.map((profit) => ({
      ...profit,
      total: profit.profit,
    }));

    newProfits.sort((a: any, b: any) => {
      const bigger = reverseFilter ? 1 : -1;
      const smaller = bigger * -1;

      if (a[filter] > b[filter]) {
        return bigger;
      }
      if (a[filter] < b[filter]) {
        return smaller;
      }

      return 0;
    });

    newProfits = newProfits.filter(profit => {
      const isBeforeToday = isBefore(new Date(profit.created_at), today)

      return !!isBeforeToday;
    });

    return newProfits;
  }, [profits, reverseFilter, filter]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
  } = useTable(
    {
      columns,
      data: orderedEarnings,
    }
  )

  return (
    <Styled.Container>
      <Styled.TableWrap className="tableWrap">
        <Styled.Table {...getTableProps()}>
          <Styled.Head>
            {headerGroups.map(headerGroup => (
              <Styled.HeadRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <Styled.HeadData
                    {...column.getHeaderProps({
                      className: column.collapse ? 'collapse' : '',
                    })}
                  >
                    {column.render('Header')}
                  </Styled.HeadData>
                ))}
              </Styled.HeadRow>
            ))}
          </Styled.Head>
          <Styled.TableBody {...getTableBodyProps()}>
            {rows.map((row: any, i: number) => {
              prepareRow(row)
              return (
                <Styled.HeadRow {...row.getRowProps()}>
                  {row.cells.map((cell: any) => {
                    return (
                      <Styled.Content
                        {...cell.getCellProps({
                          className: cell.column.collapse ? 'collapse' : '',
                        })}
                      >
                        {cell.column.Header === 'Data' && (
                          <span>{format(new Date(cell.value), 'dd/MM/yyyy')}</span>
                        )}

                        {cell.column.Header === 'Carteira' && (
                          <span>{cell.value}</span>
                        )}

                        {cell.column.Header === 'Código' && (
                          <span>{cell.value}</span>
                        )}

                        {cell.column.Header === 'Tipo' && (
                          <span>{ProfitTypeTranslator[cell.value]}</span>
                        )}

                        {cell.column.Header === 'Valor' && (
                          <Currency
                            value={cell.value}
                          />
                        )}

                        {cell.column.Header === 'Qtde.' && (
                          <span>{cell.value}</span>
                        )}

                        {cell.column.Header === 'Receita' && (
                          <Currency
                            value={cell.value}
                          />
                        )}
                      </Styled.Content>
                    )
                  })}
                </Styled.HeadRow>
              )
            })}
          </Styled.TableBody>
        </Styled.Table>
      </Styled.TableWrap>
    </Styled.Container>
  )
}
const mapStateToProps = (state: IState) => ({
  profits: state.profit.profits,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(HistoryTable)
