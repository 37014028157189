import { motion } from 'framer-motion';
import { transparentize } from 'polished';
import styled, { css } from 'styled-components';
import Button from '../../../../../../components/Button';

const AnimatedButton = motion.button;

export const Container = styled.div`
${({ theme }) => css`
  width: 100%;
  display: flex;
  flex-direction: column;
`}
`;
