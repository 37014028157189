import { Dispatch } from "redux";
import { getActivesSuccess, getActivesFailure, getBalanceInfoSuccess, getStockRatingSuccess, getOrdersSuccess, getOrdersFailure, getOrdersRequest, getActivesRequest, getBalanceInfoRequest, getStockRatingRequest } from "../store/modules/actives/actions";
import api from "./api";

export const fetchOrders = async (dispatch: Dispatch<any>, async?: boolean) => {
  console.log('fetching orders...', Date.now())

  if (async) {
    dispatch(getOrdersRequest());
  }

  try {
    const response = await api.get('/invest/get-orders');

    dispatch(getOrdersSuccess(response.data));
  } catch (err) {
    dispatch(getOrdersFailure('error'));
  }
}

export const fetchActives = async (dispatch: Dispatch<any>, async?: boolean) => {
  console.log('fetching actives...', Date.now())

  if (async) {
    dispatch(getActivesRequest());
  }

  try {
    const response = await api.get('/actives/list');

    dispatch(getActivesSuccess(response.data));
  } catch (err) {
    dispatch(getActivesFailure('error'));
  }
}

export const fetchBalance = async (dispatch: Dispatch<any>, async?: boolean) => {
  console.log('fetching balance...')

  if (async) {
    dispatch(getBalanceInfoRequest());
  }

  try {
    const response = await api.get('/dashboard/money-balance');

    dispatch(getBalanceInfoSuccess({ balance: response.data.wallets }));
  } catch (err) {
    dispatch(getActivesFailure('error'));
  }
}

export const fetchStockInfos = async (dispatch: Dispatch<any>, async?: boolean) => {
  console.log('fetching stock infos...')

  if (async) {
    dispatch(getStockRatingRequest());
  }

  try {
    const response = await api.get('/dashboard/active-infos');

    dispatch(getStockRatingSuccess(response.data));
  } catch (err) {
    dispatch(getActivesFailure('error'));
  }
}
