import styled, { css } from 'styled-components'
import { TypographyType } from '.'

interface SpanProps {
  color?: string;
  type: TypographyType
}

export const Span = styled.div<SpanProps>`
${({ theme, color, type }) => css`
  ${type === 'body1' && css`
    color: ${color || theme.colors.white};
    font-size: ${theme.fontSizes.default};
    font-weight: bold;
  `}

  ${type === 'body2' && css`
    color: ${color || theme.colors.white};
    font-size: ${theme.fontSizes.default};
  `}

  ${type === 'body3' && css`
    color: ${color || theme.colors.grey};
    font-size: ${theme.fontSizes.large};
  `}

  ${type === 'title' && css`
    color: ${color || theme.colors.primary};
    font-size: ${theme.fontSizes.huge};
    font-weight: bold;
  `}
`}
`
