import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

export const Container = styled.div`
${({ theme }) => css`
  background: ${theme.colors.lightGrey};
  border-radius: 0.8rem;

  padding: 0.8rem 2.4rem;
  margin-right: 0.4rem;

  > span {
    font-size: ${theme.fontSizes.small};
    letter-spacing: 0.03em;
    margin-bottom: 0.4rem;

    font-weight: normal;
    font-style: normal;

    line-height: 15px;

    color: ${theme.colors.grey};
  }

  > h6 {
    > p {
      color: ${theme.colors.grey};
      font-size: ${theme.fontSizes.large};
      margin-right: 0.6rem;
    }

    font-size: ${theme.fontSizes.large};
    color: ${theme.colors.white};
  }
`}
`;
