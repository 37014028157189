import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

export const Container = styled.div`
${({ theme }) => css`
  width: 100%;

  display: flex;
  flex-direction: column;

  flex: 1;
`}
`;

export const DropContainer = styled.div`
${({ theme }) => css`
  padding: 2.4rem;
  border: dashed 2px ${theme.colors.success};
  border-radius: 0.8rem;

  flex: 1;

  background: ${transparentize(0.95, theme.colors.success)};

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  color: ${theme.colors.success};

  margin-bottom: 3.2rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`}
`;

export const FileDetails = styled.div`
${({ theme }) => css`
  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    margin-right: 0.8rem;
  }
`}
`;

export const HeaderText = styled.span`
${({ theme }) => css`
  color: ${theme.colors.success};
  font-weight: bold;
  font-size: ${theme.fontSizes.huge};

  margin-bottom: 1.6rem;
`}
`;

export const FinanceProductSelectionContainer = styled.div`
${({ theme }) => css`
  width: 30%;
  background: ${theme.colors.lightestGrey};
  padding: 2.4rem;

  display: flex;
  flex-direction: column;

  border-radius: 1.6rem;
  margin-bottom: 1.6rem;

  > div:not(:first-child) {
    height: 4.8rem;

    > svg {
      color: ${theme.colors.success} !important;
    }

    > div {
      > button:hover {
        border-color: ${theme.colors.success};
      }
    }
  }
`}
`;

export const Title = styled.div`
${({ theme }) => css`
  color: ${theme.colors.success};
  margin-bottom: 1.6rem;
`}
`;
