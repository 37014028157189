import React from 'react';
import { useTheme } from 'styled-components';

import {
  Container,
  StyledSelect,
} from './styles';

interface IObject {
  [key: string]: any;
}

interface DropdownProps {
  options: IObject[];
  values?: any;
  minWidth?: number;
  loading: boolean;
  placeholder: string;
  searchBy?: string;
  labelField?: string;
  valueField?: string;
  callback(value: any): void;
  itemRenderer?(data: any): JSX.Element;
}

const Dropdown: React.FC<DropdownProps> = ({ values = [], minWidth, options, loading = false, searchBy = "name", labelField = "name", valueField = "name", callback, placeholder }) => {
  const theme = useTheme();

  const noData = () => {
    return (
      <p style={{ textAlign: "center", padding: "8px" }}>
        <strong>Sem dados</strong> encontrados
      </p>
    );
  }

  return (
    <Container
      minWidth={minWidth}
    >
      <StyledSelect
        placeholder={placeholder}
        addPlaceholder=""
        color={theme.colors.darkGrey}
        disabled={false}
        loading={loading}
        searchBy={searchBy}
        separator={true}
        clearable={true}
        searchable={true}
        create={false}
        keepOpen={false}
        dropdownHandle={true}
        dropdownHeight={"300px"}
        direction={"ltr"}
        multi={false}
        values={values}
        labelField={labelField}
        valueField={valueField}
        options={options}
        dropdownGap={5}
        keepSelectedInList={true}
        onDropdownOpen={() => undefined}
        onDropdownClose={() => undefined}
        onClearAll={() => undefined}
        onSelectAll={() => undefined}
        onChange={(values: any) => callback(values[0])}
        noDataLabel="Sem dados"
        closeOnSelect={false}
        noDataRenderer={noData}
        dropdownPosition={"bottom"}
      />
    </Container>
  );
};

export default Dropdown;
