import React from 'react';
import styled, { css } from 'styled-components';

interface StockEmblemProps {
  image_url: string;
}

export const ImageContainer = styled.div`
${({ theme }) => css`
  margin-right: 1.6rem;

  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    border-radius: 100%;
    width: 4rem;
    height: 4rem;
  }
`}
`;


export const StockEmblem = ({ image_url }: StockEmblemProps) => {
  return (
    <ImageContainer>
      <img
        src={image_url}
      />
    </ImageContainer>
  )
}
