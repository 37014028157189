import * as XLSX from 'xlsx';

import { parse } from '../../../../../../../node_modules/csv-parse/dist/esm/sync';
import { readUTF8String } from '../../../../../../utils/stringUtil';

const importCsvHandler = (file_content: string) => {
  const possibleDelimiters = [',', ';'];
  const possibleDelimitersCount = [0, 0];

  possibleDelimiters.forEach((delimiter, index) => {
    const occurrences = file_content.split(delimiter).length;

    possibleDelimitersCount[index] = occurrences;
  });

  const delimiter = possibleDelimitersCount[0] > possibleDelimitersCount[1] ? ',' : ';';

  const records = parse(readUTF8String(file_content), {
    columns: true,
    skip_empty_lines: true,
    skip_records_with_error: true,
    trim: true,
    delimiter,
  });

  return records;
}

const importXlsxFile = (file_content: string) => {
  const excelFileContent = XLSX.read(file_content, {
    type: 'binary',
    cellDates: true,
  });

  const firstSheetName = excelFileContent.SheetNames[0];
  const firstSheet = excelFileContent.Sheets[firstSheetName];

  const data = XLSX.utils.sheet_to_json(firstSheet);

  return data;
}

interface ImportFunctionsTypes {
  [key: string]: (file: string) => any;
}

export const ImportFunctionByFormat = {
  csv: importCsvHandler,
  xlsx: importXlsxFile,
} as ImportFunctionsTypes
