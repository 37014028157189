import React from 'react';

import { Container } from './styles';

interface LoadingProps {
  color: string;
  size: number;
}

const Loading: React.FC<LoadingProps> = ({ color, size }) => {
  return (
    <Container color={color} size={size}>
      <div className="lds-hourglass"></div>
    </Container>
  );
};

export default Loading;
