import styled, { css } from 'styled-components';
import Button from '../../../../../components/Button';

export const Container = styled.button<{ disabled?: boolean }>`
${({ disabled }) => css`
  background: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  max-width: 24rem;

  ${disabled && css`
    opacity: 0.5;
    cursor: not-allowed;
  `}
`}
`;

export const BigIcon = styled.div`
${({ theme }) => css`
  background: #111;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 21rem;
  width: 21rem;

  padding: 3.2rem;

  margin-bottom: 1.6rem;

  border: solid 0.1rem ${theme.colors.primary};
  border-radius: 1.6rem;

  > svg {
    margin-bottom: 0.8rem;
    color: ${theme.colors.primary};
  }

  color: ${theme.colors.primary};
  font-weight: bold;
  text-align: center;
`}
`;

export const Text = styled.div`
${({ theme }) => css`
  color: ${theme.colors.primary};
  text-align: center;
`}
`;
