import React, { useEffect, useMemo, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import { Dispatch } from 'redux';
import Chart from "react-apexcharts";

import { IWallet } from '../../../../../store/modules/wallet/types';
import { IState } from '../../../../../store';

import formatCurrency, { formatPercentage } from '../../../../../utils/formatCurrency';
import { toggleOrderModal } from '../../../../../store/modules/ui/actions';
import { graphPallete } from '../../../../../utils/colors';
import { Box, Button, Loading } from '../../../../../components';
import { WidgetCard } from '../../../../../components/WidgetCard';
import { GraphContainer } from '../PatrimonyChart/style';

import * as Styled from './styles';


const StocksPieChart: React.FC = () => {
  const location = useLocation();
  const theme = useTheme();
  const history = useHistory()

  const [display, setDisplay] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setDisplay(true), 1000);
    return () => clearTimeout(timeout);
  }, [])

  const wallet = useSelector<IState, IWallet>(state => {
    const [id] = location.pathname.split('/').reverse();

    const wallet = state.wallet.wallets.find((wallet: any) => wallet.id === id);

    if (!wallet) {
      return state.wallet.wallets[0];
    }

    return wallet;
  });

  const pieChartData = useMemo(() => {
    const stocksValues = wallet.stocks.map(stock => ({
      x: stock.name,
      y: stock.totalCurrentValue,
    }))

    stocksValues.sort((a, b) => b.y - a.y);

    return [{
      data: stocksValues,
      name: 'Valor Atual',
    }]
  }, [wallet]);

  const walletHasData = useMemo(() => {
    const totalCurrentValue = wallet.stocks.reduce((acc, stock) => {
      return acc + stock.totalCurrentValue;
    }, 0);

    return totalCurrentValue !== 0
  }, [pieChartData]);

  const annotations = useMemo(() => {
    const points = [] as any;

    const total = pieChartData.reduce((acc, serie) => {
      return acc + serie.data.reduce((acc, point) => {
        return acc + point.y;
      }, 0);
    }, 0);

    pieChartData[0].data.forEach((active, index) => {
      points.push({
        x: active.x,
        y: active.y + 100,
        marker: {
          size: 0,
          radius: 0,
          strokeColor: 0,
        },
        label: {
          text: formatPercentage(active.y / total),
          borderColor: theme.colors.darkGrey,
          style: {
            background: theme.colors.grey,
            color: theme.colors.lightGrey,
            fontWeight: 'bold',
          }
        }
      });
    });

    return points;
  }, [pieChartData]);

  if (!display) {
    return (
      <WidgetCard
        w={'100%'}
        title="Balanço de Ativos"
      >
        <Box
          flex={1}
          w={'100%'}
          alignItems='center'
          justifyContent='center'
          minHeight={'415px'}
        >
          <Loading color={theme.colors.primary} size={16} />
        </Box>
      </WidgetCard>
    )
  }

  return (
    <WidgetCard
      w={'100%'}
      title="Balanço de Ativos"
      withoutContent={!walletHasData}
      withoutContentText={'Aqui aparecerão o balanço dos seus ativos, assim que você tiver algum dentro de sua carteira. Você pode importar ou adicionar manualmente suas ordens'}
    >
      <GraphContainer>
        <Chart
          options={{
            colors: graphPallete,
            annotations: {
              points: annotations,
            },
            states: {
              active: {
                filter: {
                  type: 'none'
                }
              },
              hover: {
                filter: {
                  type: 'none'
                }
              },
              normal: {
                filter: {
                  type: 'none',
                },
              },
            },
            plotOptions: {
              bar: {
                horizontal: false,
              }
            },
            dataLabels: {
              enabled: true,
              formatter: function(val, opt) {
                return `${formatCurrency(Number(val) / 100)}`;
              },
              style: {
                fontSize: '10px',
              },
              dropShadow: {
                enabled: true,
                left: 2,
                top: 2,
                opacity: 0.5
              },
            },
            tooltip: {
              theme: 'dark',
              onDatasetHover: {
                highlightDataSeries: false,
              },
              enabled: true,
              x: {
                show: true,
                // formatter: labelFormatter,
              },
              y: {
                formatter: (val) => `${formatCurrency(val / 100)}`,
                title: {
                  formatter: (seriesName) => seriesName
                }
              },
              marker: {
                show: true,
              },
            },
            chart: {
              animations: {
                enabled: false,
                easing: 'linear',
                speed: 350,
              },
              id: "bar-chart",
              type: 'bar',
              stacked: true,
              toolbar: {
                show: false,
                offsetX: 0,
                offsetY: 0,
                autoSelected: 'pan',
                tools: {
                  selection: false,
                  pan: false,
                  download: false,
                  zoom: false,
                  zoomin: false,
                  zoomout: false,
                  reset: false,
                },
              },
            },
            responsive: [
              {
                breakpoint: 480,
                options: {
                  legend: {
                    position: 'bottom',
                    offsetX: -10,
                    offsetY: 0,
                  },
                },
              },
            ],
            legend: {
              show: false,
              showForZeroSeries: false,
              itemMargin: {
                horizontal: 8,
                vertical: 4,
              },
              position: 'right',
              offsetY: -10,
              labels: {
                // colors: chartLegendColor,
              }
            },
            xaxis: {
              tooltip: {
                enabled: false,
              },
              axisBorder: {
                show: true,
                color: theme.colors.grey,
              },
              crosshairs: {
                show: false,
              },
              labels: {
                style: {
                  colors: theme.colors.grey,
                },
                rotate: -45,
              }
            },
            yaxis:
              {
                seriesName: 'Dividendos',
                axisBorder: {
                  show: false,
                },
                labels: {
                  formatter: (val) => `${formatCurrency(val / 100)}`,
                  style: {
                    colors: theme.colors.grey,
                    fontSize: '8px',
                  },
                },

              },
            grid: {
              borderColor: theme.colors.lightGrey,
            },
          }}
          series={pieChartData}
          width={'100%'}
          height="400"
          type="bar"
        />
      </GraphContainer>
    </WidgetCard>
  );
};

const mapStateToProps = (state: IState) => ({})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  toggleOrderModal: () => dispatch(toggleOrderModal()),
})

export default connect(mapStateToProps, mapDispatchToProps)(StocksPieChart);
