import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import Chart from "react-apexcharts";

import { IState } from '../../../../../store';

import {
  GraphContainer,
} from './styles';
import { DividendMonthlyChartSerie, EarningsByMonth, IProfit } from '../../../../../store/modules/profit/types';
import { useTheme } from 'styled-components';
import { IWallet } from '../../../../../store/modules/wallet/types';
import formatCurrency from '../../../../../utils/formatCurrency';
import { format } from 'date-fns';
import { getMonthObject } from '../../../../../utils/dateUtils';
import { useMediaQuery } from 'react-responsive';
import { graphPallete } from '../../../../../utils/colors';
import { Box } from '../../../../../components/Box';
import { Loading } from '../../../../../components';

interface GraphProps {
  profits: IProfit[];
  wallets: IWallet[];
  earningsByMonth: EarningsByMonth | null;
  walletFilter: string[]
}

const Graph: React.FC<GraphProps> = ({ wallets, profits, earningsByMonth, walletFilter }) => {
  const [display, setDisplay] = useState(false);

  useEffect(() => {
    setTimeout(() => setDisplay(true), 100);
  }, [])

  const isSmallDevice = useMediaQuery({ query: '(max-width: 1300px)' });

  const theme = useTheme();

  const graphWidth = useMemo(() => {
    if (isSmallDevice) {
      return '300';
    } else {
      return '95%';
    }
  }, [isSmallDevice])

  const labelFormatter = (date: number) => {
    const month = new Date(date).getMonth();
    const year = format(new Date(date), 'yyyy');

    const monthString = getMonthObject(month).monthAbbrev;

    return `${monthString}/${year}`;
  }

  const chartData = useMemo(() => {
    let chartSeries = [] as DividendMonthlyChartSerie[];

    if (!earningsByMonth) {
      return {
        chartSeries: [],
        monthsList: [],
        monthsLabels: [],
      }
    }

    walletFilter.forEach(wallet_id => {
      if (!earningsByMonth?.chartSeries[wallet_id]) {
        return;
      }

      if (!chartSeries.length) {
        chartSeries = earningsByMonth.chartSeries[wallet_id];
        return;
      }

      const walletEarnings = earningsByMonth.chartSeries[wallet_id];

      chartSeries = chartSeries.map((chartSerie, index) => {
        const serieInWallet = walletEarnings[index];

        const totalData = chartSerie.data.map((monthValue, _index) => {
          const serieInWalletMonthValue = serieInWallet.data[_index];

          return monthValue + serieInWalletMonthValue;
        });

        return {
          name: chartSerie.name,
          type: chartSerie.type,
          data: totalData,
        }
      })
    });

    return {
      chartSeries,
      monthsList: earningsByMonth.monthsList.map(date => new Date(date)),
      monthsLabels: earningsByMonth.monthsLabels,
    }
  }, [profits, walletFilter]);

  const annotations = useMemo(() => {
    const points = [] as any;

    chartData.monthsList.forEach((month, index) => {
      let totalOfMonth = 0;

      chartData.chartSeries.forEach((serie: any) => {
        totalOfMonth += serie.data[index];
      });

      if (!totalOfMonth) {
        return;
      };

      points.push({
        x: month.getTime(),
        y: totalOfMonth,
        marker: {
          size: 0,
          radius: 0,
          strokeColor: 0,
        },
        label: {
          text: formatCurrency(totalOfMonth / 100),
          borderColor: theme.colors.darkGrey,
          style: {
            background: theme.colors.grey,
            color: theme.colors.lightGrey,
            fontWeight: 'bold',
          }
        }
      });
    });

    return points;
  }, [chartData]);

  const labelMinimumValue = useMemo(() => {
    const values = [] as number[];

    chartData.chartSeries.forEach((chartSerie) => [
      chartSerie.data.forEach((data) => {
        values.push(data);
      })
    ]);

    const valuesSortes = values.sort((a, b) => {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    }).filter(v => v > 0);

    if (!valuesSortes.length) {
      return 0;
    }

    const index = Math.round(9 * valuesSortes.length / 10)

    return valuesSortes[index]
  }, [chartData])

  const chartColors = useMemo(() => {
    return chartData.monthsLabels.map(() => theme.colors.grey);
  }, [chartData]);

  const chartLegendColor = useMemo(() => {
    return chartData.chartSeries.map(() => theme.colors.grey);
  }, [chartData]);


  if(!display) {
    return (
      <Box h={'51.5rem'} flex={1} alignItems={'center'} justifyContent={'center'}>
        <Loading color={theme.colors.primary} size={16} />
      </Box>
    );
  }

  return (
    <GraphContainer>
      <Chart
        options={{
          colors: graphPallete,
          annotations: {
            points: annotations,
          },
          states: {
            active: {
              filter: {
                type: 'none'
              }
            },
            hover: {
              filter: {
                type: 'none'
              }
            },
            normal: {
              filter: {
                type: 'none',
              },
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
            }
          },
          dataLabels: {
            enabled: false,
            formatter: function(val, opt) {
              return `${formatCurrency(Number(val) / 100)}`;
            },
            style: {
              fontSize: '10px',
            },
            dropShadow: {
              enabled: true,
              left: 2,
              top: 2,
              opacity: 0.5
            },
          },
          tooltip: {
            theme: 'dark',
            onDatasetHover: {
              highlightDataSeries: false,
            },
            enabled: true,
            x: {
              show: true,
              formatter: labelFormatter,
            },
            y: {
              formatter: (val) => `${formatCurrency(val / 100)}`,
              title: {
                formatter: (seriesName) => seriesName
              }
            },
            marker: {
              show: true,
            },
          },
          chart: {
            animations: {
              enabled: true,
              easing: 'linear',
              speed: 350,
            },
            id: "bar-chart",
            type: 'bar',
            stacked: true,
            toolbar: {
              show: false,
              offsetX: 0,
              offsetY: 0,
              autoSelected: 'pan',
              tools: {
                selection: false,
                pan: false,
                download: false,
                zoom: false,
                zoomin: false,
                zoomout: false,
                reset: false,
              },
            },
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                legend: {
                  position: 'bottom',
                  offsetX: -10,
                  offsetY: 0,
                },
              },
            },
          ],
          legend: {
            show: false,
            showForZeroSeries: false,
            itemMargin: {
              horizontal: 8,
              vertical: 4,
            },
            position: 'right',
            offsetY: -10,
            labels: {
              colors: chartLegendColor,
            }
          },
          xaxis: {
            type: 'datetime',
            tooltip: {
              enabled: false,
            },
            axisBorder: {
              show: true,
              color: theme.colors.grey,
            },
            crosshairs: {
              show: false,
            },
            categories: chartData.monthsLabels,
            labels: {
              style: {
                colors: chartColors,
              },
              rotate: -45,
            }
          },
          yaxis:
            {
              seriesName: 'Dividendos',
              axisBorder: {
                show: false,
              },
              labels: {
                formatter: (val) => `${formatCurrency(val / 100)}`,
                style: {
                  colors: chartColors,
                },
              },

            },
          grid: {
            borderColor: theme.colors.lightGrey,
          },
        }}
        series={chartData.chartSeries}
        width={graphWidth}
        height="500"
        type="bar"
      />
    </GraphContainer>
  )
}

const mapStateToProps = (state: IState) => ({
  profits: state.profit.profits,
  earningsByMonth: state.profit.earningsByMonth,
  wallets: state.wallet.wallets,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Graph)
