import { Dispatch } from "redux";
import { getValuationsSuccess, getValuationsFailure, getValuationsRequest } from "../store/modules/valuation/actions";
import api from "./api";

export const fetchValuations = async (dispatch: Dispatch<any>, async?: boolean) => {
  console.log('fetching valuations...');

  if (async) {
    dispatch(getValuationsRequest());
  }

  try {
    const response = await api.get('/valuation/consult');

    dispatch(getValuationsSuccess(response.data));
  } catch (err) {
    dispatch(getValuationsFailure('error'));
  }
}
