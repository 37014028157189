import React, { useMemo } from 'react';
import { useTheme } from 'styled-components';
import Chart from "react-apexcharts";

import {
  GraphContainer,
  NoContentWrapper,
} from './styles';

import getPallete from '../../../../../utils/colors';
import Button from '../../../../../components/Button';
import { useHistory } from 'react-router-dom';
import { useWallet } from '..';
import { WidgetCard } from '../../../../../components/WidgetCard';

const MonthVariation: React.FC = () =>  {
  const theme = useTheme();
  const history = useHistory();

  const { wallet } = useWallet();

  const chartData = useMemo(() => {
    let stocks = wallet.stocks.map(s => {
      return {
        valorization: s.valuation ? ((s.valuation - s.current_value) / s.current_value) * 100 : 0,
        stock: s.name
      }
    });

    stocks = stocks.filter(s => s.valorization)

    stocks.sort((a: any, b: any) => {
      if (a.valorization < b.valorization) {
        return 1;
      } else {
        return -1;
      }
    })

    const chartSeries = [{
      name: 'Potencial de Valorização',
      data: stocks.map(s => s.valorization),
    }] as any;


    return {
      chartSeries,
      stock_names: stocks.map(s => s.stock),
    }
  }, [wallet]);

  const chartColors = useMemo(() => {
    const pallete = getPallete(theme.colors.success, '#fff', chartData.chartSeries[0].data.length)

    return pallete;
  }, [chartData])

  const labelChartColors = useMemo(() => {
    return chartData.stock_names.map(() => theme.colors.grey);
  }, [chartData]);

  const chartLegendColor = useMemo(() => {
    return chartData.chartSeries.map(() => theme.colors.grey);
  }, [chartData]);

  const chartIsAvailable = useMemo(() => {
    return !!chartData.chartSeries[0].data.length;
  }, [chartData]);

  const handleGoToValuation = () => {
    history.push('/dashboard/valuation');
  }

  return (
    <WidgetCard
      title="Potenciais de Valorização"
      w={'100%'}
    >
      <GraphContainer>
        {chartIsAvailable && (
          <Chart
            options={{
              states: {
                active: {
                  filter: {
                    type: 'none'
                  }
                },
                hover: {
                  filter: {
                    type: 'none'
                  }
                },
                normal: {
                  filter: {
                    type: 'none',
                  },
                },
              },
              plotOptions: {
                bar: {
                  horizontal: true,
                }
              },
              dataLabels: {
                style: {
                  fontSize: '12px',
                },
                enabled: true,
                formatter: function(val, opt) {
                  return `${Math.floor(Number(val))} %`;
                },
                offsetX: 0,
                dropShadow: {
                  enabled: true,
                  left: 2,
                  top: 2,
                  opacity: 0.5
                },
              },
              tooltip: {
                theme: 'dark',
                onDatasetHover: {
                  highlightDataSeries: false,
                },
                enabled: true,
                x: {
                  show: true,
                },
                y: {
                  formatter: (val) => `${Math.floor(Number(val))} %`,
                  title: {
                    formatter: (seriesName) => seriesName
                  }
                },
                marker: {
                  show: true,
                },
              },
              colors: chartColors,
              chart: {
                animations: {
                  enabled: false,
                  speed: 1000,
                  animateGradually: {
                    enabled: false,
                  },
                  dynamicAnimation: {
                    enabled: true,
                    speed: 200,
                  }
                },
                id: "bar-chart",
                type: 'bar',
                stacked: false,
                toolbar: {
                  show: false,
                  offsetX: 0,
                  offsetY: 0,
                  autoSelected: 'pan',
                  tools: {
                    selection: false,
                    pan: false,
                    download: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    reset: false,
                  },
                },
              },
              responsive: [
                {
                  breakpoint: 480,
                  options: {
                    legend: {
                      position: 'bottom',
                      offsetX: -10,
                      offsetY: 0,
                    },
                  },
                },
              ],
              legend: {
                showForZeroSeries: false,
                itemMargin: {
                  horizontal: 8,
                  vertical: 4,
                },
                position: 'right',
                offsetY: -10,
                labels: {
                  colors: chartLegendColor,
                }
              },
              xaxis: {
                tooltip: {
                  enabled: false,
                },
                axisBorder: {
                  show: true,
                  color: theme.colors.grey,
                },
                crosshairs: {
                  show: false,
                },
                categories: chartData.stock_names,
                labels: {
                  rotateAlways: true,
                  formatter: (val) => `${(Math.floor(Number(val)))} %`,
                  style: {
                    fontSize: '1rem',
                    colors: labelChartColors,
                  },
                  rotate: 0,
                }
              },
              yaxis: {
                seriesName: 'Potencial',
                axisBorder: {
                  show: false,
                },
                labels: {
                  style: {
                    colors: labelChartColors,
                  },
                },
              },
              grid: {
                borderColor: theme.colors.lightGrey,
              }
            }}
            series={chartData.chartSeries}
            height="100%"
            type="bar"
          />
        )}

        {!chartIsAvailable && (
          <NoContentWrapper>
            <p>Valuation aparecerão aqui caso você os adicione!</p>
            <Button
              onClick={handleGoToValuation}
            >
              Adicionar Valuation
            </Button>
          </NoContentWrapper>
        )}
      </GraphContainer>
    </WidgetCard>
  );
};

export default MonthVariation

