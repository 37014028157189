import { Reducer } from "redux";
import { ICryptoWalletsState, CryptoWalletActionTypes } from "./types";
import produce from 'immer';

const INITIAL_THEME_STATE = {
  crypto_wallets: [],
  isLoadingCryptoWallets: true,
}

const loadingReducer: Reducer<ICryptoWalletsState> = (state = INITIAL_THEME_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case CryptoWalletActionTypes.GET_CRYPTO_WALLETS_REQUEST: {
        draft.isLoadingCryptoWallets = true;

        break;
      }
      case CryptoWalletActionTypes.GET_CRYPTO_WALLETS_SUCCESS: {
        draft.crypto_wallets = action.payload.wallets;
        draft.isLoadingCryptoWallets = false;

        break;
      }
      case CryptoWalletActionTypes.GET_CRYPTO_WALLETS_FAILURE: {
        draft.isLoadingCryptoWallets = false;

        break;
      }
      default: {
        return draft;
      }
    }
  });
}

export default loadingReducer;
