import { isDate, isMatch } from "date-fns";
import { isCharacterALetter } from "../../../../../utils/stringUtil";

export const valueValidator = (value: number | string) => {
  if (value === '-') {
    return 0
  }

  if (Number.isNaN(Number(value))) {
    return 'Número Inválido';
  } else if (!value) {
    return ''
  }

  return Number(value) * 100;
}

export const valuesValidator = (values: number[]) => {
  const valuesFormatted = values.map((value: number) => {
    return valueValidator(value);
  });

  return valuesFormatted;
}

export const dateValidator = (rawDate: any) => {
  if (isDate(rawDate)) {
    return rawDate;
  }

  const isBrazilianDateWithoutHour = isMatch(rawDate, 'dd/MM/yyyy')
  const isBrazilianDateWithHour = isMatch(rawDate, 'dd/MM/yyyy HH:mm:ss')
  const isAmericanDate = isMatch(rawDate, 'MM/dd/yyyy HH:mm:ss')
  const isAmericanDateWithHour = isMatch(rawDate, 'MM/dd/yyyy HH:mm:ss')

  if (isBrazilianDateWithHour) {
    const [dateString, hoursString] = rawDate.split(' ');
    const [day, month, year] = dateString.split('/');
    const [hours, minutes, seconds] = hoursString.split(':');

    return new Date(+year, +month - 1, +day, +hours, +minutes, +seconds);
  }

  if (isBrazilianDateWithoutHour) {
    const [day, month, year] = rawDate.split('/');

    return new Date(+year, +month - 1, +day);
  }

  if (isAmericanDate) {
    const [month, day, year] = rawDate.split('/');

    return new Date(+year, +month - 1, +day);
  }

  if (isAmericanDateWithHour) {
    const [dateString, hoursString] = rawDate.split(' ');
    const [month, day, year] = dateString.split('/');
    const [hours, minutes, seconds] = hoursString.split(':');

    return new Date(+year, +month - 1, +day, +hours, +minutes, +seconds);
  }

  return new Date();
}

export const datesValidator = (dates: any): Date[] => {
  const datesFormatted = dates.map((rawDate: any) => {
    return dateValidator(rawDate);
  })

  return datesFormatted;
}

export const amountValidator = (amount: string) => {
  if (!amount) return 'Quantidade inválida';

  return String(amount).replace(',', '.');
}

export const amountsValidator = (amounts: string[]) => {
  return amounts.map((amount) => {
    return amountValidator(amount);
  })
}

export const nameValidator = (name: string) => {
  if (!name) return '';

  if (name.trim().length > 7) {
    let stockPossibleName = name.slice(0, 7).trim();

    while(stockPossibleName.length) {
      const lastCharacter = stockPossibleName[stockPossibleName.length - 1];

      const lastCharacterIsNumber = Number(lastCharacter);
      const lastCharacterIsChar = isCharacterALetter(lastCharacter);

      if (!lastCharacterIsNumber && !lastCharacterIsChar) {
        stockPossibleName = stockPossibleName.slice(0, -1);
      } else {
        return stockPossibleName;
      }
    }
  }

  const formattedName = name[name.length - 1] === 'F' ? name.slice(0, -1) : name;

  return formattedName;
}

export const namesValidator = (names: string[]) => {
  const namesFormatted = names.map((name: string) => {
    return nameValidator(name);
  });

  return namesFormatted;
}
