import styled, { css } from 'styled-components';
import Button from '../Button';

export const Container = styled.div<{ stayInCornerBottom?: boolean }>`
${({ theme, stayInCornerBottom }) => css`
  > div {
    position: fixed;
    bottom: 7rem;
    right: 4.8rem;
  }

  a {
    ${stayInCornerBottom && css`
      position: fixed;
      bottom: 1.6rem;
      right: 1.6rem;
    `}

    background: ${theme.colors.background};
    padding: 1.6rem;
    border-radius: 50%;

    display: flex;

    > svg {
      color: ${theme.colors.primary} !important;
    }

    &:link {
      > svg {
        color: ${theme.colors.primary};
      }
    }

    &:active {
      >svg {
        color: ${theme.colors.primary};
      }
    }

    &:visited {
      > svg {
        color: ${theme.colors.primary};
      }
    }

    &:hover {
      > svg {
        color: ${theme.colors.secondary};
      }
    }
  }
`}
`;

