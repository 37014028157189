import React, { useMemo } from 'react';
import { useTheme } from 'styled-components';
import { ImportSteps } from '..';
import { WlltIcon } from '../../../../../../assets/images/icons';
import Loading from '../../../../../../components/Loading';
import Tooltip from '../../../../../../components/Tooltip';

import {
  Container,
  Steps,
  ButtonsContainer,
  StyledBackButton,
  StyledNextButton,
  Step,
  StepDivider,
} from './styles';

interface FooterProps {
  steps: Array<{
    id: string;
    label?: any;
    isFinalStep?: boolean;
  }>;
  currentStep: string;
  currentStepIndex: number;
  isLoading: boolean;
  handleMoveToNextStep(): void;
  handleMoveBackStep(): void;
  isNextStepEnabled: {
    message: string;
    isEnabled: boolean;
  }
}

const Footer: React.FC<FooterProps> = ({
  steps,
  currentStep,
  currentStepIndex,
  handleMoveToNextStep,
  handleMoveBackStep,
  isLoading,
  isNextStepEnabled,
}) => {
  const theme = useTheme()

  const nextStepButtonText = useMemo(() => {
    return currentStepIndex === 4 ? 'Concluir' : 'Próximo'
  }, [currentStepIndex]);

  const { message, isEnabled } = isNextStepEnabled;

  return (
    <Container>
      <Steps>
        {steps.map((step, index) => {
          if (!step.label) return null;

          if (step.isFinalStep) {
            return (
              <Step
                isCurrentStep={index <= currentStepIndex}
                key={step.id}
                isFinalStep
              >
                <WlltIcon
                  color={index <= currentStepIndex ? theme.colors.white : theme.colors.extremeLightGrey}
                />
              </Step>
            )
          } else {
            return (
              <div
                key={step.id}
              >
                <Step
                  isCurrentStep={index <= currentStepIndex}
                >
                  {step.label}
                </Step>
                <StepDivider
                  isCurrentStep={index + 1 <= currentStepIndex}
                />
              </div>
            )
          }
        })}
      </Steps>

      {currentStep !== ImportSteps.CHOOSE_IMPORT_TYPE && (
        <ButtonsContainer>
          <StyledBackButton
            onClick={handleMoveBackStep}
          >
            Anterior
          </StyledBackButton>
          <Tooltip
            width={300}
            color={theme.colors.success}
            title={message}
          >
            <StyledNextButton
              disabled={!isEnabled}
              onClick={handleMoveToNextStep}
            >
              {isLoading ? (
                <>
                  <Loading
                    color={'#fff'}
                    size={8}
                  />
                </>
              ) : (
                <>
                  {nextStepButtonText}
                </>
              )}
            </StyledNextButton>
          </Tooltip>
        </ButtonsContainer>
      )}
    </Container>
  )
}

export default Footer;
