import styled, { css } from 'styled-components';

export const Container = styled.div`
${({ theme }) => css`
  margin-bottom: 4rem;
`}
`;

export const TopInfos = styled.div`
${({ theme }) => css`
  display: flex;
  align-items: center;

  margin-bottom: 1.6rem;

  > h1:first-child {
    margin-right: 1.6rem;
  }
`}
`;

export const ReceivedEarnings = styled.div`
${({ theme }) => css`
  display: flex;
  align-items: center;

  > h6 {
    > p {
      margin-right: 2.4rem;
      letter-spacing: 0em;
    }

    margin-right: 2.4rem;

    letter-spacing: 0.03em;
    font-size: 10rem;
    color: ${theme.colors.white};
  }

  @media (max-width: 670px) {
    > h6 {
      font-size: ${theme.fontSizes.medium_large};
    }
  }
`}
`;
