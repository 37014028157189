import { format } from 'date-fns';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FiAlertTriangle, FiCheck, FiCheckCircle, FiSearch } from 'react-icons/fi';
import { connect } from 'react-redux';
import { useTheme } from 'styled-components';
import { FormattedOrder, StatusType } from '..';
import Checkbox from '../../../../../../components/Checkbox';
import Currency from '../../../../../../components/Currency';
import Input from '../../../../../../components/Input';
import Tooltip from '../../../../../../components/Tooltip';
import api from '../../../../../../services/api';
import { IState } from '../../../../../../store';
import { IOrder } from '../../../../../../store/modules/actives/types';
import { ICryptoWallet } from '../../../../../../store/modules/crypto/types';
import { ITesouroWallet } from '../../../../../../store/modules/tesouroWallet/types';
import { IWallet } from '../../../../../../store/modules/wallet/types';
import { amountValidator, dateValidator, nameValidator, valueValidator } from '../columnsValidators';
import { MatchingColumns } from '../MatchFileColumns';
import { identifyErrors } from './errorsIdentifier';

import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { removeSpecialCharactersFromString } from '../../../../../../utils/stringUtil';

import {
  Container,
  SelectOrdersTable,
  ColumnSelectionRow,
  ColumnToMatchRow,
  ImportTableData,
  ValuesRow,
  PatrimonyInfosContainer,
  PreviousPatrimony,
  StyledButton,
} from './styles';
import { Box } from '../../../../../../components';

interface SelectRowsProps {
  matchedColumns: MatchingColumns;
  orders: IOrder[];
  fileContentRaw: any;
  setFormattedOrders: (x: any) => void;
  formattedOrders: FormattedOrder[];
  setIsNextStepEnabled(x: any): void;
  statusKeywords: StatusType;

  wallets: IWallet[];
  tesouro_wallets: ITesouroWallet[];
  crypto_wallets: ICryptoWallet[];
}

interface StockIdsByName {
  [key: string]: string;
}

const SelectRows: React.FC<SelectRowsProps> = ({
  orders,
  matchedColumns,
  fileContentRaw,
  wallets,
  tesouro_wallets,
  crypto_wallets,
  setFormattedOrders,
  formattedOrders: orderAlreadyFormatted,
  setIsNextStepEnabled,
  statusKeywords,
}) => {
  const [isImportingArray, setIsImportingArray] = useState(Array.from({ length: fileContentRaw.length }, () => true));
  const [stockIdsByName, setStockIdsByName] = useState<StockIdsByName>({});
  const [hasErrorArray, setHasErrorArray] = useState<{ message: string; hasError: boolean }[]>([]);

  const [nameFilter, setNameFilter] = useState('');

  const theme = useTheme();

  useEffect(() => {
    setIsNextStepEnabled({
      isEnabled: true,
      message: '',
    })
  }, []);

  const formattedOrders = useMemo(() => {
    const statusColumnName = matchedColumns['Status']
    const dateColumnName = matchedColumns['Data da Operação']
    const nameColumnName = matchedColumns['Ativo']
    const amountColumnName = matchedColumns['Quantidade']
    const valueColumnName = matchedColumns['Valor']

    const formattedOrders = fileContentRaw.map((rawContent: any, index: number) => {
      const formattedName = nameValidator(rawContent[nameColumnName]);
      const formattedPrice = valueValidator(rawContent[valueColumnName]);
      const validatedDates = dateValidator(rawContent[dateColumnName]);
      const amountValidated = amountValidator(rawContent[amountColumnName])

      const stock_id = stockIdsByName[formattedName];

      const formattedContent = {
        id: rawContent.id,
        status: removeSpecialCharactersFromString(rawContent[statusColumnName]).toLowerCase(),
        date: validatedDates,
        name: formattedName,
        amount: amountValidated,
        price: formattedPrice,
        stock_id,
        isChecked: isImportingArray[index],
      }

      return formattedContent;
    });

    return formattedOrders;
  }, [matchedColumns, fileContentRaw, isImportingArray, stockIdsByName]);

  const formattedOrdersOrderedWithErrors = useMemo(() => {
    const mergedArray = formattedOrders.map((data: any, index: number) => ({
      ...data,
      ...hasErrorArray[index],
    }));

    const filteredByName = mergedArray.filter((order: any) => order.name.includes(nameFilter));

    return filteredByName.sort(function (a: any, b: any) {
      if (a.hasError) {
        return -1;
      }
      if (b.hasError) {
        return 1;
      }

      return 0;
    });
  }, [formattedOrders, hasErrorArray, nameFilter])

  const totalImportValue = useMemo(() => {
    let total = 0;

    formattedOrders.forEach((order: any, index: number) => {
      const amount = Number.isNaN(+order.amount) ? 0 : +order.amount;
      const value = Number.isNaN(+order.price) ? 0 : +order.price;

      const isSell = statusKeywords.SELL.includes(order.status.toLowerCase());

      const operator = isSell ? -1 : 1;

      if (order.isChecked) {
        total += operator * amount * value;
      }
    });

    return total;
  }, [formattedOrders]);

  const handleToggleOrderSelection = (id: string) => {
    const index = formattedOrders.findIndex((o: any) => o.id === id);

    setIsImportingArray(arr => {
      const newArray = [...arr];

      newArray[index] = !newArray[index]

      return newArray;
    });
  }

  const patrimony = useMemo(() => {
    let total = 0;

    wallets.forEach(wallet => {
      total += wallet.current_wallet_value;
    });

    tesouro_wallets.forEach(tesouroWallet => {
      total += tesouroWallet.current_wallet_value;
    });

    crypto_wallets.forEach(cryptoWallet => {
      total += cryptoWallet.current_wallet_value * 100;
    });

    return total;
  }, [wallets, tesouro_wallets, crypto_wallets]);

  useEffect(() => {
    const names = [] as string[];

    formattedOrders.forEach((order: any) => {
      const nameIsInArray = names.find(str => str === order.name);

      if (!nameIsInArray) {
        names.push(order.name);
      }
    });

    async function getStockIds() {
      const response = await api.get('/stocks/find-stocks', {
        params: {
          names,
        },
      });

      const stockIds = {} as any;

      response.data.forEach((stock: any) => {
        stockIds[stock.name] = stock.id;
      })

      setStockIdsByName(stockIds);
    }

    getStockIds();
  }, []);

  useEffect(() => {
    const errors = identifyErrors(formattedOrders, orders, statusKeywords);

    setHasErrorArray(errors);
    setIsImportingArray(arr => {
      const newArray = [...arr];

      errors.forEach((error, index) => {
        if (!orderAlreadyFormatted[index]) {
          newArray[index] = !error.hasError;
        } else {
          newArray[index] = orderAlreadyFormatted[index].isChecked;
        }
      })

      return newArray;
    });
  }, []);

  useEffect(() => {
    setFormattedOrders(formattedOrders.filter((order: any) => order.isChecked).map((formattedOrder: any) => {
      const order = orderAlreadyFormatted.find(o => o.id === formattedOrder.id);

      if (order) {
        return {
          ...formattedOrder,
          wallet_id: order.wallet_id,
        }
      } else {
        return { ...formattedOrder };
      }
    }));
  }, [formattedOrders]);

  const handleSelectAllOrders = () => {
    setIsImportingArray(Array.from({ length: fileContentRaw.length }, () => true))
  }

  const OrderRenderer = ({ index, style }: any) => {
    const content = formattedOrdersOrderedWithErrors[index];

    return (
      <ValuesRow
        hasError={content.hasError && !content.isChecked}
        key={content.id}
        style={{
          ...style,
        }}
      >
        <td>
          <div>
            <span>
              {content.status}
            </span>
          </div>
        </td>
        <td>{format(content.date, 'dd/MM/yyyy')}</td>
        <td>{content.name}</td>
        <td><strong>{content.amount}</strong></td>
        <td>
          {
            Number.isNaN(+content.price) ? (
              content.price
            ) : (
              <Currency
                value={content.price}
              />
            )
          }
        </td>
        <td>
          {content.hasError && (
            <Tooltip
              title={content.message}
              color={theme.colors.warning}
              position={'bottom'}
              width={200}
            >
              <FiAlertTriangle
                color={theme.colors.warning}
              />
            </Tooltip>
          )}
          <Checkbox
            isChecked={content.isChecked}
            type={content.hasError ? 'error' : 'secondary'}
            onClick={() => {
              handleToggleOrderSelection(content.id)
            }}
          />
        </td>
      </ValuesRow>
    )
  }

  return (
    <Container>
      <div>
        <h1>Revise todos os seus dados</h1>

        <Input
          icon={<FiSearch color={theme.colors.success} size={22} />}
          callback={(e) => setNameFilter(e.toUpperCase())}
          value={nameFilter}
          inputType="secondary"
          placeholder="Pesquise pelo nome do ativo"
        />
      </div>

      <SelectOrdersTable>
        <ColumnSelectionRow>
          <td>
            <span>
            {matchedColumns['Status']}
            </span>
          </td>
          <td>
            <span>
            {matchedColumns['Data da Operação']}
            </span>
          </td>
          <td>
            <span>
            {matchedColumns['Ativo']}
            </span>
          </td>
          <td>
            <span>
            {matchedColumns['Quantidade']}
            </span>
          </td>
          <td>
            <span>
            {matchedColumns['Valor']}
            </span>
          </td>
          <td>
            <ImportTableData>
              <span>
                Importar
              </span>

              <div>
                <StyledButton
                  onClick={handleSelectAllOrders}
                >
                  <FiCheckCircle />
                  <span>Selecionar Todas</span>
                </StyledButton>
              </div>
            </ImportTableData>
          </td>
        </ColumnSelectionRow>

        <ColumnToMatchRow>
          <td>Compra ou Venda</td>
          <td>Data da Operação</td>
          <td>Ativo</td>
          <td>Quantidade</td>
          <td>Valor</td>
          <td>Importar</td>
        </ColumnToMatchRow>

        <AutoSizer>
          {({ height, width}) => (
            <List
              height={240}
              width={width}
              itemCount={formattedOrdersOrderedWithErrors.length}
              itemSize={48}
            >
              {OrderRenderer}
            </List>
          )}
        </AutoSizer>
      </SelectOrdersTable>

      <PatrimonyInfosContainer>
        <PreviousPatrimony>
          <span>Saldo Anterior do Patrimônio</span>
          <Currency
            value={patrimony}
            color={theme.colors.success}
          />
        </PreviousPatrimony>
        <PreviousPatrimony>
          <span>Novo Saldo</span>
          <Currency
            value={patrimony + totalImportValue}
            color={theme.colors.success}
          />
        </PreviousPatrimony>
      </PatrimonyInfosContainer>
    </Container>
  )
}

const mapStateToProps = (state: IState) => ({
  orders: state.actives.orders,
  wallets: state.wallet.wallets,
  tesouro_wallets: state.tesouro.tesouro_wallets,
  crypto_wallets: state.crypto.crypto_wallets,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(SelectRows)
