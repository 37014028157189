import React from 'react';
import { Switch, useLocation, Route, Redirect, useRouteMatch } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

import PrivateRoute from './PrivateRoute';

import WalletList from '../pages/Dashboard/WalletList';
import Wallet from '../pages/Dashboard/Wallets/Wallet';
import TesouroWallet from '../pages/Dashboard/Wallets/TesouroWallet';
import Valuation from '../pages/Dashboard/Valuation';
import Invest from '../pages/Dashboard/Invest';
import ActiveList from '../pages/Dashboard/ActiveList';
import MainPage from '../pages/Dashboard/MainPage';
import ActivesChart from '../pages/Dashboard/ActivesChart';
import Import from '../pages/Dashboard/Import';
import Earnings from '../pages/Dashboard/Earnings';
import Extract from '../pages/Dashboard/Extract';
import StockInfos from '../pages/Dashboard/StockInfo';

const DashboardRouter: React.FC = () => {
  const location = useLocation();

  const { url } = useRouteMatch();

  return (
    <AnimatePresence>
      <Switch location={location}>
        <PrivateRoute path={`${url}`} exact component={MainPage} isPrivate />
        <PrivateRoute path={`${url}/wallets`} component={WalletList} isPrivate />
        <PrivateRoute path={`${url}/investir`} component={Invest} isPrivate />
        <PrivateRoute path={`${url}/valuation`} component={Valuation} isPrivate />
        <PrivateRoute path={`${url}/wallet/:id`} component={Wallet} isPrivate />
        <PrivateRoute path={`${url}/tesouro-wallet/:id`} component={TesouroWallet} isPrivate />
        <PrivateRoute path={`${url}/actives`} exact component={ActiveList} isPrivate />
        <PrivateRoute path={`${url}/actives/chart`} component={ActivesChart} isPrivate />
        <PrivateRoute path={`${url}/import`} component={Import} isPrivate />
        <PrivateRoute path={`${url}/earnings`} component={Earnings} isPrivate />
        <PrivateRoute path={`${url}/extract`} component={Extract} isPrivate />
        <PrivateRoute path={`${url}/stocks/:id`} component={StockInfos} isPrivate />

        <Route render={() => <Redirect to="/dashboard" />} />
      </Switch>
    </AnimatePresence>
  )
}

export default DashboardRouter;
