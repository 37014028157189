import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  Logo,
  Form,
  ButtonsContainer,
  RotatedBox,
  RotatedBox2,
  TermsBox,
} from './styles';

import { useHistory } from 'react-router-dom';
import Button from '../../components/Button';
import Loading from '../../components/Loading';
import NumberFormat from 'react-number-format';
import { useTheme } from 'styled-components';
import api from '../../services/api';
import { createToastMessage } from '../../store/modules/toast/actions';
import LogoSrc from '../../assets/images/illustrations/logo.png';
import axios from 'axios';
import { Box, Input } from '../../components';
import { Container } from '../LogIn/styles';

const Register: React.FC = () => {
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [name, setName] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();
  const theme = useTheme();
  const dispatch = useDispatch();

  const handleRegister = async () => {
    setIsLoading(true);

    try {
      const formattedPhone = phone.replaceAll('(', '').replaceAll(')', '').replaceAll('-', '').replaceAll(' ', '').replaceAll('+55', '');

      await api.post('/register', {
        email,
        phone: formattedPhone,
        name,
        password,
      });

      dispatch(createToastMessage({
        title: 'Conta criada com sucesso!',
        created_at: new Date(),
        isPined: false,
        type: 'success',
      }));

      history.push('/');
    } catch (err) {
      if (axios.isAxiosError(err)) {
        dispatch(createToastMessage({
          title: err?.response?.data?.message || 'Erro interno em nosso servidor. Por favor tente novamente mais tarde',
          created_at: new Date(),
          isPined: false,
          type: 'error',
        }));
      }
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Container>
      <Box bg={theme.colors.primaryDark} flex={1} style={{ position: 'relative', overflow: 'hidden' }}>
        <Box borderRadius='100%' style={{ position: 'absolute', bottom: -50, left: -50 }} w={'250px'} h={'250px'} bg={theme.colors.primary} />
        <RotatedBox />
        <RotatedBox2 />
      </Box>
      <Box alignItems='center' justifyContent='center' p={'32px'} flexDirection='column'>
        <Logo>
          <img src={LogoSrc} alt="wllt" />
          <h1>Criar Conta</h1>
          <span>Registrando-se no wllt você gerencia todos os seus investimentos</span>
        </Logo>

        <Form>
          <NumberFormat
            format="(##) #####-####"
            placeholder="Telefone com DDD"
            mask="_"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setPhone(e.target.value)
            }}
          />
          <Box mb={'16px'} />
          <Input
            value={name}
            placeholder='Seu nome'
            callback={e => setName(e)}
          />
          <Box mb={'16px'} />
          <Input
            value={email}
            placeholder='Seu e-mail'
            callback={e => setEmail(e)}
          />
          <Box mb={'16px'} />
          <Input
            type='password'
            value={password}
            placeholder='Senha'
            callback={(e) => setPassword(e)}
          />
          <Box mb={'16px'} />
          <TermsBox>
            <input type="checkbox" id="terms" checked={acceptedTerms} onClick={() => setAcceptedTerms(!acceptedTerms)} />
            <Box mr={'8px'} />
            <label htmlFor="terms">Li e aceito os <a target="_blank" href="https://drive.google.com/file/d/1i_ggkkb-GSu09ObkY9W0JT7xlBCXF2yS/view?usp=sharing">Termos de Uso</a> e <a href="https://drive.google.com/file/d/1cTim9YOV_u-5Htxe5GROaN-VgwRfj_Xz/view?usp=sharing" target="_blank">Política de Privacidade</a></label>
          </TermsBox>
          <Box mb={'16px'} />
          <ButtonsContainer>
            <Button
              type='submit'
              revision='primary'
              onClick={handleRegister}
              disabled={isLoading || !acceptedTerms}
            >{
            isLoading ? (
              <Loading color={theme.colors.background} size={8} />
            ) : 'Cadastrar'
          }</Button>
          </ButtonsContainer>
        </Form>

        <Box mb={'0.8rem'} />
        <Button onClick={() => history.push('/login')}>Já possuo uma conta</Button>
      </Box>
    </Container>
  );
};


export default Register
