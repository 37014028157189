import styled, { css } from 'styled-components';

export const Container = styled.div`
${({ theme }) => css`
  background: ${theme.colors.background};
  border-radius: 1.6rem;
  padding: 5.6rem 4.8rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`}
`

export const Header = styled.div`
${({ theme }) => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-bottom: 5.6rem;
`}
`

export const Title = styled.h1`
${({ theme }) => css`
  font-weight: bold;
  font-size: ${theme.fontSizes.medium_large};
  color: ${theme.colors.white};

  margin-bottom: 1.6rem;
`}
`

export const ThirdTitle = styled.div`
${({ theme }) => css`
  max-width: 75%;
  text-align: center;
  color: ${theme.colors.white};
  font-size: ${theme.fontSizes.default};
`}
`

export const Content = styled.div`
${({ theme }) => css`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
`}
`

export const Subtitle = styled.div`
${({ theme }) => css`
  margin-bottom: 2.4rem;
  max-width: 50%;
  text-align: center;
  color: ${theme.colors.white};
  font-size: ${theme.fontSizes.huge};
`}
`

export const Footer = styled.div`
${({ theme }) => css`
  margin-top: 4rem;
  align-items: center;
  display: flex;

  > button:first-child {
    margin-right: 1.6rem;
  }
`}
`

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;

  > svg {
    margin-right: 0.8rem;
  }
`
