import React from 'react';
import { FiFile } from 'react-icons/fi';
import { useTheme } from 'styled-components';
import Currency from '../../../../components/Currency';
import Percentage from '../../../../components/Percentage';
import { DEFAULT_TRANSITION } from '../../../../constants';

import {
  Container,
  Header,
  TotalInvested,
  TotalValue,
  ProfitPercentage,
} from './styles';

type ActiveProps = {
  isDarkTheme: boolean;
  totalInvested: number;
  totalCurrent: number;
  totalProfit: number;
  totalProfitInPercentage: number;
}

const cardsAnimation = {
  unMounted: { x: -25, opacity: 0 },
  mounted: {
    x: 0,
    opacity: 1,
  },
}

const Active: React.FC<ActiveProps> = ({
  isDarkTheme = false,
  totalInvested,
  totalCurrent,
  totalProfit,
  totalProfitInPercentage,
}) => {
  const theme = useTheme();

  return (
    <Container
      key={`card-${1}`}
      variants={cardsAnimation}
      transition={DEFAULT_TRANSITION}
      whileHover={{ x: -2 }}
      layout
      isDarkTheme={isDarkTheme}
    >
      <Header>
        <FiFile size={18} />
        <h1>Total</h1>
      </Header>

      <Header />
      <Header />
      <Header />


      <TotalInvested>
        <Currency value={totalInvested} />
      </TotalInvested>
      <TotalValue>
        <Currency value={totalCurrent} />
      </TotalValue>

      <ProfitPercentage>
        <Percentage
          value={totalProfitInPercentage}
          color={totalProfitInPercentage < 0 ? theme.colors.error : theme.colors.success}
        />
      </ProfitPercentage>

      <ProfitPercentage>
        <Currency
          value={totalProfit}
          color={totalProfitInPercentage < 0 ? theme.colors.error : theme.colors.success}
        />
      </ProfitPercentage>
    </Container>
  );
};

export default Active;
