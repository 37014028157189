import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import Modal from '../../../components/Modal';
import api from '../../../services/api';

import { IState } from '../../../store';
import IntegrateInvestments from './IntegrateInvestments';
import OnboardingCreateWallets from './OnboardingCreateWallets';

import * as Styled from './styles';
import WelcomePage from './WelcomePage';

interface OnboardingProps {
  setIsOnboardingOn: (b: boolean) => void;
  isOnboardingOn: boolean;
}

enum Steps {
  WELCOME = 'WELCOME',
  CREATE_WALLETS = 'CREATE_WALLETS',
  INTEGRATE_INVESTMENTS = 'INTEGRATE_INVESTMENTS'
}

const Onboarding: React.FC<OnboardingProps> = ({ isOnboardingOn, setIsOnboardingOn }) => {

  const [step, setStep] = useState({
    index: 0,
    type: Steps.WELCOME,
  });

  const steps = [
    {
      index: 0,
      type: Steps.WELCOME,
    },
    {
      index: 1,
      type: Steps.CREATE_WALLETS
    },
    {
      index: 2,
      type: Steps.INTEGRATE_INVESTMENTS
    }
  ];

  const handleNextStep = () => {
    const nextStep = steps[step.index + 1];
    setStep(nextStep);
  };

  const handleCloseOnboarding = async () => {
    await api.patch('/', {
      props: {
        hasDismissedOnboarding: true,
      }
    })

    setIsOnboardingOn(false)
  }

  return (
    <Modal
      isVisible={isOnboardingOn}
      closeModal={() => setIsOnboardingOn(false)}
    >
      <Styled.Container>
        {step.type === Steps.WELCOME && (
          <WelcomePage
            handleNextStep={handleNextStep}
            handleCloseOnboarding={handleCloseOnboarding}
          />
        )}

        {step.type === Steps.CREATE_WALLETS && (
          <OnboardingCreateWallets
            handleNextStep={handleNextStep}
            handleCloseOnboarding={handleCloseOnboarding}
          />
        )}

        {step.type === Steps.INTEGRATE_INVESTMENTS && (
          <IntegrateInvestments
            handleCloseOnboarding={handleCloseOnboarding}
          />
        )}
      </Styled.Container>
    </Modal>
  )
}

const mapStateToProps = (state: IState) => ({ })

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Onboarding)
