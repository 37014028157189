import React, { useMemo } from 'react';
import { FiChevronRight } from 'react-icons/fi';
import { connect } from 'react-redux';
import { useTheme } from 'styled-components';

import { IState } from '../../../../store';

import {
  Container,
  MainContent,
} from './styles';

type DropdownProps = {
  icon: React.ReactNode;
  current_page: string;
  sectionName: string;
  dropdownId: string;
  onClick(): void;
}

const Dropdown: React.FC<DropdownProps> = ({ icon, sectionName, current_page, dropdownId, onClick }) => {
  const theme = useTheme();
  const handleClickOnDropdown = () => {
    onClick();
  }

  const isPageSelected = useMemo(() => {
    return current_page === dropdownId;
  }, [current_page]);

  return (
    <Container>
      <MainContent
        revision={"transparent"}
        onClick={handleClickOnDropdown}
        isSelected={isPageSelected}
      >
        <div>
          {icon}
        </div>
        {sectionName}
        <FiChevronRight
          size={16}
          color={isPageSelected ? theme.colors.primary : theme.colors.grey}
        />
      </MainContent>
    </Container>
  );
};

const mapStateToProps = (state: IState) => ({
  current_page: state.sideBarNavigator.current_page,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Dropdown)
