import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FiX } from 'react-icons/fi';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Dispatch } from 'redux';
import Button from '../../../../../components/Button';
import Modal from '../../../../../components/Modal';
import api from '../../../../../services/api';
import { fetchData } from '../../../../../services/data';
import { IState } from '../../../../../store';
import { IWallet } from '../../../../../store/modules/wallet/types';
import ChooseImportType from './ChooseImportType';
import FinalScreen from './FinalScreen';
import Footer from './Footer';
import ImportFile from './ImportFile';
import MatchFileColumns, { MatchingColumns, PredefinedFileTypes } from './MatchFileColumns';
import SelectRows from './SelectRows';
import SelectWallets from './SelectWallets';

import {
  Container,
  Header,
  ModalTitle,
  CloseButton,
  FinalConclusionButton,
} from './styles';

export interface StatusType {
  SELL: string[];
  BUY: string[];
}

interface ImportCentralModalProps {
  isVisible: boolean;
  closeModal(): void;
  wallets: IWallet[],
}

export interface FormattedOrder {
  id: string;
  status: string;
  date: Date;
  name: string;
  amount: number;
  price: number;
  isChecked: boolean;
  stock_id: string;
  wallet_id?: string;
}

export enum ImportSteps {
  CHOOSE_IMPORT_TYPE = 'CHOOSE_IMPORT_TYPE',
  IMPORT_FILE = 'IMPORT_FILE',
  MATCH_FILE_COLUMNS = 'MATCH_FILE_COLUMNS',
  SELECT_ROWS = 'SELECT_ROWS',
  SELECT_WALLETS = 'SELECT_WALLETS',
  FINAL_SCREEN = 'FINAL_SCREEN',
}

export enum ImportTypes {
  MANUAL = 'MANUAL',
  INTEGRATION = 'INTEGRATION'
}

const INITIAL_MATCHED_COLUMNS = {
  'Status': '',
  'Data da Operação': '',
  'Ativo': '',
  'Quantidade': '',
  'Valor': '',
}

const ImportCentralModal: React.FC<ImportCentralModalProps> = ({ isVisible, closeModal, wallets }) => {
  const [currentStep, setCurrentStep] = useState<ImportSteps>(ImportSteps.CHOOSE_IMPORT_TYPE);
  const [importType, setImportType] = useState(ImportTypes.MANUAL);
  const [file, setFile] = useState<File | null>(null);
  const [fileContentRaw, setFileContentRaw] = useState<any>([]);
  const [matchedColumns, setMatchedColumns] = useState<MatchingColumns>(INITIAL_MATCHED_COLUMNS);
  const [formattedOrders, setFormattedOrders] = useState<FormattedOrder[]>([]);
  const [financialProduct, setFinancialProduct] = useState('Ações, FIIs, BDRs, ETFs Nacionais');
  const [predefinedType, setPredefinedType] = useState('');

  const [initialWalletValues, setInitialWalletValues] = useState(Object.fromEntries(wallets.map(wallet => ([wallet.id, wallet.current_wallet_value]))));

  const [statusKeywords, setStatusKeywords] = useState<StatusType>({
    SELL: ['venda'],
    BUY: ['compra'],
  })

  const [isNextStepEnabled, setIsNextStepEnabled] = useState({
    message: '',
    isEnabled: true,
  });

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    setMatchedColumns(INITIAL_MATCHED_COLUMNS);
    setFormattedOrders([]);
    setPredefinedType('');
  }, [file])

  const steps = useMemo(() => {
    return [
      {
        id: ImportSteps.CHOOSE_IMPORT_TYPE,
        label: 1,
      },
      {
        id: ImportSteps.IMPORT_FILE,
        label: 2,
      },
      {
        id: ImportSteps.MATCH_FILE_COLUMNS,
        label: 3,
      },
      {
        id: ImportSteps.SELECT_ROWS,
        label: 4,
      },
      {
        id: ImportSteps.SELECT_WALLETS,
        label: 5,
        isFinalStep: true,
      },
      {
        id: ImportSteps.FINAL_SCREEN,
      },
    ]
  }, []);

  const currentStepIndex = useMemo(() => {
    return steps.findIndex(s => s.id === currentStep);
  }, [steps, currentStep]);

  const handleSelectPredefinedType = (predefinedType: string) => {
    if (predefinedType === PredefinedFileTypes.CEI) {
      setFileContentRaw((state: any) => {
        const newState = [...state].filter(order => order['Movimentação'] === 'Transferência - Liquidação' || order['Movimentação'] === 'Desdobro' || order['Movimentação'] === 'Bonificação em Ativos');

        return newState.map((order) => {
          let status = order['Entrada/Saída'];

          if (order['Movimentação'] === 'Desdobro') {
            status = 'Desdobro';
          }

          if (order['Movimentação'] === 'Bonificação em Ativos') {
            status = 'Bonificação em Ativos';
          }

          return {
            ...order,
            'Entrada/Saída': status,
          }
        })
      });

      setStatusKeywords({
        SELL: ['debito'],
        BUY: ['credito', 'desdobro', 'bonificacao em ativos'],
      });
    } else if (predefinedType === PredefinedFileTypes.CLEAR) {
      setStatusKeywords({
        SELL: ['venda'],
        BUY: ['compra'],
      });
    }
  }

  const handleMoveToNextStep = useCallback(async () => {
    const currentStep = steps[currentStepIndex];
    const nextStep = steps[currentStepIndex + 1];

    if (currentStep.isFinalStep) {
      setIsLoading(true);

      const stocks = formattedOrders.map((order) => ({
        name: order.name,
        stock_id: order.stock_id,
        price: Math.round(+order.price),
        amount: Math.floor(order.amount),
        averagePrice: 0,
        type: statusKeywords.BUY.includes(order.status.toLowerCase()) ? 'buy' : 'sell',
        date: order.date,
        wallet_id: order.wallet_id,
      }));

      await api.post('/stocks/create-orders', { stocks })

      await fetchData({ dispatch });

      setIsLoading(false);
    }

    setCurrentStep(nextStep.id);
  }, [steps, currentStepIndex, formattedOrders])

  const handleMoveBackStep = useCallback(() => {
    const nextStep = steps[currentStepIndex - 1];

    setCurrentStep(nextStep.id);
  }, [steps, currentStepIndex]);

  const handleFinishImport = useCallback(() => {
    setFile(null);
    setFileContentRaw([]);
    setMatchedColumns(INITIAL_MATCHED_COLUMNS);
    setFormattedOrders([]);
    setFinancialProduct('Ações, FIIs, BDRs, ETFs Nacionais');
    setPredefinedType('');
    setIsLoading(false);
    setIsNextStepEnabled({
      message: '',
      isEnabled: true,
    });

    setInitialWalletValues(Object.fromEntries(wallets.map(wallet => ([wallet.id, wallet.current_wallet_value]))));

    closeModal();
    setCurrentStep(ImportSteps.CHOOSE_IMPORT_TYPE);
    history.push('/dashboard');
  }, [wallets]);

  return (
    <Modal
      isVisible={isVisible}
      closeModal={closeModal}
    >
      <Container>
        <Header>
          <ModalTitle>Assistente de Importação</ModalTitle>

          <CloseButton
            onClick={closeModal}
          >
            <FiX
              size={16}
            />
          </CloseButton>
        </Header>

        {currentStep === ImportSteps.CHOOSE_IMPORT_TYPE && (
          <ChooseImportType
            setImportType={setImportType}
            handleMoveToNextStep={handleMoveToNextStep}
          />
        )}

        {currentStep === ImportSteps.IMPORT_FILE && (
          <ImportFile
            setIsNextStepEnabled={setIsNextStepEnabled}
            file={file}
            setFile={setFile}
            setFileContentRaw={setFileContentRaw}
            setFinancialProduct={setFinancialProduct}
            financialProduct={financialProduct}
          />
        )}

        {currentStep === ImportSteps.MATCH_FILE_COLUMNS && (
          <MatchFileColumns
            handleSelectPredefinedType={handleSelectPredefinedType}
            setIsNextStepEnabled={setIsNextStepEnabled}
            fileContentRaw={fileContentRaw}
            setMatchedColumns={setMatchedColumns}
            matchedColumns={matchedColumns}
            setPredefinedType={setPredefinedType}
            predefinedType={predefinedType}
          />
        )}

        {currentStep === ImportSteps.SELECT_ROWS && (
          <SelectRows
            statusKeywords={statusKeywords}
            setIsNextStepEnabled={setIsNextStepEnabled}
            fileContentRaw={fileContentRaw}
            matchedColumns={matchedColumns}
            setFormattedOrders={setFormattedOrders}
            formattedOrders={formattedOrders}
          />
        )}

        {currentStep === ImportSteps.SELECT_WALLETS && (
          <SelectWallets
            statusKeywords={statusKeywords}
            setIsNextStepEnabled={setIsNextStepEnabled}
            formattedOrders={formattedOrders}
            setFormattedOrders={setFormattedOrders}
          />
        )}

        {currentStep === ImportSteps.FINAL_SCREEN && (
          <FinalScreen
            formattedOrders={formattedOrders}
            initialWalletValues={initialWalletValues}
          />
        )}

        {currentStep !== ImportSteps.FINAL_SCREEN && (
          <Footer
            isLoading={isLoading}
            currentStepIndex={currentStepIndex}
            steps={steps}
            currentStep={currentStep}
            handleMoveToNextStep={handleMoveToNextStep}
            handleMoveBackStep={handleMoveBackStep}
            isNextStepEnabled={isNextStepEnabled}
          />
        )}

        {currentStep === ImportSteps.FINAL_SCREEN && (
          <FinalConclusionButton>
            <Button
              onClick={handleFinishImport}
              revision="primary"
            >
              Finalizar
            </Button>
          </FinalConclusionButton>
        )}
      </Container>
    </Modal>
  )
}

const mapStateToProps = (state: IState) => ({
  wallets: state.wallet.wallets,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(ImportCentralModal)
