import axios, { AxiosError } from 'axios';
import { storageKey } from '../hooks/useLocalStorage';

type FailedRequestQueueItem = {
  onSuccess: (token: string) => void;
  onFailure: (error: AxiosError) => void;
};

let isRefreshing = false
let failedRequestQueue: FailedRequestQueueItem[] = []

const api = axios.create({
  baseURL: 'https://dwallet.api.jorgehabib.com',
});

// const api = axios.create({
//   baseURL: 'http://localhost:3333',
// });

// const api = axios.create({
//   baseURL: 'http://192.168.1.21:3333',
// });

api.interceptors.response.use(response => {
  return response
}, (error: AxiosError) => {
  if (error.response?.status === 401) {
    const refreshToken = localStorage.getItem(storageKey('refresh_token'));

    if (!isRefreshing && refreshToken) {
      isRefreshing = true

      api.post('/session/refresh-token', {
        token: JSON.parse(refreshToken),
      }).then(response => {
        localStorage.setItem(storageKey('token'), JSON.stringify(response.data.token));
        localStorage.setItem(storageKey('refresh_token'), JSON.stringify(response.data.refresh_token));

        api.defaults.headers.authorization = `Bearer ${response.data.token}`

        failedRequestQueue.forEach(request => request.onSuccess(response.data.token))
        failedRequestQueue = []
      }).catch(err => {
        failedRequestQueue.forEach(request => request.onFailure(err))
        failedRequestQueue = []
      }).finally(() => {
        isRefreshing = false
      })
    }

    return new Promise((resolve, reject) => {
      failedRequestQueue.push({
        onSuccess: (token: string) => {
          error.config.headers['Authorization'] = `Bearer ${token}`
          resolve(api(error.config))
        },
        onFailure: (err: AxiosError) => {
          reject(err)
        }
      })
    })
  } else {
    throw error;
  }
})

export default api;
