import axios, { CancelTokenSource } from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { useTheme } from 'styled-components';
import { DEFAULT_TRANSITION } from '../../../constants';
import useEventListener from '../../../hooks/useEventListener';
import api from '../../../services/api';
import { IState } from '../../../store';
import Loading from '../../Loading';
import CryptoOption from './CryptoOption';

import {
  Container,
  Input,
  OptionsContainer,
  ActiveTypes,
  Tag,
} from './styles';

interface ICryptoSearchBarResponse {
  crypto: ICryptoNameOption;
  inputValue: string;
}

interface CryptoSearchBarProps {
  placeholder?: string;
  callback(d: ICryptoSearchBarResponse): void;
  shouldShowClasses?: boolean;
  type?: CryptoSearchBarTypes;
}

type CryptoSearchBarTypes = "primary" | "secondary";

export interface ICryptoNameOption {
  id: string;
  complete_name: string;
  name: string;
  type: string;
  current_price: number;
  current_profit: number;
}

const optionsAnimation = {
  unMounted: { height: '0', opacity: 0 },
  mounted: {
    height: 'auto',
    opacity: 1,
    transition: {
      ...DEFAULT_TRANSITION,
    }
  },
}

const StockSearchBar:React.FC<CryptoSearchBarProps> = ({
  shouldShowClasses,
  callback,
  placeholder,
  type: SearchBarType,
}) => {
  const theme = useTheme();

  const cancelRequestToken = useRef<CancelTokenSource | undefined>();

  const [isOpened, setIsOpened] = useState(false);
  const [stockNamesOptions, setStockNamesOptions] = useState<ICryptoNameOption[]>([] as ICryptoNameOption[]);
  const [inputTextValue, setInputTextValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [hasSelectedCrypto, setHasSelectedCrypto] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  const [isSmallInput, setIsSmallInput] = useState(false);

  const onFocus = () => {
    if (!hasSelectedCrypto) {
      setIsOpened(true);
      getAutoCompleteNames(inputTextValue);
    }
  }

  async function getAutoCompleteNames(name: string) {
    setIsLoading(true);

    if (cancelRequestToken.current) {
      cancelRequestToken.current.cancel();
    }

    cancelRequestToken.current = axios.CancelToken.source();

    const response = await api.get(`/crypto/search-crypto?name=${name.toUpperCase()}`, {
      headers: {
        cancelToken: cancelRequestToken.current.token,
      }
    });

    setStockNamesOptions(response.data);
    setIsLoading(false);
  }

  useEffect(() => {
    if (dropdownRef.current) {
      setIsSmallInput(dropdownRef.current.clientWidth < 300);
    }
  }, [])

  const handleCloseDropdown = useCallback(({ target }: Event): void => {
    if (dropdownRef.current?.contains(target as Node)) {
      return;
    }

    setIsOpened(false);
  }, [setIsOpened]);

  useEventListener('click', handleCloseDropdown, {
    enabled: isOpened,
  });

  const handleEditInput = (e: any) => {
    setHasSelectedCrypto(false);
    setInputTextValue(e.target.value);
    setIsOpened(true);
    getAutoCompleteNames(e.target.value);

    callback({
      crypto: {
        id: '',
        name: '',
        complete_name: '',
        current_price: 0,
        current_profit: 0,
        type: '',
      },
      inputValue: inputTextValue,
    });
  }

  const handleClickStock = (stockOption: ICryptoNameOption) => {
    callback({
      crypto: stockOption,
      inputValue: inputTextValue,
    });

    setInputTextValue(`${stockOption.name} - ${stockOption.complete_name}`);
    setIsOpened(false);
    setHasSelectedCrypto(true);
  }

  return (
    <Container
      ref={dropdownRef}
      type={SearchBarType}
      isOpened={!!stockNamesOptions.length && isOpened}
    >
      <FiSearch
        color={isOpened || inputTextValue ? theme.colors.primary : theme.colors.grey}
      />
      <Input
        hasSelected={hasSelectedCrypto}
        onFocus={onFocus}
        placeholder={placeholder}
        value={inputTextValue}
        onChange={e => handleEditInput(e)}
      />

      {isLoading && (
        <Loading
          color={theme.colors.primary}
          size={8}
        />
      )}

      {isOpened && !hasSelectedCrypto && (
        <OptionsContainer
          variants={optionsAnimation}
          padding={isOpened}
          type={SearchBarType}
          isOpened={!!stockNamesOptions.length && isOpened}
        >
          {shouldShowClasses && (
            <ActiveTypes>
              <Tag active>Em alta</Tag>
              <Tag>Tudo</Tag>
              <Tag>Ativos</Tag>
            </ActiveTypes>
          )}

          {!!stockNamesOptions.length && (
            <p>Ativos</p>
          )}

          {stockNamesOptions.map(stock => (
            <CryptoOption
              onClick={() => handleClickStock(stock)}
              complete_name={isSmallInput ? '' : stock.complete_name}
              short_name={stock.name}
              price={stock.current_price}
              variation={stock.current_profit}
              isSmallInput={isSmallInput}
            />
          ))}
        </OptionsContainer>
      )}
    </Container>
  )
}

const mapStateToProps = (state: IState) => ({})

const mapDispatchToProps = (dispatch: Dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(StockSearchBar)
