import React, { useMemo, useState } from 'react'
import { useTable } from 'react-table'

import * as Styled from './styles';

import { endOfDay, format, isBefore } from 'date-fns';
import { IProfit, ProfitTypeTranslator } from '../../../../../store/modules/profit/types';
import { IState } from '../../../../../store';
import { connect } from 'react-redux';
import Currency from '../../../../../components/Currency';
import { useSellsProfits } from '../../../../../hooks/react-query/useSellsProfits';
import { useTheme } from 'styled-components';
import { Box } from '../../../../../components/Box';
import Percentage from '../../../../../components/Percentage';
import { useOrders, IOrder } from '../../../../../hooks/react-query/useOrders';
import { FiTrash2, FiEdit } from 'react-icons/fi';
import Button from '../../../../../components/Button';
import { Show } from '../../../../../components/Show';
import { StockEmblem } from '../../../../../components/StockEmblem';
import { useTesouroWallet } from '..';

export const TesouroProfitList: React.FC = () => {
  const { wallet } = useTesouroWallet();
  const theme = useTheme()

  const [reverseFilter, setReverseFilter] = useState(false);
  const [filter, setFilter] = useState('created_at');

  const columns = useMemo(() => {
    return (
      [
        {
          Header: 'Data',
          accessor: 'created_at',
          collapse: true,
        },
        {
          Header: 'Código',
          accessor: 'name',
        },
        {
          Header: 'Valor Investido',
          accessor: 'purchase_value',
        },
        {
          Header: 'Valor Atual',
          accessor: 'current_value',
        },
        {
          Header: 'Lucro (R$)',
          accessor: 'profit',
        },
        {
          Header: 'Lucro (%)',
          accessor: 'profit_in_percentage',
        },
      ]
    )
  }, []);

  const orderedTreasures = useMemo(() => {
    if (!wallet.tesouros.length) return []

    const orderedTesouros = [...wallet.tesouros]

    orderedTesouros.sort((a: any, b: any) => {
      const bigger = reverseFilter ? 1 : -1;
      const smaller = bigger * -1;

      if (a[filter] > b[filter]) {
        return bigger;
      }
      if (a[filter] < b[filter]) {
        return smaller;
      }

      return 0;
    });


    return orderedTesouros;
  }, [wallet, reverseFilter, filter]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
  } = useTable(
    {
      columns: columns as any,
      data: orderedTreasures,
    }
  )

  const orderTableByColumn = (columnName: string) => {
    const column = columns.find(c => c.accessor === columnName)

    if (filter === columnName) {
      setReverseFilter(v => !v)
    }

    if (column) {
      setFilter(columnName);
    }
  }

  return (
    <Styled.Container>
      <Styled.TableWrap className="tableWrap">
        <Styled.Table {...getTableProps()}>
          <Styled.Head>
            {headerGroups.map(headerGroup => (
              <Styled.HeadRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <Styled.HeadData
                    {...column.getHeaderProps({
                      className: column.collapse ? 'collapse' : '',
                    })}
                  >
                    <Styled.HeaderLabel onClick={() => orderTableByColumn(column.id)}>
                      <Show when={!column.hide}>
                        {column.render('Header')}
                      </Show>
                    </Styled.HeaderLabel>
                  </Styled.HeadData>
                ))}
              </Styled.HeadRow>
            ))}
          </Styled.Head>
          <Styled.TableBody {...getTableBodyProps()}>
            {rows.map((row: any, i: number) => {
              prepareRow(row)
              return (
                <Styled.HeadRow {...row.getRowProps()}>
                  {row.cells.map((cell: any) => {
                    return (
                      <Styled.Content
                        {...cell.getCellProps({
                          className: cell.column.collapse ? 'collapse' : '',
                        })}
                      >
                        {cell.column.Header === 'Data' && (
                          <span>{cell.value ? format(new Date(cell.value), 'dd/MM/yyyy') : '-'}</span>
                        )}

                        {cell.column.Header === 'Código' && (
                          <Box alignItems='center'>
                            <Show when={row.original?.image_url}>
                              <StockEmblem
                                image_url={row.original?.image_url}
                              />
                            </Show>
                            <span>{cell.value}</span>
                          </Box>
                        )}

                        {cell.column.Header === 'Valor Investido' && (
                          <Styled.SellResult>
                            <Currency
                              color={cell.value > 0 ? theme.colors.success : theme.colors.error}
                              value={cell.value}
                              type='flat'
                            />
                          </Styled.SellResult>
                        )}

                        {cell.column.Header === 'Valor Atual' && (
                          <Styled.SellResult>
                            <Currency
                              color={cell.value > 0 ? theme.colors.success : theme.colors.error}
                              value={cell.value}
                              type='flat'
                            />
                          </Styled.SellResult>
                        )}

                        {cell.column.Header === 'Lucro (R$)' && (
                          <Styled.SellResult>
                            <Currency
                              color={cell.value > 0 ? theme.colors.success : theme.colors.error}
                              value={cell.value}
                              type='flat'
                            />
                          </Styled.SellResult>
                        )}

                        {cell.column.Header === 'Lucro (%)' && (
                          <Styled.SellResult>
                            <Percentage
                              color={cell.value > 0 ? theme.colors.success : theme.colors.error}
                              value={cell.value}
                              type='variation'
                            />
                          </Styled.SellResult>
                        )}
                      </Styled.Content>
                    )
                  })}
                </Styled.HeadRow>
              )
            })}
          </Styled.TableBody>
        </Styled.Table>
      </Styled.TableWrap>
    </Styled.Container>
  )
}
