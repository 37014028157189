import React from 'react';
import { AuthProvider } from './auth';
import { ModalsProvider } from './modals';

const AppCustomProviders: React.FC = ({ children }) => {
  return (
    <React.Fragment>
      <AuthProvider>
        <ModalsProvider>
          {children}
        </ModalsProvider>
      </AuthProvider>
    </React.Fragment>
  )
}

export default AppCustomProviders;
