import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { useTheme } from 'styled-components';
import { DEFAULT_TRANSITION } from '../../../constants';
import useEventListener from '../../../hooks/useEventListener';
import api from '../../../services/api';
import { IState } from '../../../store';
import { ITesouroWallet } from '../../../store/modules/tesouroWallet/types';
import { IWallet } from '../../../store/modules/wallet/types';
import WalletOption from './WalletOption';

import {
  Container,
  Input,
  OptionsContainer,
  ActiveTypes,
  Tag,
} from './styles';
import { ICryptoWallet } from '../../../store/modules/crypto/types';

interface IWalletSearchBarResponse {
  wallet: IWalletOption;
  inputValue: string;
}

interface WalletSearchBarProps {
  placeholder?: string;
  callback(d: IWalletSearchBarResponse): void;
  type?: StockSearchBarTypes;
  wallets: IWallet[];
  valueId?: string;
  tesouro_wallets: ITesouroWallet[];
  crypto_wallets: ICryptoWallet[];
}

type StockSearchBarTypes = "primary" | "secondary" | "tertiary";

export interface IWalletOption {
  id: string;
  name: string;
  value: number;
  current_profit: number;
  type: string;
}

const optionsAnimation = {
  unMounted: { height: '0', opacity: 0 },
  mounted: {
    height: 'auto',
    opacity: 1,
    transition: {
      ...DEFAULT_TRANSITION,
    }
  },
}

const WalletSearchBar:React.FC<WalletSearchBarProps> = ({
  callback,
  placeholder,
  type: SearchBarType,
  wallets,
  valueId,
  tesouro_wallets,
  crypto_wallets,
}) => {
  const theme = useTheme();

  const [isOpened, setIsOpened] = useState(false);
  const [inputTextValue, setInputTextValue] = useState('');
  const [selectedWalletId, setSelectedWalletId] = useState(valueId);

  const dropdownRef = useRef<HTMLDivElement>(null);

  const [isSmallInput, setIsSmallInput] = useState(false);

  const onFocus = () => {
    setIsOpened(true);
  }

  useEffect(() => {
    setSelectedWalletId(valueId);

    const selectedWallet = wallets.find(wallet => wallet.id === valueId);

    setInputTextValue(selectedWallet?.name || '');
  }, [valueId]);

  useEffect(() => {
    if (dropdownRef.current) {
      setIsSmallInput(dropdownRef.current.clientWidth < 300);
    }
  }, [])

  const walletsFormatted = useMemo(() => {
    const formattedWallets = wallets.map((wallet) => ({
      id: wallet.id,
      name: wallet.name,
      value: wallet.current_wallet_value,
      current_profit: wallet.profit_in_percentage,
      dollar: false,
      type: 'STOCKS',
    }));

    const formattedTesouroWallets = tesouro_wallets.map((wallet) => ({
      id: wallet.id,
      name: wallet.name,
      value: wallet.current_wallet_value,
      current_profit: wallet.profit_in_percentage,
      dollar: false,
      type: 'TESOURO',
    }));

    const formattedCryptoWallets = crypto_wallets.map((wallet) => ({
      id: wallet.id,
      name: wallet.name,
      value: wallet.current_wallet_value_in_dollars * 100,
      current_profit: wallet.profit_in_percentage * 1000,
      dollar: true,
      type: 'CRYPTO',
    }));

    return [
      ...formattedWallets,
      ...formattedTesouroWallets,
      ...formattedCryptoWallets,
    ];
  }, [wallets, tesouro_wallets]);

  const walletsFormattedFiltered = useMemo(() => {
    const walletsWithMatchingNames = walletsFormatted.filter(wallet => wallet.name.toLowerCase().includes(inputTextValue.toLowerCase()));

    return walletsWithMatchingNames;
  }, [walletsFormatted, inputTextValue]);

  const handleCloseDropdown = useCallback(({ target }: Event): void => {
    if (dropdownRef.current?.contains(target as Node)) {
      return;
    }

    setIsOpened(false);
  }, [setIsOpened]);

  useEventListener('click', handleCloseDropdown, {
    enabled: isOpened,
  });

  const handleEditInput = (e: any) => {
    setInputTextValue(e.target.value);
    setSelectedWalletId('');

    callback({
      wallet: {
        id: '',
        name: '',
        value: 0,
        current_profit: 0,
        type: '',
      },
      inputValue: inputTextValue,
    });
  }

  const handleClickWallet = (walletOption: IWalletOption) => {
    callback({
      wallet: walletOption,
      inputValue: inputTextValue,
    });

    setSelectedWalletId(walletOption.id);
    setInputTextValue(walletOption.name);
    setIsOpened(false);
  }

  return (
    <Container
      ref={dropdownRef}
      type={SearchBarType}
      isOpened={isOpened && !!inputTextValue}
      hasSelectedValue={!!selectedWalletId}
    >
      <FiSearch
        color={isOpened || inputTextValue ? theme.colors.primary : theme.colors.grey}
      />
      <Input
        onFocus={onFocus}
        placeholder={placeholder}
        value={inputTextValue}
        onChange={e => handleEditInput(e)}
      />

      {isOpened && (
        <OptionsContainer
          variants={optionsAnimation}
        >
          <p>Carteiras</p>

          {walletsFormattedFiltered.map(wallet => (
            <WalletOption
              onClick={() => handleClickWallet(wallet)}
              name={wallet.name}
              profit={wallet.current_profit}
              value={wallet.value}
              isSmallInput={isSmallInput}
              dollar={wallet.dollar}
            />
          ))}
        </OptionsContainer>
      )}
    </Container>
  )
}

const mapStateToProps = (state: IState) => ({
  wallets: state.wallet.wallets,
  tesouro_wallets: state.tesouro.tesouro_wallets,
  crypto_wallets: state.crypto.crypto_wallets,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(WalletSearchBar)
