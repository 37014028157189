import { useQuery } from '@tanstack/react-query'
import api from '../../services/api'

export interface IStockRank {
  id: string;
  stock_id: string;
  name: string;
  previous_close: number;
  price: number;
  todayGrowthInPercentage: number;
  todayProfit: number
}

export interface IStocksTodayRank {
  todayTotalVariation: number;
  totalNegativeVariation: number;
  totalPositiveVariation: number;
  positiveRank: IStockRank[]
  negativeRank: IStockRank[]
}

export const useStocksTodayRank = () => {
  const query = useQuery(['stockRank'], async () => {
    const response = await api.get<IStocksTodayRank>('/dashboard/get-stocks-rank')

    return response.data
  })

  return query
}
