import React from 'react';
import { useMediaQuery } from 'react-responsive';

import {
  Container,
} from './styles';

interface LabelRowProps {
  onChangeFilter(str: string): void;
}

const LabelRow: React.FC<LabelRowProps> = ({ onChangeFilter }) => {
  const isSmallDevice = useMediaQuery({ query: '(max-width: 670px)' });

  return (
    <Container>
      <th onClick={() => { onChangeFilter('name') }}>
        Nome
      </th>
      <th onClick={() => { onChangeFilter('price') }}>
        Preço atual
      </th>
      <th onClick={() => { onChangeFilter('valuation') }}>
        Valuation
      </th>
      {
        !isSmallDevice && (
          <th onClick={() => { onChangeFilter('div_yield') }}>
            Dividend Yield
          </th>
        )
      }
      <th onClick={() => { onChangeFilter('percentage') }}>
        Pot. Valorização %
      </th>
    </Container>
  );
}

export default LabelRow;
