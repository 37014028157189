import { IInvestmentInfos } from './index';

export default function calculateNewPrice(stockInfos: IInvestmentInfos[]): number {
  let total = 0;

  stockInfos.forEach(stock => {
    total += stock.papers * stock.unitPrice;
  });

  return total;
}
