import React from 'react';
import { FiCreditCard, FiDollarSign, FiFile } from 'react-icons/fi';
import { useTheme } from 'styled-components';

import Currency from '../../../../components/Currency';
import Percentage from '../../../../components/Percentage';

import {
  Container,
  WalletHeader,
  WalletSummary,
  WalletContent,
} from './styles';

type WalletCardProps = {
  name: string;
  type: string;
  description: string;
  amountInvested: number;
  value: number;
  profit: number;
  profit_in_percentage: number;
  onClick(): void;
}

const WalletCard: React.FC<WalletCardProps> = ({ name, type, description, amountInvested, value, onClick, profit, profit_in_percentage }) => {
  const theme = useTheme();

  return (
    <Container
      onClick={onClick}
    >
      <WalletHeader>
        {type === 'STOCK' && (
          <FiCreditCard />
        )}
        {type === 'TESOURO' && (
          <FiFile />
        )}
        {type === 'CRYPTO' && (
          <FiDollarSign />
        )}
        <h4>{name}</h4>
      </WalletHeader>

      <WalletContent>
        <WalletSummary>
          <div>
            <span>Total investido</span>
            <Currency value={amountInvested} />
          </div>
          <div>
            <span>Valor da Carteira</span>
            <Currency value={value} />
          </div>
        </WalletSummary>

        <WalletSummary>
          <div>
            <p>Lucro</p>
            <Currency
              value={profit}
              color={profit > 0 ? theme.colors.success : theme.colors.error}
            />
          </div>
          <div>
            <p>Lucro</p>
            <Percentage
              value={profit_in_percentage}
              color={profit > 0 ? theme.colors.success : theme.colors.error}
            />
          </div>
        </WalletSummary>

      </WalletContent>
    </Container>
  );
};

export default WalletCard;
