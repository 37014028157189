import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled(motion.div)`
${({ theme }) => css`
  max-width: ${theme.spacing.mainWidth};
  width: 100%;

  display: flex;
  flex-direction: column;

  @media (max-width: 670px) {
    max-width: 100%;
  }
`}
`;

export const AddValuation = styled.div<{ isAddOpened: boolean }>`
${({ theme, isAddOpened }) => css`
  background: ${theme.colors.background};

  border-radius: 0.8rem;
  padding: 1.6rem;


  margin-bottom: 0.8rem;
  width: 100%;

  ${!isAddOpened && css`
      height: 100%;

      display: flex;
      align-items: center;
      justify-content: center;
    `
    }

  box-shadow: ${theme.shadows.default};

  > button:first-child {
    display: flex;
    align-items: center;

    background: 0;
    color: ${theme.colors.grey};
    font-size: ${theme.fontSizes.large};
    font-weight: bold;

    > svg {
      margin-right: 0.4rem;
      color: ${theme.colors.primary};
    }
  }
`}
`;

export const InnerAddValuationForms = styled.div<{ disabled?: boolean }>`
${({ theme, disabled }) => css`
  display: flex;
  width: 100%;

  > div {
    display: flex;
    flex-direction: column;
    padding: 0.8rem;

    > button {
      align-self: flex-end;

      padding: 0.8rem;

      border: 0;
      background: ${theme.colors.primary};
      color: ${theme.colors.lightGrey};

      font-size: ${theme.fontSizes.tiny};

      border-radius: 0.2rem;

      ${disabled && css`
        opacity: 0.2;
      `}

      width: 100%;
      height: 4rem;

      margin-top: auto;
    }
  }

  > div {
    > div:first-child {
      margin-bottom: 1.6rem;
    }

    > div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      > label {
        font-size: ${theme.fontSizes.small};

        margin-bottom: 0.8rem;
        margin-top: 0.4rem;
      }

      > input {
        border: 0;
        background: ${theme.colors.lightGrey};

        /* max-width: 70%; */

        padding: 0.8rem 0.8rem;

        color: ${theme.colors.grey};
      }

      > input::placeholder {
        color: ${theme.colors.softGrey};
      }
    }
  }
`}
`;

export const ValuationList = styled(motion.table)`
  table-layout: fixed;
`;

export const TopMenu = styled.div`
${({ theme }) => css`
  display: flex;

  > div:first-child {
    margin-right: 0.8rem;
  }

  @media (max-width: 670px) {
    flex-direction: column;
  }
`}
`;

export const Separator = styled.div`
${({ theme }) => css`
  margin-top: 2.4rem;

  height: 1px;
  width: 100%;
  background: ${theme.colors.grey};
  opacity: 0.5;
`}
`;

export const CustomInput = styled.div<{ isFocused: boolean }>`
${({ theme, isFocused }) => css`
  margin-top: 1.6rem;
  padding: 0.8rem;

  max-width: 30%;

  transition: 0.5s;

  ${isFocused ? css`
    border: 1px solid ${theme.colors.primary};
  `: css`
    border: 1px solid ${theme.colors.darkGrey};
  `}

  > svg:hover {
    transition: 0.5s;
    color: ${theme.colors.primary};
  }

  border-radius: 0.2rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  > input {
    background: transparent;
    border: 0;
    color: ${theme.colors.grey};
    font-size: ${theme.fontSizes.default};
  }

  > svg {
    cursor: pointer;
  }

  @media (max-width: 670px) {
    max-width: 100%;
  }
`}
`;

export const ValuationHeader = styled.thead<{ tbodyScrollWidth: number }>`
${({ tbodyScrollWidth }) => css`
  display: block;
  width: 100%;
  padding-right: ${tbodyScrollWidth}px;
`}
`;

export const ValuationContent = styled.tbody`
${({ theme }) => css`
  height: 40rem;
`
}`

export const AddValuationRow = styled.tr`
${({ theme }) => css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 1.6rem;
  margin-bottom: 0.8rem;

  background: ${theme.colors.background};
  box-shadow: ${theme.shadows.default};

  border-radius: 0.8rem;

  > td {
    display: flex;
    align-items: center;

    width: 100%;

    > svg {
      margin-right: 0.8rem;
      color: ${theme.colors.primary};
    }
  }

  > td:first-child {
    width: 200%;

    > div {
      width: 80%;
      height: 4rem;
    }
  }
`}
`;

export const SearchValuationContainer = styled.div`
${({ theme }) => css`
  background: ${theme.colors.background};
  padding: 1.6rem;

  border-radius: 0.8rem;
`}
`;
