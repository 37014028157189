import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FiPlus, FiEdit2, FiX } from 'react-icons/fi';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { FiFileText } from 'react-icons/fi'

import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

import { IState } from '../../../store';
import { IValuation } from '../../../store/modules/valuation/types';
import { createToastMessage } from '../../../store/modules/toast/actions';

import CurrencyInput from '../../../components/CurrencyInput';
import Loading from '../../../components/Loading';

import { animations } from '../../../resources';

import orderValuations from './orderValuations';
import LabelRow from './LabelRow';
import ValuationStock from './ValuationStock';

import {
  Container,
  SearchValuationContainer,
  ValuationHeader,
  ValuationList,
  ValuationContent,
  Separator,
  CustomInput,
  AddValuationRow,
} from './styles';

import { fetchValuations } from '../../../services/valuation';
import api from '../../../services/api';
import Button from '../../../components/Button';
import StockSearchBar from '../../../components/SearchBars/StockSearchBar';

interface IStockNameOption {
  id: string;
  complete_name: string;
  name: string;
  sector: string;
  type: string;
}

const containerAnimation = {
  ...animations.containerAnimation,
  mounted: {
    ...animations.containerAnimation.mounted,
    transition: {
      ...animations.containerAnimation.mounted.transition,
      duration: undefined,
    }
  }
}

const cardsAnimation = {
  unMounted: { y: 25, opacity: 0 },
  mounted: {
    y: 0,
    opacity: 1,
  },
};

const AnimatedCard = motion.div;

const Valuation: React.FC = () => {
  const valuations = useSelector<IState, IValuation[]>(state => state.valuations.valuations);

  const theme = useTheme();

  const [isLoading, setIsLoading] = useState(false);
  const [stockName, setStockName] = useState('');
  const [stockValuation, setStockValuation] = useState('');
  const [reverseFilter, setReverseFilter] = useState(false);
  const [filterString, setFilterString] = useState('valuation');
  const [filterByName, setFilterByName] = useState('');
  const [searchInputIsFocused, setSearchInputIsFocused] = useState(false);

  const [stockNamesOptions, setStockNamesOptions] = useState<IStockNameOption[]>([] as IStockNameOption[]);
  const [isAutocompleteLoading, setIsAutocompleteLoading] = useState(false);

  const [tbodyScrollWidth, setTBodyScrollWidth] = useState(0);

  const tbodyRef= useRef<HTMLTableSectionElement>(null);

  const dispatch = useDispatch();

  async function getAutoCompleteNames(name: string) {
    setIsAutocompleteLoading(true);

    const response = await api.get(`/stocks/autocomplete?name=${name}`);

    setIsAutocompleteLoading(false);
    setStockNamesOptions(response.data);
  }

  useEffect(() => {
    if (tbodyRef.current) {
      const scrollBarWidth = tbodyRef.current.offsetWidth - tbodyRef.current.clientWidth;
      setTBodyScrollWidth(scrollBarWidth)
    }

    getAutoCompleteNames('');
  }, [dispatch]);

  const handleChangeFilter = (newFilter: string) => {
    if (filterString === newFilter) {
      setReverseFilter(!reverseFilter);
      return;
    }

    setFilterString(newFilter);
  }

  const handleAddStockValuation = async () => {
    setIsLoading(true);

    try {
      await api.post('/valuation/create-update', {
        name: stockName,
        valuation: Number((Number(stockValuation) * 100).toFixed()),
      });

      await fetchValuations(dispatch);

      dispatch(createToastMessage({
        title: 'Valuation adicionado!',
        created_at: new Date(),
        isPined: false,
        type: 'success',
      }));
    } catch (err) {
      dispatch(createToastMessage({
        title: 'Erro ao adicionar valuation.',
        created_at: new Date(),
        isPined: false,
        type: 'error',
      }));

      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }

  const valuationOrdered = useMemo(() => {
    const bigger = reverseFilter ? 1 : -1;
    const valuationsArray = [...valuations];

    let newValuations = orderValuations(valuationsArray, filterString ,bigger);

    newValuations = newValuations.filter(valuation => valuation.name.includes(filterByName));

    return newValuations;
  }, [valuations, reverseFilter, filterString, filterByName]);

  const isAddOrUpdateButtonDisabled = useMemo(() => {
    return isLoading || !stockName || !stockValuation;
  }, [isLoading, stockName, stockValuation]);

  const ValuationItemRenderer = ({ index, style }: any) => {
    return (
      <ValuationStock
        style={{
          ...style,
          top: style.top + 8,
          height: style.height - 8
        }}
        key={valuationOrdered[index].name}
        name={valuationOrdered[index].name}
        valuation={valuationOrdered[index].valuation}
        currentPrice={valuationOrdered[index].price}
        dividend_yield={valuationOrdered[index].div_yield}
      />
    )
  }

  return (
    <Container
      variants={containerAnimation}
    >
      <AnimatedCard
        key={`card-${1}`}
        variants={cardsAnimation}
      >
        <SearchValuationContainer>
          <label>
            Pesquise por ações
          </label>
          <CustomInput
            isFocused={searchInputIsFocused}
          >
            <input
              placeholder={"Escolha sua ação"}
              onChange={(e) => setFilterByName(e.target.value.toUpperCase())}
              value={filterByName}
              onFocus={() => setSearchInputIsFocused(true)}
              onBlur={() => setSearchInputIsFocused(false)}
            />
            <FiX
              onClick={() => setFilterByName('')}
            />
          </CustomInput>
        </SearchValuationContainer>
      </AnimatedCard>

      <Separator />

      <ValuationList>
        <ValuationHeader
          tbodyScrollWidth={tbodyScrollWidth}
        >
          <LabelRow onChangeFilter={(str: string) => { handleChangeFilter(str) }}/>
        </ValuationHeader>

        <ValuationContent
          ref={tbodyRef}
        >
          <AddValuationRow>
            <td>
              <StockSearchBar
                type="secondary"
                placeholder="Ativo"
                callback={({ stock }) => setStockName(stock.name)}
              />
            </td>

            <td>
              <CurrencyInput
                callBack={(e) => setStockValuation(e)}
              />
            </td>
            <td></td>
            <td>
              <Button
                onClick={handleAddStockValuation}
                revision="secondary"
                disabled={isAddOrUpdateButtonDisabled}
              >
                {isLoading ? (
                  <Loading
                    color={theme.colors.primary}
                    size={8}
                  />
                ) : (
                  'Adicionar ou Editar'
                )}
              </Button>
            </td>
          </AddValuationRow>

          <AutoSizer>
            {({ height, width}) => (
              <List
                height={height}
                width={width}
                itemCount={valuationOrdered.length}
                itemSize={56}
              >
                {ValuationItemRenderer}
              </List>
            )}
          </AutoSizer>
        </ValuationContent>
      </ValuationList>
    </Container>
  );
};

export default Valuation;
