import axios, { CancelTokenSource } from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { useTheme } from 'styled-components';
import { DEFAULT_TRANSITION } from '../../../constants';
import useEventListener from '../../../hooks/useEventListener';
import api from '../../../services/api';
import { IState } from '../../../store';
import TesouroOption from './TesouroOption';

import {
  Container,
  Input,
  OptionsContainer,
  ActiveTypes,
  Tag,
} from './styles';
import Loading from '../../Loading';

interface ITesouroSearchBarResponse {
  tesouro: ITesouroNameOption;
  inputValue: string;
}

interface TesouroSearchBarProps {
  placeholder?: string;
  callback(d: ITesouroSearchBarResponse): void;
  shouldShowClasses?: boolean;
  type?: StockSearchBarTypes;
}

type StockSearchBarTypes = "primary" | "secondary";

export interface ITesouroNameOption {
  id: string;
  name: string;
  lowercased_name: string;
  technical_name: string;
  rentability: number;
  current_buy_price: number;
  current_sell_price: number;
  type: string;
  expiration: string;
}

const optionsAnimation = {
  unMounted: { height: '0', opacity: 0 },
  mounted: {
    height: 'auto',
    opacity: 1,
    transition: {
      ...DEFAULT_TRANSITION,
    }
  },
}

const TesouroSearchBar:React.FC<TesouroSearchBarProps> = ({
  shouldShowClasses,
  callback,
  placeholder,
  type: SearchBarType,
}) => {
  const theme = useTheme();

  const cancelRequestToken = useRef<CancelTokenSource | undefined>();

  const [isOpened, setIsOpened] = useState(false);
  const [tesouroNamesOptions, setTesouroNamesOptions] = useState<ITesouroNameOption[]>([] as ITesouroNameOption[]);
  const [inputTextValue, setInputTextValue] = useState('');

  const [hasTesouroSelected, setHasTesouroSelected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  const [isSmallInput, setIsSmallInput] = useState(false);

  const onFocus = () => {
    if (!hasTesouroSelected) {
      setIsOpened(true);
      getAutoCompleteNames(inputTextValue);
    }
  }

  async function getAutoCompleteNames(name: string) {
    setIsLoading(true);

    if (cancelRequestToken.current) {
      cancelRequestToken.current.cancel();
    }

    cancelRequestToken.current = axios.CancelToken.source();

    const response = await api.get(`/tesouro/consult?name=${name.toLowerCase()}`, {
      headers: {
        cancelToken: cancelRequestToken.current.token,
      }
    });

    setIsLoading(false);
    setTesouroNamesOptions(response.data);
  }

  useEffect(() => {
    if (dropdownRef.current) {
      setIsSmallInput(dropdownRef.current.clientWidth < 300);
    }
  }, [])

  const handleCloseDropdown = useCallback(({ target }: Event): void => {
    if (dropdownRef.current?.contains(target as Node)) {
      return;
    }

    setIsOpened(false);
  }, [setIsOpened]);

  useEventListener('click', handleCloseDropdown, {
    enabled: isOpened,
  });

  const handleEditInput = (e: any) => {
    setInputTextValue(e.target.value);
    getAutoCompleteNames(e.target.value);
    setIsOpened(true);
    setHasTesouroSelected(false);

    callback({
      tesouro: {
        id: '',
        name: '',
        lowercased_name: '',
        technical_name: '',
        rentability: 0,
        current_buy_price: 0,
        current_sell_price: 0,
        type: '',
        expiration: '',
      },
      inputValue: inputTextValue,
    });
  }

  const handleClickTesouro = (tesouroOption: ITesouroNameOption) => {
    callback({
      tesouro: tesouroOption,
      inputValue: inputTextValue,
    });

    setInputTextValue(tesouroOption.name);
    setHasTesouroSelected(true);
    setIsOpened(false);
  }

  return (
    <Container
      ref={dropdownRef}
      type={SearchBarType}
      isOpened={isOpened && !!tesouroNamesOptions.length}
    >
      <FiSearch
        color={isOpened || inputTextValue ? theme.colors.primary : theme.colors.grey}
      />
      <Input
        hasSelected={hasTesouroSelected}
        onFocus={onFocus}
        placeholder={placeholder}
        value={inputTextValue}
        onChange={e => handleEditInput(e)}
      />

      {isLoading && (
        <Loading
          color={theme.colors.primary}
          size={8}
        />
      )}


      {isOpened && !hasTesouroSelected && (
        <OptionsContainer
          variants={optionsAnimation}
          padding={isOpened}
          type={SearchBarType}
          isOpened={!!tesouroNamesOptions.length && isOpened}
        >
          {shouldShowClasses && (
            <ActiveTypes>
              <Tag active>Em alta</Tag>
              <Tag>Tudo</Tag>
              <Tag>Ativos</Tag>
            </ActiveTypes>
          )}

          <p>Tesouros</p>

          {tesouroNamesOptions.map(tesouro => (
            <TesouroOption
              onClick={() => handleClickTesouro(tesouro)}
              name={tesouro.name}
              expiration={tesouro.expiration}
              type={tesouro.type}
              isSmallInput={isSmallInput}
            />
          ))}
        </OptionsContainer>
      )}
    </Container>
  )
}

const mapStateToProps = (state: IState) => ({})

const mapDispatchToProps = (dispatch: Dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(TesouroSearchBar)
