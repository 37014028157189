import { motion } from 'framer-motion';
import { transparentize } from 'polished';
import styled, { css } from 'styled-components';
import Button from '../../../../components/Button';

export const Container = styled(motion.div)`
${({ theme }) => css`
  width: 100%;

  margin-bottom: 2.4rem;

  > h1 {
    color: ${theme.colors.white};
    font-size: ${theme.fontSizes.huge};

    margin-bottom: 1.6rem;
  }

  > div {
    width: 100%;
    background: ${({ theme }) => transparentize(0.05, theme.colors.background)};
    border-radius: 0.8rem;

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 4.2rem;
  }
`}
`;

export const StyledButton = styled(Button)`
${({ theme }) => css`
  width: 96%;
  padding: 5.6em;
  border: solid 0.1rem ${theme.colors.primary};
  background: 0;

  display: flex;
  flex-direction: column;

  border-radius: 0.8rem;

  > svg {
    margin-bottom: 1.6rem;
  }

  font-size: ${theme.fontSizes.large};
  font-weight: bold;
  letter-spacing: 0.03em;
`}
`
