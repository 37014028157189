import React, { useCallback, useMemo, useRef, useState } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { connect } from 'react-redux';
import Checkbox from '../../../../../components/Checkbox';
import useEventListener from '../../../../../hooks/useEventListener';

import { IState } from '../../../../../store';
import { IWallet } from '../../../../../store/modules/wallet/types';

import * as Styled from './styles';

import isDescendant from '../../../../../utils/isDecendant';

interface WalletSelectionProps {
  walletFilter: string[]
  setWalletFilter(s: any): void;
  wallets: IWallet[]
}

const WalletSelection: React.FC<WalletSelectionProps> = ({
  wallets,
  walletFilter,
  setWalletFilter,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  const walletIds = useMemo(() => {
    return wallets.map((wallet) => wallet.id);
  }, [wallets]);

  const handleCloseDropdown = useCallback(({ target }: any): void => {
    if (isDescendant(dropdownRef.current as Node, target as Node)) {
      return;
    }

    if (dropdownRef.current?.contains(target as Node)) {
      return;
    }

    if (walletIds.includes(target.id)) {
      return;
    }

    setIsOpen(false);
  }, [setIsOpen]);

  useEventListener('click', handleCloseDropdown, {
    enabled: isOpen,
  });

  const handleSelectWallet = (walletId: string) => {
    setWalletFilter((state: string[]) => {
      const newWalletState: string[] = [];

      if (!state.includes(walletId)) {
        newWalletState.push(walletId);
      }

      state.forEach((wId: any) => {
        if (wId !== walletId) {
          newWalletState.push(wId);
        }
      });

      return newWalletState;
    })
  }

  return (
  <Styled.Container>
    <Styled.InputContainer
      ref={dropdownRef}
      isOpen={isOpen}
      >
      <Styled.MainContainer
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>
          Selecione suas carteiras
        </span>
        <FiChevronDown
          size={24}
        />
      </Styled.MainContainer>

      <Styled.WalletSelectionContainer
        isOpen={isOpen}
      >
        {isOpen && wallets.map((wallet) => (
          <Styled.Wallet
            onClick={() => handleSelectWallet(wallet.id)}
            key={wallet.id}
            id={wallet.id}
          >
            <Checkbox
              onClick={() => {}}
              isChecked={walletFilter.includes(wallet.id)}
              type='primary'
            />
            <span>{wallet.name}</span>
          </Styled.Wallet>
        ))}
      </Styled.WalletSelectionContainer>
    </Styled.InputContainer>
  </Styled.Container>
  )
}

const mapStateToProps = (state: IState) => ({
  wallets: state.wallet.wallets,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(WalletSelection)

