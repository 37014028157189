import React, { useState } from 'react';
import { FiAlertTriangle, FiTrash2, FiX } from 'react-icons/fi';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import Button from '../../../../../components/Button';
import Input from '../../../../../components/Input';
import Loading from '../../../../../components/Loading';
import Modal from '../../../../../components/Modal';
import api from '../../../../../services/api';
import { fetchData } from '../../../../../services/data';
import { IState } from '../../../../../store';

import {
  Container,
  Heading,
  Body,
} from './styles';

interface ExcludeWalletModalProps {
  name: string;
  id: string;
  isVisible: boolean;
  closeModal(): void;
  email?: string;
  phone?: string;
}

const ExcludeWalletModal: React.FC<ExcludeWalletModalProps> = ({ name, id, email, phone, isVisible, closeModal }) => {
  const [walletName, setWalletName] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleDeleteWallet = async  () => {
    setIsLoading(true);

    await api.delete(`/tesouro/delete/${id}`);

    await fetchData({ dispatch });

    setIsLoading(false);
    closeModal();

    history.push('/dashboard/wallets');
    // api call
  }

  const handleCloseModal = () => {
    closeModal();
    setWalletName('');
  }

  return (
    <Modal
      isVisible={isVisible}
      closeModal={closeModal}
    >
      <Container>
        <Heading>
          <div>
            <FiAlertTriangle
              size={22}
            />
            <h1>
              Exclusão da carteira {name}
            </h1>
          </div>
          <div>
            <FiX
              onClick={handleCloseModal}
            />
          </div>
        </Heading>

        <p>Ao excluir sua carteira, todas as suar ordens e ativos presentes nessa carteira também serão excluídos!</p>

        <Body>
          <p>
            Digite o nome da carteira para confirmar sua exclusão
          </p>
          <Input
            callback={x => setWalletName(x)}
            value={walletName}
            placeholder={name}
          />
        </Body>

        <Button
          onClick={handleDeleteWallet}
          revision="secondary"
          disabled={walletName !== name}
        >
          {
            isLoading ? (
              <Loading
                color={theme.colors.error}
                size={6}
              />
            ) : (
              <>
                <FiTrash2 />
                Apagar Carteira
              </>
            )
          }
        </Button>
      </Container>
    </Modal>
  );
};

const mapStateToProps = (state: IState) => ({
  email: state.user.user?.email,
  phone: state.user.user?.phone,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ExcludeWalletModal)
