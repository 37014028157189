import React from 'react';
import ActivesRank from './ActivesRank';

import EarningsByMonthGraph from './EarningsByMonthGraph';
import EarningsByTypeGraph from './EarningsByTypeGraph';
import EarningsByWalletGraph from './EarningsByWalletGraph';
import EarningsHistory from './EarningsHistory';
import FutureEarnings from './FutureEarnings';

import Heading from './Heading';
import SectorRank from './SectorRank';

import {
  Container,
  Content,
  FirstRow,
  SecondRow,
  SideColumn,
  MainColumn,
} from './styles';

const Earnings = () => {
  return (
    <Container>
      <Heading />

      <Content>
        <FirstRow>
          <EarningsByMonthGraph />

          <SideColumn>
            <EarningsByWalletGraph />
            <EarningsByTypeGraph />
          </SideColumn>
        </FirstRow>

        <SecondRow>
          <MainColumn>
            <EarningsHistory />
            <SecondRow>
              <ActivesRank />
              <SectorRank />
            </SecondRow>
          </MainColumn>

          <SideColumn>
            <FutureEarnings />
          </SideColumn>
        </SecondRow>
      </Content>
    </Container>
  )
}

export default Earnings;
