export interface IToast {
  title: string;
  content?: string;
  type: IToastTypes;
  isPined: boolean;
  created_at: Date;
}

type IToastTypes = 'info' | 'error' | 'success' | 'wallet-investment';

export interface IToastState {
  toastMessages: IToast[];
}

export enum ToastTypes {
  CREATE_TOAST_MESSAGE = 'CREATE_TOAST_MESSAGE',
  DELETE_TOAST_MESSAGE = 'DELETE_TOAST_MESSAGE',
  TOGGLE_PIN_TOAST = 'TOGGLE_PIN_TOAST',
}
