import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

export const Container = styled.button<{ bold?: boolean }>`
${({ theme, bold }) => css`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;

  background: ${theme.colors.white};
  color: ${theme.colors.success};
  font-weight: bold;
  font-size: ${theme.fontSizes.default};

  position: relative;

  > span {
    ${bold && css`
      letter-spacing: 0.2rem;
    `}
  }
`}
`;

export const DropdownContainer = styled.div`
${({ theme }) => css`
  position: absolute;
  top: 100%;
  left: -1rem;
  width: calc(100% + 2rem);
  padding: 0.8rem;

  background: ${theme.colors.white};

  max-height: 16rem;
  overflow-y: scroll;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
`}
`;

export const DropdownOption = styled.button`
${({ theme }) => css`
  text-align: left;
  padding: 0.4rem 0.8rem;

  background: ${theme.colors.white};
  color: ${theme.colors.success};
  font-weight: bold;

  font-size: ${theme.fontSizes.default};

  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  :hover {
    background: ${transparentize(0.75, theme.colors.grey)};
  }
`}
`;

export const OptionLabel = styled.div<{ bold?: boolean }>`
${({ theme, bold }) => css`
  ${bold && css`
    letter-spacing: 0.2rem;
  `}
`}
`;
