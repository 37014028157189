import React, { useMemo, useState } from 'react'
import { useTable } from 'react-table'

import * as Styled from './styles';

import { format, isBefore } from 'date-fns';
import { ProfitTypeTranslator } from '../../store/modules/profit/types';
import { Currency, Percentage } from '../../components';

interface IColumn {
  Header: string; // Name of the column that appears
  accessor: string; // Key of the object to get the value
}

export interface TableProps {
  columns: IColumn[]
  data: any // Data can have any format, and this format should be the same as the one used in the accessor
  renderCells: (params: { headerName: string, columnValue: any }) => JSX.Element
}

export const Table: React.FC<TableProps> = ({ columns, data, renderCells }) => {
  const [reverseFilter, setReverseFilter] = useState(false);
  const [filter, setFilter] = useState('created_at');

  const orderedData = useMemo(() => {
    return data.sort((a: any, b: any) => {
      if (isBefore(a[filter], b[filter])) {
        return reverseFilter ? 1 : -1;
      }
      if (isBefore(b[filter], a[filter])) {
        return reverseFilter ? -1 : 1;
      }

      return 0;
    });
  }, [])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
  } = useTable(
    {
      columns: columns as any,
      data: orderedData,
    }
  )

  return (
    <Styled.Container>
      <Styled.TableWrap className="tableWrap">
        <Styled.Table {...getTableProps()}>
          <Styled.Head>
            {headerGroups.map(headerGroup => (
              <Styled.HeadRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <Styled.HeadData
                    {...column.getHeaderProps({
                      className: column.collapse ? 'collapse' : '',
                    })}
                  >
                    {column.render('Header')}
                  </Styled.HeadData>
                ))}
              </Styled.HeadRow>
            ))}
          </Styled.Head>
          <Styled.TableBody {...getTableBodyProps()}>
            {rows.map((row: any, i: number) => {
              prepareRow(row)
              return (
                <Styled.HeadRow {...row.getRowProps()}>
                  {row.cells.map((cell: any) => {
                    return (
                      <Styled.Content
                        {...cell.getCellProps({
                          className: cell.column.collapse ? 'collapse' : '',
                        })}
                      >
                        {renderCells({
                          headerName: cell.column.Header,
                          columnValue: cell.value,
                        })}
                      </Styled.Content>
                    )
                  })}
                </Styled.HeadRow>
              )
            })}
          </Styled.TableBody>
        </Styled.Table>
      </Styled.TableWrap>
    </Styled.Container>
  )
}

