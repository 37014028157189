import styled, { css } from 'styled-components';
import Button from '../../../../components/Button';

export const Container = styled.div`
${({ theme }) => css`
  background: ${theme.colors.background};
  border-radius: 1.6rem;
  padding: 5.6rem 4.8rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`}
`

export const Header = styled.div`
${({ theme }) => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-bottom: 2.4rem;
`}
`

export const WlltIconContainer = styled.div`
  margin-bottom: 10.2rem;
`;

export const Title = styled.h1`
${({ theme }) => css`
  font-weight: bold;
  font-size: ${theme.fontSizes.large};
  color: ${theme.colors.white};

  margin-bottom: 1.6rem;

  > strong {
    color: ${theme.colors.primary};
  }
`}
`

export const ThirdTitle = styled.div`
${({ theme }) => css`
  max-width: 75%;
  text-align: center;
  color: #525252;
  font-size: ${theme.fontSizes.default};

  margin-bottom: 3.2rem;
`}
`

export const Content = styled.div`
${({ theme }) => css`
  width: 100%;
`}
`

export const Subtitle = styled.div`
${({ theme }) => css`
  margin-bottom: 2.4rem;
  max-width: 75%;
  text-align: center;
  color: ${theme.colors.white};
  font-size: ${theme.fontSizes.default};
`}
`

export const Footer = styled.div`
${({ theme }) => css`
  margin-top: 4rem;
  align-items: center;
  display: flex;
`}
`

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;

  > svg {
    margin-right: 0.8rem;
  }
`

export const StepContainer = styled.div`
${({ theme }) => css`
  display: flex;
  align-items: center;
  flex-direction: row;

  margin-bottom: 2.4rem;
`}
`;

export const Step = styled.div<{ active?: boolean }>`
${({ theme, active }) => css`
  width: 1.6rem;
  height: 1.6rem;
  background: ${active ? theme.colors.primary : theme.colors.background};
  border-radius: 100%;

  ${active && css`
    box-shadow: 0px 2px 8px rgba(149, 237, 176, 0.25);
  `}

  border: solid 1px ${theme.colors.primary};
`}
`;

export const StepSeparator = styled.div`
${({ theme }) => css`
  height: 1px;
  width: 4rem;
  background: ${theme.colors.extremeLightGrey};
`}
`;

export const StyledButton = styled(Button)`
${({ theme }) => css`
  border-radius: 1.6rem;
  padding: 3.2rem;
  border: solid 1px ${theme.colors.primary};
`}
`;
