export default function shouldOperate(): boolean {
  const now = new Date();
  const weekDay = now.getDay();

  if (weekDay === 0 || weekDay === 6) {
    return false;
  }

  const utc_time = new Date(now.toUTCString());

  const OPENING_MARKET_IN_UTC = new Date();
  OPENING_MARKET_IN_UTC.setUTCHours(13);
  OPENING_MARKET_IN_UTC.setUTCMinutes(0);
  OPENING_MARKET_IN_UTC.setUTCSeconds(0);

  const CLOSING_MARKET_IN_UTC = new Date();
  CLOSING_MARKET_IN_UTC.setUTCHours(21);
  CLOSING_MARKET_IN_UTC.setUTCMinutes(0);
  CLOSING_MARKET_IN_UTC.setUTCSeconds(0);

  if (utc_time > OPENING_MARKET_IN_UTC && utc_time < CLOSING_MARKET_IN_UTC) {
    return true;
  }

  return false;
}
