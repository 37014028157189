import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect, RouteProps as DefaultRouteProps } from 'react-router-dom';
import { useAuth } from '../contexts/auth';
import { IState } from '../store';
import { IUserState } from '../store/modules/user/types';

interface RouteProps extends DefaultRouteProps {
  component: React.ComponentType;
  isPrivate?: boolean;
}

const PrivateRoute: React.FC<RouteProps> = ({ component: Component, isPrivate = false, ...rest }) => {
  const { isAuthenticated } = useAuth()

  if (isPrivate === !isAuthenticated) {
    return (
      <Redirect to={{
        pathname: isPrivate ? '/' : '/dashboard',
      }} />
    );
  }

  return (
    <Route
      {...rest}
      render={() => <Component />}/>
  )
}

export default PrivateRoute;
