import { MatchingColumns } from ".";

export const identifyStatus = (content: MatchingColumns, keys: string[]) => {
  return ''
}

export const identifyOperationDate = (rawOrders: MatchingColumns[], keys: string[]) => {
  const numberOfDatesPerColumn  = {} as any;

  keys.forEach((key) => {
    numberOfDatesPerColumn[key] = 0;
  })

  rawOrders.forEach((order) => {
    keys.forEach((key) => {
      if (!order[key] || !order[key].split) {
        return;
      }

      const numberOfBarsIsThree = order[key].split('/')?.length === 3;

      if (numberOfBarsIsThree) {
        numberOfDatesPerColumn[key] += 1;
      }
    });
  });

  let greatestKey = '';
  let greatestColumnIntegerCount = 0;

  keys.forEach((key) => {
    const keyCount = numberOfDatesPerColumn[key];
    if (keyCount > greatestColumnIntegerCount) {
      greatestKey = key;
      greatestColumnIntegerCount = keyCount;
    }
  });

  return greatestKey;
}

export const identifyActive = (content: MatchingColumns, keys: string[]) => {
  return ''
}

export const identifyAmount = (rawOrders: MatchingColumns[], keys: string[]) => {
  const numberOfIntegersPerColumn  = {} as any;

  keys.forEach((key) => {
    numberOfIntegersPerColumn[key] = 0;
  })

  rawOrders.forEach((order) => {
    keys.forEach((key) => {
      if (!!Number(order[key]) && Number.isInteger(Number(order[key]))) {
        numberOfIntegersPerColumn[key] += 1;
      }
    });
  });

  let greatestKey = '';
  let greatestColumnIntegerCount = 0;

  keys.forEach((key) => {
    const keyCount = numberOfIntegersPerColumn[key];
    if (keyCount > greatestColumnIntegerCount) {
      greatestKey = key;
      greatestColumnIntegerCount = keyCount;
    }
  });

  return greatestKey;
}

export const identifyValue = (content: MatchingColumns, keys: string[]) => {
  return ''
}



