import React, { useEffect, useMemo } from 'react';
import GenericSearchBar from '../../../../../../components/SearchBars/GenericSearchBar';
import { identifyActive, identifyAmount, identifyOperationDate, identifyStatus, identifyValue } from './columnsIdentifiers';
import { amountsValidator, datesValidator, dateValidator, namesValidator, nameValidator, valuesValidator, valueValidator } from '../columnsValidators';
import { handleIdentifyPredefinedFile, predefinedHandlerFunctions } from './predefinedFileTypes';
import SelectColumnInput from './SelectColumnInput';

import {
  Container,
  SelectPredefinedContent,
  Heading,
  Infos,
  MatchTable,
  TableHead,
  ColumnSelectionRow,
  ColumnToMatchRow,
  TableContent,
  ValuesRow,
} from './styles';
import { getStringDatesFromDates } from '../validateOrders';
import Currency from '../../../../../../components/Currency';

interface MatchFileColumnsProps {
  fileContentRaw: any;
  setMatchedColumns(x: MatchingColumns): void;
  matchedColumns: MatchingColumns;
  setPredefinedType(x: string): void;
  predefinedType: string;
  setIsNextStepEnabled(x: any): void;
  handleSelectPredefinedType(x: string): void;
}

export interface MatchingColumns {
  [key: string]: any
}

export enum PredefinedFileTypes {
  CLEAR = 'CLEAR',
  CEI = 'CEI',
};

const MatchFileColumns: React.FC<MatchFileColumnsProps> = ({
  matchedColumns,
  fileContentRaw,
  setMatchedColumns,
  predefinedType,
  setPredefinedType,
  setIsNextStepEnabled,
  handleSelectPredefinedType,
}) => {
  useEffect(() => {
    const hasActiveMatchedColumn = !!matchedColumns['Ativo'];
    const hasAmountMatchedColumn = !!matchedColumns['Quantidade'];
    const hasValueMatchedColumn = !!matchedColumns['Valor'];

    if (!hasActiveMatchedColumn) {
      setIsNextStepEnabled({
        isEnabled: false,
        message: 'Escolha a coluna correspondente ao NOME do Ativo',
      });
    } else if (!hasAmountMatchedColumn) {
      setIsNextStepEnabled({
        isEnabled: false,
        message: 'Escolha a coluna correspondente à QUANTIDADE do Ativo',
      });
    } else if (!hasValueMatchedColumn) {
      setIsNextStepEnabled({
        isEnabled: false,
        message: 'Escolha a coluna correspondente ao VALOR do Ativo',
      });
    } else {
      setIsNextStepEnabled({
        isEnabled: true,
        message: '',
      });
    }
  }, [matchedColumns]);

  const contentPreview = useMemo(() => {
    const statusColumnName = matchedColumns['Status']
    const dateColumnName = matchedColumns['Data da Operação']
    const nameColumnName = matchedColumns['Ativo']
    const amountColumnName = matchedColumns['Quantidade']
    const valueColumnName = matchedColumns['Valor']

    const statusValues = fileContentRaw.map((fileContent: any) => fileContent[statusColumnName]).slice(0, 3);
    const dateValues = fileContentRaw.map((fileContent: any) => fileContent[dateColumnName]).slice(0, 3);
    const nameValues = fileContentRaw.map((fileContent: any) => fileContent[nameColumnName]).slice(0, 3);
    const amountValues = fileContentRaw.map((fileContent: any) => fileContent[amountColumnName]).slice(0, 3);
    const valueValues = fileContentRaw.map((fileContent: any) => fileContent[valueColumnName]).slice(0, 3);

    const datesValidated = datesValidator(dateValues);
    const datesFormatted = getStringDatesFromDates(datesValidated);

    return {
      'Status': statusValues,
      'Data da Operação': datesFormatted,
      'Ativo': namesValidator(nameValues),
      'Quantidade': amountsValidator(amountValues),
      'Valor': valuesValidator(valueValues),
    }
  }, [matchedColumns, fileContentRaw]);

  const columnOptions = useMemo(() => {
    const element = fileContentRaw[fileContentRaw.length - 1];

    if (!element) return [];

    const columns = Object.entries(element).map(([key, value]) => key);

    return columns.filter(o => o !== 'id');
  }, [fileContentRaw]);


  const handleChangeColumnMatch = (column: string, newMatch: string) => {
    setMatchedColumns((state: any) => {
      const newState = { ...state };

      newState[column] = newMatch;

      return newState;
    })
  }

  const predefinedTypes = useMemo(() => {
    return [
      {
        id: PredefinedFileTypes.CLEAR,
        name: 'Clear'
      },
      {
        id: PredefinedFileTypes.CEI,
        name: 'Área Logada da B3'
      }
    ]
  }, []);

  const numberOfOrders = useMemo(() => {
    const nameColumnName = matchedColumns['Ativo']
    const nameValues = fileContentRaw.map((fileContent: any) => fileContent[nameColumnName]);

    return nameValues.length;
  }, [matchedColumns, matchedColumns]);

  useEffect(() => {
    setMatchedColumns({
      'Status': matchedColumns['Status'] || identifyStatus(fileContentRaw, columnOptions),
      'Data da Operação': matchedColumns['Data da Operação'] || identifyOperationDate(fileContentRaw, columnOptions),
      'Ativo': matchedColumns['Ativo'] || identifyActive(fileContentRaw, columnOptions),
      'Quantidade': matchedColumns['Quantidade'] || identifyAmount(fileContentRaw, columnOptions),
      'Valor': matchedColumns['Valor'] || identifyValue(fileContentRaw, columnOptions),
    });

    const shouldSearchForMatchedFile = !matchedColumns['Valor'] && !matchedColumns['Quantidade'] && !matchedColumns['Ativo']

    if (shouldSearchForMatchedFile) {
      const fileMatched = handleIdentifyPredefinedFile(columnOptions);

      if (fileMatched) {
        setPredefinedType(fileMatched);
      }
    }
  }, []);

  useEffect(() => {
    if (!predefinedType) return;

    if (predefinedType) {
      const predefinedHandlerFunction = predefinedHandlerFunctions[predefinedType as 'CEI' | 'CLEAR'];

      const newMatchedColumns = predefinedHandlerFunction();

      handleSelectPredefinedType(predefinedType);
      setMatchedColumns(newMatchedColumns);
    }
  }, [predefinedType]);

  return (
    <Container>
      <SelectPredefinedContent>
        <Heading>Selecione a fonte de dados da sua planilha</Heading>
        <GenericSearchBar
          callback={(e) => setPredefinedType(e.option.id)}
          options={predefinedTypes}
          valueId={predefinedType}
          placeholder="Escolha alguma planilha já pronta :)"
          type={"primary"}
        />
      </SelectPredefinedContent>

      <Infos>
        <h1>{numberOfOrders} Ordens foram reconhecidos nesse arquivo</h1>
        <span>Selecione abaixo as colunas da sua planilha que correspondem às informações solicitadas</span>
      </Infos>

      <MatchTable>
        <TableHead>
          <ColumnSelectionRow>
            <td>
              <SelectColumnInput
                matchingColumn={matchedColumns['Status']}
                setNewColumnName={(newMatch: string) => handleChangeColumnMatch('Status', newMatch)}
                options={columnOptions}
              />
            </td>
            <td>
              <SelectColumnInput
                matchingColumn={matchedColumns['Data da Operação']}
                setNewColumnName={(newMatch: string) => handleChangeColumnMatch('Data da Operação', newMatch)}
                options={columnOptions}
              />
            </td>
            <td>
              <SelectColumnInput
                matchingColumn={matchedColumns['Ativo']}
                setNewColumnName={(newMatch: string) => handleChangeColumnMatch('Ativo', newMatch)}
                options={columnOptions}
              />
            </td>
            <td>
              <SelectColumnInput
                matchingColumn={matchedColumns['Quantidade']}
                setNewColumnName={(newMatch: string) => handleChangeColumnMatch('Quantidade', newMatch)}
                options={columnOptions}
              />
            </td>
            <td>
              <SelectColumnInput
                matchingColumn={matchedColumns['Valor']}
                setNewColumnName={(newMatch: string) => handleChangeColumnMatch('Valor', newMatch)}
                options={columnOptions}
              />
            </td>
          </ColumnSelectionRow>

          <ColumnToMatchRow>
            <td>Compra ou venda</td>
            <td>Data da Operação</td>
            <td>Ativo</td>
            <td>Quantidade</td>
            <td>Valor</td>
          </ColumnToMatchRow>
        </TableHead>

        <TableContent>
          {
            contentPreview['Status'].map((_: any, index: number) => (
              <ValuesRow>
                <td>{contentPreview['Status'][index]}</td>
                <td>{contentPreview['Data da Operação'][index]}</td>
                <td>{contentPreview['Ativo'][index]}</td>
                <td>{contentPreview['Quantidade'][index]}</td>
                <td>
                  {
                    !contentPreview['Valor'][index] || Number.isNaN(+contentPreview['Valor'][index]) ? (
                      contentPreview['Valor'][index]
                    ) : (
                      <Currency
                        value={+contentPreview['Valor'][index]}
                      />
                    )
                  }
                </td>
              </ValuesRow>
            ))
          }
        </TableContent>
      </MatchTable>
    </Container>
  )
}

export default MatchFileColumns;
