import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

export const Container = styled.div`
${({ theme }) => css`
  background: 'transparent';
  width: 100%;
  /* padding: 2.4rem; */

  border-radius: 1.5rem 0.3rem 1rem 1.5rem;
  margin-right: 2.4rem;

  height: 100%;
  width: 100%;

  @media (max-width: 670px) {
    margin-bottom: 0.8rem;
  }
`}
`;
