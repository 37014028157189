import chroma from "chroma-js";

const getPallete = (firstTone: string, secondTone: string, amount: number) => {
  return chroma.scale([firstTone, secondTone]).colors(amount);
}

export const graphPallete = [
  '#00CCA3',
  '#4BC1FB',
  '#005FCC',
  '#5858DA',
  '#B150E2',
  '#FF3369',
  '#FF2E1F',
  '#FF8800',
  '#FFCC00',
]

export default getPallete;
