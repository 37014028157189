import React, { useMemo }  from 'react';
import { GraphWidget } from '../../../../components/Widgets/GraphWidget';
import { useStockHistoricalDividends, useStockProfile } from '../hooks';
import { useTheme } from 'styled-components';
import { useLocation } from 'react-router-dom';
import { DividendMonthlyChartSerie } from '../../../../store/modules/profit/types';
import formatCurrency from '../../../../utils/formatCurrency';

export const DividendsGraph = () => {
  const location = useLocation();
  const theme = useTheme();

  const [id] = location.pathname.split('/').reverse();
  const { data: profile, isLoading: isLoadingProfile } = useStockProfile(id)
  const { data: dividends, isLoading: isLoadingDividends } = useStockHistoricalDividends(id)

  const barChartData = useMemo<DividendMonthlyChartSerie[]>(() => {
    return [{
      name: `${profile?.name} - Dividendos`,
      type: 'column',
      data: dividends?.dividends.values.slice(-120).map((p) => p.y) || [],
    }];
  }, [profile, dividends]);

  const labels = useMemo(() => {
    return dividends?.dividends.values.slice(-120).map((p) => p.x) || []
  }, [dividends])

  const annotations = useMemo(() => {
    const points = [] as PointAnnotations[];

    dividends?.dividends.values.forEach((p, index) => {
      if (p.y) {
        let hasAnnotationNearBefore = false;

        if (index !== 0 && ((dividends?.dividends.values[index - 1].y - p.y) > 0.1 * p.y)) {
          hasAnnotationNearBefore = true;
        }

        const y = p.y + (0.05 * Math.random()) * p.y;
        const adjustedY = hasAnnotationNearBefore ? y - 10000 : y;


        points.push({
          x: new Date(p.x).getTime(),
          y: adjustedY,
          marker: {
            size: 0,
          },
          label: {
            text: formatCurrency(p.y / 100),
            borderColor: theme.colors.darkGrey,
            style: {
              background: theme.colors.grey,
              color: theme.colors.lightGrey,
              fontWeight: 'bold',
            }
          }
        });
      }
    });


    return points;
  }, [dividends]);


  return (
    <GraphWidget
      isLoading={isLoadingProfile || isLoadingDividends}
      title={`Dividendos Mensais de ${profile?.name}`}
      type={'timestampBarChart'}
      barChartData={barChartData}
      annotations={annotations}
      labels={labels}
    />
  )
}
