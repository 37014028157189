import styled, { css } from 'styled-components';

import { motion } from 'framer-motion';
import Button from '../../components/Button';

export const Container = styled.div`
${({ theme }) => css`
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  align-items: stretch;

  /* @media (max-width: 670px) {
    width: 100%;
    overflow: hidden;
  } */
`}
`;

export const Wrapper = styled(motion.form)`
${({ theme }) => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 3.2rem;
  background: ${theme.colors.background};

  min-width: 38.8rem;

  box-shadow: ${theme.shadows.default};
  border-radius: ${theme.radius.default};

  svg {
    margin-bottom: 1.6rem;

    width: 12rem !important;
    height: auto !important;
  }

  span {
    text-align: center;
    margin-bottom: 4rem;
  }

  input {
    background: ${theme.colors.lightGrey};
    border: 0;
    padding: 0.8rem;
    width: 100%;

    height: 4rem;

    border-radius: ${theme.radius.small};
    color: ${theme.colors.darkGrey};
    margin-bottom: 1.6rem;

    ::placeholder {
      opacity: 0.6;
    }
  }
`}
`;

export const StyledButton = styled(Button) <{ disabled?: boolean }>`
${({ theme, disabled }) => css`
  background: ${`linear-gradient(90deg, ${theme.colors.secondary} 0%, ${theme.colors.primary} 100%)`};

  height: 4rem;
  width: 100%;

  margin-bottom: 0.8rem;

  transition: 0.5s;
  :hover {
    opacity: 0.7;
  }

  ${disabled && css`
    opacity: 0.3;
    cursor: not-allowed;

    :hover {
      opacity: 0.3;
    }
  `}


`}
`;

export const Logo = styled.div`
${({ theme }) => css`
    display: flex;
    font-size: ${theme.fontSizes.huge};
    margin-bottom: ${theme.spacing.default};

    > img {
      width: 25rem;
    }
`}
`;
