import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

export const Container = styled.div`
${({ theme }) => css`
  height: 42rem;
  border-radius: 1.6rem;
  background: ${transparentize(0.05, theme.colors.background)};

  width: 50%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: 2rem;

  > div {
    margin-bottom: 1.6rem;
  }

  @media (max-width: 670px) {
    padding: 0.8rem;
    max-width: 95%;
    margin: 0 auto;
    width: 100%;
    margin-bottom: 1.6rem;
  }
`}
`;

export const GraphContainer = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;
