import React, { useMemo, useState, useEffect } from 'react';
import Chart from "react-apexcharts";
import CardTitle from '../../../../../components/CardTitle';

import {
  Container,
  GraphContainer,
} from './styles';
import formatCurrency from '../../../../../utils/formatCurrency';
import { useTheme } from 'styled-components';
import { graphPallete } from '../../../../../utils/colors';
import { Box } from '../../../../../components/Box';
import { useTesouroWallet } from '..';

export const TesourosDistributionChart: React.FC = () => {
  const theme = useTheme();
  const [display, setDisplay] = useState(false);

  const { wallet } = useTesouroWallet()

  useEffect(() => {
    setTimeout(() => setDisplay(true), 1);
  }, [])

  const data = useMemo(() => {
    const data = {} as any;

    wallet.tesouros.forEach(tesouro => {
      if (data[tesouro.name]) {
        data[tesouro.name] += tesouro.current_value;
      } else {
        data[tesouro.name] = tesouro.current_value;
      }
    });

    return data;
  }, [wallet])

  const pieChartData = useMemo(() => {
    return Object.entries(data).map(([key, value]: any) => value);
  }, []);

  const pieChartDataLabels = useMemo(() => {
    return Object.entries(data).map(([key, value]: any) => key);
  }, []);


  if (!display) {
    return <Box h={'50%'}/>;
  }

  return (
    <Container>
      <CardTitle
        title='Tesouros da Carteira'
        type='secondary'
      />

      <GraphContainer>
        <Chart
          options={{
            colors: graphPallete,
            tooltip: {
              y: {
                formatter: function(val) {
                  return 'R$ ' + formatCurrency(val / 100)
                },
                title: {
                  formatter: function (seriesName) {
                    return seriesName;
                  },
                },
              }
            },
            labels: pieChartDataLabels,
            legend: {
              // floating: true,
              position: 'bottom',
              offsetY: 8,
              height: 36,
              show: true,
              labels: {
                colors: [theme.colors.grey],
              }
            },
            states: {
              active: {
                filter: {
                  type: 'none'
                },
              },
              hover: {
                filter: {
                  type: 'none'
                },
              },
            },
            chart: {
              animations: {
                enabled: true,
                easing: 'easeinout',
                speed: 800,
              },
              events: {
                click: undefined,
                markerClick: undefined,
              }
            },
            stroke: {
              show: false,
            },
            dataLabels: {
              formatter: (val, opt) => {
                return `${Number(val).toFixed(2)} %`;
              },
            },
            plotOptions: {
              pie: {
                expandOnClick: false,
                dataLabels: {
                  offset: -5,
                },
                donut: {
                  background: 'transparent',
                },
              },
            }
          }}
          series={pieChartData}
          type="donut"
          width="100%"
          height="100%"
          animations={{ initialAnimation: { enabled: false }}}
        />
      </GraphContainer>
    </Container>
  )
}

