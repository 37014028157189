import React, { useMemo, useState } from 'react'
import { useTable } from 'react-table'

import * as Styled from './styles';

import { endOfDay, format, isBefore } from 'date-fns';
import { IProfit, ProfitTypeTranslator } from '../../../../../store/modules/profit/types';
import { IState } from '../../../../../store';
import { connect } from 'react-redux';
import Currency from '../../../../../components/Currency';
import { useSellsProfits } from '../../../../../hooks/react-query/useSellsProfits';
import { useTheme } from 'styled-components';
import { Box } from '../../../../../components/Box';
import Percentage from '../../../../../components/Percentage';

const SellsProfitsList: React.FC = () => {
  const { data: sellsProfits, isLoading } = useSellsProfits()
  const theme = useTheme()

  const [reverseFilter, setReverseFilter] = useState(false);
  const [filter, setFilter] = useState('date');

  const columns = useMemo(() => {
    return (
      [
        {
          Header: 'Data',
          accessor: 'date',
        },
        {
          Header: 'Código',
          accessor: 'stockName',
        },
        {
          Header: 'P. Médio',
          accessor: 'averagePrice',
        },
        {
          Header: 'P. Venda',
          accessor: 'sellPrice',
        },
        {
          Header: 'Qtd.',
          accessor: 'amount',
        },
        {
          Header: 'Lucro',
          accessor: 'profit',
        },
        {
          Header: 'Lucro (%)',
          accessor: 'profitInPercentage',
        }
      ]
    )
  }, []);

  const orderedSells = useMemo(() => {
    if (!sellsProfits) return []

    const sellsProfitsList = [...(sellsProfits?.stockSells || [])]

    sellsProfitsList.sort((a: any, b: any) => {
      const bigger = reverseFilter ? 1 : -1;
      const smaller = bigger * -1;

      if (a[filter] > b[filter]) {
        return bigger;
      }
      if (a[filter] < b[filter]) {
        return smaller;
      }

      return 0;
    });

    return sellsProfitsList;
  }, [sellsProfits?.stockSells, reverseFilter, filter]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
  } = useTable(
    {
      columns: columns as any,
      data: orderedSells,
    }
  )

  const orderTableByColumn = (columnName: string) => {
    const column = columns.find(c => c.accessor === columnName)

    if (filter === columnName) {
      setReverseFilter(v => !v)
    }

    if (column) {
      setFilter(columnName);
    }
  }

  return (
    <Styled.Container>
      <Styled.TableWrap className="tableWrap">
        <Styled.Table {...getTableProps()}>
          <Styled.Head>
            {headerGroups.map(headerGroup => (
              <Styled.HeadRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <Styled.HeadData
                    {...column.getHeaderProps({
                      className: column.collapse ? 'collapse' : '',
                    })}
                  >
                    <Styled.HeaderLabel onClick={() => orderTableByColumn(column.id)}>
                      {column.render('Header')}
                    </Styled.HeaderLabel>
                  </Styled.HeadData>
                ))}
              </Styled.HeadRow>
            ))}
          </Styled.Head>
          <Styled.TableBody {...getTableBodyProps()}>
            {rows.map((row: any, i: number) => {
              prepareRow(row)
              return (
                <Styled.HeadRow {...row.getRowProps()}>
                  {row.cells.map((cell: any) => {
                    return (
                      <Styled.Content
                        {...cell.getCellProps({
                          className: cell.column.collapse ? 'collapse' : '',
                        })}
                      >
                        {cell.column.Header === 'Data' && (
                          <span>{format(new Date(cell.value), 'dd/MM/yyyy')}</span>
                        )}

                        {cell.column.Header === 'Código' && (
                          <span>{cell.value}</span>
                        )}

                        {cell.column.Header === 'P. Médio' && (
                          <Currency
                            value={cell.value}
                            color={theme.colors.white}
                          />
                        )}

                        {cell.column.Header === 'P. Venda' && (
                          <Currency
                            value={cell.value}
                            color={theme.colors.darkGrey}
                          />
                        )}

                        {cell.column.Header === 'Qtd.' && (
                          <span>{cell.value}</span>
                        )}

                        {cell.column.Header === 'Lucro' && (
                          <Styled.SellResult>
                            <Currency
                              color={cell.value > 0 ? theme.colors.success : theme.colors.error}
                              value={cell.value}
                              type='flat'
                            />
                          </Styled.SellResult>
                        )}

                        {cell.column.Header === 'Lucro (%)' && (
                          <Styled.SellResult>
                            <Percentage
                              color={cell.value > 0 ? theme.colors.success : theme.colors.error}
                              value={cell.value * 1000}
                              type='flat'
                            />
                          </Styled.SellResult>
                        )}
                      </Styled.Content>
                    )
                  })}
                </Styled.HeadRow>
              )
            })}
          </Styled.TableBody>
        </Styled.Table>
      </Styled.TableWrap>
    </Styled.Container>
  )
}

export default SellsProfitsList
