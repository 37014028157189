import styled, { css } from 'styled-components';

export const Container = styled.div`
${({ theme }) => css`
  width: 100%;
  flex: 1;
`}
`;

export const FirstRow = styled.div`
${({ theme }) => css`
  display: flex;

  margin-bottom: 2.4rem;
`}
`;

export const CongratsContainer = styled.div`
${({ theme }) => css`
  display: flex;
  flex-direction: column;

  flex: 1;

  padding: 3.3rem 0 3.3rem 0;

  > h1 {
    color: ${theme.colors.white};
    font-size: ${theme.fontSizes.giant};
    margin-bottom: 0.8rem;
  }

  > span {
    color: ${theme.colors.white};
    font-size: ${theme.fontSizes.huge};

    > strong {
      color: ${theme.colors.success};
    }
  }
`}
`;

export const BalanceContainer = styled.div`
${({ theme }) => css`
  background: ${theme.colors.lightestGrey};
  border-radius: 0.8rem;
  flex: 2;

  padding: 2.4rem;

  display: flex;
  flex-direction: column;
  justify-content: center;

  > span {
    font-size: ${theme.fontSizes.huge};
    font-weight: bold;
    color: ${theme.colors.white};
  }

  > h6 {
    font-size: ${theme.fontSizes.enourmous};
    color: ${theme.colors.success};
  }
`}
`;

export const WalletsContainer = styled.div`
${({ theme }) => css`
  display: flex;
  overflow-x: scroll;

  ::-webkit-scrollbar-thumb:vertical {
    background-color: ${({ theme }) => theme.colors.success};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.success};
    border-radius: 0.4rem;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.colors.success};
  }
`}
`;

export const WalletContainer = styled.div`
${({ theme }) => css`
  padding: 3.2rem;
  min-width: 40rem;
  margin-right: 1.6rem;

  background: ${theme.colors.lightestGrey};
  border-radius: 0.8rem;

  > h2 {
    font-size: ${theme.fontSizes.huge};
    font-weight: normal;
    color: ${theme.colors.white};

    margin-bottom: 1.6rem;
  }
`}
`;

export const WalletBalances = styled.div`
${({ theme }) => css`
  display: flex;
  flex-direction: column;

  margin-bottom: 1.6rem;
`}
`;

export const OldBalance = styled.div`
${({ theme }) => css`
  > h6 {
    color: ${theme.colors.extremeLightGrey};
    font-size: ${theme.fontSizes.huge};
  }
`}
`;

export const NewBalance = styled.div`
${({ theme }) => css`
> h6 {
    color: ${theme.colors.success};
    font-size: ${theme.fontSizes.giant};
  }
`}
`;

export const WalletInfos = styled.div`
${({ theme }) => css`
  display: flex;
  align-items: center;
`}
`;

interface ValueIncreaseProps {
  positive?: boolean;
  negative?: boolean;
  neutral?: boolean;
}

export const ValueIncrease = styled.div<ValueIncreaseProps>`
${({ theme, positive, neutral, negative }) => css`
  padding: 0.4rem;

  background: ${theme.colors.success};
  border-radius: 0.4rem;

  margin-right: auto;

  > h6 {
    color: ${theme.colors.white};
    font-size: ${theme.fontSizes.default};
  }

  ${negative && css`
    background: ${theme.colors.error};
  `}

  ${neutral && css`
    background: ${theme.colors.grey};
  `}
`}
`;

export const ActivesIncreased = styled.div`
${({ theme }) => css`
  color: ${theme.colors.white};
  font-size: ${theme.fontSizes.large};

  > strong {
    color: ${theme.colors.success};
  }
`}
`;

