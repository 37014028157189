import React, { useEffect, useMemo, useState }  from 'react';

import Chart from 'react-apexcharts';
import { WidgetCard } from '../../../../../components/WidgetCard';
import { useWallet } from '..';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components';
import { format } from 'date-fns';
import { getMonthObject } from '../../../../../utils/dateUtils';
import { connect } from 'react-redux';
import { IState } from '../../../../../store';
import { DividendMonthlyChartSerie, EarningsByMonth, IProfit } from '../../../../../store/modules/profit/types';
import formatCurrency from '../../../../../utils/formatCurrency';
import { Box, Loading } from '../../../../../components';
import { graphPallete } from '../../../../../utils/colors';
import { Container } from './styles';

interface GraphProps {
  profits: IProfit[];
  earningsByMonth: EarningsByMonth | null;
}


const WalletEarningsByMonthGraph: React.FC<GraphProps> = ({ profits, earningsByMonth }) => {
  const { wallet } = useWallet()
  const theme = useTheme();

  const [display, setDisplay] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setDisplay(true), 750);
    return () => clearTimeout(timeout);
  }, [])

  const labelFormatter = (date: number) => {
    const month = new Date(date).getMonth();
    const year = format(new Date(date), 'yyyy');

    const monthString = getMonthObject(month).monthAbbrev;

    return `${monthString}/${year}`;
  }

  const chartData = useMemo(() => {
    const defaultValue = {
      chartSeries: [],
      monthsList: [],
      monthsLabels: [],
    }

    if (!earningsByMonth) {
      return defaultValue
    }

    let chartSeries = earningsByMonth.chartSeries[wallet.id] as DividendMonthlyChartSerie[];

    if (!earningsByMonth || !earningsByMonth?.chartSeries[wallet.id] || !chartSeries.length) {
      return defaultValue
    }

    const walletEarnings = earningsByMonth.chartSeries[wallet.id];

    chartSeries = chartSeries.map((chartSerie, index) => {
      const serieInWallet = walletEarnings[index];

      const totalData = chartSerie.data.map((monthValue, _index) => {
        const serieInWalletMonthValue = serieInWallet.data[_index];

        return monthValue + serieInWalletMonthValue;
      });

      return {
        name: chartSerie.name,
        type: chartSerie.type,
        data: totalData,
      }
    })

    return {
      chartSeries,
      monthsList: earningsByMonth.monthsList.map(date => new Date(date)),
      monthsLabels: earningsByMonth.monthsLabels,
    }
  }, [profits, earningsByMonth, wallet]);

  const annotations = useMemo(() => {
    const points = [] as any;

    chartData.monthsList.forEach((month, index) => {
      let totalOfMonth = 0;

      chartData.chartSeries.forEach((serie: any) => {
        totalOfMonth += serie.data[index];
      });

      if (!totalOfMonth) {
        return;
      };

      points.push({
        x: month.getTime(),
        y: totalOfMonth,
        marker: {
          size: 0,
          radius: 0,
          strokeColor: 0,
        },
        label: {
          text: formatCurrency(totalOfMonth / 100),
          borderColor: theme.colors.darkGrey,
          style: {
            background: theme.colors.grey,
            color: theme.colors.lightGrey,
            fontWeight: 'bold',
          }
        }
      });
    });

    return points;
  }, [chartData]);

  const chartColors = useMemo(() => {
    return chartData.monthsLabels.map(() => theme.colors.grey);
  }, [chartData]);

  const chartLegendColor = useMemo(() => {
    return chartData.chartSeries.map(() => theme.colors.grey);
  }, [chartData]);

  if(!display) {
    return (
      <WidgetCard
        title='Dividendos Mensais'
        w={'100%'}
      >
        <Box h={'365px'} alignItems='center' justifyContent='center' w='100%'>
          <Loading size={16} color={theme.colors.primary} />
        </Box>
      </WidgetCard>
    );
  }

  return (
    <WidgetCard
      title='Dividendos Mensais'
      w={'100%'}
      withoutContent={!annotations.length}
      withoutContentText={'Mostraremos mensalmente seus dividendos aqui, assim que você começar a recebê-los!'}
    >
      <Container>
        <Chart
          options={{
            colors: graphPallete,
            annotations: {
              points: annotations,
            },
            states: {
              active: {
                filter: {
                  type: 'none'
                }
              },
              hover: {
                filter: {
                  type: 'none'
                }
              },
              normal: {
                filter: {
                  type: 'none',
                },
              },
            },
            plotOptions: {
              bar: {
                horizontal: false,
              }
            },
            dataLabels: {
              enabled: false,
              style: {
                fontSize: '10px',
              },
              dropShadow: {
                enabled: true,
                left: 2,
                top: 2,
                opacity: 0.5
              },
            },
            tooltip: {
              theme: 'dark',
              onDatasetHover: {
                highlightDataSeries: false,
              },
              enabled: true,
              x: {
                show: true,
                formatter: labelFormatter,
              },
              y: {
                formatter: (val) => `${formatCurrency(val / 100)}`,
                title: {
                  formatter: (seriesName) => seriesName
                }
              },
              marker: {
                show: true,
              },
            },
            chart: {
              animations: {
                enabled: false,
                easing: 'linear',
                speed: 1000,
              },
              id: "bar-chart",
              type: 'bar',
              stacked: true,
              toolbar: {
                show: false,
                offsetX: 0,
                offsetY: 0,
                autoSelected: 'pan',
                tools: {
                  selection: false,
                  pan: false,
                  download: false,
                  zoom: false,
                  zoomin: false,
                  zoomout: false,
                  reset: false,
                },
              },
            },
            responsive: [
              {
                breakpoint: 480,
                options: {
                  legend: {
                    position: 'bottom',
                    offsetX: -10,
                    offsetY: 0,
                  },
                },
              },
            ],
            legend: {
              show: false,
              showForZeroSeries: false,
              itemMargin: {
                horizontal: 8,
                vertical: 4,
              },
              position: 'right',
              offsetY: -10,
              labels: {
                colors: chartLegendColor,
              }
            },
            xaxis: {
              type: 'datetime',
              tooltip: {
                enabled: false,
              },
              axisBorder: {
                show: true,
                color: theme.colors.grey,
              },
              crosshairs: {
                show: false,
              },
              categories: chartData.monthsLabels,
              labels: {
                style: {
                  colors: chartColors,
                },
                rotate: -45,
              }
            },
            yaxis:
              {
                seriesName: 'Dividendos',
                axisBorder: {
                  show: false,
                },
                labels: {
                  formatter: (val) => `${formatCurrency(val / 100)}`,
                  style: {
                    colors: chartColors,
                    fontSize: '8px',
                  },
                },

              },
            grid: {
              borderColor: theme.colors.lightGrey,
            },
          }}
          series={chartData.chartSeries}
          width={'95%'}
          height="350"
          type="bar"
        />
      </Container>
    </WidgetCard>
  )
}

const mapStateToProps = (state: IState) => ({
  profits: state.profit.profits,
  earningsByMonth: state.profit.earningsByMonth,
  wallets: state.wallet.wallets,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(WalletEarningsByMonthGraph)
