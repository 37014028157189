import { Reducer } from "redux";
import { PersonalizedWalletState, PersonalizedWalletActionTypes } from "./types";
import produce from 'immer';

const INITIAL_STATE: PersonalizedWalletState = {
  personalizedWallets: [],
  isLoadingPersonalizedWallets: true,
}

const personalizedWalletReducer: Reducer<PersonalizedWalletState> = (state = INITIAL_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case PersonalizedWalletActionTypes.GET_PERSONALIZED_WALLETS_REQUEST: {
        draft.isLoadingPersonalizedWallets = true;
        break;
      }
      case PersonalizedWalletActionTypes.GET_PERSONALIZED_WALLETS_SUCCESS: {
        draft.personalizedWallets = action.payload.personalized_wallets;
        draft.isLoadingPersonalizedWallets = false;
        break;
      }
      case PersonalizedWalletActionTypes.GET_PERSONALIZED_WALLETS_FAILURE: {
        draft.isLoadingPersonalizedWallets = false;
        break;
      }
      case PersonalizedWalletActionTypes.SET_LOADING_PERSONALIZED_WALLETS: {
        draft.isLoadingPersonalizedWallets = true;
        break;
      }
      default: {
        return draft;
      }
    }
  });
}

export default personalizedWalletReducer;
