import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

export const Container = styled.button`
${({ theme }) => css`
    min-width: 26.4rem;

    min-height: 18.0rem;
    max-height: 38.0rem;
    max-width: 40rem;

    padding: 1.6rem;

    background: ${transparentize(0.05, theme.colors.background)};
    border-radius: 1.6rem 0.4rem 0.8rem 1.6rem;

    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    box-shadow: ${theme.shadows.default};

    > p {
      font-size: ${theme.fontSizes.default};
      color: ${theme.colors.grey};
      text-align: left;

      margin-bottom: 16px;
    }

    @media (max-width: 670px) {
      max-width: 90%;
      width: 100%;

      margin: 0.8rem auto;
    }
`}
`;

export const WalletHeader = styled.div`
${({ theme }) => css`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-bottom: 1.2rem;

  color: ${theme.colors.darkGrey};

  h4 {
    margin-right: auto;
  }

  svg:nth-child(1n) {
    color: ${theme.colors.primary};
    margin-right: 0.8rem;
  }

  svg:nth-child(3n) {
    margin-left: auto;
    color: ${theme.colors.darkGrey};
  }
`};
`;

export const WalletSummary = styled.div`
${({ theme }) => css`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: auto;

  > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.4rem;

    span {
      font-size: ${theme.fontSizes.default};
      color: ${theme.colors.softGrey};
    }

    h6 {
      font-size: ${theme.fontSizes.large};
      line-height: 2.4rem;
    }

    h3 {
      font-size: ${theme.fontSizes.large};
      line-height: 2.4rem;
    }

    p {
      font-size: ${theme.fontSizes.default};
      color: ${theme.colors.softGrey};
      text-align: left;
    }
  }

`}
`;

export const WalletContent = styled.div`
${({ theme }) => css`
  display: flex;
  flex-direction: column;
  align-items: space-between;

  width: 100%;
`}
`;
