import React from 'react';
import { connect } from 'react-redux';
import CardTitle from '../../../../components/CardTitle';

import { IState } from '../../../../store';
import ActivesTable from './ActivesTable';

import {
  Container,
} from './styles';

interface ActivesRankProps { }

const ActivesRank: React.FC<ActivesRankProps> = () => {
  return (
  <Container>
    <CardTitle
      title='Maiores Pagadores'
      type='secondary'
    />

    <ActivesTable />
  </Container>
  )
}

const mapStateToProps = (state: IState) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ActivesRank)
