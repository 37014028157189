import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import Modal from '../../../components/Modal';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import { fetchData } from '../../../services/data';

import api from '../../../services/api';
import { PersonalizedWallet } from '../../../store/modules/personalized_wallets/types';
import { Box } from '../../../components';
import { useTheme } from 'styled-components';
import * as Styled from './styles';
import { FiX } from 'react-icons/fi';
import { useWallets } from '../../../hooks/react-query/useWallets';
import { ValueContainer } from './styles';

interface UpdatePersonalizedWalletModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
  wallet: PersonalizedWallet;
}

const UpdatePersonalizedWalletModal: React.FC<UpdatePersonalizedWalletModalProps> = ({
  isOpen,
  setIsOpen,
  wallet,
}) => {
  const dispatch = useDispatch();
  const { refetch } = useWallets()
  const [name, setName] = useState(wallet.name);
  const [startingValue, setStartingValue] = useState(wallet.starting_value.toString());
  const [currentValue, setCurrentValue] = useState(wallet.current_value.toString());
  const [error, setError] = useState('');

  const handleSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      setError('');

      if (!name || !startingValue || !currentValue) {
        setError('Todos os campos são obrigatórios');
        return;
      }

      try {
        await api.put(`/personalized-wallets/${wallet.id}`, {
          name,
          starting_value: parseFloat(startingValue),
          current_value: parseFloat(currentValue),
        });

        refetch()
        await fetchData({ dispatch });

        setIsOpen();
      } catch (err) {
        console.error(err);
        setError('Erro ao atualizar a carteira');
      }
    },
    [dispatch, setIsOpen, wallet.id, name, startingValue, currentValue]
  );

  const theme = useTheme()

  return (
    <Modal
      isVisible={isOpen}
      closeModal={() => setIsOpen()}
    >
      <Box bg={theme.colors.background} flexDirection='column' w={'20%'}>
      <Styled.Heading>
          <h1>Atualizar Carteira</h1>
          <button
            onClick={() => setIsOpen()}
          >
            <FiX />
          </button>
        </Styled.Heading>
          <form onSubmit={handleSubmit}>
            <Box flexDirection='column' mr="8px" ml="8px">
              <ValueContainer>
                <label>Nome</label>
                <Input
                  name="name"
                  placeholder="Wallet Name"
                  value={name}
                  callback={(e) => setName(e)}
                />
              </ValueContainer>
              <ValueContainer>
                <label>Valor Inicial</label>
                <Input
                  name="starting_value"
                  placeholder="Starting Value"
                  type="number"
                  step="0.01"
                  value={startingValue}
                  callback={(e) => setStartingValue(e)}
                />
              </ValueContainer>
              <ValueContainer>
                <label>Valor Atual</label>
                <Input
                  name="current_value"
                  placeholder="Current Value"
                  type="number"
                  step="0.01"
                  value={currentValue}
                  callback={(e) => setCurrentValue(e)}
                />
              </ValueContainer>
            </Box>

            {error && <p style={{ color: 'red' }}>{error}</p>}
            <Styled.SubmitButtonContainer>
              <Button revision='primary' type="submit">Atualizar Carteira</Button>
            </Styled.SubmitButtonContainer>
          </form>
      </Box>
    </Modal>
  );
};

export default UpdatePersonalizedWalletModal;
