import React, { useState } from 'react';
import { Box } from '../../../components/Box';
import Modal from '../../../components/Modal';
import { useTheme } from 'styled-components';
import CardTitle from '../../../components/CardTitle';
import Typography from '../../../components/Typography';
import { IOrder, useOrders } from '../../../hooks/react-query/useOrders';
import { Show } from '../../../components/Show';
import { StockEmblem } from '../../../components/StockEmblem';
import { FiX } from 'react-icons/fi';
import Button from '../../../components/Button';
import { ValueContainer } from './styles';
import { format } from 'date-fns';
import { useAuth } from '../../../hooks/react-query/useAuth';
import api from '../../../services/api';
import { fetchData } from '../../../services/data';
import { useDispatch } from 'react-redux';

interface DeleteOrderModal {
  handleCloseModal: () => void;
  orderToDelete: IOrder | null;
}

export const DeleteOrderModal = ({ orderToDelete, handleCloseModal }: DeleteOrderModal) => {
  const { refetch } = useOrders({})

  const [isLoading, setIsLoading] = useState(false);
  const { data: user } = useAuth();

  const dispatch = useDispatch()
  const theme = useTheme()

  const handleDeleteOrder = async () => {
    if (!orderToDelete) return;

    setIsLoading(true)

    await api.delete(`/invest/delete-order/${orderToDelete.id}`);
    await fetchData({ dispatch });
    refetch();

    handleCloseModal();
    setIsLoading(false);
  }

  return (
    <Modal
      isVisible={!!orderToDelete}
      closeModal={() => handleCloseModal()}
    >
      <Box bg={theme.colors.background} p={'2.4rem'} flexDirection='column' w={'25%'}>
        <Box mb={'1.6rem'} justifyContent='space-between'>
          <CardTitle title={'Excluir Ordem'} />
          <Button
            style={{ padding: 8}}
            revision={'secondary'}
            onClick={() => handleCloseModal()}
          >
            <FiX />
          </Button>
        </Box>

        <Box flexDirection='column'>
          <Box alignItems='center' mb={'1.6rem'}>
            <Show when={(orderToDelete?.entity as any)?.stockEnterprisesInfos?.image_url}>
              <StockEmblem
                image_url={(orderToDelete?.entity as any)?.stockEnterprisesInfos?.image_url}
              />
            </Show>
            <Typography>{orderToDelete?.entity.name}</Typography>
          </Box>

          <Show when={orderToDelete?.created_at}>
            <ValueContainer>
              <label>Data</label>
              <div>{orderToDelete ? format(new Date(orderToDelete.created_at), 'dd/MM/yyyy') : '-'}</div>
            </ValueContainer>
          </Show>

          <ValueContainer>
            <label>Valor</label>
            <div>{(orderToDelete?.price || 0) / 100}</div>
          </ValueContainer>

          <ValueContainer>
            <label>Quantidade</label>
            <div>{(orderToDelete?.amount || 0)}</div>
          </ValueContainer>

          <Box mb={'1.6rem'} />

          <Button revision='primary' onClick={handleDeleteOrder} isLoading={isLoading}>
            Excluir Ordem
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}
