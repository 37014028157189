import React from 'react';

import { Container } from './styles';

interface TooltipProps {
	title: string;
	className?: string;
  width?: number;
  color?: string;
  position?: string;
  textColor?: string;
}

const Tooltip: React.FC<TooltipProps> = ({
	title,
	children,
  color,
  textColor,
  width,
	className = '',
  position='top'
}) => {
	return (
    <>
      {title ? (
        <Container
          width={width}
          className={className}
          color={color}
          textColor={textColor}
          position={position}
        >
          <>
            {children}
            <span>{title}</span>
          </>
        </Container>
      ) : (
        <>
          {children}
        </>
      )}
    </>
	);
};

export default Tooltip;
