import { Reducer } from "redux";
import { ITesouroWalletState, TesouroWalletActionTypes } from "./types";
import produce from 'immer';

const INITIAL_THEME_STATE = {
  tesouro_wallets: [],
  isLoadingTesouroWallets: true,
}

const walletReducer: Reducer<ITesouroWalletState> = (state = INITIAL_THEME_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case TesouroWalletActionTypes.GET_TESOURO_WALLETS_REQUEST: {
        draft.isLoadingTesouroWallets = true;

        break;
      }
      case TesouroWalletActionTypes.GET_TESOURO_WALLETS_SUCCESS: {
        draft.tesouro_wallets = action.payload.tesouro_wallets;
        draft.isLoadingTesouroWallets = false;

        break;
      }
      case TesouroWalletActionTypes.GET_TESOURO_WALLETS_FAILURE: {
        draft.isLoadingTesouroWallets = false;

        break;
      }
      default: {
        return draft;
      }
    }
  });
}

export default walletReducer;
