import { IToast, ToastTypes } from "./types";

export function createToastMessage(toast: IToast) {
  return {
    type: ToastTypes.CREATE_TOAST_MESSAGE,
    payload: {
      toast,
    }
  }
}

export function deleteToastMessage(toastCreatedAt: Date) {
  return {
    type: ToastTypes.DELETE_TOAST_MESSAGE,
    payload: {
      toastCreatedAt,
    }
  }
}

export function togglePinToastMessage(toastCreatedAt: Date) {
  return {
    type: ToastTypes.TOGGLE_PIN_TOAST,
    payload: {
      toastCreatedAt,
    }
  }
}
