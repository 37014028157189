import React, { useState } from 'react';
import { Box } from '../../../components/Box';
import CardTitle from '../../../components/CardTitle';
// import theme from '../../../styles/theme';
import { useTheme } from 'styled-components';
import Typography from '../../../components/Typography';
import { motion } from 'framer-motion';
import animations from '../../../resources/animations';
import { ExtractList } from './ExtractList';
import StockSearchBar from '../../../components/SearchBars/StockSearchBar';
import { DeleteOrderModal } from './DeleteOrderModal';
import { IOrder } from '../../../hooks/react-query/useOrders';
import { UpdateOrderModal } from './UpdateOrderModal';

const Extract = () => {
  const theme = useTheme()
  const [stockName, setStockName] = useState('')

  const [orderToDelete, setOrderToDelete] = useState<IOrder | null>(null)
  const [orderToEdit, setOrderToEdit] = useState<IOrder | null>(null)

  return (
    <Box w={'100%'} maxWidth={theme.spacing.mainWidth} flexDirection='column'>
      <Box
        ml={'0.8rem'}
        p={'0rem 2rem 5rem 2rem'}
        flexDirection='column'
        alignItems='flex-start'
      >
        <motion.div
          variants={{
            ...animations.containerAnimation,
            mounted: {
              ...animations.containerAnimation.mounted,
              transition: {
                ...animations.containerAnimation.mounted.transition,
                staggerChildren: 0.25,
                delay: 0,
              }
            }
          }}
        >
          <CardTitle
            title={'Extrato'}
            type={'primary'}
          />
        </motion.div>

        <motion.div
          variants={{
            ...animations.containerAnimation,
            mounted: {
              ...animations.containerAnimation.mounted,
              transition: {
                ...animations.containerAnimation.mounted.transition,
                staggerChildren: 0.25,
                delay: 0.1
              }
            }
          }}
        >
          <Box mb={'0.8rem'} />
          <Typography type='body3' color={theme.colors.lightGrey}>Confira seu histórico de transações</Typography>
        </motion.div>
      </Box>


      <motion.div
        variants={{
          ...animations.containerAnimation,
          mounted: {
            ...animations.containerAnimation.mounted,
            transition: {
              ...animations.containerAnimation.mounted.transition,
              staggerChildren: 0.25,
              delay: 0.3,
            }
          }
        }}
      >
      <Box flexDirection='column'>
        <Box flexDirection='column' p={'2.4rem'} bg={theme.colors.background} borderRadius='0.8rem 0.8rem 0rem 0rem'>
          <StockSearchBar
            shouldShowClasses={false}
            callback={E => setStockName(E.inputValue.toUpperCase())}
            placeholder= 'Nome do ativo...'
            type={'primary'}
            shouldHideOptions={true}
          />
        </Box>

          <ExtractList
            stockName={stockName}
            setOrderToDelete={setOrderToDelete}
            setOrderToEdit={setOrderToEdit}
          />
        </Box>
      </motion.div>

      <DeleteOrderModal
        setOrderToDelete={setOrderToDelete}
        orderToDelete={orderToDelete}
      />
      <UpdateOrderModal
        setOrderToDelete={setOrderToEdit}
        orderToDelete={orderToEdit}
      />
    </Box>
  )
}

export default Extract;
