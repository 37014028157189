import React from 'react';
import { connect } from 'react-redux';
import CardTitle from '../../../../components/CardTitle';

import { IState } from '../../../../store';
import SectorsRank from './SectorsRank';

import {
  Container,
} from './styles';

interface SectorRankProps {}

const SectorRank: React.FC<SectorRankProps> = () => {
  return (
  <Container>
    <CardTitle
      title='Ranking por Setores'
      type='secondary'
    />

    <SectorsRank />
  </Container>
  )
}

const mapStateToProps = (state: IState) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(SectorRank)
