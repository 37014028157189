import { loginFailure, loginSuccess, logoutSuccess, logoutRequest, logoutFailure, loginRequest } from "../store/modules/user/actions";
import api from "./api";

import { Dispatch } from "redux";

export const fetchUser = async (dispatch: Dispatch<any>, async?: boolean) => {
  console.log('fetching user...');

  if (async) {
    dispatch(loginRequest());
  }

  try {
    const response = await api.get('/user');

    dispatch(loginSuccess(response.data));

    return response.data;
  } catch (err) {
    dispatch(loginFailure('error'));
    throw err;
  }
}

export const userLogout = (dispatch: Dispatch<any>) => {
  console.log('Logouting user...');

  try {
    dispatch(logoutRequest());
    dispatch(logoutSuccess());
  } catch (err) {
    dispatch(logoutFailure());
  }
}
