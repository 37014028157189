import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;

    scrollbar-color: ${({ theme }) => theme.colors.primary };

    /* width */
    ::-webkit-scrollbar {
      width: 0.4rem;
      height: 0.4rem;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: 0;
      border-radius: 0.4rem;
    }

    ::-webkit-scrollbar-track-piece  {
      background-color: ${({ theme }) => theme.colors.extremeLightGrey};
    }

    ::-webkit-scrollbar-thumb:vertical {
      background-color: ${({ theme }) => theme.colors.primary};
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.colors.primary};
      border-radius: 0.4rem;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: ${({ theme }) => theme.colors.primaryDark};
    }
  }

  html {
    height: 100%;
    transition: 0;

    font-size: 50%;

    @media (min-width: 1480px) {
      font-size: 62.5%;
    };
  }

  body {
    height: auto;
    background: ${({ theme }) => theme.colors.lightGrey};
    color: ${({ theme }) => theme.colors.grey};
    overflow-x: hidden;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale;
  }

  body, #root {
    min-height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  #root {
    height: 100%;
  }

  input {
    border-radius: 0.2rem;
  }

  body, input, button {
    font: 1.6rem 'Space Grotesk', 'Lato', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    line-height: 1.48;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 700;
  }

  a {
    text-decoration: none;
    background: none;
    font-weight: 700;
    cursor: pointer;
    border: 0;
    transition: 180ms ease-in-out;
  }

  button {
    cursor: pointer;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ul {
    list-style: none;
    text-align: left;
    padding: 0;
  }

  * {

  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
`;
