import styled, { css } from 'styled-components';

export const Container = styled.tr`
${({ theme }) => css`
  background: 0;

  margin-bottom: 0.8rem;
  border-radius: 0.4rem;

  width: 100%;

  padding: 0.8rem;

  display: flex;
  align-items: center;

  @media (max-width: 670px) {
    max-width: 100%;
    flex-direction: column;

    align-items: flex-start;
  }
`}
`;

export const Header = styled.th<{ isSelected?: boolean }>`
${({ theme, isSelected }) => css`
  display: flex;
  align-items: center;
  width: 100%;

  cursor: pointer;

  > h1 {
    font-size: ${theme.fontSizes.small};
    color: ${theme.colors.darkGrey};

    font-weight: 500;

    ${isSelected && css`
      font-weight: bold;
    `}
  }

  > svg {
    color: ${theme.colors.primary};
    margin-right: 0.4rem;
  }

  @media (max-width: 670px) {
    width: 100%;
    margin-bottom: 1.6rem;
  }
`}
`;

export const ActualPrice = styled.th<{ isSelected?: boolean }>`
${({ theme, isSelected }) => css`
  color: ${theme.colors.softGrey};
  width: 100%;
  font-size: ${theme.fontSizes.small};
  text-align: left;
  cursor: pointer;

  display: flex;
  align-items: center;

  > svg {
    margin-right: 0.4rem;
  }

  ${isSelected && css`
    font-weight: bold;
  `}

  background: 0;
`}
`;

export const MediumPrice = styled.th<{ isSelected?: boolean }>`
${({ theme, isSelected }) => css`
  width: 100%;
  color: ${theme.colors.darkGrey};
  text-align: left;
  cursor: pointer;

  font-size: ${theme.fontSizes.small};

  ${isSelected && css`
    font-weight: bold;
  `}
`}
`;

export const WalletPercentage = styled.div``;

export const TotalPercentage = styled.th<{ isSelected?: boolean }>`
${({ theme, isSelected }) => css`
  display: flex;
  align-items: center;

  > svg {
    margin-right: 0.4rem;
  }

  text-align: left;
  width: 100%;
  cursor: pointer;
  background: 0;
  font-size: ${theme.fontSizes.small};
  color: ${theme.colors.grey};

  ${isSelected && css`
    font-weight: bold;
  `}
`}
`;

export const TotalInvested = styled.th<{ isSelected?: boolean }>`
${({ theme, isSelected }) => css`
  display: flex;
  align-items: center;

  > svg {
    margin-right: 0.4rem;
  }

  text-align: left;
  width: 100%;
  cursor: pointer;
  background: 0;
  font-size: ${theme.fontSizes.small};
  color: ${theme.colors.grey};

  ${isSelected && css`
    font-weight: bold;
  `}
`}
`;

export const TotalValue = styled.th<{ isSelected?: boolean }>`
${({ theme, isSelected }) => css`
  display: flex;
  align-items: center;

  > svg {
    margin-right: 0.4rem;
  }

  text-align: left;
  width: 100%;
  cursor: pointer;
  background: 0;
  font-size: ${theme.fontSizes.small};
  color: ${theme.colors.grey};

  ${isSelected && css`
    font-weight: bold;
  `}
`}
`;

export const ProfitPercentage = styled.th<{ isSelected?: boolean }>`
${({ theme, isSelected }) => css`
  display: flex;
  align-items: center;

  > svg {
    margin-right: 0.4rem;
  }

  cursor: pointer;
  width: 100%;

  background: 0;
  font-size: ${theme.fontSizes.small};

  ${isSelected && css`
    font-weight: bold;
  `}

  > span {
    margin: 0 0.8rem;

    color: ${theme.colors.grey};
  }
`}
`;
