import { Reducer } from "redux";
import { IActivesState, ActivesActionTypes } from "./types";
import produce from 'immer';

const INITIAL_THEME_STATE = {
  actives: [],
  orders: [],
  balance: {
    balance: [],
  },
  stock_infos: {
    bestStocks: [],
    worstStocks: [],
  },
  wallets_profits: [],
  isLoadingOrders: true,
  isLoadingActives: true,
  isLoadingBalance: true,
  isLoadingRating: true,
  isLoadingChart: true,
}

const activesReducer: Reducer<IActivesState> = (state = INITIAL_THEME_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case ActivesActionTypes.GET_ORDERS_REQUEST: {
        draft.isLoadingOrders = true;

        break;
      }
      case ActivesActionTypes.GET_ORDERS_SUCCESS: {
        draft.orders = action.payload.orders;
        draft.isLoadingOrders = false;

        break;
      }
      case ActivesActionTypes.GET_ACTIVES_REQUEST: {
        draft.isLoadingActives = true;

        break;
      }
      case ActivesActionTypes.GET_ACTIVES_SUCCESS: {
        draft.actives = action.payload.actives;
        draft.isLoadingActives = false;

        break;
      }
      case ActivesActionTypes.SET_IS_LOADING_ACTIVES: {
        draft.isLoadingActives = true;

        break;
      }
      case ActivesActionTypes.GET_ACTIVES_FAILURE: {
        draft.isLoadingActives = false;

        break;
      }
      case ActivesActionTypes.GET_BALANCE_INFO_REQUEST: {
        draft.isLoadingBalance = true;

        break;
      }
      case ActivesActionTypes.GET_BALANCE_INFO_SUCCESS: {
        draft.balance = action.payload.balance;
        draft.isLoadingBalance = false;

        break;
      }
      case ActivesActionTypes.GET_BALANCE_INFO_FAILURE: {
        draft.isLoadingBalance = false;

        break;
      }
      case ActivesActionTypes.SET_IS_LOADING_BALANCE: {
        draft.isLoadingBalance = true;

        break;
      }
      case ActivesActionTypes.GET_STOCK_RATING_REQUEST: {
        draft.isLoadingRating = true;

        break;
      }
      case ActivesActionTypes.GET_STOCK_RATING_SUCCESS: {
        draft.stock_infos = action.payload.stock_infos;
        draft.isLoadingRating = false;

        break;
      }
      case ActivesActionTypes.GET_STOCK_RATING_FAILURE: {
        draft.isLoadingRating = false;

        break;
      }
      case ActivesActionTypes.SET_IS_LOADING_RATING: {
        draft.isLoadingRating = true;

        break;
      }
      default: {
        return draft;
      }
    }
  });
}

export default activesReducer;
