import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import Tooltip from '../Tooltip';

import {
  Container,
} from './styles';

interface HelpButtonProps {
  url: string;
  stayInCornerBottom?: boolean;
}

const HelpButton:React.FC<HelpButtonProps> = ({ url, stayInCornerBottom }) => {
  return (
    <Container
      stayInCornerBottom={stayInCornerBottom}
    >
      <Tooltip
        title="Estamos disponíveis 24 horas por dia para suas dúvidas!"
      >
            <a
              href={'https://wa.link/58s730'}
              target="_blank"
            >
              <FaWhatsapp
                size={26}
              />
            </a>
      </Tooltip>
    </Container>
  )
}

export default HelpButton;
