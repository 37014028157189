import { EnumToUnion } from "../../../utils/typings";

export enum WalletActionTypes {
  GET_WALLETS_REQUEST = 'GET_WALLETS_REQUEST',
  GET_WALLETS_SUCCESS = 'GET_WALLETS_SUCCESS',
  GET_WALLETS_FAILURE = 'GET_WALLETS_FAILURE',
  SET_LOADING_WALLETS = 'SET_LOADING_WALLETS',
}

export interface IWallet {
  id: string;
  user_id: string;
  name: string;
  total_wallet_value: number;
  current_wallet_value: number;
  yesterday_wallet_value: number;
  variation: number;
  profit: number;
  profit_in_percentage: number;
  wallet_earnings: number;
  stocks: IWalletStock[];
}

export interface IWalletStock {
  id: string;
  wallet_id: string;
  stock_id: string;
  percentage: number;
  is_active: boolean;
  dividendYield: number | null;
  name: string;
  rank: number | null;
  valuation: number;
  earnings: number;
  current_value: number;
  averagePrice: number;
  todayVariation: number;
  totalPapers: number;
  totalPurchaseValue: number;
  totalCurrentValue: number;
  totalProfitInPercentage: number;
  totalProfit: number;
  image_url: string;
}

export interface IWalletState {
  wallets: IWallet[];
  isLoadingWallets: boolean;
}

export enum WalletType {
  'Ações, BDRs, ETFs Nacionais' = 'STOCKS',
  'Fundos Imobiliários' = 'FIIs',
  'Tesouro Direto' = 'Tesouro Direto',
  'Personalizada' = 'PERSONALIZED',
}

export enum WalletTypeToName {
  'STOCKS' = 'Ações, BDRs, ETFs Nacionais',
  'FIIs' = 'Fundos Imobiliários',
  'Tesouro Direto' = 'Tesouro Direto',
  'PERSONALIZED' = 'Personalizada',
}

export type WalletTypeNames = 'Ações, BDRs, ETFs Nacionais' | 'Fundos Imobiliários' | 'Tesouro Direto'

export type WalletTypes = EnumToUnion<WalletType>
