import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { useTheme } from 'styled-components';
import { DEFAULT_TRANSITION } from '../../../constants';
import useEventListener from '../../../hooks/useEventListener';
import { IState } from '../../../store';
import Option from './Option';

import {
  Container,
  Input,
  OptionsContainer,
} from './styles';

interface IGenericSearchBarResponse {
  option: IOption;
  inputValue: string;
}

interface GenericSearchBarProps {
  placeholder?: string;
  options: IOption[];
  valueId?: string;
  callback(d: IGenericSearchBarResponse): void;
  type?: GenericSearchBarTypes;
}

type GenericSearchBarTypes = "primary" | "secondary" | "tertiary";

export interface IOption {
  id: string;
  name: string;
  description?: string;
  icon?: JSX.Element | null;
}

const optionsAnimation = {
  unMounted: { height: '0', opacity: 0 },
  mounted: {
    height: 'auto',
    opacity: 1,
    transition: {
      ...DEFAULT_TRANSITION,
    }
  },
}

const GenericSearchBar:React.FC<GenericSearchBarProps> = ({
  placeholder,
  callback,
  type: SearchBarType,
  options,
  valueId,
}) => {
  const theme = useTheme();

  const [isOpened, setIsOpened] = useState(false);
  const [inputTextValue, setInputTextValue] = useState('');
  const [selectedValueId, setSelectedValueId] = useState(valueId);

  const dropdownRef = useRef<HTMLDivElement>(null);

  const [isSmallInput, setIsSmallInput] = useState(false);

  useEffect(() => {
    setSelectedValueId(valueId);

    const selectedOption = options.find(option => option.id === valueId);

    setInputTextValue(selectedOption?.name || '');
  }, [valueId]);

  const onFocus = () => {
    setIsOpened(true);
  }

  useEffect(() => {
    if (dropdownRef.current) {
      setIsSmallInput(dropdownRef.current.clientWidth < 300);
    }
  }, [])

  const handleCloseDropdown = useCallback(({ target }: Event): void => {
    if (dropdownRef.current?.contains(target as Node)) {
      return;
    }

    setIsOpened(false);
  }, [setIsOpened]);

  useEventListener('click', handleCloseDropdown, {
    enabled: isOpened,
  });

  const handleEditInput = (e: any) => {
    setInputTextValue(e.target.value);
    setSelectedValueId('');

    callback({
      option: {
        id: '',
        name: '',
        description: '',
        icon: null
      },
      inputValue: inputTextValue,
    });
  }

  const handleClickOption = (option: IOption) => {
    callback({
      option: option,
      inputValue: inputTextValue,
    });

    setSelectedValueId(option.id);
    setInputTextValue(option.name);
    setIsOpened(false);
  }

  const optionsToRender = useMemo(() => {
    const optionsMatchingNames = options.filter(option => option.name.toLowerCase().includes(inputTextValue.toLowerCase()));

    return optionsMatchingNames;
  }, []);

  return (
    <Container
      ref={dropdownRef}
      type={SearchBarType}
      isOpened={isOpened && !!inputTextValue}
      hasSelectedValue={!!selectedValueId}
    >
      <FiSearch
        color={isOpened || inputTextValue ? theme.colors.primary : theme.colors.grey}
      />
      <Input
        onFocus={onFocus}
        placeholder={placeholder}
        value={inputTextValue}
        onChange={e => handleEditInput(e)}
      />

      {isOpened && (
        <OptionsContainer
          type={SearchBarType}
          variants={optionsAnimation}
          inputText={inputTextValue}
        >
          {optionsToRender.map(option => (
            <Option
              onClick={() => handleClickOption(option)}
              name={option.name}
              description={option.description}
              icon={option.icon}
              isSmallInput={isSmallInput}
            />
          ))}
        </OptionsContainer>
      )}
    </Container>
  )
}

const mapStateToProps = (state: IState) => ({})

const mapDispatchToProps = (dispatch: Dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(GenericSearchBar)
