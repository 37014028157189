import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTheme } from 'styled-components';
import { FiCreditCard, FiInfo, FiSmile, FiX, FiXCircle } from 'react-icons/fi';
import { TiPin, TiPinOutline } from 'react-icons/ti';
import { connect, useDispatch } from 'react-redux';
import { IState } from '../../store';

import {
  Container,
  Info,
  Options,
  ProgressBar,
  Content,
} from './styles';
import { IToast } from '../../store/modules/toast/types';
import { deleteToastMessage, togglePinToastMessage } from '../../store/modules/toast/actions';
import { useMotionValue } from 'framer-motion';

interface ToastMessageProps {
  isDarkTheme: boolean;
  toast: IToast;
}

const ToastMessage: React.FC<ToastMessageProps> = ({ isDarkTheme, toast }) => {
  const progressWidth = useMotionValue(400);

  const theme = useTheme();
  const dispatch = useDispatch();

  const timeout = useRef<any>();
  const unsubscribeUpdateWidth = useRef<any>();

  useEffect(() => {
    timeout.current = setTimeout(() => {
      if (toast && !toast.isPined) {
        dispatch(deleteToastMessage(toast.created_at));
      }
    }, 5000);

    unsubscribeUpdateWidth.current = setInterval(() => {
      const newWidth = progressWidth.get() - 0.85;
      progressWidth.set(newWidth);
    }, 10);

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }

      clearInterval(unsubscribeUpdateWidth.current);
    };
  }, []);

  useEffect(() => {
    if (toast.isPined && timeout.current) {
      clearTimeout(timeout.current);
      timeout.current = null;

    }
  }, [toast]);

  const handleDeleteToastMessage = () => {
    dispatch(deleteToastMessage(toast.created_at));
  }

  const handlePinToastMessage = () => {
    progressWidth.set(0);
    clearInterval(unsubscribeUpdateWidth.current);
    dispatch(togglePinToastMessage(toast.created_at));
  }

  const toastColor = useMemo(() => {
    if (toast.type === 'info') {
      return theme.colors.grey;
    }

    if (toast.type === 'success') {
      return theme.colors.success;
    }

    if (toast.type === 'error') {
      return theme.colors.error;
    }

    if (toast.type === 'wallet-investment') {
      return theme.colors.primary;
    }
  }, [theme]);

  return (
    <Container isDarkTheme={isDarkTheme} type={toast.type}>
      <Content>
        <Info marginBottom={!!toast.content}>
          <div>
            {toast.type === 'info' && (<FiInfo />)}
            {toast.type === 'error' && (<FiXCircle color={toastColor}/>)}
            {toast.type === 'success' && (<FiSmile color={toastColor} />)}
            {toast.type === 'wallet-investment' && (<FiCreditCard color={toastColor} />)}
            <h1>{toast.title}</h1>
          </div>
          {toast.content && <span>{toast.content}</span>}
        </Info>
        <Options marginBottom={!toast.isPined}>
          <FiX onClick={handleDeleteToastMessage} />
          {!toast.isPined && <TiPinOutline onClick={handlePinToastMessage} />}
        </Options>
      </Content>
      <ProgressBar style={{ width: progressWidth }} color={toastColor}/>
    </Container>
  );
}

const mapStateToProps = (state: IState) => ({
  isDarkTheme: !state.themeSelector.isLightMode,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ToastMessage)
