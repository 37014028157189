import React, { createContext, useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { storageKey } from '../hooks/useLocalStorage'
import api from '../services/api'
import { fetchData } from '../services/data'
import { refreshUserToken } from '../services/token'
import setIsLoadingAllData from '../utils/setIsLoadingAllData'
import setIsNotLoadingAllData from '../utils/setIsNotLoadingAllData'
import shouldOperate from '../utils/shouldOperate'

type SignInCredentials = {
  email: string
  password: string
}

type AuthContextData = {
  signIn(credentials: SignInCredentials): Promise<void>
  isAuthenticated: boolean
}

const AuthContext = createContext({} as AuthContextData)

export const AuthProvider: React.FC = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  const dispatch = useDispatch()
  const history = useHistory()

  const timeoutCleaner = useRef<any>();

  const startDataChronometerFetch = useCallback(() => {
    const TIMEOUT = 100 * 1000;

    timeoutCleaner.current = setTimeout(() => {
      fetchData({
        dispatch,
        async: false
      });

      if (shouldOperate()) {
        startDataChronometerFetch()
      }
    }, TIMEOUT);
  }, [dispatch])

  useEffect(() => {
    const token = localStorage.getItem(storageKey('token'));
    const refresh_token = localStorage.getItem(storageKey('refresh_token'));

    if (!!token && !!refresh_token) {
      refreshUserToken(JSON.parse(refresh_token)).then(() => {
        setIsAuthenticated(true)

        fetchData({
          dispatch,
          async: true
        });

        startDataChronometerFetch()
      }).catch(() => {
        setIsNotLoadingAllData(dispatch);
        history.push('/');
      })
      // setIsLoadingAllData(dispatch);
    } else {
      setIsNotLoadingAllData(dispatch);
      history.push('/');
    }

    return () => {
      if (timeoutCleaner.current) clearTimeout(timeoutCleaner.current)
    }
  }, [])

  const signIn = async ({ email, password }: SignInCredentials) => {
    try {
      const response = await api.post('/session/login', {
        email: email.toLowerCase(),
        password,
      });

      if (response.data.token) {
        localStorage.setItem(storageKey('token'), JSON.stringify(response.data.token));
        localStorage.setItem(storageKey('refresh_token'), JSON.stringify(response.data.refresh_token));

        api.defaults.headers['Authorization'] = `Bearer ${response.data.token}`;

        fetchData({
          dispatch,
          async: true
        });

        startDataChronometerFetch()
        setIsLoadingAllData(dispatch);
        setIsAuthenticated(true)
      }

      history.push('/dashboard');
    } catch (err) {
      throw err
    }
  }

  return (
    <AuthContext.Provider value={{ signIn, isAuthenticated }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  const context = React.useContext(AuthContext)

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider')
  }

  return context
}
