import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled(motion.div)`
  position: absolute;
  z-index: -5;

  > img {
    width: 100%;
  }
`;
