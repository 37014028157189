import React from 'react';
import { FiCheck } from 'react-icons/fi';
import { useTheme } from 'styled-components';

import { Container, getIconColorByType } from './styles';

export type CheckboxTypes = 'primary' | 'error' | 'secondary'

interface CheckboxProps {
  isChecked?: boolean;
  type?: CheckboxTypes;
  onClick(): void;
}

const Checkbox: React.FC<CheckboxProps> = ({
  isChecked,
  type = 'primary',
  onClick,
}) => {
  const theme = useTheme();

  return (
    <Container
      checkboxType={type}
      onClick={onClick}
    >
      {isChecked && (
        <FiCheck
          size={24}
          color={getIconColorByType(theme, type)}
        />
      )}
    </Container>
  )
}

export default Checkbox;
