import React from 'react';
import { Box, CardTitle, Percentage, Currency, Button } from '../../../../../components';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IState } from '../../../../../store';
import { ITesouroWallet } from '../../../../../store/modules/tesouroWallet/types';

import { motion } from 'framer-motion';
import { getContainerAnimation } from '../../../../../resources/animations';

import * as Styled from './styles';
import { FiTrash2 } from 'react-icons/fi';
import { ImArrowUpRight2, ImArrowDownRight2 } from 'react-icons/im';
import { useTheme } from 'styled-components';
import { useTesouroWallet } from '..';

interface TesouroHeaderInfosProps {
  setIsExcludeWalletModalOn: (s: boolean) => void
}

export const TesouroHeaderInfos = ({ setIsExcludeWalletModalOn }: TesouroHeaderInfosProps) => {
  const theme = useTheme()
  const { wallet: tesouroWallet } = useTesouroWallet();

  return (
    <Box
      flexDirection={'column'}
    >
      <Box
        alignItems={'center'}
        justifyContent={'space-between'}
        mb={'1.6rem'}
      >
        <Styled.LeftHeader
          positiveProfit={tesouroWallet.profit > 0}
        >
          <motion.div
            variants={getContainerAnimation(0.1)}
          >
            <CardTitle title={tesouroWallet.name} />
          </motion.div>

          <Box mr={'0.8rem'} />

          <motion.div
            variants={getContainerAnimation(0.2)}
          >
            <Percentage
              value={tesouroWallet.profit_in_percentage}
              type='variation'
            />
          </motion.div>

          <Box mr={'0.8rem'} />

          <motion.div
            variants={getContainerAnimation(0.3)}
          >
            <Currency
              value={tesouroWallet.profit}
              type='variation'
            />
          </motion.div>
        </Styled.LeftHeader>

        <Styled.RightHeader>
          <Button
            onClick={() => setIsExcludeWalletModalOn(true)}
          >
            <FiTrash2 />
          </Button>
        </Styled.RightHeader>
      </Box>

      <Styled.WalletValue>
        <motion.div
          variants={getContainerAnimation(0.4)}
        >
          <Currency
            value={tesouroWallet.current_wallet_value}
          />
        </motion.div>

        <motion.div
          variants={getContainerAnimation(0.5)}
        >
          {tesouroWallet.current_wallet_value > 0 && (
            <ImArrowUpRight2
              size={56}
              color={theme.colors.success}
            />
          )}

          {tesouroWallet.current_wallet_value < 0 && (
            <ImArrowDownRight2
              size={56}
              color={theme.colors.error}
            />
          )}
        </motion.div>
      </Styled.WalletValue>
    </Box>
  )
}
