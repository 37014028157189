import React, { memo } from 'react';

import Logo from '../../../assets/images/illustrations/logo.png';

import { FaBeer, FaZhihu } from 'react-icons/fa';

import AccountDropDown from './AccountDropDown';
import Gradient from './Gradient';

import {
  Container,
  Wrapper,
  LeftNav,
  RightNav,
} from './styles';

import { useHistory } from 'react-router-dom';
import AddOrderButton from './AddOrderButton';
import BackgroundMask from './BackgroundMask';
import TotalPatrimony from './TotalPatrimony';
import Rentability from './Rentability';
import StockSearchBar from '../../../components/SearchBars/StockSearchBar';
import { useMediaQuery } from 'react-responsive';

const animation = {
  unMounted: { y: -150, scale: 0.9 },
  mounted: {
    y: 0,
    scale: 1,
  }
}

const Header: React.FC = () => {
  const history = useHistory();
  const isSmallDevice = useMediaQuery({ query: '(max-width: 1300px)' });

  const handleGoToDashboard = () => {
    history.push('/dashboard');
  }

  const navigateToStock = (stock_id: string) => {
    if (stock_id) {
      history.push(`/dashboard/stocks/${stock_id}`);
    }
  }

  return (
    <>
      <Container>
        <Wrapper
          variants={animation}
          initial="unMounted"
          animate="mounted"
          transition={{ duration: 1.2, delay: 0.5 }}
        >
          <LeftNav
            onClick={handleGoToDashboard}
          >
            <div>
              <img src={Logo} alt="wllt" />
            </div>
          </LeftNav>

          {!isSmallDevice && (
            <div>
              <StockSearchBar
                shouldShowClasses
                callback={e => navigateToStock(e.stock.id)}
                placeholder="Pesquisar por ativo, criptoativo ou usuário"
              />
            </div>
          )}

          <RightNav>
            {!isSmallDevice && (
              <>
                <TotalPatrimony />
                <Rentability />
                <AddOrderButton />
              </>
            )}
            <AccountDropDown />
          </RightNav>
        </Wrapper>
      </Container>
      <BackgroundMask />
    </>
  );
};

export default memo(Header);
