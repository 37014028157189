import React, { memo } from 'react';

import { Container } from './styles';

import { ButtonProps } from './Button.d';
import Loading from '../Loading';
import { useTheme } from 'styled-components';
import { Show } from '../Show';
import Tooltip from '../Tooltip';

const Button: React.FC<ButtonProps> = ({
  isLoading,
  revision = 'transparent',
  children,
  background,
  color,
  hoverEffect,
  disableMessage,
  ...rest
}) => {
  const theme = useTheme()
  const loadingColor: any = {
    secondary: theme.colors.primary,
    transparent: theme.colors.primary,
    primary: theme.colors.background,
  }
  if (disableMessage) {
    return (
      <Tooltip width={200} title={disableMessage!}>
        <Container
          type="button"
          revision={revision}
          color={color}
          background={background}
          hoverEffect={hoverEffect}
          {...rest as any}
        >
            {isLoading ? (
              <Loading
                color={loadingColor[revision]}
                size={8}
              />
            ) : (
              children
            )}
        </Container>
      </Tooltip>
    );
  }

  return (
    <Container
      type="button"
      revision={revision}
      color={color}
      background={background}
      hoverEffect={hoverEffect}
      {...rest as any}
    >
        {isLoading ? (
          <Loading
            color={loadingColor[revision]}
            size={8}
          />
        ) : (
          children
        )}
    </Container>
  )
};

export default memo(Button);
