import React, { useState, useMemo, createContext, useContext } from 'react';
import { connect, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { IState } from '../../../../store';
import { ITesouroWallet } from '../../../../store/modules/tesouroWallet/types';

import {
  Container,
} from './styles';

import { useTheme } from 'styled-components';
import sortTesourosByFilter from './sortTesourosByFilter';
import ExcludeWalletModal from './ExcludeWalletModal';
import { TesouroHeaderInfos } from './TesouroHeaderInfos';
import { getContainerAnimation } from '../../../../resources/animations';
import { Box } from '../../../../components';
import { TesourosDistributionChart } from './TesourosDistributionChart';
import { TesouroPatrimonyValueChart } from './TesouroPatrimonyValueChart';
import { TesourosTypesDistributionChart } from './TesourosTypesDistributionChart';
import { TesouroProfitList } from './TesouroProfitList';
import { TesouroOrdersList } from './TesouroOrdersList';

interface ITesouroWalletProps { }

interface TesouroWalletContextData {
  wallet: ITesouroWallet;
}

const TesouroWalletContext = createContext<TesouroWalletContextData>({} as TesouroWalletContextData);


const TesouroWallet: React.FC<ITesouroWalletProps> = () => {
  const location = useLocation();
  const theme = useTheme();

  const [reverseFilter, setReverseFilter] = useState(true);
  const [isExcludeWalletModalOn, setIsExcludeWalletModalOn] = useState(false);
  const [currentFilter, setCurrentFilter] = useState('');

  const tesouroWallet = useSelector<IState, ITesouroWallet>(state => {
    const [id] = location.pathname.split('/').reverse();

    const wallet = state.tesouro.tesouro_wallets.find(tesouro_wallet => tesouro_wallet.id === id);

    if (!wallet) {
      return state.tesouro.tesouro_wallets[0];
    }

    return wallet;
  });

  const tesouros = useMemo(() => {
    const bigger = reverseFilter ? 1 : -1;
    const tesouros = [...tesouroWallet.tesouros];

    const sortedTesouros = sortTesourosByFilter(currentFilter, tesouros, tesouroWallet.current_wallet_value, bigger);

    return sortedTesouros;
  }, [tesouroWallet, currentFilter, reverseFilter]);

  const handleChangeFilter = (newFilter: string) => {
    if (newFilter === currentFilter) {
      setReverseFilter(!reverseFilter);
      return;
    }

    setCurrentFilter(newFilter)
  }

  return (
    <TesouroWalletContext.Provider
      value={{
        wallet: tesouroWallet
      }}
    >
      <Container
        variants={getContainerAnimation(0)}
      >
        <TesouroHeaderInfos
          setIsExcludeWalletModalOn={setIsExcludeWalletModalOn}
        />
        <Box w={'100%'} flexDirection='column'>
          <Box mb={'1.6rem'}>
            <TesouroPatrimonyValueChart />
            <Box ml={'1.6rem'}>
              <TesourosDistributionChart />
            </Box>
          </Box>
          <Box w={'100%'} mb={'1.6rem'}>
            <TesourosTypesDistributionChart />
            <Box mr="1.6rem"/>
            <TesouroOrdersList />
          </Box>
          <Box>
            <TesouroProfitList />
          </Box>
        </Box>

        <ExcludeWalletModal
          closeModal={() => setIsExcludeWalletModalOn(false)}
          isVisible={isExcludeWalletModalOn}
          name={tesouroWallet.name}
          id={tesouroWallet.id}
        />
      </Container>
    </TesouroWalletContext.Provider>
  );
};

export function useTesouroWallet(): TesouroWalletContextData {
  const context = useContext(TesouroWalletContext);

  return context;
}

const mapStateToProps = (state: IState) => ({
  wallets_profits: state.actives.wallets_profits,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(TesouroWallet);
