import { Reducer } from "redux";
import { IWalletState, WalletActionTypes } from "./types";
import produce from 'immer';

const INITIAL_THEME_STATE = {
  wallets: [],
  isLoadingWallets: true,
}

const walletReducer: Reducer<IWalletState> = (state = INITIAL_THEME_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case WalletActionTypes.GET_WALLETS_REQUEST: {
        draft.isLoadingWallets = true;

        break;
      }
      case WalletActionTypes.GET_WALLETS_SUCCESS: {
        draft.wallets = action.payload.wallets;
        draft.isLoadingWallets = false;

        break;
      }
      case WalletActionTypes.GET_WALLETS_FAILURE: {
        draft.isLoadingWallets = false;

        break;
      }
      case WalletActionTypes.SET_LOADING_WALLETS: {
        draft.isLoadingWallets = true;

        break;
      }
      default: {
        return draft;
      }
    }
  });
}

export default walletReducer;
