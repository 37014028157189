import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 10;

  margin-top: 4rem;
`;

export const Wrapper = styled(motion.div)`
${({ theme }) => css`
  max-width: ${theme.spacing.middleWrapperSpacing};
  width: 100%;
  height: 100%;

  display: inline-block;

  display: flex;
  align-items: flex-start;
  justify-content: center;

  flex: 1;

  @media (max-width: 670px) {
    flex-direction: column;
    max-width: 100%;
  }
`}
`;

export const WrapperWithoutAnimation = styled.div`
  max-width: 113rem;
  width: 100%;
  height: 100%;

  display: inline-block;

  display: flex;
  align-items: flex-start;
  justify-content: center;

  flex: 1;
`;
