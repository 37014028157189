import { useQuery } from "@tanstack/react-query"
import { IPoint } from "../../../../hooks/react-query/usePatrimonyChart";
import api from "../../../../services/api"

interface IStockHistoricalValue {
  historicalValues: {
    values: IPoint[]
    maxValue: number;
    minValue: number;
  }
  historicalInvestment: {
    values: IPoint[]
    maxValue: number;
    minValue: number;
  }
  historicalProfitability: {
    values: IPoint[]
    maxValue: number;
    minValue: number;
  }
  totalPapers: {
    values: IPoint[]
    maxValue: number;
    minValue: number;
  }
  totalInvestedValue: number
  totalCurrentValue: number
  profitability: number
  profitabilityInPercent: number
}

export const useStockHistoricalValue = (stock_id: string) => {
  const query = useQuery(['stock-historical-value', stock_id], async () => {
    const response = await api.get<IStockHistoricalValue>(`/stocks/get-stock-historical-values/${stock_id}`)

    return response.data
  })

  return query
}
