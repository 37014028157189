import styled from 'styled-components';


export const Container = styled.footer`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;

  margin-top: 6.4rem;
`;

