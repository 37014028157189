import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import Button from '../../../../components/Button';

export const Container = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  background: none;
  border-radius: 50%;

  margin-left: 2.4rem;

  position: relative;

  > img {
    height: 4.8rem;
    width: 4.8rem;
  }
`;

export const AnimatedDropdown = styled(motion.nav)`
  ${({ theme }) => css`
    position: absolute;
    z-index: 100;
    top: 128%;
    right: 0;
    min-width: 14.6rem;
    border-radius: 0.4rem;
    background: ${theme.colors.lightGrey};
    box-shadow: 0 0.6rem 0.6rem rgba(0, 0, 0, 0.12);

    ::before {
      content: '';
      display: block;
      position: absolute;
      top: -12%;
      right: 11%;
      border-color: ${`transparent transparent ${theme.colors.lightGrey} transparent `};
      border-style: solid;
      border-width: 0.8rem;
    }
  `}
`;

export const ProfileContainer = styled.div`
${({ theme }) => css`
    position: relative;
`}
`;

export const Tool = styled.div`
${({ theme }) => css`
    position: absolute;
    bottom: 0;
    right: -0.5rem;

    /* box-shadow: ${theme.shadows.new_default}; */

    background: ${theme.colors.lightGrey};
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    height: 1.8rem;
    width: 1.8rem;

    > svg {
      color: ${theme.colors.secondary};
      height: 1.4rem;
      width: 1.4rem;
    }
`}
`;

export const NavButton = styled(Button)<{ isWarning?: boolean }>`
  ${({ theme, isWarning }) => css`
    width: 100%;
    padding: 1.2rem 1.6rem;
    justify-content: space-between;
    font-size: ${theme.fontSizes.default};
    background: ${theme.colors.lightGrey};

    > svg {
      margin-right: 0.8rem;
    }

    :hover {
      background: ${theme.colors.background};
    }

    :first-child {
      border-radius: 0.4rem 0.4rem 0 0;
    }

    :last-child {
      border-radius: 0 0 0.4rem 0.4rem;
    }

    ${isWarning && css`
      color: ${theme.colors.error};
    `}
  `}
`;
