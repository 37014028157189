import React from 'react';

import {
  Container,
} from './styles';

import { useLocation } from 'react-router-dom';
import { HeaderInfos } from './HeaderInfos';
import { StockPatrimonyGraph } from './StockPatrimonyGraph';
import { Box } from '../../../components';
import { DividendsGraph } from './DividendsGraph';
import { DividendsTable } from './DividendsTable';
import { DailyStockProfitGraph } from './DailyStockProfitGraph';

interface StockInfosContextData {
  stock_id: string;
}

const StockInfosContext = React.createContext<StockInfosContextData>({} as StockInfosContextData);

const StockInfosProvider: React.FC = () => {
  const location = useLocation();
  const [id] = location.pathname.split('/').reverse();

  return (
    <StockInfosContext.Provider value={{ stock_id: id }}>
      <StockInfos />
    </StockInfosContext.Provider>
  )
}

export const useStockInfos = (): StockInfosContextData => {
  const context = React.useContext(StockInfosContext);

  if (!context) {
    throw new Error('useStockInfos must be used within an StockInfosProvider');
  }

  return context;
}


const StockInfos: React.FC = () => {
  return (
    <Container>
      <div>
        <HeaderInfos />
      </div>
      <Box mb={'3.2rem'} />
      <div>
        <StockPatrimonyGraph />
      </div>
      <Box mb={'3.2rem'} />
      <Box>
        <Box w={'50%'}>
          <DividendsGraph />
        </Box>
        <Box mr={'1.6rem'} />
        <Box w={'50%'}>
          <DividendsTable />
        </Box>
      </Box>
      <Box mb={'3.2rem'} />
      <Box>
        <DailyStockProfitGraph />
      </Box>
    </Container>
  );
};

export default StockInfosProvider;
