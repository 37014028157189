import { Reducer } from "redux";
import { IUserState, UserActionTypes } from "./types";
import produce from 'immer';
import { storageKey } from "../../../hooks/useLocalStorage";

const INITIAL_THEME_STATE = {
  user: null,
  hasDismissedOnboarding: false,
  isLoadingUser: true,
}

const userReducer: Reducer<IUserState> = (state = INITIAL_THEME_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case UserActionTypes.LOGIN_SUCCESS: {
        draft.user = {
          id: action.payload.user.id,
          email: action.payload.user.email,
          phone: action.payload.user.phone,
          hasDismissedOnboarding: action.payload.user.hasDismissedOnboarding,
        }

        draft.isLoadingUser = false;

        break;
      }
      case UserActionTypes.LOGIN_FAILURE: {
        draft.isLoadingUser = false;

        break;
      }
      case UserActionTypes.LOGIN_REQUEST: {
        draft.isLoadingUser = true;

        break;
      }
      case UserActionTypes.LOGOUT_REQUEST: {
        draft.isLoadingUser = true;

        break;
      }
      case UserActionTypes.LOGOUT_SUCCESS: {
        draft.user = null

        localStorage.removeItem(storageKey('token'))
        localStorage.removeItem(storageKey('refresh_token'))

        draft.isLoadingUser = false;

        break;
      }
      case UserActionTypes.LOGOUT_FAILURE: {
        draft.isLoadingUser = false;

        break;
      }
      case UserActionTypes.SET_LOADING_USER: {
        draft.isLoadingUser = true;

        break;
      }
      default: {
        return draft;
      }
    }
  });
}

export default userReducer;
