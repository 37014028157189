import { Dispatch } from "redux";
import { getPersonalizedWalletsSuccess, getPersonalizedWalletsFailure, getPersonalizedWalletsRequest } from "../store/modules/personalized_wallets/actions";
import api from "./api";

export const fetchPersonalizedWallets = async (dispatch: Dispatch<any>, async?: boolean) => {
  console.log('fetching personalized wallets...', Date.now())

  if (async) {
    dispatch(getPersonalizedWalletsRequest());
  }

  try {
    const response = await api.get('/personalized-wallets');
    dispatch(getPersonalizedWalletsSuccess(response.data));
  } catch (err) {
    console.log('error', err)
    dispatch(getPersonalizedWalletsFailure('error'));
  }
}
