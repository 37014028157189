import React from 'react';

import * as Styled from './styles';

interface IntegrationOptionProps {
  icon: JSX.Element;
  iconText: string;
  description: string;
  onClick: () => void;
  disabled?: boolean
}

const IntegrationOption: React.FC<IntegrationOptionProps> = ({
  description,
  icon,
  iconText,
  onClick,
  disabled,
}) => {
  return (
  <Styled.Container
    onClick={onClick}
    disabled={disabled}
  >
    <Styled.BigIcon>
      {!!icon && icon}
      {iconText}
    </Styled.BigIcon>
    <Styled.Text>{description}</Styled.Text>
  </Styled.Container>
  )
}

export default IntegrationOption
