import { ITesouroWallet, TesouroWalletActionTypes } from "./types";

export function getTesouroWalletsRequest() {
  return {
    type: TesouroWalletActionTypes.GET_TESOURO_WALLETS_REQUEST,
  }
}

export function getTesouroWalletsSuccess(tesouro_wallets: ITesouroWallet[]) {
  return {
    type: TesouroWalletActionTypes.GET_TESOURO_WALLETS_SUCCESS,
    payload: {
      tesouro_wallets,
    }
  }
}

export function getTesouroWalletFailure(error: string) {
  return {
    type: TesouroWalletActionTypes.GET_TESOURO_WALLETS_FAILURE,
    payload: {
      error,
    }
  }
}
