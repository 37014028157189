import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

import { ButtonProps } from './Button.d';

export const Container = styled.button<ButtonProps>`
  ${({ theme, width, revision, color, background, disabled, hoverEffect }) => css`
    display: flex;
    align-items: center;
    justify-content: center;

    background: ${background || theme.colors.background};

    border-radius: 0.8rem;

    ${width && css`
      width: ${width};
    `}

    border: 0;
    padding: 0.8rem 2.4rem;
    /* border-radius: 0.4rem; */
    color: ${color || theme.colors.primary};

    ${disabled && css`
      opacity: 0.5;
      cursor: not-allowed;
    `}

    ${revision === 'primary' && css`
      background: ${theme.colors.primary};
      color: ${theme.colors.background};
    `}

    ${revision === 'transparent' && css`
      background: transparent;
    `}

    ${revision === 'secondary' && css`
      background: transparent;
      border: 0.1rem solid ${({ theme }) => theme.colors.primary};
    `}

    ${hoverEffect && css`
      transition: 0.45s;

      &:hover {
        transform: scale(1.008);
        box-shadow: 0px 0.2rem 0.2rem ${transparentize(0.72, theme.colors.primary)};
      }
    `}
  `}
`;
