import { storageKey } from "../hooks/useLocalStorage";
import api from "./api";

export const refreshUserToken = async (refresh_token: string) => {
  try {
    const response = await api.post('/session/refresh-token', {
      token: refresh_token,
    });

    localStorage.setItem(storageKey('token'), JSON.stringify(response.data.token));
    localStorage.setItem(storageKey('refresh_token'), JSON.stringify(response.data.refresh_token));

    api.defaults.headers.authorization = `Bearer ${response.data.token}`

    return response.data;
  } catch (err) {
    localStorage.removeItem(storageKey('token'));
    localStorage.removeItem(storageKey('refresh_token'));

    throw err;
  }
}
