import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

export const Container = styled(motion.div)`
${({ theme }) => css`
  max-width: ${theme.spacing.mainWidth};
  width: 100%;

  background: 0;
  border-radius: 0.8rem;

  display: flex;
  flex-direction: column;

  > div:nth-child(5) {
    margin-bottom: 1.6rem;
  }

  > div:nth-child(3) {
    display: flex;
    flex-direction: row;

    margin-bottom: 1.6rem;
    height: 40rem;
  }

  > div:nth-child(4) {
    display: flex;
    flex-direction: row;

    margin-bottom: 1.6rem;
  }


  >div:nth-child(5) {
    display: flex;
    flex-direction: row;

    max-height: 72.5rem;
  }

  @media (max-width: 670px) {
    > div:nth-child(3) {
      flex-direction: column;

      height: 100%;
    }

    >div:nth-child(5) {
      flex-direction: column;

      max-height: 100%;
    }

    > div:nth-child(4) {
      flex-direction: column;
      max-height: 100%;
    }

    max-width: 100%;
  }
`}
`;
