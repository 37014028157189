import React, { useMemo } from 'react';
import { FiCloudLightning, FiCreditCard, FiUploadCloud } from 'react-icons/fi';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ImportTypes } from '..';
import { IState } from '../../../../../../store';
import { IWallet } from '../../../../../../store/modules/wallet/types';
import ImportOptionComponent from './ImportOption';

import {
  Container,
} from './styles';

interface ChooseImportTypeProps {
  setImportType(x: ImportTypes): void;
  handleMoveToNextStep(): void;

  wallets: IWallet[];
}

const ChooseImportType: React.FC<ChooseImportTypeProps> = ({ wallets, setImportType, handleMoveToNextStep }) => {
  const history = useHistory();

  const handleChooseImportType = (type: ImportTypes) => {
    handleMoveToNextStep();
    setImportType(type);
  }

  const userWithoutWallet = useMemo(() => {
    return !wallets.length;
  }, [wallets]);

  const handleGoToCreateWallet = () => {
    history.push('/dashboard/create-wallet');
  }

  return (
    <Container>
      {userWithoutWallet && (
        <ImportOptionComponent
          icon={(<FiCreditCard />)}
          onClick={() => handleGoToCreateWallet()}
          title={'Parece que você ainda não tem carteiras no Wllt!'}
          details={'Crie suas carteiras para poder importar suas ordens!!'}
        />
      )}

      <ImportOptionComponent
        disabled={userWithoutWallet}
        icon={(<FiUploadCloud />)}
        onClick={() => handleChooseImportType(ImportTypes.MANUAL)}
        title={'Importação Inteligente por Planilha'}
        details={'Envie uma planilha com suas últimas movimentações e atualize suas carteiras.'}
      />

      <ImportOptionComponent
        disabled
        icon={(<FiCloudLightning />)}
        onClick={() => {}}
        title={'Integração Automatizada'}
        details={'A inteligência da Wllt possui uma integração com o CEI - Canal Eletrônico do Investidor. Sincronize sua conta e passe a gerenciar seus ativos de maneira mais rápida.'}
      />
    </Container>
  )
}

const mapStateToProps = (state: IState) => ({
  wallets: state.wallet.wallets,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ChooseImportType)
