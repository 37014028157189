import { motion } from "framer-motion";
import { transparentize } from "polished";
import styled, { css } from "styled-components";

export const Container = styled(motion.div)<{ isExpanded?: boolean }>`
${({ theme, isExpanded }) => css`
  background: ${theme.colors.background};
  border-radius: 0.4rem;

  ${!isExpanded && css`
    margin-top: -30rem;
  `}

  z-index: 100;
`}
`;

export const Heading = styled.div`
${({ theme }) => css`
  display: flex;
  justify-content: space-between;
  align-items: center;

  min-width: 30rem;

  padding: 2.4rem;

  > h1 {
    color: ${theme.colors.primary};
    font-size: ${theme.fontSizes.huge};
    margin-right: 4rem;
  }

  > button {
    background: 0;

    border: 0.1rem solid ${theme.colors.primary};
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 0.2rem;

    padding: 0.1rem;

    > svg {
      color: ${theme.colors.primary};
    }
  }
`}
`

export const NoContentWrapper = styled.div`
${({ theme }) => css`
  background: ${theme.colors.background};
  padding: 2.4rem;
  border-radius: 0.4rem;

  z-index: 100;

  display: flex;
  flex-direction: column;
  align-items: center;

  > p {
    margin-bottom: 1.6rem;
  }
`}
`;

export const SelectWalletContainer = styled.div`
${({ theme }) => css`
  height: 6rem;

  > div {
    height: 100%;
  }
`}
`;
