import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { FormattedOrder, StatusType } from '..';
import Currency from '../../../../../../components/Currency';
import Percentage from '../../../../../../components/Percentage';
import { IState } from '../../../../../../store';
import { ICryptoWallet } from '../../../../../../store/modules/crypto/types';
import { ITesouroWallet } from '../../../../../../store/modules/tesouroWallet/types';
import { IWallet } from '../../../../../../store/modules/wallet/types';

import {
  Container,
  FirstRow,
  CongratsContainer,
  BalanceContainer,
  WalletsContainer,
  WalletContainer,
  WalletBalances,
  OldBalance,
  NewBalance,
  WalletInfos,
  ValueIncrease,
  ActivesIncreased,
} from './styles';

interface FinalScreenProps {
  formattedOrders: FormattedOrder[];
  wallets: IWallet[];
  tesouro_wallets: ITesouroWallet[];
  crypto_wallets: ICryptoWallet[];
  initialWalletValues: {
    [key: string]: number;
  }
}

const FinalScreen: React.FC<FinalScreenProps> = ({
  wallets,
  formattedOrders,
  crypto_wallets,
  tesouro_wallets,
  initialWalletValues,
}) => {
  const numberOfOrders = useMemo(() => {
    const checkedOrders = formattedOrders.filter(order => !!order.isChecked);

    return checkedOrders.length;
  }, [formattedOrders]);

  const patrimony = useMemo(() => {
    let total = 0;

    wallets.forEach(wallet => {
      total += wallet.current_wallet_value;
    });

    tesouro_wallets.forEach(tesouroWallet => {
      total += tesouroWallet.current_wallet_value;
    });

    crypto_wallets.forEach(cryptoWallet => {
      total += cryptoWallet.current_wallet_value * 100;
    });

    return total;
  }, [wallets, tesouro_wallets, crypto_wallets]);

  const walletIncreasePercentageById = useMemo(() => {
    const increase = {} as any;

    wallets.forEach((wallet) => {
      const walletNewValue = wallet.current_wallet_value;
      const walletOldValue = initialWalletValues[wallet.id];

      const variation = walletOldValue ? (walletNewValue - walletOldValue) / walletOldValue * 1000 : 1000

      increase[wallet.id] = variation;
    });

    return increase;
  }, [wallets, formattedOrders]);

  const ordersIncreaseById = useMemo(() => {
    const orderNumber = {} as {[key: string]: number}

    wallets.forEach((wallet) => {
      const formattedWalletOrders = formattedOrders.filter(ord => ord.wallet_id === wallet.id && ord.isChecked);
      orderNumber[wallet.id] = formattedWalletOrders.length;
    });

    return orderNumber;
  }, [wallets, formattedOrders])

  return (
    <Container>
      <FirstRow>
        <CongratsContainer>
          <h1>Parabéns!</h1>
          <span>
            <strong>{numberOfOrders}</strong> Novas ordens Foram adicionadas!
          </span>
        </CongratsContainer>

        <BalanceContainer>
          <span>Novo Patrimônio:</span>
          <Currency
            value={patrimony}
          />
        </BalanceContainer>
      </FirstRow>

      <WalletsContainer>
        {wallets.map(wallet => (
          <WalletContainer>
            <h2>{wallet.name}</h2>

            <WalletBalances>
              <OldBalance>
                <Currency
                  value={initialWalletValues[wallet.id]}
                />
              </OldBalance>
              <NewBalance>
                <Currency
                  value={wallet.current_wallet_value}
                />
              </NewBalance>
            </WalletBalances>

            <WalletInfos>
              <ValueIncrease>
                <Percentage
                  value={walletIncreasePercentageById[wallet.id]}
                />
              </ValueIncrease>
              <ActivesIncreased>
                <strong>{ordersIncreaseById[wallet.id]}</strong> Novas Ordens
              </ActivesIncreased>
            </WalletInfos>
          </WalletContainer>
        ))}
      </WalletsContainer>
    </Container>
  )
}

const mapStateToProps = (state: IState) => ({
  wallets: state.wallet.wallets,
  tesouro_wallets: state.tesouro.tesouro_wallets,
  crypto_wallets: state.crypto.crypto_wallets,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(FinalScreen)
