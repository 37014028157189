import styled, { css } from 'styled-components'

export const ValueContainer = styled.div`
${({ theme }) => css`
  display: flex;
  flex-direction: column;

  margin-bottom: 1.6rem;

  > label {
    margin-bottom: 0.8rem;
  }

  > div {
    display: flex;
    flex-direction: column;

    display: flex;

    height: 4rem;

    padding: 0.8rem 0.8rem;
    border-radius: 0.8rem;

    background: ${theme.colors.lightGrey};

    border: solid 1px ${theme.colors.grey};
  }
`}
`

export const Heading = styled.div`
${({ theme }) => css`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 2.4rem;

  > h1 {
    color: ${theme.colors.primary};
    font-size: ${theme.fontSizes.huge};
    margin-right: 4rem;
  }

  > button {
    background: 0;

    border: 0.1rem solid ${theme.colors.primary};
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 0.2rem;

    padding: 0.1rem;

    > svg {
      color: ${theme.colors.primary};
    }
  }
`}
`

export const AmountContainer = styled.div`
${({ theme }) => css`
  padding: 2rem 2.4rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  > span {
    color: ${theme.colors.grey};
    font-size: ${theme.fontSizes.default};
    max-width: 25%;
  }

  > div {
    max-width: 50%;

    border: solid 1px ${theme.colors.lightestGrey};

    > input {
      background: ${theme.colors.lightGrey};
      border: 0;

      color: ${theme.colors.white};

      padding: 0.8rem 1.6rem;
    }
  }
`}
`;

export const SubmitButtonContainer = styled.div`
${({ theme }) => css`
  width: 100%;
  display: flex;
  flex: 1;

  margin-top: 1.6rem;

  > button {
    flex: 1;
    border-radius: 0;
  }
`}
`
