import React from 'react';
import { Container } from './styles';

interface CardTitleProps {
  title: string;
  type?: 'primary' | 'secondary'
}

const CardTitle: React.FC<CardTitleProps> = ({ title, type = 'primary' }) => {
  return (
    <Container
      type={type}
    >
      {title}
    </Container>
  )
}

export default CardTitle;
