import React, { useState, useRef, useCallback } from 'react';
import { useTheme } from 'styled-components';
import { connect, useDispatch } from 'react-redux';

import { FiLogOut } from 'react-icons/fi'

import { AnimatePresence } from 'framer-motion';
import {
  Container,
  AnimatedDropdown,
  NavButton,
  ProfileContainer,
  Tool,
} from './styles';

import { IState } from '../../../../store';
import { DEFAULT_TRANSITION } from '../../../../constants';
import useEventListener from '../../../../hooks/useEventListener';
import { userLogout } from '../../../../services/user';
import { IUser } from '../../../../store/modules/user/types';
import { VscGear } from 'react-icons/vsc';

type AccountDropDownProps = {
  user: IUser | null;
  isLightMode: boolean;
  realizationIsEnabled: boolean;
}

const removeHashFromColor = (color: string): string => color.slice(1);

export const DROP_DOWN_ANIMATION = {
  visible: {
    y: 0,
    opacity: 1,
    transition: DEFAULT_TRANSITION,
  },
  hidden: {
    y: -20,
    opacity: 0,
    transition: DEFAULT_TRANSITION,
  },
};

const AccountDropDown: React.FC<AccountDropDownProps> = ({ user }) => {
  const dispatch = useDispatch();

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleSignOut = () => {
    userLogout(dispatch);
    window.location.reload();
  }

  const handleCloseDropdown = useCallback(({ target }: Event): void => {
    if (dropdownRef.current?.contains(target as Node)) {
      return;
    }

    setIsDropdownVisible(false);
  }, [setIsDropdownVisible]);

  useEventListener('click', handleCloseDropdown, {
    enabled: isDropdownVisible,
  });

  const handleDropdownVisibility = (): void => {
    setIsDropdownVisible(true);
  };

  const { lightGrey, secondary } = useTheme().colors;

  return (
    <ProfileContainer>
      <Container
        onClick={handleDropdownVisibility}
      >
        <img src={`https://ui-avatars.com/api/?rounded=true&format=svg&background=${removeHashFromColor(lightGrey)}&color=${removeHashFromColor(secondary)}&name=${user ? user.email : ''}`} alt="user" />
        <Tool>
          <VscGear />
        </Tool>
      </Container>
      <AnimatePresence>
        {isDropdownVisible && (
          <AnimatedDropdown
            variants={DROP_DOWN_ANIMATION}
            initial="hidden"
            animate="visible"
            exit="hidden"
            ref={dropdownRef}
          >
            <ul>
              <NavButton onClick={handleSignOut}>
                <FiLogOut />
                Sair da Conta
              </NavButton>
            </ul>
          </AnimatedDropdown>
        )}
      </AnimatePresence>
    </ProfileContainer>
  );
};

const mapStateToProps = (state: IState) => ({
  user: state.user.user,
  isLightMode: state.themeSelector.isLightMode,
  realizationIsEnabled: state.themeSelector.realizationIsEnabled,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(AccountDropDown)
