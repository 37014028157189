import { AnimatePresence, motion, useViewportScroll } from 'framer-motion';
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { SMOOTH_TRANSITION } from '../../constants';
import { IState } from '../../store';
import { IToast } from '../../store/modules/toast/types';
import ToastMessage from '../ToastMessage';

import {
  Container,
  ToastMessages,
} from './styles';

interface ToastMessagesContainerProps {
  toastMessages: IToast[];
}

const AnimatedCard = motion.div;

const ToastMessageContainer: React.FC<ToastMessagesContainerProps> = ({ children, toastMessages }) => {
  useEffect(() => {

  }, []);

  return (
    <Container>
      <>
        {children}

        <ToastMessages>
          <AnimatePresence>
              {toastMessages.map(toast => (
                <AnimatedCard
                  layout
                  key={`card-${toast.created_at.getTime()}`}
                  initial={{ opacity: 0, x: 420 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: 420 }}
                  transition={SMOOTH_TRANSITION}
                  whileTap={{ x: 5 }}
                >
                  <ToastMessage toast={toast} />
                </AnimatedCard>
              ))}
          </AnimatePresence>
        </ToastMessages>
      </>
    </Container>
  );
}

const mapStateToProps = (state: IState) => ({
  toastMessages: state.toast.toastMessages,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ToastMessageContainer)
