import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

export const Container = styled.div<{ type?: string, isOpened?: boolean }>`
${({ theme, type, isOpened }) => css`
  display: flex;
  align-items: center;

  position: relative;

  box-sizing: padding-box;
  padding: 1.6rem;

  background-color: ${theme.colors.lightGrey};

  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;

  ${type === 'secondary' && css`
    border: solid 1px ${theme.colors.grey};

    ${!isOpened && css`
      border-radius: 0.2rem;
    `}

    ${isOpened && css`
      border-bottom: 0;
    `}
  `};

  > svg {
    height: 2.4rem !important;
    width: 2.4rem !important;
    stroke-width: 0.2rem;
  }
`}
`;

export const Input = styled.input<{
  hasSelected: boolean
}>`
${({ theme, hasSelected }) => css`
  width: 100% !important;

  padding: 0 1.2rem;

  border: 0;
  background: 0;

  color: ${theme.colors.white};

  ${hasSelected && css`
    color: ${theme.colors.primary};
  `}
`}
`;

export const OptionsContainer = styled(motion.div)<{
  type?: string;
  padding?: boolean;
  isOpened?: boolean;
  maxContainerHeight?: number;
}>`
${({ theme, padding, type, isOpened, maxContainerHeight }) => css`
  position: absolute;
  top: 100%;
  left: -1px;

  z-index: 5;

  ${maxContainerHeight ? css`
    max-height: ${maxContainerHeight}px;
  ` : css`
    max-height: 40rem;
  `}
  overflow-y: scroll;

  border-bottom-left-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;

  background: ${theme.colors.lightGrey};

  width: calc(100% + 0.2rem);

  ${padding && css`
    padding: 0 1.6rem 1.6rem 1.6rem;
  `}

  ${type === 'secondary' && css`
    left: -1px;
    width: calc(100% + 0.2rem);
    border: solid 1px ${theme.colors.grey};

    ${isOpened && css`
      border-top: 0;
    `}
  `};

  > p {
    font-size: ${theme.fontSizes.default};
    font-weight: bold;
    color: ${theme.colors.white};

    margin-left: 0.8rem;
    margin-bottom: 0.8rem;
  }
`}
`;

export const ActiveTypes = styled.div`
${({ theme }) => css`
  width: 100%;

  background: ${theme.colors.background};

  padding: 1.2rem 4rem;
  border-radius: 0.8rem;

  margin-bottom: 1.6rem;

  > span + span {
    margin-left: 0.8rem;
  }
`}
`;

export const Tag = styled.span<{ active?: boolean }>`
${({ theme, active }) => css`
  color: ${theme.colors.white};
  font-weight: bold;

  font-size: ${theme.fontSizes.default};

  padding: 0.4rem 0.8rem;

  border-radius: 0.8rem;

  cursor: pointer;

  ${active && css`
    background-color: ${theme.colors.extremeLightGrey};
  `}
`}
`;
