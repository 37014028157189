import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { IState } from '../../../../store';
import { IStockInfos } from '../../../../store/modules/actives/types';

import { animations } from '../../../../resources';

import Button from '../../../../components/Button';

import {
  Container,
  NoContentWrapper,
  StocksContainer,
} from './styles';
import { useStocksTodayRank } from '../../../../hooks/react-query/useStocksTodayRank';
import ActiveRankList from './ActiveRankList';
import { Box } from '../../../../components/Box';


const containerAnimation = {
  ...animations.containerAnimation,
  mounted: {
    ...animations.containerAnimation.mounted,
    transition: {
      ...animations.containerAnimation.mounted.transition,
      duration: undefined,
      staggerChildren: undefined,
      delay: 0.7,
    }
  }
}

const ActivesInfos: React.FC = () =>  {
  const { data: rank } = useStocksTodayRank()

  const history = useHistory();

  const handleGoToCreateWallet = () => {
    history.push('/dashboard/create-wallet');
  }

  const shouldShowStockInfos = useMemo(() => {
    if (rank?.negativeRank?.length === 0 && rank.positiveRank?.length === 0) {
      return false;
    }

    return true;
  }, [rank]);

  return (
    <Container
      variants={containerAnimation}
    >
      <div>
        <h1>Destaques de suas Carteiras</h1>
      </div>

      {!shouldShowStockInfos && (
        <NoContentWrapper>
          <p>Os destaques de suas carteiras aparecerão aqui quando criá-las!</p>
          <Button
            onClick={handleGoToCreateWallet}
          >
            Criar Carteira
          </Button>
        </NoContentWrapper>
      )}

      {shouldShowStockInfos && (
        <StocksContainer>
          <div>
            {!!rank?.positiveRank?.length && (
              <ActiveRankList
                type='positive'
              />

            )}

            {rank?.positiveRank?.length === 0 && (
              <p>Parece que nenhum dos ativos de suas carteiras se valorizou hoje :(</p>
            )}
          </div>
          <div>
            {!!rank?.negativeRank?.length && (
              <ActiveRankList
                type='negative'
              />
            )}

            {rank?.negativeRank?.length === 0 && (
              <p>Parece que nenhum dos ativos de suas carteiras se desvalorizou hoje :)</p>
            )}
          </div>
        </StocksContainer>
      )}
    </Container>
  );
};

export default ActivesInfos;
