import React from 'react';

import * as Styled from './styles';

interface ExampleCardProps {
  img: any;
  description: string;
}

const ExampleCard: React.FC<ExampleCardProps> = ({ img, description }) => {
  return (
    <Styled.Container>
      <Styled.CardImage src={img} />
      <Styled.Description>{description}</Styled.Description>
    </Styled.Container>
  )
}

export default ExampleCard
