import styled, { css } from 'styled-components';
import Button from '../../components/Button';

export const StyledButton = styled(Button) <{ disabled?: boolean }>`
${({ theme, disabled }) => css`
  background: ${`linear-gradient(90deg, ${theme.colors.secondary} 0%, ${theme.colors.primary} 100%)`};

  height: 4rem;
  width: 100%;

  margin-bottom: 0.8rem;

  transition: 0.5s;
  :hover {
    opacity: 0.7;
  }

  ${disabled && css`
    opacity: 0.3;
    cursor: not-allowed;

    :hover {
      opacity: 0.3;
    }
  `}
`}
`;

export const Logo = styled.div`
${({ theme }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    font-size: ${theme.fontSizes.huge};
    margin-bottom: 4.8rem;

    > img {
      width: 25rem;
      margin-bottom: 0.8rem;
    }

    > span {
      max-width: 50%;
      text-align: center;
    }

    > h1 {
      text-align: center;
    }

    @media (max-width: 670px) {
      > span {
        max-width: 100%;
        text-align: center;
      }
    }
`}
`;

export const Form = styled.form`
${({ theme }) => css`
  width: 50%;

  > input:first-child {
    background: ${theme.colors.lightGrey};
    border: 0;
    padding: 0.8rem 1.6rem;
    width: 100%;

    font-size: ${theme.fontSizes.big};

    border: solid 1px ${theme.colors.grey};

    height: 4.8rem;

    border-radius: 0.8rem;
    color: ${theme.colors.grey};

    ::placeholder {
      color: ${theme.colors.grey};
    }
  }

  @media (max-width: 670px) {
    width: 100%;
  }
`}
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  > button {
    width: 100%;
  }
`;

export const RotatedBox = styled.div`
${({ theme }) => css`
  position: absolute;
  top: 25px;
  left: 150px;
  width: 250px;
  height: 250px;
  background-color: ${theme.colors.primary};

  -webkit-transform: rotate(45deg);  /* to support Safari and Android browser */
  -ms-transform: rotate(45deg);      /* to support IE 9 */
  transform: rotate(45deg);
`}
`

export const RotatedBox2 = styled.div`
${({ theme }) => css`
  position: absolute;
  bottom: 150px;
  right: 0px;
  width: 200px;
  height: 42px;
  background-color: ${theme.colors.primary};

  -webkit-transform: rotate(45deg);  /* to support Safari and Android browser */
  -ms-transform: rotate(45deg);      /* to support IE 9 */
  transform: rotate(45deg);
`}
`

export const TermsBox = styled.div`
${({ theme }) => css`
  display: flex;
  align-items: center;

  > label {
    > a {
      color: ${theme.colors.primary};
    }

    > a:active {
      color: ${theme.colors.primary};
    }

    > a:visited {
      color: ${theme.colors.primary};
    }
    > a:hover {
      color: ${theme.colors.secondary};
    }
  }

  > a:active {
    color: ${theme.colors.primary};
  }
`}
`
