import React from 'react';
import { useTheme } from 'styled-components';
import { Box, CardTitle, Loading } from '../../../components';
import { Table, TableProps } from '../../Table';
import { WidgetCard } from '../../WidgetCard';

import {
  Container,
} from './styles';

interface TableWidgetProps extends TableProps {
  title: string
  isLoading: boolean
}

export const TableWidget: React.FC<TableWidgetProps> = ({ isLoading, renderCells, title, columns, data }) => {
  const theme = useTheme()

  if (isLoading) {
    return (
      <WidgetCard w='100%' minHeight='40rem'>
        <CardTitle
          title={title}
          type='secondary'
        />
        <Box mb={'1.6rem'} />
        <Box w='100%' flex='1' alignItems='center' justifyContent='center'>
          <Loading color={theme.colors.primary} size={20} />
        </Box>
      </WidgetCard>
    )
  }

  return (
    <Container>
      <CardTitle
        title={title}
        type='secondary'
      />

      <Table
        columns={columns}
        data={data}
        renderCells={renderCells}
      />
    </Container>
  )
}
