import React from 'react';
import Lottie from 'react-lottie';

import loadingAnimation from '../../assets/animations/loading_animation.json';

import { Container } from './styles';

const LoadingComponent: React.FC = () => {
  return (
    <Container>
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: loadingAnimation,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
          }
        }}
        speed={1.5}
        height={300}
        width={300}
        isStopped={false}
        isPaused={false}
      />
    </Container>
  );
};

export default LoadingComponent;
