import { motion } from "framer-motion";
import styled from "styled-components";

export const Container = styled.div``;

export const ToastMessages = styled(motion.div)`
  position: fixed;
  top: 0;
  right: 0;

  z-index: 1000;
`;
