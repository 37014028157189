import React from 'react';
import NumberFormat from 'react-number-format';
import { Container } from './styles';
import { InputTypes } from '../Input';

interface DateInputProps {
  dateString: string;
  setDateString(x: string): void
  type?: InputTypes
}

export const DateInput = ({ dateString, setDateString, type = 'primary' }: DateInputProps) => {
  return (
    <Container
      type={type}
      format="##/##/####"
      defaultValue={dateString}
      value={dateString}
      placeholder="dd/mm/aaaa"
      mask={['d', 'd', 'm', 'm', 'a', 'a', 'a', 'a']}
      onChange={(e: any) => {
        setDateString(e.target.value)
      }}
    />
  )
}
