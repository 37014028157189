export enum CryptoWalletActionTypes {
  GET_CRYPTO_WALLETS_REQUEST = 'GET_CRYPTO_WALLETS_REQUEST',
  GET_CRYPTO_WALLETS_SUCCESS = 'GET_CRYPTO_WALLETS_SUCCESS',
  GET_CRYPTO_WALLETS_FAILURE = 'GET_CRYPTO_WALLETS_FAILURE',
}

export interface ICryptoWallet {
  id: string;
  user_id: string;
  name: string;
  total_wallet_value: number;
  current_wallet_value: number;
  yesterday_wallet_value_in_dollars: number;
  current_wallet_value_in_dollars: number;
  yesterday_wallet_value: number;
  variation: number;
  profit: number;
  profit_in_dollars: number;
  profit_in_percentage: number;
  cryptos: IWalletCrypto[];
}

export interface IWalletCrypto {
  id: string;
  wallet_id: string;
  percentage: number;
  is_active: boolean;
  name: string;
  currentValue: number;
  currentValueInDollars: number;
  cryptocurrency_id: string;
  todayVariation: number;
  totalPapers: number;
  totalPurchaseValue: number;
  totalCurrentValue: number;
  totalYesterdayValue: number;
  totalPurchaseValueInDollars: number;
  totalCurrentValueInDollars: number;
  totalYesterdayValueInDollars: number;
  currentPriceInDollars: number;
}

export interface ICryptoWalletsState {
  crypto_wallets: ICryptoWallet[];
  isLoadingCryptoWallets: boolean;
}
