import styled, { css } from 'styled-components';

export const Container = styled.div`
${({ theme }) => css`
  display: flex;
  align-items: center;
`}
`;

export const InputContainer = styled.div<{ isOpen?: boolean }>`
${({ theme, isOpen }) => css`
  background: ${theme.colors.primary};
  display: flex;
  align-items: center;
  justify-content: space-between;

  position: relative;

  font-size: ${theme.fontSizes.large};

  color: ${theme.colors.background};
  font-weight: bold;
  padding: 0.2rem 1rem;

  border-radius: 0.8rem;

  ${isOpen && css`
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  `}
`}
`;

export const WalletSelectionContainer = styled.div<{ isOpen?: boolean }>`
${({ theme, isOpen }) => css`
  position: absolute;
  top: 100%;
  left: 0px;

  width: 100%;

  background: ${theme.colors.background};
  z-index: 99;
  padding: 1.6rem;

  border: solid 0.1rem ${theme.colors.primary};

  border-bottom-left-radius: 0.8rem;
  border-bottom-right-radius: 0.8rem;

  max-height: 50rem;
  overflow-y: scroll;
  overflow-x: hidden;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${!isOpen && css`
    padding: 0rem;
    border: 0px;
  `}
`}
`;

export const Wallet = styled.button`
${({ theme }) => css`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  width: 100%;

  background: 0;

  color: ${theme.colors.white};

  margin-bottom: 1.6rem;

  > span {
    margin-left: 0.8rem;
  }
`}
`;

export const MainContainer = styled.button`
${({ theme }) => css`
  background: 0;
  font-size: ${theme.fontSizes.large};

  color: ${theme.colors.background};
  font-weight: bold;

  > svg {
    margin-left: 2.4rem;
  }
`}
`;
