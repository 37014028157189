import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

export const Container = styled(motion.tr)`
${({ theme }) => css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 0.8rem;

  width: 100%;

  background: ${theme.colors.background};
  box-shadow: ${theme.shadows.default};
  padding: 1.6rem;

  border-radius: 0.8rem;

  > svg {
    color: ${theme.colors.grey};
  }

  > td {
    > h6 {
      font-size: ${theme.fontSizes.large};
    }

    font-size: ${theme.fontSizes.small};
    width: 100%;
  }
`}
`;

export const Header = styled.td`
${({ theme }) => css`
  display: flex;
  align-items: center;
  width: 100%;

  > div {
    display: flex;
    align-items: center;

    width: 10rem;

    > h1 {
      font-size: ${theme.fontSizes.large};
      color: ${theme.colors.grey};
    }

    > svg {
      color: ${theme.colors.primary};
      margin-right: 0.8rem;
    }
  }
`}
`;
