import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import Button from '../../../components/Button';
import { motion } from 'framer-motion';
import Tooltip from '../../../components/Tooltip';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  flex: 1;

  margin-right: 2.4rem;

  @media (max-width: 670px) {
    max-width: 100%;
    width: 100%;
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    z-index: 50;

    margin-right: 0;
    margin-bottom: 0.8rem;
  }
`;

export const Container = styled(motion.section)`
  ${({ theme }) => css`
    background: ${({ theme }) => transparentize(0.05, theme.colors.background)};
    backdrop-filter: blur(8rem);
    max-height: 100rem;

    width: 100%;
    height: 100%;

    border-radius: 1.6rem 0.3rem 1rem 1.6rem;

    box-shadow: ${theme.shadows.default};

    padding: 3rem 2.4rem;
  `}
`;

export const AmountInvested = styled(Button)<{ home?: boolean }>`
${({ theme, home }) => css`
  display: flex;
  justify-content: flex-start;
  background: 0;

  font-size: ${theme.fontSizes.default};
  width: 100%;

  padding: 0.8rem 0rem;

  > svg {
    margin-right: 0.8rem;
    margin-left: 0.8rem;
  }

  letter-spacing: 0.16rem;
  transition: transform 0.5s, box-shadow 0.5s;

  ${home && css`
    justify-content: space-between;
    padding: 0rem 0rem 1.6rem 0;

    font-weight: bold;
  `}
`};
`;

export const ButtonsContainer = styled.div`
${({ theme }) => css`
  display: flex;
  flex-direction: row;
  padding: 0.8rem 0;
`}
`;

export const OpenMenuButton = styled(Button)`

`;

export const SidebarMenu = styled.div`

`;

export const AddWalletButton = styled(Button)`
${({ theme }) => css`
  padding: 0.4rem 0.8rem;
  margin-left: 0.8rem;

  height: 100%;

  transition: 0.5s;

  &:hover {
    background: ${theme.colors.primary};
    border-color: ${theme.colors.primary};

    > svg {
      color: ${theme.colors.background};
    }
  }
`}
`;

export const StyledTooltip = styled(Tooltip)``;

export const Separator = styled.div`
${({ theme }) => css`
  height: 0.1rem;

  margin-bottom: 2.4rem;

  background: ${theme.colors.extremeLightGrey};
`}
`;
