import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

export const Container = styled.div`
${({ theme }) => css`
  display: block;
  width: 100%;
  background: ${transparentize(0.0, theme.colors.background)};
  padding: 1.6rem;
  border-radius: 0rem 0rem 0.8rem 0.8rem;
`}
`;

export const TableWrap = styled.div`
  display: block;
  max-width: 100%;
`;

export const HeaderLabel = styled.button`
${({ theme }) => css`
  background: transparent;
  color: ${theme.colors.grey};
`}
`;

export const Table = styled.table`
${({ theme }) => css`
  width: 100%;
  border-spacing: 0;
  display: block;

  position: relative;

  overflow-y: scroll;
  overflow-x: hidden;
`}
`;

export const Head = styled.thead`
${({ theme }) => css`
  position: sticky;
  background: ${transparentize(0.01, theme.colors.background)};
  top: 0px;
`}`;

export const HeadRow = styled.tr`
  &:last-child {
    td {
      border-bottom: 0;
    }
  }
`;

export const HeadData = styled.th`
  &.collapse {
    width: 0.0000000001%;
  }

  &:last-child {
    border-right: 0;
  }

  text-align: left;
  color: #8C90A2;
  font-weight: 300;

  margin: 0;

  padding: 0.5rem;
  width: 1%;
`;

export const TableBody = styled.tbody`
  margin: 0;
  padding: 0.5rem;
  width: 1%;
`;

export const ContentRow = styled.tr``;

export const Content = styled.td`
${({ theme }) => css`
  &.collapse {
    width: 0.0000000001%;
  }

  &:last-child {
    border-right: 0;
  }

  font-size: ${theme.fontSizes.default};
  color: ${theme.colors.white};

  margin: 0;
  padding: 0.5rem;
  width: 1%;

  > h6 {
    font-size: ${theme.fontSizes.default};

    > p {
      font-weight: 300;
    }
  }
`}
`;


export const SellResult = styled.div`
${({ theme }) => css`
  display: flex;

  > h6 {
    font-size: ${theme.fontSizes.default};
    font-weight: bold;
  }
`}
`
