import React, { createContext, useContext, useMemo } from "react";
import { Show } from "../../components";
import { IOrder } from "../../hooks/react-query/useOrders";
import { EnumToUnion } from "../../utils/typings";
import { AuthenticatedModal } from "./AuthenticatedModal";
import { CreateWalletModal } from "./CreateWalletModal";
import { DeleteOrderModal } from "./DeleteOrderModal";
import { UpdateOrderModal } from "./UpdateOrderModal";

interface ModalContextData {
  turnModalOn: (modal: ModalTypes, modalContext?: ModalContext) => void;
  turnModalOff: (modal: ModalTypes, modalContext?: ModalContext) => void;
}

export enum ModalTypesEnum {
  CREATE_ORDER = 'CREATE_ORDER',
  CREATE_WALLET = 'CREATE_WALLET',
  DELETE_ORDER = 'DELETE_ORDER',
  UPDATE_ORDER = 'UPDATE_ORDER',
}

export type ModalTypes = EnumToUnion<ModalTypesEnum>

const ModalContext = createContext<ModalContextData>({} as ModalContextData);

interface OrderModalProps {
  order: IOrder | null;
}

type ModalContext = OrderModalProps

interface Drawer {
  type: ModalTypes;
  isOpen: boolean;
  context?: ModalContext
}

export const ModalsProvider: React.FC = ({ children }) => {
  const [modals, setModals] = React.useState<Drawer[]>(
    [
      {
        type: ModalTypesEnum.CREATE_ORDER,
        isOpen: false,
      },
      {
        type: ModalTypesEnum.CREATE_WALLET,
        isOpen: false,
      },
      {
        type: ModalTypesEnum.UPDATE_ORDER,
        isOpen: false,
      },
      {
        type: ModalTypesEnum.DELETE_ORDER,
        isOpen: false,
      },
    ]
  );

  const deleteOrderModal = useMemo(() => {
    const deleteModal = modals.find(modal => modal.type === ModalTypesEnum.DELETE_ORDER)
    return {
      modal: deleteModal,
      shouldShow: !!(deleteModal && deleteModal.context && deleteModal.context.order)
    }
  }, [modals])

  const updateOrderModal = useMemo(() => {
    const updateModal = modals.find(modal => modal.type === ModalTypesEnum.UPDATE_ORDER)
    return {
      modal: updateModal,
      shouldShow: !!(updateModal && updateModal.context && updateModal.context.order)
    }
  }, [modals])

  const createWalletModal = useMemo(() => {
    const createWalletModal = modals.find(modal => modal.type === ModalTypesEnum.CREATE_WALLET)
    return {
      modal: createWalletModal,
      shouldShow: !!(createWalletModal)
    }
  }, [modals])


  const turnModalOn = (Modal: ModalTypes, modalContext?: ModalContext) => {
    setModals(modals => {
      const modal = modals.find(modal => modal.type === Modal)
      if (modal) {
        modal.isOpen = true;
        modal.context = modalContext
      }
      return [...modals]
    })
  }

  const turnModalOff = (Modal: ModalTypes, modalContext?: ModalContext) => {
    setModals(modals => {
      const modal = modals.find(modal => modal.type === Modal)
      if (modal) {
        modal.isOpen = false;
        modal.context = modalContext
      }
      return [...modals]
    })
  }

  return (
    <ModalContext.Provider
      value={{ turnModalOn, turnModalOff }}
    >
     {children}
     {updateOrderModal.shouldShow && (
        <AuthenticatedModal>
          <UpdateOrderModal
            handleCloseModal={() => turnModalOff(ModalTypesEnum.UPDATE_ORDER)}
            orderToUpdate={updateOrderModal.modal!.context!.order}
          />
        </AuthenticatedModal>
     )}
     {deleteOrderModal.shouldShow && (
        <AuthenticatedModal>
          <DeleteOrderModal
            handleCloseModal={() => turnModalOff(ModalTypesEnum.DELETE_ORDER)}
            orderToDelete={deleteOrderModal.modal!.context!.order}
          />
        </AuthenticatedModal>
      )}
      {createWalletModal.shouldShow && (
        <AuthenticatedModal>
          <CreateWalletModal
            closeModal={() => turnModalOff(ModalTypesEnum.CREATE_WALLET)}
            isVisible={!!createWalletModal.modal?.isOpen}
          />
        </AuthenticatedModal>
      )}
    </ModalContext.Provider>
  )
}

export function useModals(): ModalContextData {
  const context = useContext(ModalContext);

  return context;
}
